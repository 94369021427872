import React, { useEffect } from 'react';
import IntlMessages from 'shared/utility/IntlMessages';
import { ReactComponent as InfoIcon } from '../../../../../assets/icon/InfoIcon.svg';
import { Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { useHomeStatus } from 'pages/homes/hooks/useHomeStatus';

const TimerSnackBar = ({ isOpen, timeEnded }) => {
  const { item: home } = useSelector<AppState, AppState['home']>(({ home }) => home);
  const { minutesLeft, percentageElapsed } = useHomeStatus();

  const hours = Math.floor(minutesLeft / 60);
  const minutes = Math.floor(minutesLeft % 60);
  const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')}`;

  useEffect(() => {
    if (minutesLeft < 1 && isOpen) {
      timeEnded();
    }
  }, [minutesLeft, isOpen]);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      sx={{
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: '4px',
          height: '3px',
          width: 'calc(100% - 8px)',
          background: `linear-gradient(to right, transparent 0%, #0E84F2 1%, #0E84F2 ${percentageElapsed}%, #EFECEB ${percentageElapsed}% );`
        },
        '& .MuiSnackbarContent-root': {
          backgroundColor: '#FFF',
          width: '457px',
          height: '72px',
          color: '#33302E',
          fontWeight: 500,
          fontSize: '14px',
          borderRadius: '12px',
          overflow: 'hidden'
        }
      }}
      message={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '16px',
            fontSize: '14px',
            fontWeight: 600
          }}>
          <InfoIcon />
          <IntlMessages id="tweaks.submit.timer" values={{ br: formattedTime }} />
        </div>
      }
    />
  );
};

export default TimerSnackBar;
