import { useTheme } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { LayoutDirection } from 'shared/constants/AppEnums';
interface ScrollbarsMenuInterface {
  children: JSX.Element | JSX.Element[];
  autoHide?: boolean;
  autoHideDuration?: number;
  styles?: {};
  autoHeight?: boolean;
  scrollLeft?: number;
  scrollRight?: number;
  autoHeightMin?: number | string;
  autoHeightMax?: number | string;
}
const ScrollbarsMenu = (props: ScrollbarsMenuInterface) => {
  const theme = useTheme();
  return (
    <Scrollbars
      {...props}
      autoHide={props.autoHide || false}
      autoHideDuration={props.autoHideDuration || 200}
      autoHeight={props.autoHeight || false}
      autoHeightMin={props.autoHeightMin || 0}
      autoHeightMax={props.autoHeightMax || 200}
      renderView={(props) =>
        theme.direction === LayoutDirection.RTL ? (
          <div
            {...props}
            style={{
              ...props.style,
              marginLeft: props.style.marginRight,
              marginRight: 0
            }}
          />
        ) : (
          <div {...props} />
        )
      }
      renderTrackVertical={
        theme.direction === LayoutDirection.RTL
          ? (props) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  left: 2,
                  bottom: 2,
                  top: 2,
                  borderRadius: 3
                }}
              />
            )
          : (props) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '100%',
                  top: 0,
                  right: 0,
                  borderRadius: 3
                }}
              />
            )
      }>
      {props.children}
    </Scrollbars>
  );
};

export default ScrollbarsMenu;
