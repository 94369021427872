import { IModification } from 'types/models/Modification';
import { AppActions } from '../../types';
import {
  GET_MODIFICATIONS,
  RESET_MODIFICATION_STORE,
  ADD_MODIFICATION,
  UPDATE_MODIFICATION,
  GET_LATEST_TILE_MODIFICATION_BY_ELEMENT_TYPE,
  RESET_LATEST_TILE_MODIFICATION_BY_ELEMENT_TYPE,
  DELETE_MODIFICATION,
  SET_MODIFICATIONS,
  GET_TILE_MODIFICATION_BY_ELEMENT_DBID,
  RESET_TILE_MODIFICATION_BY_ELEMENT_DBID
} from 'types/actions/Modification.actions';

const initialState: {
  item: IModification;
  items: IModification[];
  itemsFetched: boolean;
  latestTileModificationByType: IModification;
  tileModificationByDbId: IModification;
} = {
  item: null,
  items: [],
  itemsFetched: false,
  latestTileModificationByType: null,
  tileModificationByDbId: null
};

const Modification = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_MODIFICATIONS:
      return {
        ...state,
        items: action.payload,
        itemsFetched: true
      };

    case ADD_MODIFICATION: {
      return {
        ...state,
        items: [...state.items, action.payload]
      };
    }

    case UPDATE_MODIFICATION: {
      return {
        ...state,
        items: state.items.map((item) => {
          return item._id === action.payload._id ? action.payload : item;
        })
      };
    }

    case DELETE_MODIFICATION: {
      return {
        ...state,
        items: state.items.filter((item) => {
          return item._id !== action.payload;
        })
      };
    }

    case SET_MODIFICATIONS: {
      return {
        ...state,
        items: action.payload
      };
    }

    case GET_LATEST_TILE_MODIFICATION_BY_ELEMENT_TYPE: {
      return {
        ...state,
        latestTileModificationByType: action.payload
      };
    }

    case RESET_LATEST_TILE_MODIFICATION_BY_ELEMENT_TYPE: {
      return {
        ...state,
        latestTileModificationByType: null
      };
    }

    case GET_TILE_MODIFICATION_BY_ELEMENT_DBID: {
      return {
        ...state,
        tileModificationByDbId: action.payload
      };
    }

    case RESET_TILE_MODIFICATION_BY_ELEMENT_DBID: {
      return {
        ...state,
        tileModificationByDbId: null
      };
    }

    case RESET_MODIFICATION_STORE:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
export default Modification;
