import { Box, LinearProgress, Stack } from '@mui/material';
import { PixelStreamingApplicationStyle } from '@yovelsapir/lib-pixelstreamingfrontend-ui-ue5.2';
import { usePixelStreaming } from './usePixelStreaming';
import AddCommentCustomRequest from 'pages/dashboards/Model/ViewerControls/CustomRequests/AddCommentCustomRequest';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { CustomRequestMode } from 'types/models/CustomRequest';
import { IconButton } from '@mui/material';
import { ReactComponent as PlayTour } from '../assets/icon/play-tour.svg';
// import { ReactComponent as AudioForward } from '../assets/icon/audio/audio-forward.svg';
import { ReactComponent as AudioPause } from '../assets/icon/audio/pause.svg';
import { ReactComponent as AudioShare } from '../assets/icon/audio/audio-share.svg';
import { ReactComponent as AudioPlay } from '../assets/icon/audio/play.svg';
import { ReactComponent as AudioStop } from '../assets/icon/audio/stop.svg';
import emitApiServiceBuilder from './services/emit-api.service';

export const PixelStreamingApplicationStyles = new PixelStreamingApplicationStyle();
PixelStreamingApplicationStyles.setColorMode(true);
PixelStreamingApplicationStyles.applyStyleSheet();
type Props = {
  previewMode: boolean;
  viewerReady: boolean;
};

export const PixelStreamingViewer = (props: Props) => {
  const pixelStreamingApi = usePixelStreaming(props.previewMode);
  const [editCustomRequestPosition, setEditCustomRequestPosition] = useState<THREE.Vector2>(
    new THREE.Vector2(0, 0)
  );
  const { mode: customRequestMode } = useSelector<AppState, AppState['customRequest']>(
    ({ customRequest }) => customRequest
  );

  const [isTourPlayMode, setIsTourPlayMode] = useState('stop');
  const [showTourOptions, setShowTourOptions] = useState(false);
  const [linearProgressValue, setLinearProgressValue] = useState<number>(0);
  const emitApiService = emitApiServiceBuilder(pixelStreamingApi.pixelStreamingRef);

  useEffect(() => {
    emitApiService.emitEventToUnreal('setTourPlayMode', isTourPlayMode);
    console.log('tour mode: ', isTourPlayMode);

    let timer;
    const intervalStep = 250; // interval step must be a divisor of 1000
    const animationTimeInSec = 90;
    const animationTimePerIntervalStep = animationTimeInSec * (1000 / intervalStep);
    const progressStep = 100 / animationTimePerIntervalStep;

    if (isTourPlayMode === 'play') {
      timer = setInterval(() => {
        setLinearProgressValue((prev) => {
          if (prev < 100) return Math.abs(prev + progressStep);
          else {
            setIsTourPlayMode('stop');
            clearInterval(timer);
            return 0;
          }
        });
      }, intervalStep);
    } else if (isTourPlayMode === 'stop') {
      setLinearProgressValue(0);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isTourPlayMode]);

  const [editCustomRequestMenuVisible, setEditCustomRequestMenuVisible] = useState<boolean>(false);

  useEffect(() => {
    const enabled = !editCustomRequestMenuVisible;
    pixelStreamingApi.pixelStreamingRef.current.stream.config.setSettings({
      KeyboardInput: enabled,
      MouseInput: true,
      TouchInput: enabled
    });
  }, [editCustomRequestMenuVisible]);

  useEffect(() => {
    if (customRequestMode === CustomRequestMode.CLOSE) setEditCustomRequestMenuVisible(false);
  }, [customRequestMode]);

  const mouseDownForNoteInsert = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (customRequestMode === 'Edit' && !editCustomRequestMenuVisible) {
      const rect = pixelStreamingApi.pixelStreamingContainer.current.getBoundingClientRect();
      const x = e.pageX - rect.left;
      const y = e.pageY - rect.top;

      setEditCustomRequestPosition(new THREE.Vector2(x, y));
      setEditCustomRequestMenuVisible(true);

      pixelStreamingApi.pixelStreamingRef.current.stream.config.setSettings({
        KeyboardInput: false,
        MouseInput: false,
        TouchInput: false
      });
    }
  };

  return (
    <div>
      <Box
        ref={pixelStreamingApi.pixelStreamingContainer}
        onClick={(e) => {
          mouseDownForNoteInsert(e);
        }}
        sx={{
          diplay: 'flex',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: props.previewMode ? 2 : 0,
          order: props.previewMode ? 0 : 1,
          width: '100%',
          height: '100%',
          borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          // pointerEvents: 'all',
          // pointerEvents: !props.previewMode ? 'none' : 'auto',
          opacity: (props.viewerReady && !props.previewMode) || props.previewMode ? 1 : 0
        }}></Box>
      <AddCommentCustomRequest
        menuPosition={editCustomRequestPosition}
        visible={editCustomRequestMenuVisible}
        setEditCustomRequestMenuVisible={setEditCustomRequestMenuVisible}
        pixelStreamingRef={pixelStreamingApi.pixelStreamingRef}
      />
      <Stack
        direction="row"
        sx={{ ml: '2rem', mb: '2rem' }}
        style={{ position: 'absolute', top: 'auto', bottom: 0, zIndex: 111 }}>
        <IconButton onClick={() => setShowTourOptions(!showTourOptions)} sx={{ p: '8px' }}>
          <PlayTour
            fontSize={32}
            style={{ stroke: '#949290' }} // '#FF5A00'
          />
        </IconButton>
        {showTourOptions && (
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              height="100%"
              sx={{
                borderRadius: '16px',
                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                height: '40px',
                my: '8px',
                px: '17px',
                '> .MuiLinearProgress-root': {
                  backgroundColor: 'white',
                  borderRadius: '8px'
                }
              }}>
              <IconButton
                size="small"
                sx={{
                  p: 1
                }}
                onClick={() => setIsTourPlayMode(isTourPlayMode == 'play' ? 'pause' : 'play')}>
                {isTourPlayMode === 'play' ? <AudioPause /> : <AudioPlay />}
              </IconButton>
              <IconButton
                size="small"
                sx={{
                  p: 1
                }}
                onClick={() => setIsTourPlayMode('stop')}>
                <AudioStop />
              </IconButton>
              <LinearProgress
                variant="determinate"
                value={linearProgressValue}
                sx={{
                  width: '132px',
                  mr: '12px',
                  ml: '2px'
                }}
              />
              <IconButton
                size="small"
                sx={{
                  p: 1
                }}
                onClick={() => {
                  window.open(
                    'https://screenpal.com/u/zCKH/tollbrothers_demo',
                    '_blank',
                    'noreferrer'
                  );
                }}>
                <AudioShare />
              </IconButton>
            </Stack>
          </Box>
        )}
      </Stack>
    </div>
  );
};
