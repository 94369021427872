/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-nocheck

import { Scene } from 'three';

export interface LineAndLabel {
  lineEdge1: THREE.Vector3;
  lineEdge2: THREE.Vector3;
  label: string;
  id: string;
  direction: 'horizontal' | 'vertical';
}

interface CreatedLineAndLabel {
  id: string;
  line: THREE.Line;
  label: HTMLDivElement;
}

export class MarkUp3DExtension extends Autodesk.Viewing.Extension {
  private viewer: Autodesk.Viewing.GuiViewer3D;

  private lineColor = '#ff8a80';

  private scene: Scene = null; // change this to viewer.impl.sceneAfter with transparency, if you want the markup always on top.

  public createdLinesAndLabels: CreatedLineAndLabel[] = []; // array containing markup data

  private sceneName = 'markup3d';

  private overlayManager;

  constructor(viewer: Autodesk.Viewing.GuiViewer3D, options) {
    super(viewer, options);

    this.viewer = viewer;
  }

  load() {
    console.log('MarkUp3DExtension was loaded!');
    this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => this.init());

    return true;
  }

  unload() {
    console.log('MarkUp3DExtension is now unloaded!');

    return true;
  }

  sphere: THREE.Mesh;

  init() {
    this.viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => this.init());

    this.overlayManager = new Autodesk.Viewing.OverlayManager(this.viewer.impl);
    this.overlayManager.addScene(this.sceneName);

    if (!this.viewer.overlays.hasScene(this.sceneName)) {
      this.viewer.overlays.addScene(this.sceneName);
    }

    this.scene = this.viewer.impl.overlayScenes[this.sceneName].scene;
    this.camera = this.viewer.impl.camera;
    this.offset = this.viewer.model.getData().globalOffset;

    ///create spehere
    // const geometry = new THREE.SphereGeometry(0.1, 0.1, 0.1);
    // const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    // this.sphere = new THREE.Mesh(geometry, material);
    // this.scene.add(this.sphere);

    return true;
  }

  addLinesAndLabel(linesAndLabel: LineAndLabel[]) {
    linesAndLabel.forEach((lineAndLabel) => {
      if (this.createdLinesAndLabels.find((l) => l.id === lineAndLabel.id) === undefined) {
        const line = this.createLine(
          lineAndLabel.lineEdge1,
          lineAndLabel.lineEdge2,
          this.lineColor
        );
        this.scene.add(line);

        const label = this.createLabel(lineAndLabel.label, lineAndLabel.id, lineAndLabel.direction);
        this.createdLinesAndLabels.push({ id: lineAndLabel.id, line: line, label: label });
      } else {
        console.log('Line already exists');
      }
    });
  }

  clearAllLinesAndLabels() {
    this.createdLinesAndLabels.forEach((lineAndLabel) => {
      this.scene.remove(lineAndLabel.line);
      this.viewer.container.removeChild(lineAndLabel.label);
    });
    this.createdLinesAndLabels = [];
  }

  createLine(edge1: THREE.Vector3, edge2: THREE.Vector3, color: number): THREE.Line {
    const geometry = new THREE.Geometry();
    geometry.vertices.push(edge1, edge2);
    const material = new THREE.LineBasicMaterial({ color: color });
    const line = new THREE.Line(geometry, material);
    return line;
  }

  createLabel(text: string, id: string, direction: string): HTMLDivElement {
    const div = document.createElement('div');
    div.id = id;
    div.style.cssText = this.labelStyle;
    div.innerHTML = text;
    div.style.position = 'absolute';
    div.style.fontSize = '10px';
    div.style.verticalAlign = 'middle';
    div.style.left = '0px';
    div.style.textAlign = 'center';
    div.style.top = '0px';
    if (direction === 'horizontal') div.style.width = '50px';
    if (direction === 'vertical') div.style.width = '20px';
    if (direction === 'horizontal') div.style.height = '20px';
    if (direction === 'vertical') div.style.height = '50px';
    div.style.color = 'black';
    // div.style.backgroundColor = 'black';
    // div.style.opacity = '0.5';
    div.style.borderRadius = '5px';
    div.style.pointerEvents = 'none';
    div.style.zIndex = '0';
    this.viewer.container.appendChild(div);
    return div;
  }

  updateLinesAndLabels(linesAndLabels: LineAndLabel[], hostNormal: THREE.Vector3) {
    linesAndLabels.forEach((lineAndLabel) => {
      const lineAndLabelFound = this.createdLinesAndLabels.find((l) => l.id === lineAndLabel.id);
      if (lineAndLabelFound) {
        const endPosition = lineAndLabel.lineEdge2;
        if (hostNormal.z !== 0) {
          endPosition.z = lineAndLabel.lineEdge1.z;
        }

        lineAndLabelFound.line.geometry.vertices[0] = lineAndLabel.lineEdge1;
        lineAndLabelFound.line.geometry.vertices[1] = endPosition;
        lineAndLabelFound.line.geometry.verticesNeedUpdate = true;
        lineAndLabelFound.label.innerHTML = lineAndLabel.label;

        this.positionLabelInMiddleOfLine(lineAndLabelFound);
      } else {
        console.log('Line not found');
      }
    });
  }

  positionLabelInMiddleOfLine(lineAndLabel: CreatedLineAndLabel) {
    const middlePosition = lineAndLabel.line.geometry.vertices[0]
      .clone()
      .add(lineAndLabel.line.geometry.vertices[1])
      .divideScalar(2);

    const canvasPos = this.viewer.worldToClient(middlePosition);

    // if (lineAndLabel.id === 'linea') this.sphere.position.copy(middlePosition);

    const labelWidth = lineAndLabel.label.offsetWidth / 2;
    const labelHeight = lineAndLabel.label.offsetHeight / 2;

    lineAndLabel.label.style.left = canvasPos.x - labelWidth + 'px';
    lineAndLabel.label.style.top = canvasPos.y - labelHeight + 'px';
  }

  static register() {
    const extensionName = 'TweaksxMarkUp';
    Autodesk.Viewing.theExtensionManager.registerExtension(extensionName, MarkUp3DExtension);
    return extensionName;
  }
}
