import React from 'react';
import IntlMessages from 'shared/utility/IntlMessages';
import { ReactComponent as AlertCircle } from '../../../../../assets/icon/AlertCircle.svg';
import { Snackbar } from '@mui/material';

const UnderReviewSnackBar = ({ isOpen }) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      sx={{
        '& .MuiSnackbarContent-root': {
          backgroundColor: '#FFF',
          width: '571px',
          height: '72px',
          color: '#33302E',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '20px',
          borderRadius: '12px'
        }
      }}
      message={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            gap: '16px',
            fontSize: '14px',
            fontWeight: 600
          }}>
          <AlertCircle />
          <IntlMessages id="actions.underReview" />
        </div>
      }
    />
  );
};

export default UnderReviewSnackBar;
