import { Box, Button } from '@mui/material';
import { Dispatch, FC, MutableRefObject, SetStateAction } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import BaseModal from 'shared/core/BaseModal';
import IntlMessages from 'shared/utility/IntlMessages';

type Props = {
  signatureDataUrl: string;
  setSignatureDataUrl: Dispatch<SetStateAction<string>>;
  signaturePadRef: MutableRefObject<any>;
  pdfSignatureModalIsOpen: boolean;
  setPdfSignatureModalIsOpen: Dispatch<SetStateAction<boolean>>;
};

const SignatureDialog: FC<Props> = ({
  signatureDataUrl,
  signaturePadRef,
  setSignatureDataUrl,
  pdfSignatureModalIsOpen,
  setPdfSignatureModalIsOpen
}) => {
  const saveSignature = () => {
    if (signaturePadRef.current !== null) {
      const _signatureDataUrl = signaturePadRef.current.toDataURL();
      setSignatureDataUrl(_signatureDataUrl);
      setPdfSignatureModalIsOpen(false);
    }
  };

  const clearSignature = () => {
    if (signaturePadRef.current !== null && !signaturePadRef.current.isEmpty()) {
      signaturePadRef.current.clear();
    }
  };

  const removeSignature = () => {
    clearSignature();
    setSignatureDataUrl('');
  };

  return (
    <BaseModal
      title={<IntlMessages id="common.please_sign_here" />}
      open={pdfSignatureModalIsOpen}
      onClose={() => setPdfSignatureModalIsOpen(false)}>
      <Box>
        {signatureDataUrl ? (
          <img
            src={signatureDataUrl}
            alt="preview"
            style={{
              pointerEvents: 'none'
            }}
          />
        ) : null}
      </Box>
      <Box border="1px solid #999">
        <SignaturePad
          ref={signaturePadRef}
          redrawOnResize
          debounceInterval={200}
          options={{ dotSize: 3 }}
        />
      </Box>
      <Box marginTop={4}>
        <Button variant="contained" onClick={removeSignature}>
          <IntlMessages id="common.remove" />
        </Button>
        <Button variant="contained" onClick={clearSignature} sx={{ marginLeft: 4 }}>
          <IntlMessages id="common.clear" />
        </Button>
        <Button variant="contained" onClick={saveSignature} sx={{ marginLeft: 4 }}>
          <IntlMessages id="common.save" />
        </Button>
      </Box>
    </BaseModal>
  );
};

export default SignatureDialog;
