import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import AppTextField from 'shared/core/AppFormComponents/AppTextField';
import IntlMessages from 'shared/utility/IntlMessages';
import { Form, Formik } from 'formik';
import { ICategory, IRoomPackage } from './RoomListItem';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { setTemplate } from 'redux/actions/Viewer';
import { messageSeverity } from 'shared/constants/AppConst';
import { useSnackbar } from 'notistack';
import { updateModelTemplatePackage } from 'redux/actions';
import { NumbersService } from 'services/numbers/number.service';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import Scrollbars from 'react-custom-scrollbars-2';
import { RoomPackageCategory } from './RoomPackageCategory';

const ModalRoomPackageDetail = ({
  openModal,
  setOpenPackageEditModal,
  currentPackageToEdit
}: {
  openModal: boolean;
  setOpenPackageEditModal: (open: boolean) => void;
  currentPackageToEdit: IRoomPackage;
}) => {
  const [open, setOpen] = useState(false);
  const { modelTemplate } = useSelector<AppState, AppState['model']>(({ model }) => model);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { linkedFileNamesMap } = useSelector<AppState, AppState['viewer']>(({ viewer }) => viewer);

  const getOnlyPackagesName = (packageName: string): string[] => {
    const splitPackageName = packageName.split(',');
    const getAllNamesWhoNotNumbers = splitPackageName.filter((name) => isNaN(Number(name)));
    return getAllNamesWhoNotNumbers;
  };

  const getOnlyNumbersFromString = (packageName: string): number[] => {
    const splitPackageName = packageName.split(',');
    const getNumbers: number[] = splitPackageName
      .filter((name) => !isNaN(Number(name)))
      .map((name) => Number(name));
    return getNumbers;
  };

  const getRevitIdsFromLinkedFileNamesMap = (
    packagesName: string[],
    roomName: string
  ): number[] => {
    const revitIds: number[] = [];
    for (const keys in linkedFileNamesMap) {
      console.log(keys);
      packagesName.forEach((packageName) => {
        if (keys.includes(packageName) && keys.includes(roomName)) {
          const revitId = linkedFileNamesMap[keys];

          revitId.dbidsToHideFromBase.forEach((revitId) => {
            revitIds.push(revitId);
          });
        }
      });
    }

    return revitIds;
  };

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <>
      {open && (
        <Modal
          keepMounted
          open={open}
          onClose={() => {
            setOpenPackageEditModal(false);
          }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description">
          <Box
            sx={{
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              width: 700,
              maxWidth: '100%',
              height: 630,
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: '15px',
              py: 6
            }}>
            <Scrollbars>
              <Box px={6}>
                <Typography
                  style={{ fontWeight: 'bold', fontSize: 15, paddingBottom: 8 }}
                  component="h2">
                  {currentPackageToEdit?.room + ' ' + currentPackageToEdit?.name}
                </Typography>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setOpenPackageEditModal(false);
                  }}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                  }}>
                  <CloseIcon />
                </IconButton>
                <Divider />
                <Formik
                  validateOnChange={true}
                  initialValues={{
                    description: currentPackageToEdit?.description,
                    summary: currentPackageToEdit?.summary,
                    title: currentPackageToEdit?.title,
                    cost: currentPackageToEdit?.price,
                    revitIdsToHideFromBase: currentPackageToEdit?.revitIdsToHideFromBase,
                    categories: JSON.stringify(currentPackageToEdit?.categories)
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    let revitIds;
                    if (values.revitIdsToHideFromBase) {
                      const packagesFromString = getOnlyPackagesName(values.revitIdsToHideFromBase);
                      const getOnlyRevitIdsFromString = getOnlyNumbersFromString(
                        values.revitIdsToHideFromBase
                      );

                      revitIds = getRevitIdsFromLinkedFileNamesMap(
                        packagesFromString,
                        currentPackageToEdit?.room
                      ).concat(getOnlyRevitIdsFromString);
                    }

                    let packageId = '';

                    const categories = JSON.parse(values.categories) as ICategory[];

                    const updatedPackage: IRoomPackage = {
                      description: values.description,
                      summary: values.summary,
                      title: values.title,
                      price: values.cost,
                      revitIdsToHideFromBase: values.revitIdsToHideFromBase
                        ? values.revitIdsToHideFromBase
                        : '',
                      name: currentPackageToEdit.name,
                      room: currentPackageToEdit.room,
                      _id: currentPackageToEdit._id,
                      categories,
                      revitIds: revitIds
                    };

                    const modelTemplateUpdate = modelTemplate;
                    const modelPackages = modelTemplate.packages.map(({ ...rest }) => rest);
                    const packageIndex = modelPackages.findIndex(
                      (p) => p._id === currentPackageToEdit._id
                    );

                    if (packageIndex !== -1) {
                      packageId = modelTemplate.packages[packageIndex]._id;
                    }

                    delete updatedPackage._id;

                    updateModelTemplatePackage(
                      modelTemplateUpdate._id,
                      packageId,
                      updatedPackage
                    ).then((data) => {
                      updatedPackage._id = data.data._id;
                      if (packageIndex !== -1) {
                        modelPackages[packageIndex] = JSON.parse(JSON.stringify(updatedPackage));
                      } else {
                        modelPackages.push(updatedPackage);
                      }
                      modelTemplateUpdate.packages = modelPackages;

                      dispatch(setTemplate(modelTemplateUpdate));
                      setOpenPackageEditModal(false);

                      setSubmitting(false);
                      enqueueSnackbar('Successfully Updated.', {
                        variant: messageSeverity.SUCCESS,
                        autoHideDuration: 3000
                      });
                    });
                  }}>
                  {({ values, isSubmitting, handleChange }) => {
                    return (
                      <Form noValidate autoComplete="off">
                        <TextField
                          id="roomPackageName"
                          label="Title"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          multiline
                          rows={1}
                          fullWidth
                          margin="normal"
                          size="small"
                          variant="outlined"
                        />

                        <TextField
                          id="roomPackageDescriptionLong"
                          label="Summary"
                          name="summary"
                          value={values.summary}
                          onChange={handleChange}
                          multiline
                          rows={2}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          size="small"
                        />

                        <Typography
                          sx={{
                            fontSize: 11,
                            color: 'rgb(107, 114, 128)',
                            ml: 3
                          }}>
                          Description
                        </Typography>

                        <MDEditor
                          data-color-mode="light"
                          title="Description"
                          textareaProps={{
                            id: 'roomPackageDescription',
                            name: 'description'
                          }}
                          value={values.description}
                          onChange={handleChange('description')}
                          previewOptions={{
                            rehypePlugins: [[rehypeSanitize]]
                          }}
                        />

                        <TextField
                          id="revitIdsToHideFromBase"
                          label="Hiding Revit Ids"
                          name="revitIdsToHideFromBase"
                          value={values.revitIdsToHideFromBase}
                          onChange={handleChange}
                          multiline
                          rows={4}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          size="small"
                        />

                        <RoomPackageCategory
                          packageId={currentPackageToEdit?._id}
                          onCategoriesChange={(categories) => {
                            handleChange('categories')(JSON.stringify(categories));
                          }}
                        />

                        <Box>
                          <AppTextField
                            size="small"
                            name="cost"
                            label={<IntlMessages id="common.cost" />}
                            type="number"
                            onChange={handleChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {NumbersService.getCurrencySymbol()}
                                </InputAdornment>
                              )
                            }}
                          />
                        </Box>

                        <Box marginTop={2}>
                          <Button
                            disabled={isSubmitting}
                            sx={{
                              width: '100px'
                            }}
                            color="primary"
                            variant="contained"
                            type="submit">
                            <IntlMessages id="common.save" />{' '}
                          </Button>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Scrollbars>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ModalRoomPackageDetail;
