import { CloseOutlined } from '@mui/icons-material';
import { Snackbar, IconButton } from '@mui/material';
import React, { useState } from 'react';
import IntlMessages from 'shared/utility/IntlMessages';

const NavigationSnackBar = () => {
  const [isOpen, setOpen] = useState<boolean>(true);

  const action = (
    <IconButton
      onClick={() => {
        setOpen(false);
      }}
      color="inherit"
      size="small">
      <CloseOutlined fontSize="small" />
    </IconButton>
  );

  return (
    <>
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        message={<IntlMessages id="tweaks.viewer.tooltip.navigation" />}
        action={action}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          '& .MuiSnackbarContent-root': {
            background: 'linear-gradient( #ff5900 9%, #FFEEE5 10%)',
            width: '700px',
            height: '63px',
            color: '#5A55F4',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            borderRadius: '8px'
          }
        }}
        style={{
          position: 'absolute'
        }}
      />
    </>
  );
};

export default NavigationSnackBar;
