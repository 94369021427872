import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IUser } from 'types/models/User';
import { DataGrid } from '@mui/x-data-grid';

export interface IUsersSelectionProps {
  users: IUser[];
  onSelectionChanged: (users: IUser[]) => void;
  loading: boolean;
}

const UsersSelector = (props: IUsersSelectionProps) => {
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const { messages } = useIntl();

  useEffect(() => {
    props.onSelectionChanged(selectedUsers);
  }, [selectedUsers]);

  const columns = [
    { field: '_id', hide: true },
    {
      field: 'displayName',
      headerName: messages['common.userName'] as string,
      flex: 1
    },
    {
      field: 'email',
      headerName: messages['common.emailAddress'] as string,
      flex: 1
    }
  ];

  return (
    <DataGrid
      rows={props.users}
      columns={columns}
      pageSize={100}
      rowsPerPageOptions={[100]}
      checkboxSelection
      disableColumnSelector={true}
      hideFooterSelectedRowCount={true}
      loading={props.loading}
      onSelectionModelChange={(ids) => {
        const selectedIDs = new Set(ids);
        const selectedRowData = props.users.filter((user) => selectedIDs.has(user._id));
        setSelectedUsers(selectedRowData);
      }}
      getRowId={(row) => row._id}
    />
  );
};

export default UsersSelector;
