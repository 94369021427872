import Table from '@mui/material/Table';
// import TableHead from "@mui/material/TableHead";
import TableBody from '@mui/material/TableBody';
// import TableHeading from "./RoomsListHeader";
import TableItem from './RoomListItem';
import AppTableContainer from 'shared/core/AppTableContainer';
import EmptyListItem from './EmptyListItem';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'redux/store';
import { useEffect } from 'react';
import { setRooms } from 'redux/actions/Viewer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CircularProgress from '@mui/material/CircularProgress';

const RoomsList = () => {
  const dispatch = useDispatch();
  const { rooms } = useSelector<AppState, AppState['viewer']>(({ viewer }) => viewer);

  useEffect(() => {
    return () => {
      dispatch(setRooms(null));
    };
  }, []);

  return (
    <AppTableContainer sxStyle={{ maxHeight: 480 }}>
      <Table>
        <TableBody
          sx={{
            borderBottom: '0 none'
          }}>
          {rooms ? (
            <>
              {rooms.length > 0 && <TableItem rooms={rooms} />}
              {rooms.length === 0 && <EmptyListItem />}
            </>
          ) : (
            <TableRow>
              <TableCell style={{ textAlign: 'center', borderBottom: '0 none' }} colSpan={4}>
                <CircularProgress size={20} />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </AppTableContainer>
  );
};

export default RoomsList;
