import { Application } from '@yovelsapir/lib-pixelstreamingfrontend-ui-ue5.2';
import { Config, PixelStreaming } from '@yovelsapir/lib-pixelstreamingfrontend-ue5.2';
import { useEffect, useRef } from 'react';
import { isLocalhost } from 'shared/utility/Utils';
import { psConfig } from './config';
import { useHandlers } from './useHandlers';
import { useJWTAuth } from 'services/auth/jwt-auth/JWTAuthProvider';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { useUnrealEventListener } from './useUnrealEventListener';

export const usePixelStreaming = (previewMode: boolean) => {
  const { user } = useJWTAuth();
  const pixelStreamingContainer = useRef<HTMLDivElement>(null);
  const pixelStreamingRef = useRef<Application>(null);
  const isDebugMode = useRef<boolean>(isLocalhost || false);
  const { item: home } = useSelector<AppState, AppState['home']>(
    ({ home: homeInstance }) => homeInstance
  );

  const {
    toggleKeyboardIfProviewModeStateChange,
    addMatchmakerHandler,
    addAfkHandler,
    addStreamLoadingHandler,
    addResponseListenerHandler,
    addStatsHandler
  } = useHandlers(pixelStreamingRef);

  useUnrealEventListener(pixelStreamingRef);

  const setAppApplication = (application: Application) => {
    application.playOverlay.textElement.setAttribute(
      'src',
      'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0MXB4IiBoZWlnaHQ9IjI0OXB4IiBzdHlsZT0ic2hhcGUtcmVuZGVyaW5nOmdlb21ldHJpY1ByZWNpc2lvbjsgdGV4dC1yZW5kZXJpbmc6Z2VvbWV0cmljUHJlY2lzaW9uOyBpbWFnZS1yZW5kZXJpbmc6b3B0aW1pemVRdWFsaXR5OyBmaWxsLXJ1bGU6ZXZlbm9kZDsgY2xpcC1ydWxlOmV2ZW5vZGQiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KPGc+PHBhdGggc3R5bGU9Im9wYWNpdHk6MC45NzkiIGZpbGw9IiMwMDAwMDAiIGQ9Ik0gLTAuNSwtMC41IEMgMC41LC0wLjUgMS41LC0wLjUgMi41LC0wLjVDIDE5LjEyODEsOC45Mzk5NyAzNi4xMjgxLDE3Ljc3MzMgNTMuNSwyNkMgMTE1LjYwNSw1OC43MTgyIDE3Ny45MzgsOTAuODg0OSAyNDAuNSwxMjIuNUMgMjQwLjUsMTIzLjUgMjQwLjUsMTI0LjUgMjQwLjUsMTI1LjVDIDE2MS4xMDEsMTY2LjUzMiA4MS43Njc2LDIwNy41MzIgMi41LDI0OC41QyAxLjUsMjQ4LjUgMC41LDI0OC41IC0wLjUsMjQ4LjVDIC0wLjUsMTY1LjUgLTAuNSw4Mi41IC0wLjUsLTAuNSBaIi8+PC9nPgo8L3N2Zz4K'
    );

    // remove settings and stats button only in production/staging mode
    if (!isDebugMode.current) {
      application.uiFeaturesElement.querySelector('#controls #settingsBtn').remove();
      application.uiFeaturesElement.querySelector('#controls #statsBtn').remove();
      application.uiFeaturesElement.querySelector('#connection').remove();
    }

    // Don't request a streamer list from the server when the page loads. do it manually on matchmaker event (reset the event handler to an empty event target)
    application.stream.webSocketController.onOpen = new EventTarget();

    // Override the onClose event handler with an empty event target to fix a bug of reconnecting to the server after the connection is closed
    application.stream.webSocketController.onClose = new EventTarget();
  };

  useEffect(() => {
    const cleanups = [];

    if (pixelStreamingContainer.current) {
      if (!previewMode && pixelStreamingRef.current) {
        pixelStreamingRef.current.stream.disconnect();
      }
      if (pixelStreamingContainer.current.children.length === 0) {
        const config = new Config();

        config.setSettings(psConfig);

        // Create a Native DOM delegate instance that implements the Delegate interface class
        const stream = new PixelStreaming(config);

        const application = new Application({
          stream
        });

        pixelStreamingRef.current = application;

        setAppApplication(application);

        addMatchmakerHandler();

        const getModelByTemaplte = () => {
          console.log('getModelByTemaplte', home?.modelTemplate?._id.toString());
          return home?.modelTemplate?._id.toString();
        };

        const addResponseListenerHandlerCleanup = addResponseListenerHandler();
        const afkHandlerCleanup = addAfkHandler(previewMode);
        const addStreamLoadingHandlerCleanup = addStreamLoadingHandler(getModelByTemaplte(), user);
        const addStatsHandlerCleanup = addStatsHandler();

        cleanups.push(afkHandlerCleanup);
        cleanups.push(addStreamLoadingHandlerCleanup);
        cleanups.push(addResponseListenerHandlerCleanup);
        cleanups.push(addStatsHandlerCleanup);

        pixelStreamingContainer.current.appendChild(application.rootElement);
      } else {
        pixelStreamingRef.current.disconnectOverlay.show();
      }

      toggleKeyboardIfProviewModeStateChange(previewMode);
    }

    return () => {
      try {
        cleanups.forEach((cleanup) => cleanup());
      } catch (e) {}

      try {
        pixelStreamingRef.current.stream.disconnect();
      } catch (e) {}
    };
  }, [previewMode, user?.email]);

  return {
    pixelStreamingContainer,
    pixelStreamingRef
  };
};
