import { IPaginatedResults } from 'types/models/Common';
import { IUser } from 'types/models/User';

export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const RESET_USER_STORE = 'RESET_USER_STORE';
export const LOADING_USERS = 'LOADING_USERS';

export interface GetUserAction {
  type: typeof GET_USER;
  payload: IUser;
}

export interface GetUsersAction {
  type: typeof GET_USERS;
  payload: IPaginatedResults<IUser>;
}

export interface CreateUserAction {
  type: typeof CREATE_USER;
  payload: IUser;
}

export interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: IUser;
}

export interface DeleteUserAction {
  type: typeof DELETE_USER;
}

export interface ResetUserStoreAction {
  type: typeof RESET_USER_STORE;
}

export interface LoadingUsersAction {
  type: typeof LOADING_USERS;
}

export type UserActionTypes =
  | GetUserAction
  | GetUsersAction
  | CreateUserAction
  | UpdateUserAction
  | DeleteUserAction
  | ResetUserStoreAction
  | LoadingUsersAction;
