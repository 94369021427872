import { Dispatch, FC, MutableRefObject, SetStateAction, useState } from 'react';
import { DraggableElement, DraggableElementType } from 'types/common.types';
import { DraggableComponent } from './DraggableComponent';

type Props = {
  boxRef: MutableRefObject<any>;
  draggableElements: DraggableElement<DraggableElementType>[];
  setDraggableElements: Dispatch<SetStateAction<DraggableElement<DraggableElementType>[]>>;
};

export const DraggableComponentList: FC<Props> = ({
  boxRef,
  draggableElements,
  setDraggableElements
}) => {
  const [activeDragElement, setActiveDragElement] = useState<string>();

  const onUpdateElement = (elementId: string) => {
    return (updateProps: DraggableElement<DraggableElementType>) => {
      setDraggableElements((prev) => {
        const findIndex = prev.findIndex((e) => e.elementId === elementId);
        if (findIndex !== -1) {
          prev[findIndex] = { ...prev[findIndex], ...updateProps };
        }
        return prev;
      });
    };
  };

  return (
    <>
      {draggableElements.map((element) => (
        <DraggableComponent
          key={element.elementId}
          pageNumber={element.pageNumber}
          coords={element.coords}
          width={50}
          height={25}
          type={element.type}
          previewUrl={element.type === 'IMAGE' ? element.previewUrl : undefined}
          elementId={element.elementId}
          containerRef={boxRef}
          style={{
            pointerEvents:
              activeDragElement === undefined
                ? 'unset'
                : element.elementId === activeDragElement
                ? 'unset'
                : 'none'
          }}
          onUpdateElement={onUpdateElement(element.elementId)}
          onRemoveCallback={(elementId) => {
            setDraggableElements((prev) => prev.filter((elm) => elementId !== elm.elementId));
          }}
          onDragIsActiveCallback={(elementId) => {
            setActiveDragElement(elementId);
          }}
        />
      ))}
    </>
  );
};
