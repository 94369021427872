import { IModel } from 'types/models/Model';

import {
  GET_MODEL,
  GET_MODEL_MODEL_TEMPLATES,
  RESET_MODEL_STORE,
  LOADING_MODEL,
  GET_MODEL_TEMPLATE
} from 'types/actions/Model.actions';

import { AppActions } from '../../types';
import { IModelTemplate } from 'types/models/ModelTemplate';

const initialState: {
  item: IModel;
  modelTemplates: IModelTemplate[] | null;
  loading: boolean;
  modelTemplate: IModelTemplate;
} = {
  item: null,
  loading: false,
  modelTemplates: null,
  modelTemplate: null
};

const Model = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_MODEL:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case GET_MODEL_MODEL_TEMPLATES:
      return {
        ...state,
        modelTemplates: action.payload,
        loading: false
      };

    case LOADING_MODEL:
      return {
        ...state,
        loading: true
      };

    case RESET_MODEL_STORE:
      return {
        ...initialState
      };

    case GET_MODEL_TEMPLATE:
      return {
        ...state,
        modelTemplate: action.payload,
        loading: false
      };

    default:
      return state;
  }
};
export default Model;
