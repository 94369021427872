import { useDispatch } from 'react-redux';
import { setSelectedRoom } from 'redux/actions/Viewer';
import { ViewerEvents } from 'shared/constants/AppConst';
import { scrollVisibilityApiType } from 'types/common.types';
import { dispatch } from 'use-bus';
import isEmpty from 'lodash/isEmpty';
import { store } from 'App';

export const useGoToRoom = () => {
  const reduxDispatch = useDispatch();

  const handleGoToRoom = (room) => {
    const roomCameras = store.getState().viewer.roomCameras;
    const roomCamera = roomCameras.find((_roomCamera) => _roomCamera.roomId === room.id)?.camera;

    if (roomCamera) {
      dispatch({
        type: ViewerEvents.GoToRoom,
        payload: {
          ...room,
          camera: roomCamera
        }
      });
    } else {
      dispatch({
        type: ViewerEvents.GoToRoom,
        payload: room
      });
    }
  };

  const handleMenuItemClick = (
    index: number,
    dragging?: boolean,
    scrollbarApiRef?: React.MutableRefObject<scrollVisibilityApiType>
  ) => {
    const rooms = store.getState().viewer.rooms;

    if (dragging || isEmpty(rooms)) {
      return false;
    }

    reduxDispatch(setSelectedRoom(rooms[index]?.id, index, rooms[index]));
    handleGoToRoom(rooms[index]);

    // Scroll to center
    if (scrollbarApiRef) {
      const currentElementId = scrollbarApiRef.current?.getItemByIndex(index);
      scrollbarApiRef.current?.scrollToItem(currentElementId, 'smooth', 'center');
    }
  };

  return { handleMenuItemClick };
};
