import mixpanel from 'mixpanel-browser';
import { IUser } from 'types/models/User';

class BIService {
  static _instance: BIService;

  private env: string;

  private userId: string;

  constructor() {
    this.env = process.env.REACT_APP_ENVIRONMENT;
    const token = process.env.REACT_APP_BI_SERVICE_TOKEN;

    if (!this.isDevelopment()) {
      mixpanel.init(token, {
        debug: this.isProduction() ? false : true,
        track_pageview: true,
        persistence: 'localStorage'
      });
    }
  }

  static getInstance(): BIService {
    if (!this._instance) {
      this._instance = new BIService();
    }
    return this._instance;
  }

  setUser(user: IUser) {
    if (!this.isDevelopment()) {
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $name: user.displayName,
        $email: user.email,
        Role: user.role,
        CompanyUser: user.email.includes('@tweaks')
      });
    }

    // mixpanel.register({
    //   age: 28,
    //   gender: 'male',
    //   source: 'facebook'
    // });
  }

  logEvent(event, context, properties?) {
    const event_properties = {
      environment: this.env,
      context,
      ...properties
    };
    if (this.isDevelopment()) {
      console.log('[biService dev]', event, event_properties);
      return;
    }
    mixpanel.track(event, event_properties);
  }

  reset() {
    if (!this.isDevelopment()) {
      mixpanel.reset();
    }
  }

  isDevelopment() {
    return this.env === 'development' || this.env == null;
  }

  isProduction() {
    return this.env === 'production';
  }
}

export default BIService;
