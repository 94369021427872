import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Params } from 'react-router-dom';
import { getProject, getProjectFile, resetProjectStore } from 'redux/actions';
import { AppState } from 'redux/store';
import AppCard from 'shared/core/AppCard';
import { Fonts } from 'shared/constants/AppEnums';
import AppLoader from 'shared/core/AppLoader';
import Grid from '@mui/material/Grid';
import AppGridContainer from 'shared/core/AppGridContainer';
import FilesDropzone from './FilesDropzone';

const FilesUploadWrapper = () => {
  const { id, file_id: fileId } = useParams<Readonly<Params>>();
  const dispatch = useDispatch();

  const { item: project, loading } = useSelector<AppState, AppState['project']>(
    ({ project }) => project
  );

  const { projectFile } = useSelector<AppState, AppState['project']>(({ project }) => project);

  const fetchFileData = () => {
    dispatch(getProjectFile(id, fileId));
  };

  useEffect(() => {
    if (id) dispatch(getProject(id));
    if (fileId) {
      fetchFileData();
    }

    return () => {
      dispatch(resetProjectStore());
    };
  }, []);

  return (
    <>
      {!loading && (!fileId || projectFile?._id) ? (
        <AppGridContainer>
          <Grid item xs={12}>
            <Box
              component="h2"
              sx={{
                fontSize: 16,
                color: 'text.primary',
                fontWeight: Fonts.SEMI_BOLD,
                mb: {
                  xs: 2,
                  lg: 4
                }
              }}>
              {project?.projectName}
            </Box>
            <AppCard>
              <Box>
                <FilesDropzone project={project} file={projectFile} onEdit={fetchFileData} />
              </Box>
            </AppCard>
          </Grid>
        </AppGridContainer>
      ) : (
        <AppLoader />
      )}
    </>
  );
};

export default FilesUploadWrapper;
