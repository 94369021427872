import React from 'react';
import IntlMessages from 'shared/utility/IntlMessages';

import { Fonts } from 'shared/constants/AppEnums';

import Table from '@mui/material/Table';
import AppTableContainer from 'shared/core/AppTableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';

import { IModelTemplate } from 'types/models/ModelTemplate';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { grey } from '@mui/material/colors';
import ModelTemplatesTableRow from './ModelTemplatesTableRow';
import { IProject } from 'types/models/Project';
import { IModel } from 'types/models/Model';

interface IModelTemplatesTable {
  data: IModelTemplate[];
  project: IProject;
  model: IModel;
}
const ModelsTemplateTable: React.FC<IModelTemplatesTable> = ({ data, project, model }) => {
  return (
    <AppTableContainer sxStyle={{ maxHeight: 480 }}>
      <Table>
        <TableHead
          sx={{
            borderBottom: '0 none'
          }}>
          <TableRow
            sx={{
              borderBottom: '0 none',
              color: grey[500],
              '& .tableCell': {
                borderBottom: '0 none',
                fontSize: 13,
                padding: { xs: 2, xl: 4 },
                fontWeight: Fonts.BOLD,
                '&:first-of-type': {
                  pl: 2
                },
                '&:last-of-type': {
                  pr: 2
                }
              }
            }}>
            <TableCell className="tableCell">
              <IntlMessages id="models.name" />
            </TableCell>
            <TableCell className="tableCell">
              <IntlMessages id="tweaks.progress" />
            </TableCell>
            <TableCell align="right" className="tableCell">
              <IntlMessages id="tweaks.default" />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          sx={{
            borderBottom: '0 none'
          }}>
          {data.map((row, index) => (
            <ModelTemplatesTableRow key={index} row={row} project={project} model={model} />
          ))}
        </TableBody>
      </Table>
    </AppTableContainer>
  );
};

export default ModelsTemplateTable;
