import { Delete, DragIndicator } from '@mui/icons-material';
import { IconButton, Input } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Coords, DraggableComponentProps, WrapperProps } from 'types/common.types';

export const DraggableComponent: FC<DraggableComponentProps & WrapperProps> = ({
  elementId,
  type,
  previewUrl,
  containerRef,
  width,
  height,
  onUpdateElement,
  onRemoveCallback,
  onDragIsActiveCallback,
  coords,
  ...rest
}) => {
  const [textInput, setTextInput] = useState<string>('Initial text');
  const elementRef = useRef<HTMLDivElement>(null);
  const dragIconRef = useRef<HTMLDivElement>(null);
  const isClicked = useRef<boolean>(false);
  const isFocusRef = useRef<boolean>(false);
  const [isFocusEffect, setIsFocusEffect] = useState<boolean>(false);
  const startX = useMemo(() => coords.startX, [coords.startX]);
  const startY = useMemo(() => coords.startY, [coords.startY]);

  const coordsRef = useRef<Coords>({
    startX: startX,
    startY: startY,
    lastX: startX,
    lastY: startY
  });

  const isInBounds = (nextX: number, nextY: number) => {
    if (!containerRef.current || !elementRef.current) return false;
    const leftBound = containerRef.current.clientWidth - elementRef.current.clientWidth;
    const bottomBound = containerRef.current.clientHeight - elementRef.current.clientHeight;
    return nextY > 0 && nextY < bottomBound && nextX > 0 && nextX < leftBound;
  };

  useEffect(() => {
    if (elementRef.current) {
      onUpdateElement({
        elementRef
      });
    }
  }, [elementRef.current]);

  useEffect(() => {
    if (type === 'TEXT') {
      onUpdateElement({
        value: textInput
      });
    }
  }, [textInput]);

  useEffect(() => {
    if (isFocusEffect && isClicked.current) {
      onDragIsActiveCallback(elementId);
    }

    return () => {
      onDragIsActiveCallback(undefined);
    };
  }, [isFocusEffect]);

  useEffect(() => {
    if (!elementRef.current || !containerRef.current || !dragIconRef) return;

    const element = elementRef.current;
    const container = containerRef.current;
    const dragButton = dragIconRef.current;

    element.style.top = `${startY}px`;
    element.style.left = `${startX}px`;

    const onMouseDown = (e: MouseEvent) => {
      if (isClicked.current) return;
      isClicked.current = true;
      coordsRef.current.startX = e.clientX;
      coordsRef.current.startY = e.clientY;

      onUpdateElement({
        coords: coordsRef.current
      });
    };

    const onMouseUp = () => {
      if (!isClicked.current) return;
      isClicked.current = false;
      coordsRef.current.lastX = element.offsetLeft;
      coordsRef.current.lastY = element.offsetTop;

      onUpdateElement({
        coords: coordsRef.current
      });
    };

    const onMouseMove = (e: MouseEvent) => {
      if (!isClicked.current || !isFocusRef.current) return;

      const nextX = e.clientX - coordsRef.current.startX + coordsRef.current.lastX;
      const nextY = e.clientY - coordsRef.current.startY + coordsRef.current.lastY;

      if (isInBounds(nextX, nextY)) {
        element.style.left = `${nextX}px`;
        element.style.top = `${nextY}px`;
      }
    };

    const onMouseFocusIn = () => {
      if (isFocusRef.current) return;
      isFocusRef.current = true;
      setIsFocusEffect(true);
    };

    const onMouseFocusOut = () => {
      if (!isFocusRef.current) return;
      isFocusRef.current = false;
      setIsFocusEffect(false);
    };

    dragButton.addEventListener('focusin', onMouseFocusIn);
    dragButton.addEventListener('focusout', onMouseFocusOut);
    element.addEventListener('mousedown', onMouseDown);
    element.addEventListener('mouseup', onMouseUp);
    container.addEventListener('mousemove', onMouseMove);
    container.addEventListener('mouseleave', onMouseUp);

    const cleanup = () => {
      dragButton.removeEventListener('focusin', onMouseFocusIn);
      dragButton.removeEventListener('focusout', onMouseFocusOut);
      element.removeEventListener('mousedown', onMouseDown);
      element.removeEventListener('mouseup', onMouseUp);
      container.removeEventListener('mousemove', onMouseMove);
      container.removeEventListener('mouseleave', onMouseUp);
    };

    return cleanup;
  }, []);

  return (
    <Box
      {...rest}
      ref={elementRef}
      sx={{
        position: 'absolute',
        zIndex: '1',
        display: 'flex',
        flexDirection: 'column',
        height: 60,
        padding: 1,
        border: '1px solid transparent',
        boxSizing: 'border-box',
        '> .MuiBox-root': {
          visibility: 'hidden'
        },
        ':hover': {
          border: '1px solid #999999aa',
          borderRadius: 1,
          '> .MuiBox-root': {
            visibility: 'visible'
          }
        }
      }}>
      <Box
        ref={dragIconRef}
        sx={{
          backgroundColor: '#eeeeee',
          display: 'flex',
          alignSelf: 'baseline',
          borderRadius: 1
        }}>
        <IconButton
          sx={{ padding: 1 }}
          size="small"
          color="default"
          aria-label="drag"
          onClick={() => {}}>
          <DragIndicator sx={{ fontSize: 18 }} />
        </IconButton>

        <IconButton
          size="small"
          color="default"
          aria-label="delete"
          component="label"
          sx={{ padding: 1 }}
          onClick={() => onRemoveCallback(elementId)}>
          <Delete sx={{ fontSize: 18 }} />
        </IconButton>
      </Box>
      {type === 'IMAGE' && previewUrl ? (
        <img
          src={previewUrl}
          alt="preview"
          style={{
            width: `${width}px`,
            height: `${height}px`,
            pointerEvents: 'none'
          }}
        />
      ) : (
        <Input
          disableUnderline
          type="text"
          value={textInput}
          onChange={({ target: { value } }) => setTextInput(value)}
          sx={{ border: 0, width: '100%', marginBlockStart: 2, margin: '0.2rem 0' }}
        />
      )}
    </Box>
  );
};
