import { SelectedRoom } from 'types/common.types';
import {
  IAddedElementsData,
  IElementInfo,
  IMovedElementsData,
  IRoomInfo,
  IRoomCameraInfo,
  ICreateRoomCameraInfo,
  IUpdateRoomCameraInfo
} from 'types/models/ForgeViewer';
import { IModelTemplate } from 'types/models/ModelTemplate';

export const SET_SELECTED_ELEMENTS = 'SET_SELECTED_ELEMENTS';
export const SET_TEMPLATE_ID = 'SET_TEMPLATE_ID';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_ROOMS = 'SET_ROOMS';
export const SET_SELECTED_ROOM = 'SET_SELECTED_ROOM';
export const GET_ROOM_CAMERAS = 'GET_ROOM_CAMERAS';
export const SAVE_ROOM_CAMERAS = 'SAVE_ROOM_CAMERAS';
export const UPDATE_ROOM_CAMERAS = 'UPDATE_ROOM_CAMERAS';
export const SET_MOVED_ELEMENTS_DATA = 'SET_MOVED_ELEMENTS_DATA';
export const SET_ADDED_ELEMENTS_DATA = 'SET_ADDED_ELEMENTS_DATA';
export const SET_DELETED_ELEMENT_DATA = 'SET_DELETED_ELEMENT_DATA';
export const SET_BASE_MODEL_LOADED = 'SET_BASE_MODEL_LOADED';
export const RESET_VIEWER_STORE = 'RESET_VIEWER_STORE';
export const SET_BATCH_TILE_MODIFICATIONS_DATA = 'SET_BATCH_TILE_MODIFICATIONS_DATA';
export const SET_VIEWER_SCREENSHOT = 'SET_VIEWER_SCREENSHOT';
export const SET_IS_TOP_VIEW = 'SET_IS_TOP_VIEW';
export const SET_LINKED_FILE_MAP = 'SET_LINKED_FILE_MAP';
export const SET_CONNECTION_QUALITY_STATUS = 'SET_CONNECTION_QUALITY_STATUS';

export interface SetSelectedElements {
  type: typeof SET_SELECTED_ELEMENTS;
  payload: IElementInfo[];
}

export interface SetTemplateID {
  type: typeof SET_TEMPLATE_ID;
  payload: string;
}

export interface SetTemplate {
  type: typeof SET_TEMPLATE;
  payload: IModelTemplate;
}

export interface SetRooms {
  type: typeof SET_ROOMS;
  payload: IRoomInfo[];
}

export interface GetRoomCameras {
  type: typeof GET_ROOM_CAMERAS;
  payload: IRoomCameraInfo[];
}
export interface SaveRoomCameras {
  type: typeof SAVE_ROOM_CAMERAS;
  payload: ICreateRoomCameraInfo[];
}
export interface UpdateRoomCameras {
  type: typeof UPDATE_ROOM_CAMERAS;
  payload: IUpdateRoomCameraInfo[];
}

export interface SetMovedElementsData {
  type: typeof SET_MOVED_ELEMENTS_DATA;
  payload: IMovedElementsData;
}

export interface SetAddedElementsData {
  type: typeof SET_ADDED_ELEMENTS_DATA;
  payload: IAddedElementsData;
}

export interface SetDeletedElementData {
  type: typeof SET_DELETED_ELEMENT_DATA;
  payload: IElementInfo;
}

export interface ResetViewerStoreAction {
  type: typeof RESET_VIEWER_STORE;
}

export interface SetBaseModelLoadedAction {
  type: typeof SET_BASE_MODEL_LOADED;
  payload: boolean;
}

export interface SetBatchTileModificationsData {
  type: typeof SET_BATCH_TILE_MODIFICATIONS_DATA;
  payload: any;
}

export interface SetViewerScreenshot {
  type: typeof SET_VIEWER_SCREENSHOT;
  payload: Blob;
}

export interface SetIsTopView {
  type: typeof SET_IS_TOP_VIEW;
  payload: boolean;
}

export interface SetSelectedRoom {
  type: typeof SET_SELECTED_ROOM;
  payload: SelectedRoom & IRoomInfo;
}

export interface SetLinkedFileMap {
  type: typeof SET_LINKED_FILE_MAP;
  payload: {};
}

export interface SetConnectionQualityStatus {
  type: typeof SET_CONNECTION_QUALITY_STATUS;
  payload: 1 | 2 | 3;
}

export type ViewerActionTypes =
  | SetSelectedElements
  | SetTemplateID
  | SetTemplate
  | SetRooms
  | GetRoomCameras
  | SaveRoomCameras
  | UpdateRoomCameras
  | SetMovedElementsData
  | SetAddedElementsData
  | SetDeletedElementData
  | ResetViewerStoreAction
  | SetBaseModelLoadedAction
  | SetBatchTileModificationsData
  | SetViewerScreenshot
  | SetIsTopView
  | SetSelectedRoom
  | SetLinkedFileMap
  | SetConnectionQualityStatus;
