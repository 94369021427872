import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';
import FileViewer from 'shared/core/FileViewer/FileViewer';
import FilesUploadWrapper from './home/FilesUploadWrapper';
import NotificationsWrapper from './notifications/NotificationsWrapper';
import ModelsUploadWrapper from './projectDetail/ModelsUploadWrapper';
import ModelTemplateDetail from './projectDetail/ModelTemplateDetail';
import ProjectsHome from 'pages/projects/home';
import ProjectHome from './home/ProjectHome';

const ProjectDetail = React.lazy(() => import('./projectDetail'));
const NewProject = React.lazy(() => import('./newProject'));
const ProjectsList = React.lazy(() => import('./projectsList'));

export const projectsConfig = [
  {
    permittedRole: [RoutePermittedRole.Builder],
    path: '/projects',
    element: <ProjectsList />
  },
  {
    permittedRole: [RoutePermittedRole.Builder],
    path: '/projects/new',
    element: <NewProject />
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Builder],
    path: ['/projects/:id'],
    element: <ProjectDetail />
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Builder],
    path: '/projects/edit/:id',
    element: <NewProject />
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Builder],
    path: '/projects/:id/notifications',
    element: <NotificationsWrapper />
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Builder],
    path: '/projects/:id/models',
    element: <ModelsUploadWrapper />
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Builder],
    path: '/projects/:id/models/:model_id',
    element: <ModelsUploadWrapper />
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Builder],
    path: '/projects/:id/models/:model_id/model-templates/:model_template_id',
    element: <ModelTemplateDetail />
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Builder],
    path: '/projects/:id/file',
    element: <FilesUploadWrapper />
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Builder],
    path: '/projects/:id/file/:file_id',
    element: <FilesUploadWrapper />
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Builder, RoutePermittedRole.User],
    path: '/projects/:id/file/:file_id/view',
    element: <FileViewer />
  },
  {
    permittedRole: [RoutePermittedRole.User],
    path: ['/home/projects'],
    element: <ProjectsHome />
  },
  {
    permittedRole: [RoutePermittedRole.User],
    path: ['/home/projects/:id'],
    element: <ProjectHome />
  }
];
