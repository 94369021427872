import CssBaseline from '@mui/material/CssBaseline';
import { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import configureStore from 'redux/store';
import JWTAuthProvider from 'services/auth/jwt-auth/JWTAuthProvider';
import { CONTEXTS, EVENT_TYPES } from 'shared/constants/AppConst';
import AppInfoView from 'shared/core/AppInfoView';
import AppLayout from 'shared/core/AppLayout';
import BIService from 'shared/utility/BIService';
import AppContextProvider from 'shared/utility/AppContextProvider';
import AppLocaleProvider from 'shared/utility/AppLocaleProvider';
import AppStyleProvider from 'shared/utility/AppStyleProvider';
import AppThemeProvider from 'shared/utility/AppThemeProvider';
import AuthRoutes from 'shared/utility/AuthRoutes';
import * as Sentry from '@sentry/react';
import { isLocalhost } from 'shared/utility/Utils';
import { PixelStreamingViewer } from 'pixel_streaming/PixelStreamingViewer';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './index.css';
import { IntercomProvider } from 'react-use-intercom';

Sentry.init({
  dsn: 'https://73194557cf1b4d70923ee93ab0d35b62@o4505277808967680.ingest.sentry.io/4505278130946048',
  enabled: !isLocalhost,
  debug: isLocalhost,
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^localhost:\d+$/,
        /^https:\/\/staging1-app-api.tweaks\.ai/,
        /^https:\/\/app-api.tweaks\.ai/
      ]
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true
    })
  ],
  // Performance Monitoring
  tracesSampleRate: isLocalhost ? 1 : 0.25, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: isLocalhost ? 1 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: isLocalhost ? 1 : 0.1 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const store = configureStore();

let App;

if (process.env.REACT_APP_RUN_ONLY_PIXEL_STREAMING === 'true') {
  App = () => {
    return <PixelStreamingViewer viewerReady={true} previewMode={true} />;
  };
} else {
  App = () => {
    const biService = BIService.getInstance();

    const handleVisibilityChange = () => {
      if (document.hidden) {
        biService.logEvent(EVENT_TYPES.SWITCH_TAB, CONTEXTS.LOGIN, {
          is_active: false
        });
      } else {
        biService.logEvent(EVENT_TYPES.SWITCH_TAB, CONTEXTS.LOGIN, {
          is_active: true
        });
      }
    };

    const handleBeforeUnload = () => {
      biService.logEvent(EVENT_TYPES.CLOSE_APP, CONTEXTS.LOGIN);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

    useEffect(() => {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('beforeunload', handleBeforeUnload);
    }, []);

    return (
      <IntercomProvider appId="ebnwettt">
        <AppContextProvider>
          <Provider store={store}>
            <AppThemeProvider>
              <AppStyleProvider>
                <AppLocaleProvider>
                  <CookiesProvider>
                    <JWTAuthProvider>
                      <AuthRoutes>
                        <CssBaseline />
                        <AppLayout />
                      </AuthRoutes>
                    </JWTAuthProvider>
                  </CookiesProvider>
                  <AppInfoView />
                </AppLocaleProvider>
              </AppStyleProvider>
            </AppThemeProvider>
          </Provider>
        </AppContextProvider>
      </IntercomProvider>
    );
  };
}

export default App;
