import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import AppAnimate from 'shared/core/AppAnimate';
import IntlMessages from 'shared/utility/IntlMessages';
import { Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

const DesktopMessage = () => {
  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box
        sx={{
          pb: 6,
          py: { xl: 4 },
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Card
          sx={{
            maxWidth: 450,
            width: '100%',
            textAlign: 'center',
            padding: { xs: 8, lg: 12, xl: '48px 64px' },
            overflow: 'hidden',
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
          }}>
          <ConstructionIcon fontSize="large" />
          <Box
            sx={{
              mb: { xs: 3, xl: 4 },
              mt: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Box
              sx={{
                fontSize: 20
              }}>
              <Typography variant="h2" sx={{ fontSize: 28, color: 'primary.main' }}>
                Tweaks
              </Typography>
            </Box>
          </Box>
          <Box>
            <IntlMessages id="common.mobile.support" />
          </Box>
        </Card>
      </Box>
    </AppAnimate>
  );
};

export default DesktopMessage;
