import React, { ReactElement } from 'react';
import { Autocomplete, Box, Button, InputAdornment, TextField } from '@mui/material';
import IntlMessages from 'shared/utility/IntlMessages';
import { Form } from 'formik';
import AppTextField from 'shared/core/AppFormComponents/AppTextField';

import { ISupplier, ITile } from 'types/models/Supplier';

interface ITileSelect {
  value: ITile | null;
  options: ITile[];
  onChange: (val) => void;
  label: string | ReactElement;
  name: string;
}

const TileSelect = (props: ITileSelect) => {
  const onChange = (e, value) => {
    props.onChange(value);
  };

  return (
    <Autocomplete
      value={props.value || null}
      isOptionEqualToValue={(option, value) => {
        return option._id === value._id;
      }}
      onChange={onChange}
      id="tile-select"
      sx={{ width: 300 }}
      options={props.options}
      autoHighlight
      getOptionLabel={(option) => option.fullName || ''}
      renderOption={(props, option) => (
        <Box
          key={option._id}
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}>
          <img loading="lazy" width="20" src={option.imgUrl} alt="" />
          {option.fullName}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          name={props.name}
          {...params}
          label={props.label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

interface ModelTemplateSupplierFormProps {
  supplier: ISupplier;
  setFieldValue: (field: string, data: any) => void;
  values: { [key: string]: any };
  isSubmitting: boolean;
  options: {
    bathroomWalls: ITile[];
    bathroomFloors: ITile[];
    floors: ITile[];
  };
}

const ModelTemplateSupplierForm: React.FC<ModelTemplateSupplierFormProps> = ({
  supplier,
  values,
  setFieldValue,
  isSubmitting,
  options
}) => {
  return (
    <Form noValidate autoComplete="off">
      <Box style={{ margin: '15px 10px' }}>
        <AppTextField
          size="small"
          name="cost"
          fullWidth
          label={<IntlMessages id="common.cost" />}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IntlMessages id="tweaks.currencySymbol" />
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box style={{ margin: '15px 10px' }}>
        <TileSelect
          name="bathroomWalls"
          value={values.bathroomWalls}
          options={options.bathroomWalls}
          label={<IntlMessages id="tweaks.defaultMaterials.bathroomWalls" />}
          onChange={(value) => {
            setFieldValue('bathroomWalls', value);
          }}
        />
      </Box>
      <Box style={{ margin: '15px 10px' }}>
        <TileSelect
          name="bathroomFloors"
          value={values.bathroomFloors}
          options={options.bathroomFloors}
          label={<IntlMessages id="tweaks.defaultMaterials.bathroomFloors" />}
          onChange={(value) => {
            setFieldValue('bathroomFloors', value);
          }}
        />
      </Box>
      <Box style={{ margin: '15px 10px' }}>
        <TileSelect
          name="floors"
          value={values.floors}
          options={options.floors}
          label={<IntlMessages id="tweaks.defaultMaterials.floors" />}
          onChange={(value) => {
            setFieldValue('floors', value);
          }}
        />
      </Box>
      <Box>
        <Button
          disabled={isSubmitting}
          sx={{
            position: 'relative',
            minWidth: 100
          }}
          color="primary"
          variant="contained"
          type="submit">
          <IntlMessages id="common.save" />{' '}
          <IntlMessages id={`tweaks.editor.supplier_name.${supplier.name.toLowerCase()}`} />
        </Button>
      </Box>
    </Form>
  );
};

export default ModelTemplateSupplierForm;
