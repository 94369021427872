import { IAsset } from 'types/models/Asset';

export const GET_ASSETS = 'GET_ASSETS';
export const RESET_ASSET_STORE = 'RESET_ASSET_STORE';
export const LOADING_ASSET = 'LOADING_ASSET';

export interface GetAssetAction {
  type: typeof GET_ASSETS;
  payload: IAsset[];
}

export interface ResetAssetStoreAction {
  type: typeof RESET_ASSET_STORE;
}

export interface LoadingAssetAction {
  type: typeof LOADING_ASSET;
}

export type AssetActionTypes = GetAssetAction | ResetAssetStoreAction | LoadingAssetAction;
