import { ISupplier } from 'types/models/Supplier';
import { AppActions } from '../../types';

import {
  GET_SUPPLIERS,
  GET_SUPPLIER_PRODUCTS_BY_TYPE,
  RESET_SUPPLIER_STORE,
  LOADING_SUPPLIER
} from 'types/actions/Supplier.actions';

const initialState: {
  items: ISupplier[] | null;
  products: any[] | null;
  loading: boolean;
} = {
  items: null,
  loading: false,
  products: null
};

const Supplier = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_SUPPLIERS:
      return {
        ...state,
        items: action.payload,
        loading: false
      };

    case GET_SUPPLIER_PRODUCTS_BY_TYPE:
      const products = action.payload.reduce((groups, item) => {
        const group = groups[item.name] || [];
        group.push(item);
        groups[item.name] = group;
        return groups;
      }, {});

      return {
        ...state,
        products,
        loading: false
      };

    case LOADING_SUPPLIER:
      return {
        ...state,
        loading: true
      };

    case RESET_SUPPLIER_STORE:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
export default Supplier;
