import React, { useEffect } from 'react';
import IntlMessages from 'shared/utility/IntlMessages';
import Box from '@mui/material/Box';
import { Fonts } from 'shared/constants/AppEnums';
import AppCard from 'shared/core/AppCard';
import { IProject } from 'types/models/Project';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import AppTableContainer from 'shared/core/AppTableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import FileListHeader from './FileListHeader';
import { TableCell, TableRow } from '@mui/material';
import FileListItem from './FileListItem';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectFiles } from 'redux/actions';
import { AppState } from 'redux/store';
import { useAuthUser } from 'shared/utility/AuthHooks';
import { RoutePermittedRole } from 'shared/constants/AppConst';

interface IProjectFiles {
  project: IProject;
}
const ProjectFiles: React.FC<IProjectFiles> = ({ project }) => {
  const dispatch = useDispatch();
  const { user } = useAuthUser();
  const { projectFiles } = useSelector<AppState, AppState['project']>(({ project }) => project);

  useEffect(() => {
    dispatch(getProjectFiles(project._id));
  }, []);
  return (
    <AppCard
      sxStyle={{
        height: 1,
        display: 'flex',
        flexDirection: 'column'
      }}
      contentStyle={{ padding: 0 }}>
      <Box
        sx={{
          pt: { xs: 4, xl: 6 },
          px: { xs: 4, xl: 6 }
        }}>
        <Box
          component="h4"
          sx={{
            mb: 4,
            fontSize: 16,
            fontWeight: Fonts.BOLD
          }}>
          <IntlMessages id="sidebar.app.dashboard.files" />
          {(user?.role === RoutePermittedRole.Admin ||
            user?.role === RoutePermittedRole.Builder) && (
            <Button
              style={{ float: 'right' }}
              component={Link}
              to={`/projects/${project._id}/file`}
              variant="contained"
              color="primary">
              <IntlMessages id="actions.add" />
            </Button>
          )}
        </Box>
        <AppTableContainer sxStyle={{ maxHeight: 480 }}>
          <Table>
            <TableHead
              sx={{
                borderBottom: '0 none'
              }}>
              <FileListHeader />
            </TableHead>
            <TableBody
              sx={{
                borderBottom: '0 none'
              }}>
              {projectFiles.map((row, index) => (
                <FileListItem key={index} row={row} project={project} />
              ))}

              {projectFiles.length === 0 && (
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      padding: 40,
                      borderBottom: 0
                    }}
                    colSpan={2}>
                    <IntlMessages id="tweaks.noFilesFound" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </AppTableContainer>
      </Box>
    </AppCard>
  );
};

export default ProjectFiles;
