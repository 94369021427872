import { authRouteConfig } from './auth';
import Error403 from './errorPages/Error403';
import { errorPagesConfigs } from './errorPages';
import { projectsConfig } from './projects';
import { homesConfig } from './homes';
import { userPagesConfig } from './userPages';
import { adminConfig } from './admin';
import Error404 from './errorPages/Error404';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [...projectsConfig, ...homesConfig, ...userPagesConfig, ...adminConfig]
};

const unAuthorizedStructure = {
  fallbackPath: '/',
  routes: authRouteConfig
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '*',
      element: <Error404 />
    }
  ])
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
