import { Flags, StatsReceivedEvent } from '@yovelsapir/lib-pixelstreamingfrontend-ue5.2';
import { Application } from '@yovelsapir/lib-pixelstreamingfrontend-ui-ue5.2';
import { MutableRefObject, useRef } from 'react';
import { AuthUser } from 'types/models/AuthUser';
import emitApiServiceBuilder from './services/emit-api.service';
import { psConfig } from './config';
import BIService from 'shared/utility/BIService';
import { CONTEXTS, EVENT_TYPES } from 'shared/constants/AppConst';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { getSupplierProductsByType } from 'redux/actions/Supplier';
import { setSelectedMarker } from 'redux/actions/CustomRequest';
import { MarkerStatus } from 'shared/extensions/tweaks-markers';
import { splitRoomKeyByRevitRoomName } from 'services/rooms/rooms.service';
import { setConnectionQualityStatus } from 'redux/actions/Viewer';
import { useGoToRoom } from 'pages/dashboards/Model/hooks/useGoToRoom';

export const useHandlers = (pixelStreamingRef: MutableRefObject<Application>) => {
  const emitApiService = emitApiServiceBuilder(pixelStreamingRef);
  const biService = BIService.getInstance();
  const reduxDispatch = useDispatch();
  const { item: home } = useSelector<AppState, AppState['home']>(({ home }) => home);
  const connectionQuality = useRef(3);
  const { handleMenuItemClick } = useGoToRoom();

  const toggleKeyboardIfProviewModeStateChange = (previewMode: boolean) => {
    pixelStreamingRef.current.stream.config.setSettings({
      KeyboardInput: previewMode,
      MouseInput: previewMode,
      TouchInput: previewMode
    });
  };

  const addMatchmakerHandler = () => {
    const handler = (data) => {
      if (data) {
        const parseData = JSON.parse(data) as any;
        if (!parseData?.matched) {
          biService.logEvent(EVENT_TYPES.MATCHMAKER_LOADING, CONTEXTS.PIXEL_STREAMING);
          pixelStreamingRef.current.showTextOverlay('Loading...');
        }

        if (parseData?.matched) {
          biService.logEvent(EVENT_TYPES.MATCHMAKER_MATCHED, CONTEXTS.PIXEL_STREAMING);
          const BrowserSendsOffer = pixelStreamingRef.current.stream.config.isFlagEnabled(
            Flags.BrowserSendOffer
          );
          if (!BrowserSendsOffer) {
            pixelStreamingRef.current.stream.webSocketController.requestStreamerList();
            pixelStreamingRef.current.disconnectOverlay.hide();
          }
        }
      }
    };

    pixelStreamingRef.current.stream.webSocketController.signallingProtocol.addMessageHandler(
      'matchmaker',
      handler
    );
  };

  const addAfkHandler = (previewMode: boolean) => {
    const handler = () => {
      if (!previewMode && pixelStreamingRef.current?.stream) {
        biService.logEvent(EVENT_TYPES.AFK_IN_EDITOR_MODE, CONTEXTS.PIXEL_STREAMING);
        pixelStreamingRef.current.stream.disconnect();
      } else if (previewMode) {
        biService.logEvent(EVENT_TYPES.AFK_IN_PREVIEW_MODE, CONTEXTS.PIXEL_STREAMING);
        pixelStreamingRef.current.stream.disconnect();
      }
    };

    pixelStreamingRef.current.stream.addEventListener('afkTimedOut', handler);

    // cleanup
    return () => {
      pixelStreamingRef.current.stream.removeEventListener('afkTimedOut', handler);
    };
  };

  const addStatsHandler = () => {
    const handler = (stats: Event & StatsReceivedEvent) => {
      const qualityValue = stats.data.aggregatedStats.sessionStats.videoEncoderAvgQP;
      const quality =
        qualityValue >= 0 && qualityValue < 26
          ? 3
          : qualityValue >= 26 && qualityValue < 37
          ? 2
          : 1;

      if (connectionQuality.current !== quality) {
        connectionQuality.current = quality;
        reduxDispatch(setConnectionQualityStatus(quality));
      }
    };

    pixelStreamingRef.current.stream.addEventListener('statsReceived', handler);

    // cleanup
    return () => {
      pixelStreamingRef.current.stream.removeEventListener('statsReceived', handler);
    };
  };

  const addStreamLoadingHandler = (streamerMapId = 'one', user?: AuthUser) => {
    let timer;
    const handler = () => {
      clearTimeout(timer);
      biService.logEvent(EVENT_TYPES.VIDEO_INITIALIZED, CONTEXTS.PIXEL_STREAMING);
      timer = setTimeout(() => {
        if (user) {
          const homeWelcomeMessage = `${user?.displayName}'s Home`;
          emitApiService.emitEventToUnreal('homeownerId', `${user?.displayName}'s Home`, {
            homeWelcomeMessage
          });
        }
        emitApiService.emitEventToUnreal('streamerMapId', streamerMapId);
        pixelStreamingRef.current.stream.config.setSettings(psConfig);

        // set the selected packages to be shown in unreal scene
        home.selectedPackages?.forEach((selectedPackage) => {
          const pkg = home.modelTemplate.packages?.find(
            (packageInTemplate) => packageInTemplate._id === selectedPackage.packageId
          );
          if (pkg && pkg.room && pkg.name) {
            const eventName = splitRoomKeyByRevitRoomName(pkg.room) + '-' + pkg.name;
            emitApiService.emitEventToUnreal('packageName', eventName);
          }
        });

        handleMenuItemClick(0);
      }, 1000);
    };

    pixelStreamingRef.current.stream.addEventListener('videoInitialized', handler);

    // cleanup
    return () => {
      clearTimeout(timer);
      pixelStreamingRef.current.stream.removeEventListener('videoInitialized', handler);
    };
  };

  const addResponseListenerHandler = () => {
    const handler = (data) => {
      console.log('addResponseListenerHandler', data);
      const dataParsed = JSON.parse(data);

      if (dataParsed.eventName === 'openCatalog') {
        const selectedCategory = dataParsed.type; // floorTiles or wallTiles
        const subCategory = dataParsed.subType; // dry or wet
        const supplierId = home.supplier._id;

        reduxDispatch(getSupplierProductsByType(supplierId, selectedCategory, subCategory));
      }

      if (dataParsed.eventName === 'markerAdded') {
        const { x, y, z } = dataParsed;
        reduxDispatch(
          setSelectedMarker({
            _id: '654a3e925597747a5dcb5411',
            data: { dbId: 3572, camera: '654a3e925597747a5dcb540f' },
            status: MarkerStatus.OPEN,
            x: x,
            y: y,
            z: z
          })
        );
      }
    };

    pixelStreamingRef.current.stream.addResponseEventListener('handle_responses', handler);

    return () => {
      pixelStreamingRef.current.stream.removeResponseEventListener('handle_responses');
    };
  };

  return {
    toggleKeyboardIfProviewModeStateChange,
    addMatchmakerHandler,
    addAfkHandler,
    addStreamLoadingHandler,
    addResponseListenerHandler,
    addStatsHandler
  };
};
