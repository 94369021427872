import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';
import ModelTemplateSupplierFormWrapper from './ModelTemplateSupplierFormWrapper';

const ModelTemplateSupplierFormTabs = ({
  suppliers,
  modelTemplateSupplierInfo,
  modelTemplate,
  onUpdate
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedSupplier, setSelectedSupplier] = useState(suppliers[0]);

  const handleTabChange = (e, tabIndex) => {
    setSelectedTab(tabIndex);
    setSelectedSupplier(suppliers[tabIndex]);
  };

  const getSelectedSupplierInfo = (supplier) => {
    return modelTemplateSupplierInfo.find((item) => item?.supplier?._id === supplier?._id);
  };

  return (
    <Box>
      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="supplier tabs">
        {suppliers.map((supplier, index) => (
          <Tab key={index} label={supplier.name} value={index} />
        ))}
      </Tabs>
      <Divider />
      <ModelTemplateSupplierFormWrapper
        key={selectedSupplier._id}
        onSave={onUpdate}
        modelTemplate={modelTemplate}
        supplier={selectedSupplier}
        modelTemplateSupplierInfo={getSelectedSupplierInfo(selectedSupplier)}
      />
    </Box>
  );
};

export default ModelTemplateSupplierFormTabs;
