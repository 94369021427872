import { Box } from '@mui/material';
import './viewerControls.css';
import { ViewerMode } from './ViewerMode';
import { usePreviewMode } from '../../../../pixel_streaming/usePreviewMode';
import { useLocation } from 'react-router-dom';
import { TweaksTotal } from './TweaksTotal';
import { ViewerTools } from './ViewerTools';
import { useState } from 'react';
import { ViewerEvents } from 'shared/constants/AppConst';
import useBus from 'use-bus';

const ViewerToolsbar = () => {
  const [disableInteractions, setDisableInteractions] = useState<boolean>(false);
  const [previewMode] = usePreviewMode();
  const location = useLocation();
  const isInUnitPage = location.pathname.includes('units');

  useBus(ViewerEvents.DisableInteractions, (event) => {
    setDisableInteractions(event.payload);
  });

  if (!isInUnitPage) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',

        '& .save-controller, & .viewer-mode': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }}>
      <>
        <TweaksTotal />
        <ViewerTools isPreviewMode={previewMode} />
        {/* <SaveControl /> */}
      </>
      <ViewerMode />
    </Box>
  );
};

export default ViewerToolsbar;
