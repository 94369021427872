import { useEffect, useState } from 'react';
import { useDebounce } from './useDebounce';
import { getPackageCategoriesOptions } from 'redux/actions/Model';
import _ from 'lodash';
import { createFilterOptions } from '@mui/material';

type CategoryGroup = {
  folderName: string;
  fileName: string;
  label: string;
};

interface PackagesCategoriesAutoCompletion {
  packagesCategories: CategoryGroup[];
  isLoading: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const filterPackagesCategoriesAutoCompletion = createFilterOptions<CategoryGroup>();

export const usePackagesCategoriesAutoCompletion = (
  query: string
): PackagesCategoriesAutoCompletion => {
  const debouncedQuery = useDebounce(query, 500);
  const [packagesCategories, setPackagesCategories] = useState<CategoryGroup[]>([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setIsLoading(true);
      setPackagesCategories([]);
    }
  }, [open]);

  useEffect(() => {
    let active = true;

    setIsLoading(true);

    (async () => {
      if (debouncedQuery === query) {
        if (debouncedQuery === '' || !debouncedQuery) {
          setPackagesCategories([]);
          return;
        }

        const data = await getPackageCategoriesOptions(debouncedQuery)
          .catch(() => {
            if (active) {
              setPackagesCategories([]);
            }
          })
          .finally(() => {
            if (active) {
              setIsLoading(false);
            }
          });

        if (!data) {
          return;
        }

        const mapData = _.flatMap(data, (value, key) =>
          _.map(value, (fileName) => ({
            folderName: key,
            fileName,
            label: fileName.replace(new RegExp('.*/', 'g'), '')
          }))
        );

        if (active) {
          setPackagesCategories(mapData);
        }
      }
    })();

    return () => {
      active = false;
    };
  }, [debouncedQuery]);

  return { packagesCategories, isLoading, open, setOpen };
};
