export interface LanguageProps {
  languageId: string;
  locale: 'he' | 'en';
  name: string;
}

const languageData: LanguageProps[] = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English'
  },
  {
    languageId: 'hebrew',
    locale: 'he',
    name: 'עִברִית'
  }
];
export default languageData;
