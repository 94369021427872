import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const OwnerRedirect = React.lazy(() => import('./OwnerRedirect'));

export const userPagesConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/sign-in/redirect',
    element: <OwnerRedirect />
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/home',
    element: <OwnerRedirect />
  }
];
