import { Close, ImageOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Chip,
  IconButton,
  ListItem,
  TextField,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box/Box';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { ICategory } from './RoomListItem';
import {
  filterPackagesCategoriesAutoCompletion,
  usePackagesCategoriesAutoCompletion
} from 'shared/hooks/usePackagesCategoriesAutoCompletion';
import { TbClick } from 'react-icons/tb';

type Option = {
  _id?: string;
  categoryId: string;
  name: string;
  image?: string;
  showImage?: boolean;
  error?: string;
  folderName?: string;
  fileName?: string;
};

type Category = {
  _id?: string;
  name: string;
  description: string;
  options: Option[];
};

export const RoomPackageCategory = ({
  packageId,
  onCategoriesChange
}: {
  packageId: string;
  onCategoriesChange: (cb: ICategory[]) => void;
}) => {
  const packages = useSelector<AppState, AppState['model']['modelTemplate']['packages']>(
    (state) => state.model.modelTemplate.packages
  );
  const currentPackage = packages.find((pkg) => pkg._id === packageId);

  const [categories, setCategories] = useState<Category[]>(
    currentPackage
      ? currentPackage.categories.map((cat) => ({
          _id: cat._id,
          description: cat.description,
          name: cat.name,
          options: cat.options.map<Option>((opt) => ({
            _id: opt._id,
            categoryId: cat._id,
            name: opt.name,
            image: opt.imageUrl,
            showImage: false,
            error: ''
          }))
        }))
      : []
  );

  const [category, setCategory] = useState<Category>({
    name: '',
    description: '',
    options: []
  });
  const [option, setOption] = useState<Option>({
    categoryId: '',
    name: '',
    image: '',
    showImage: false,
    error: ''
  });
  const [categoryError, setCategoryError] = useState<string>('');
  const categoryOptionsAutoComplete = usePackagesCategoriesAutoCompletion(option.name);
  const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: Option[] }>({});

  const addCategory = (category: Category) => {
    if (categories.find((cat) => cat.name === category.name)) {
      setCategoryError('Category already exists');
      return;
    }
    setCategoryError('');
    setCategories([...categories, category]);
    setCategory({ name: '', description: '', options: [] });
  };

  const addCategoryOption = (category: Category, option: Option) => {
    const add = (_option: Option) => {
      if (category.options.find((opt) => opt.name === _option.name)) {
        setOption((prev) => ({ ...prev, error: 'Option already exists' }));
        return;
      }

      setCategories((prev) => {
        const index = prev.findIndex((cat) => cat.name === category.name);
        prev[index].options.push(_option);
        return [...prev];
      });

      setOption({ categoryId: '', name: '', error: '' });
    };

    // add multiple options from autocomplete
    if (selectedOptions[category.name]) {
      selectedOptions[category.name].forEach((selectedOption) => {
        const options = selectedOption.name.split(',');
        options.forEach((opt) => {
          add({ ...option, name: opt, image: selectedOption.image });
        });
      });
      setSelectedOptions((prev) => {
        return {
          ...prev,
          [category.name]: []
        };
      });
    }
  };

  const deleteCategory = (category: Category) => {
    setCategories((prev) => prev.filter((cat) => cat.name !== category.name));
  };

  const deleteCategoryOption = (category: Category, option: Option) => {
    setCategories((prev) => {
      const index = prev.findIndex((cat) => cat.name === category.name);
      prev[index].options = prev[index].options.filter((opt) => opt.name !== option.name);
      return [...prev];
    });
  };

  const showOptionImageInput = (category: Category, option: Option) => {
    setCategories((prev) => {
      const index = prev.findIndex((cat) => cat.name === category.name);
      const optionIndex = prev[index].options.findIndex((opt) => opt.name === option.name);
      prev[index].options[optionIndex].showImage = !prev[index].options[optionIndex].showImage;
      return [...prev];
    });
  };

  const updateCategoryOptionImage = (category: Category, categoryOption: Option, image: string) => {
    setCategories((prev) => {
      const index = prev.findIndex((cat) => cat.name === category.name);
      const optionIndex = prev[index].options.findIndex((opt) => opt.name === categoryOption.name);
      prev[index].options[optionIndex].image = image;
      return [...prev];
    });
  };

  useEffect(() => {
    const categoriesFormatted = categories.map<ICategory>((cat) => ({
      _id: cat?._id || undefined,
      description: cat.description,
      name: cat.name,
      options: cat.options.map((opt) => ({
        _id: opt._id || undefined,
        name: opt.name,
        imageUrl: opt.image,
        title: opt.name
      }))
    }));

    onCategoriesChange(categoriesFormatted);
  }, [categories]);

  return (
    <Box py={4}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          mb: 2,
          gap: 4
        }}>
        <TextField
          label="Add Category"
          error={!!categoryError}
          helperText={categoryError}
          id="roomPackageCategory"
          name="category"
          value={category.name}
          onChange={({ target }) => setCategory((prev) => ({ ...prev, name: target.value }))}
          title="Category"
          sx={{ flex: 1, flexShrink: 0 }}
          size="small"
          placeholder="Add new category"
        />
        <Button
          variant="draft"
          size="small"
          sx={{ maxHeight: 38 }}
          onClick={() => addCategory(category)}>
          Add Category
        </Button>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, pb: 4 }}>
        {categories.map((category) => {
          const isActiveCategory = category.name === option.categoryId;
          return (
            <Box
              key={category.name}
              sx={{
                p: 3,
                backgroundColor: '#f5f5f5',
                mt: 2,
                borderRadius: 2
              }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pb: 3
                }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 600
                  }}>
                  {category.name}
                </Typography>
                <Button
                  variant="danger"
                  size="small"
                  sx={{ maxHeight: 38, width: 140 }}
                  onClick={() => deleteCategory(category)}>
                  Delete Category
                </Button>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: 4
                }}>
                <TextField
                  multiline
                  rows={2}
                  label="Add Category Description"
                  id="roomPackageCategoryDescription"
                  name={`${category.name}-description`}
                  value={category.description}
                  onChange={({ target }) => {
                    setCategories((prev) => {
                      const index = prev.findIndex((cat) => cat.name === category.name);
                      prev[index].description = target.value;
                      return [...prev];
                    });
                  }}
                  title="Category Description"
                  sx={{ flex: 1, flexShrink: 0, mb: 2 }}
                  size="small"
                  placeholder="Category description"
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  gap: 4
                }}>
                <Autocomplete
                  multiple
                  id={`auto-complete-category-options-${category.name}}`}
                  sx={{
                    flex: 1
                  }}
                  open={isActiveCategory ? categoryOptionsAutoComplete.open : false}
                  onOpen={() => {
                    categoryOptionsAutoComplete.setOpen(true);
                  }}
                  onClose={() => {
                    categoryOptionsAutoComplete.setOpen(false);
                  }}
                  groupBy={(option) => option.folderName}
                  getOptionLabel={(option) => option.fileName}
                  loading={categoryOptionsAutoComplete.isLoading}
                  options={categoryOptionsAutoComplete.packagesCategories}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      {params.group === 'New Option' ? (
                        <Box
                          sx={{
                            position: 'sticky',
                            top: '-8px',
                            padding: '4px 10px',
                            backgroundColor: 'white',
                            zIndex: 1
                          }}>
                          {params.group}
                        </Box>
                      ) : (
                        <Button
                          onClick={() => {
                            const findGroupedOption =
                              categoryOptionsAutoComplete.packagesCategories.filter(
                                (c) => c.folderName === params.group
                              );

                            setSelectedOptions((prev) => ({
                              ...prev,
                              [category.name]: findGroupedOption.map((opt) => ({
                                categoryId: category.name,
                                name: opt.label,
                                image: opt.fileName,
                                folderName: opt.folderName,
                                fileName: opt.fileName
                              }))
                            }));
                          }}
                          sx={{
                            position: 'sticky',
                            top: '-8px',
                            padding: '4px 10px',
                            backgroundColor: 'white',
                            zIndex: 1,
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                          }}>
                          <TbClick size={32} />
                          {params.group}
                        </Button>
                      )}

                      <ul>{params.children}</ul>
                    </li>
                  )}
                  renderOption={(props, option) => (
                    <ListItem {...props} key={option.fileName}>
                      {option.fileName.match(new RegExp('jpg|jpeg|png')) ? (
                        <img
                          src={option.fileName}
                          alt={option.label}
                          width="30px"
                          style={{ marginInlineEnd: 16 }}
                        />
                      ) : null}
                      {option.label}
                    </ListItem>
                  )}
                  isOptionEqualToValue={(option, value) => option.fileName === value.fileName}
                  renderTags={(options, getTagProps) =>
                    options.map((option, index) => (
                      <Chip {...getTagProps({ index })} label={option.label} variant="outlined" />
                    ))
                  }
                  filterOptions={(options, params) => {
                    const filtered = filterPackagesCategoriesAutoCompletion(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.fileName);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        fileName: `Add "${inputValue}"`,
                        folderName: 'New Option',
                        label: inputValue
                      });
                    }
                    return filtered;
                  }}
                  value={
                    selectedOptions[category.name]
                      ? selectedOptions[category.name].map((opt) => ({
                          fileName: opt.fileName,
                          folderName: opt.folderName,
                          label: opt.name
                        }))
                      : []
                  }
                  inputValue={isActiveCategory ? option.name : ''}
                  filterSelectedOptions
                  onChange={(e, _selectedOptions) => {
                    setSelectedOptions((prev) => {
                      return {
                        ...prev,
                        [category.name]: _selectedOptions.map((opt) => ({
                          categoryId: category.name,
                          name: opt.label,
                          image: opt.fileName,
                          folderName: opt.folderName,
                          fileName: opt.fileName
                        }))
                      };
                    });
                  }}
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(p) => (
                    <TextField
                      {...p}
                      label="Add Category Option"
                      autoComplete="off"
                      error={isActiveCategory ? !!option.error : false}
                      helperText={isActiveCategory ? option.error : ''}
                      id="roomPackageCategoryOption"
                      name={`${category.name}-option`}
                      title="Category Option"
                      sx={{ flex: 1, flexShrink: 0 }}
                      size="small"
                      placeholder="Add category Option"
                      onChange={({ target }) =>
                        setOption({ categoryId: category.name, name: target.value })
                      }
                    />
                  )}
                />

                <Button
                  variant="draft"
                  size="small"
                  sx={{ maxHeight: 38, width: 140 }}
                  disabled={!selectedOptions[category.name]?.length}
                  onClick={() => addCategoryOption(category, option)}>
                  Add Option
                </Button>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  mt: 2,
                  flexWrap: 'wrap'
                }}>
                {category.options.map((categoryOption) => {
                  const isActiveOption =
                    categoryOption.categoryId === category.name ||
                    categoryOption.categoryId === category._id;
                  return (
                    <Box
                      key={categoryOption.name}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        gap: 1,
                        py: '2px',
                        px: 2
                      }}>
                      <Typography key={categoryOption.name}>{categoryOption.name}</Typography>
                      {isActiveOption && categoryOption.showImage ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2
                          }}>
                          <TextField
                            id="image"
                            name={`${categoryOption.name}-image`}
                            placeholder="insert image url"
                            size="small"
                            sx={{
                              borderRadius: 2
                            }}
                            inputProps={{
                              style: {
                                height: '8px'
                              }
                            }}
                            value={isActiveOption ? categoryOption.image : ''}
                            onChange={({ target }) => {
                              updateCategoryOptionImage(category, categoryOption, target.value);
                            }}
                          />
                        </Box>
                      ) : null}

                      <IconButton
                        size="small"
                        sx={{
                          position: 'relative',
                          '.MuiSvgIcon-root': {
                            fontSize: 14
                          }
                        }}
                        onClick={() => showOptionImageInput(category, categoryOption)}>
                        <ImageOutlined fill="black" />
                        {isActiveOption && categoryOption.showImage ? (
                          <Box
                            sx={{
                              position: 'absolute',
                              width: '17px',
                              height: '2px',
                              backgroundColor: '#3d3d3d94',
                              transform: 'rotateZ(140deg)'
                            }}
                          />
                        ) : null}
                      </IconButton>

                      <IconButton
                        size="small"
                        sx={{
                          '.MuiSvgIcon-root': {
                            fontSize: 14
                          }
                        }}
                        onClick={() => deleteCategoryOption(category, categoryOption)}>
                        <Close fill="black" />
                      </IconButton>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
