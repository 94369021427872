import { useRef, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { Fonts } from 'shared/constants/AppEnums';
import {
  Chip,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  Tooltip,
  Typography
} from '@mui/material';
import { BiRun } from 'react-icons/bi';
import { MessageEvents, ViewerEvents } from 'shared/constants/AppConst';
import { AiOutlineSave } from 'react-icons/ai';
import { getRoomCameras, saveRoomCamera, updateRoomCamera } from 'redux/actions/Viewer';
import { useDispatch, useSelector } from 'react-redux';
import useBus, { dispatch as busDispatch } from 'use-bus';

import { CameraInfoRespondTo } from 'types/models/Common';
import IntlMessages from 'shared/utility/IntlMessages';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { messageSeverity } from 'shared/constants/AppConst';
import { AppState } from 'redux/store';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ModalRoomPackageDetail from './ModalRoomPackageDetail';
import { Packages } from 'mocks/roomPackagesService';

export interface ICategoryOption {
  _id?: string;
  name: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  price?: number;
}

export interface ICategory {
  _id?: string;
  name: string;
  title?: string;
  description?: string;
  options?: ICategoryOption[];
}

export interface IRoomPackage {
  _id?: string;
  room: string;
  name: string;
  title: string;
  summary: string;
  description: string;
  price: number;
  type?: Packages;
  revitIdsToHideFromBase?: string;
  revitIds?: number[];
  hidingBaseDbids?: Map<number, number>;
  categories: ICategory[];
  dbids?: {
    dbidsToHideFromBase?: number[];
    dbidsToShow?: { dbid: number; revitId: number }[];
  };
}

const RoomListItem = ({ rooms }) => {
  const dispatch = useDispatch();
  const roomId = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { messages } = useIntl();
  const [open, setOpen] = useState(rooms.map(() => false));
  const [currentPackageToEdit, setCurrentPackageToEdit] = useState<IRoomPackage>(null);
  const [showPackageEditModal, setShowPackageEditModal] = useState<boolean>(false);

  const { roomCameras } = useSelector<AppState, AppState['viewer']>(({ viewer }) => viewer);
  const { modelTemplate } = useSelector<AppState, AppState['model']>(({ model }) => model);
  const { templateId: modelTemplateId } = useSelector<AppState, AppState['viewer']>(
    ({ viewer }) => viewer
  );

  useBus(
    MessageEvents.Error,
    () => {
      enqueueSnackbar(String(messages['tweaks.error']), {
        variant: messageSeverity.ERROR,
        autoHideDuration: 3000
      });
    },
    []
  );
  useBus(
    MessageEvents.SuccessfullySaved,
    () => {
      enqueueSnackbar(String(messages['common.successfullySaved']), {
        variant: messageSeverity.SUCCESS,
        autoHideDuration: 3000
      });
    },
    []
  );
  useBus(
    MessageEvents.SuccessfullyUpdated,
    () => {
      enqueueSnackbar(String(messages['common.successfullyUpdated']), {
        variant: messageSeverity.SUCCESS,
        autoHideDuration: 3000
      });
    },
    []
  );
  useBus(
    ViewerEvents.CameraInfoRespForRoomCamera,
    (e) => {
      const roomCamera = roomCameras?.find((roomCamera) => roomCamera.roomId === roomId.current);
      if (roomCamera) {
        const data = {
          cameraId: roomCamera.camera._id,
          cameraInfo: e.payload
        };
        dispatch(updateRoomCamera(data, () => dispatch(getRoomCameras(modelTemplateId))));
      } else {
        const data = {
          modelTemplateId: modelTemplateId,
          roomId: roomId.current,
          cameraInfo: e.payload
        };
        dispatch(saveRoomCamera(data, () => dispatch(getRoomCameras(modelTemplateId))));
      }
      dispatch(getRoomCameras(modelTemplateId));
    },
    [roomCameras]
  );

  const handleSaveRoom = (id) => {
    roomId.current = id;
    busDispatch({
      type: ViewerEvents.CameraInfoRequest,
      payload: CameraInfoRespondTo.ROOM_CAMERA
    });
  };

  const handleOpen = (index) => {
    setOpen(open.map((isOpen, idx) => (idx === index ? !isOpen : isOpen)));
  };

  const openPackageEditModal = () => {
    setShowPackageEditModal(true);
  };

  return (
    <>
      {rooms.map((row, index) => (
        <TableRow
          key={index}
          sx={{
            borderBottom: '0 none',
            '& .tableCell': {
              borderBottom: '0 none',
              fontSize: 13,
              padding: '6px 8px',
              '&:first-of-type': {
                pl: 5
              },
              '&:last-of-type': {
                pr: 5
              }
            }
          }}
          className="item-hover">
          <TableCell scope="row" className="tableCell">
            <Box
              component="span"
              sx={{
                fontWeight: Fonts.MEDIUM
              }}>
              {row.category.replace(/ *\[[^)]*\] */g, '')}
              {row.packages?.length > 0 && (
                <>
                  <IconButton size="small" onClick={() => handleOpen(index)}>
                    {open[index] ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>

                  <Collapse in={open[index]} timeout="auto" unmountOnExit>
                    <List disablePadding>
                      {row.packages?.map((packageItem) => (
                        <ListItemButton
                          onClick={() => {
                            let roomPackage: IRoomPackage;
                            const packageIndex = modelTemplate.packages.findIndex(
                              (p) => p.name === packageItem.name && p.room === row.category
                            );

                            if (packageIndex > -1) {
                              roomPackage = modelTemplate.packages[packageIndex];
                            } else {
                              roomPackage = {
                                title: packageItem.name,
                                room: row.category,
                                name: packageItem.name,
                                summary: '',
                                description: '',
                                price: 0,
                                categories: []
                              };
                            }

                            setCurrentPackageToEdit(roomPackage);
                            openPackageEditModal();
                          }}>
                          <Typography variant="inherit">{packageItem.name}</Typography>
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                </>
              )}
            </Box>
          </TableCell>
          <TableCell align="left" className="tableCell">
            {roomCameras?.some((roomCamera) => roomCamera.roomId === row.id) && (
              <Tooltip title={<IntlMessages id="tweaks.see.savedCamera" />} placement="top">
                <Chip
                  size="small"
                  label={<IntlMessages id="common.saved" />}
                  color="success"
                  variant="outlined"
                  sx={{
                    fontWeight: Fonts.MEDIUM
                  }}
                  onClick={() =>
                    busDispatch({
                      type: ViewerEvents.GoToRoom,
                      payload: {
                        ...row,
                        camera: roomCameras.find((roomCamera) => roomCamera.roomId === row.id)
                          ?.camera
                      }
                    })
                  }
                />
              </Tooltip>
            )}
          </TableCell>
          <TableCell align="left" className="tableCell">
            <Tooltip title={<IntlMessages id="tweaks.see.room" />} placement="top">
              <Box
                sx={{ '& svg': { fontSize: '20px' }, cursor: 'pointer' }}
                onClick={() =>
                  busDispatch({
                    type: ViewerEvents.GoToRoom,
                    payload: row
                  })
                }>
                <BiRun />
              </Box>
            </Tooltip>
          </TableCell>

          <TableCell align="left" className="tableCell">
            <Box
              sx={{ '& svg': { fontSize: '20px' }, cursor: 'pointer' }}
              onClick={() => handleSaveRoom(row.id)}>
              <AiOutlineSave />
            </Box>
          </TableCell>
        </TableRow>
      ))}
      <ModalRoomPackageDetail
        openModal={showPackageEditModal}
        setOpenPackageEditModal={setShowPackageEditModal}
        currentPackageToEdit={currentPackageToEdit}
      />
    </>
  );
};

export default RoomListItem;
