import { dispatch } from 'use-bus';
import { ViewerEvents } from 'shared/constants/AppConst';
import { init_TransformGizmos } from './transform-controls';

const TweaksMoveToolName = 'TweaksxMoveTool';
const TweaksMoveSceneName = 'TweaksxMoveToolScene';

export class Move extends Autodesk.Viewing.ToolInterface {
  names: string[];

  viewer: any;

  _selectedFragProxyMap: {};

  _hitPoint: any;

  _isDragging: boolean;

  _transformMesh: any;

  _modifiedFragIdMap: {};

  _transformControlTx: any;

  _selectedDbIds: number[];

  _model: any;

  constructor() {
    super();
    this.names = [TweaksMoveToolName];

    this._hitPoint = null;
    this._isDragging = false;
    this._transformMesh = null;
    this._modifiedFragIdMap = {};
    this._selectedFragProxyMap = {};
    this._transformControlTx = null;
    this._selectedDbIds = [];
    this._model = null;

    // Hack: delete functions defined *on the instance* of the tool.
    // We want the tool controller to call our class methods instead.
    delete this.register;
    delete this.deregister;
    delete this.activate;
    delete this.deactivate;
    delete this.getPriority;
    delete this.handleMouseMove;
    delete this.handleButtonDown;
    delete this.handleButtonUp;
    delete this.handleSingleClick;
  }

  createTransformMesh() {
    const material = new THREE.MeshPhongMaterial({ color: 0xff0000 });

    this.viewer.impl.matman().addMaterial(this.guid(), material, true);

    const sphere = new THREE.Mesh(new THREE.SphereGeometry(0.0001, 5), material);

    sphere.position.set(0, 0, 0);

    return sphere;
  }

  guid() {
    let d = new Date().getTime();

    const guid = 'xxxx-xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });

    return guid;
  }

  onTxChange = () => {
    for (const fragId in this._selectedFragProxyMap) {
      const fragProxy = this._selectedFragProxyMap[fragId];

      const position = new THREE.Vector3(
        this._transformMesh.position.x - fragProxy.offset.x,
        this._transformMesh.position.y - fragProxy.offset.y,
        this._transformMesh.position.z - fragProxy.offset.z
      );

      fragProxy.position = position;

      fragProxy.updateAnimTransform();
    }

    this.viewer.impl.sceneUpdated(true);
  };

  onElementMoved = () => {
    // @ts-ignore
    const offset = Object.values(this._selectedFragProxyMap)[0].position;
    const dbIds = this._selectedDbIds;
    const model = this._model;

    dispatch({
      type: ViewerEvents.ElementMoved,
      payload: { dbIds, offset, model }
    });
  };

  onCameraChanged = () => {
    this._transformControlTx?.update();
  };

  getModifiedWorldBoundingBox(fragIds, fragList) {
    const fragbBox = new THREE.Box3();
    const nodebBox = new THREE.Box3();

    fragIds.forEach(function (fragId) {
      fragList.getWorldBounds(fragId, fragbBox);

      nodebBox.union(fragbBox);
    });

    return nodebBox;
  }

  onItemSelected = (event, forceAxis) => {
    const dbIdArray = event.selections[0].dbIdArray;
    const model = event.selections[0].model;
    this._model = model;
    this._selectedFragProxyMap = {};
    this._selectedDbIds = dbIdArray;

    const elementFragIds = [];
    const tree = model.getData().instanceTree;
    tree.enumNodeFragments(
      dbIdArray[0],
      function (fragId) {
        elementFragIds.push(fragId);
      },
      true
    );

    if (!elementFragIds.length) {
      this._hitPoint = null;

      this._transformControlTx.visible = false;

      this._transformControlTx.removeEventListener('change', this.onTxChange);

      this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);

      return;
    }

    if (this._hitPoint) {
      const bbox = this.getModifiedWorldBoundingBox(elementFragIds, this._model.getFragmentList());

      if (forceAxis) {
        this._transformControlTx.setAxis(forceAxis);
      } else {
        // @TODO this will only work for elements with depth < height and depth < width
        // find a solution that works independently of this assumption
        const xDiff = Math.abs(Math.abs(bbox.max.x) - Math.abs(bbox.min.x));
        const yDiff = Math.abs(Math.abs(bbox.max.y) - Math.abs(bbox.min.y));
        const zDiff = Math.abs(Math.abs(bbox.max.z) - Math.abs(bbox.min.z));

        if (xDiff < yDiff && xDiff < zDiff) {
          this._transformControlTx.setAxis('YZ');
        }

        if (yDiff < xDiff && yDiff < zDiff) {
          this._transformControlTx.setAxis('XZ');
        }

        if (zDiff < xDiff && zDiff < yDiff) {
          this._transformControlTx.setAxis('XY');
        }
      }

      this._transformControlTx.visible = true;

      this._transformControlTx.setPosition(this._hitPoint);

      this._transformControlTx.addEventListener('change', this.onTxChange);

      this._transformControlTx.addEventListener('objectChange', this.onElementMoved);

      this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);

      elementFragIds.forEach((fragId) => {
        const fragProxy = this.viewer.impl.getFragmentProxy(model, fragId);

        fragProxy.getAnimTransform();

        const offset = {
          x: this._hitPoint.x - fragProxy.position.x,
          y: this._hitPoint.y - fragProxy.position.y,
          z: this._hitPoint.z - fragProxy.position.z
        };

        fragProxy.offset = offset;

        this._selectedFragProxyMap[fragId] = fragProxy;

        this._modifiedFragIdMap[fragId] = {};
      });

      this._hitPoint = null;
    } else {
      this._transformControlTx.visible = false;
    }
  };

  normalize(screenPoint) {
    const viewport = this.viewer.navigation.getScreenViewport();

    const n = {
      x: (screenPoint.x - viewport.left) / viewport.width,
      y: (screenPoint.y - viewport.top) / viewport.height
    };

    return n;
  }

  getHitPoint(event) {
    const screenPoint = {
      x: event.clientX,
      y: event.clientY
    };

    const n = this.normalize(screenPoint);

    const hitPoint = this.viewer.utilities.getHitPoint(n.x, n.y);

    return hitPoint;
  }

  getTransformMap() {
    const transformMap = {};

    for (const fragId in this._modifiedFragIdMap) {
      let fragProxy = this.viewer.impl.getFragmentProxy(this.viewer.model, fragId);

      fragProxy.getAnimTransform();

      transformMap[fragId] = {
        position: fragProxy.position
      };

      fragProxy = null;
    }

    return transformMap;
  }

  register() {
    console.info('TweaksxMove Tool Registered.');
  }

  deregister() {
    console.info('TweaksxMove Tool Unregistered.');
  }

  activate(name, viewer) {
    this.viewer = viewer;

    const bbox = this.viewer.model.getBoundingBox();

    this.viewer.impl.createOverlayScene(TweaksMoveSceneName);

    init_TransformGizmos();

    // @ts-ignore
    this._transformControlTx = new THREE.TweaksTransformControls(
      this.viewer.impl.camera,
      this.viewer.impl.canvas,
      'translate'
    );

    this._transformControlTx.setSize(bbox.getBoundingSphere().radius * 5);

    this._transformControlTx.visible = false;

    this.viewer.impl.addOverlay(TweaksMoveSceneName, this._transformControlTx);

    this._transformMesh = this.createTransformMesh();

    this._transformControlTx.attach(this._transformMesh);
  }

  deactivate(name) {
    console.info('Move deactivated.');
    this.viewer.impl.removeOverlay(TweaksMoveSceneName, this._transformControlTx);

    this._transformControlTx.removeEventListener('change', this.onTxChange);

    this._transformControlTx = null;

    this.viewer.impl.removeOverlayScene(TweaksMoveSceneName);

    this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);

    this.viewer.removeEventListener(
      Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
      this.onItemSelected
    );
  }

  getPriority() {
    return 42; // Or feel free to use any number higher than 0 (which is the priority of all the default viewer tools)
  }

  update(highResTimestamp) {
    return false;
  }

  handleMouseMove(event) {
    if (this._isDragging) {
      if (this._transformControlTx.onPointerMove(event)) {
        return true;
      }

      return false;
    }

    if (this._transformControlTx.onPointerHover(event)) return true;

    return false;
  }

  handleButtonDown(event, button) {
    this._hitPoint = this.getHitPoint(event);

    this._isDragging = true;

    if (this._transformControlTx?.onPointerDown(event)) return true;

    return false;
  }

  handleButtonUp(event, button) {
    this._isDragging = false;

    if (this._transformControlTx.onPointerUp(event)) return true;

    return false;
  }

  handleSingleClick(event, button) {
    return false;
  }

  _update() {
    // Here we will be updating the actual geometry
  }

  updateHitPoint(event, viewer) {
    const screenPoint = {
      x: event.clientX,
      y: event.clientY
    };
    const viewport = viewer.navigation.getScreenViewport();
    const n = {
      x: (screenPoint.x - viewport.left) / viewport.width,
      y: (screenPoint.y - viewport.top) / viewport.height
    };

    this._hitPoint = viewer.utilities.getHitPoint(n.x, n.y);
    return this._hitPoint;
  }
}

export class MoveExtension extends Autodesk.Viewing.Extension {
  tool: Move;

  constructor(viewer, options) {
    super(viewer, options);
    this.tool = new Move();
    this.viewer = viewer;
  }

  load() {
    this.viewer.toolController.registerTool(this.tool);
    return true;
  }

  unload() {
    this.viewer.toolController.deregisterTool(this.tool);
    return true;
  }

  static register() {
    const extensionName = 'TweaksxMove';
    Autodesk.Viewing.theExtensionManager.registerExtension(extensionName, MoveExtension);
    return extensionName;
  }

  startMoving(e, forceAxis) {
    console.info('TweaksxMove start');
    this.viewer.toolController.activateTool(TweaksMoveToolName);
    this.tool.onItemSelected(e, forceAxis);
  }

  handleClick(e, viewer) {
    return this.tool.updateHitPoint(e, viewer);
  }

  stopMoving() {
    console.info('TweaksxMove stop');
    this.viewer.toolController.deactivateTool(TweaksMoveToolName);
  }

  applyElementOffset(elementId: number, offset: THREE.Vector3, modelId) {
    let model;

    if (modelId === undefined) {
      model = this.viewer.model;
    } else {
      const models = this.viewer.getAllModels();
      model = models.filter((m) => m.id === modelId)[0];
    }

    const tree = model.getData().instanceTree;
    const elementFragIds = [];
    tree.enumNodeFragments(
      elementId,
      function (fragId) {
        elementFragIds.push(fragId);
      },
      true
    );

    for (let i = 0; i < elementFragIds.length; i++) {
      const fragment = elementFragIds[i];
      const fragProxy = this.viewer.impl.getFragmentProxy(model, fragment);
      fragProxy.getAnimTransform();
      fragProxy.position.add(offset);
      fragProxy.updateAnimTransform();
    }
    this.viewer.impl.sceneUpdated(true);
  }

  getElementOffset(elementId: number, modelId) {
    let model;
    if (modelId === undefined) {
      model = this.viewer.model;
    } else {
      const models = this.viewer.getAllModels();
      model = models.filter((m) => m.id === modelId)[0];
    }

    const tree = model.getData().instanceTree;
    const elementFragIds = [];
    tree.enumNodeFragments(elementId, function (fragId) {
      elementFragIds.push(fragId);
    });

    const fragProxy = this.viewer.impl.getFragmentProxy(model, elementFragIds[0]);

    fragProxy.getAnimTransform();
    return fragProxy.position;
  }
}
