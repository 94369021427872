/* eslint-disable no-console */
// @ts-nocheck

export class MaterialSwap extends Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.viewer = viewer;
    this.presets = null;
    this._swatch = null;
    this._urn =
      'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6dHdlYWtzX2FpX21hdGVyaWFscy9NYXRfUzFfV2hpdGVfV2FsbHMuZjNk'; // Use your own swatch model URN
  }

  load() {
    console.info('Tweaksx MaterialSwap extension has been loaded');
    //this._loadSwatchModel(this._urn).then((swatch) => this._swatch = swatch);
    return true; // This returning flag represents the load success
  }

  unload() {
    console.info('Tweaksx MaterialSwap extension has been unloaded');
    return true; // This returning flag represents the unload success
  }

  static register() {
    const extensionName = 'TweaksxMaterialSwap';
    Autodesk.Viewing.theExtensionManager.registerExtension(extensionName, MaterialSwap);
    return extensionName;
  }

  async swapMaterials(viewer, wallIds, ceilingIds) {
    await this.getPresets();
    const dbids = wallIds.concat(ceilingIds);

    const name = 'Wall_White_245em100';
    // const materials = viewer.impl.matman();
    // const material = this.presets.get(name);
    // materials.addMaterial(name, material, true);

    for (const dbid of dbids) {
      await this.applyPreset(name, viewer.model, dbid, this.presets);
    }

    return true;
  }

  /**
   * Lists all available material presets.
   * @async
   * @returns {Map} Mapping of preset names to instances of THREE.Material.
   */
  async getPresets() {
    if (this.presets) return;
    const models = this.viewer.getAllModels();
    let isLoaded = false;
    for (const model of models) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (model._isSwatch) {
        isLoaded = true;
        break;
      }
    }
    if (!isLoaded) {
      this._swatch = await this._loadSwatchModel(this._urn);
    }

    const tree = this._swatch.getInstanceTree();
    const presets = new Map();
    const frags = this._swatch.getFragmentList();
    if (!tree) throw new Error('Could not get the instance tree');
    tree.enumNodeChildren(
      tree.getRootId(),
      function (dbid) {
        if (tree.getChildCount(dbid) === 0) {
          const name = tree.getNodeName(dbid);
          tree.enumNodeFragments(
            dbid,
            function (fragid) {
              if (!presets.has(name)) {
                presets.set(name, frags.getMaterial(fragid));
              }
            },
            true
          );
        }
      },
      true
    );

    this.presets = presets;
  }

  /**
   * Applies material preset to specific object.
   * @async
   * @param {string} name Material preset name.
   * @param {Autodesk.Viewing.Model} targetModel Model that contains the object to be modified.
   * @param {number} targetObjectId DbID of the object to be modified.
   */
  async applyPreset(name, targetModel, targetObjectId, presets, newMaterial) {
    const material = newMaterial || presets.get(name);
    const tree = targetModel.getInstanceTree();
    const frags = targetModel.getFragmentList();
    tree.enumNodeFragments(
      targetObjectId,
      function (fragid) {
        frags.setMaterial(fragid, material);
      },
      true
    );
    targetModel.unconsolidate();
  }

  async _loadSwatchModel(urn) {
    const viewer = this.viewer;

    return new Promise((resolve, reject) => {
      function onSuccess(doc) {
        const viewable = doc.getRoot().getDefaultGeometry();

        viewer
          .loadDocumentNode(doc, viewable, {
            preserveView: true,
            keepCurrentModels: true,
            loadAsHidden: true
          })
          .then((model) => {
            model._isSwatch = true;

            // Poll for the tree until it's ready or until timeout
            let pollAttempts = 0;
            const maxAttempts = 50; // 5 seconds with 0.1-second polling
            const pollForTree = setInterval(() => {
              const tree = model.getInstanceTree();
              if (tree) {
                clearInterval(pollForTree);
                console.log('Tree is ready');
                resolve(model);
              } else if (pollAttempts++ >= maxAttempts) {
                clearInterval(pollForTree);
                console.log('Tree loading timed out');
                reject(new Error('Tree loading timed out'));
              }
            }, 100); // Poll every 0.1 seconds
          });
      }

      function onError(code, msg) {
        console.log('Error loading swatch model');
        reject(msg);
      }

      Autodesk.Viewing.Document.load('urn:' + urn, onSuccess, onError);
    });
  }
}
