import { Box, debounce, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import useBus, { dispatch } from 'use-bus';
import { useDispatch, useSelector } from 'react-redux';

import {
  CONTEXTS,
  doubleSocketOutLetNames,
  EVENT_TYPES,
  floorsDbidsByCategory,
  REAL_WORLD_SIZE_MAP,
  singleSocketOutLetNames,
  toolTipElementNameAndInfo,
  ToolTipInfo,
  ViewerEvents
} from 'shared/constants/AppConst';
import { messageSeverity } from 'shared/constants/AppConst';
import './style.css';
import moment from 'moment';
import { Customizer } from 'shared/extensions/tweaksx-customizer';
import { Rooms } from 'shared/extensions/tweaksx-rooms';
import { Markers, MarkerStatus } from 'shared/extensions/tweaks-markers';
import { MoveExtension } from 'shared/extensions/tweaksx-move';
import { MaterialSwap } from 'shared/extensions/tweaksx-materials';
import {
  IBlinkingElement,
  IElementInfo,
  IIntersectElement,
  IRoomInfo
} from 'types/models/ForgeViewer';
import {
  IModification,
  MODIFICATION_TYPE,
  IAddModificationAttrs,
  IRevitProperty,
  ILightModificationAttrs
} from 'types/models/Modification';
import { IMarker } from 'shared/extensions/tweaks-markers';
import { AppState } from 'redux/store';
import {
  setBaseModelLoaded,
  setMovedElementsData,
  setSelectedElements,
  setRooms as setModelRooms,
  getRoomCameras,
  getForgeAccessToken,
  setViewerScreenshot,
  setDeletedElementData,
  setIsTopView,
  SetLinkedFileMap
} from 'redux/actions/Viewer';
import { AssetCategory, AssetCategoryProperties, IAsset } from 'types/models/Asset';
import { useIntl } from 'react-intl';
import { openCatelogDialog } from 'redux/actions/Catelog';
import {
  setCameraInfo,
  getCustomRequests,
  setSelectedMarker,
  setCustomRequestViewMode
} from 'redux/actions/CustomRequest';
import { CustomRequestMode, CustomRequestStatus } from 'types/models/CustomRequest';
import { CameraInfoRespondTo } from 'types/models/Common';
import AppLoader from 'shared/core/AppLoader';
import { getAssets } from 'redux/actions/Asset';
import {
  CalcModificationPrice,
  getModifications,
  processModification,
  getElementModification,
  resetModifications,
  resetModificationStore
} from 'redux/actions/Modification';
import BIService from 'shared/utility/BIService';
import { getHome, updateHome, updateSelectedPackages } from 'redux/actions/Home';
import { ForgeUtils, IDbidWithModelId } from 'shared/utility/ForgeUtilities/ForgeUtils';
import { MarkUp3DExtension } from 'shared/extensions/tweaks-markupex';
import { PixelStreamingViewer } from 'pixel_streaming/PixelStreamingViewer';
import { usePreviewMode } from 'pixel_streaming/usePreviewMode';
import AppConfirmDialog from 'shared/core/AppConfirmDialog';
import IntlMessages from 'shared/utility/IntlMessages';
import { Catelog } from '../Catelog';
import { ICategoryOption, IRoomPackage } from 'pages/projects/projectDetail/RoomsList/RoomListItem';
import { IHome } from 'types/models/Home';
import ElementEditMenu from './ViewerControls/ElementEditMenu.tsx/ElementEditMenu';
import { OpenInFullOutlined } from '@mui/icons-material';
import AddCommentCustomRequest from './ViewerControls/CustomRequests/AddCommentCustomRequest';
import AddCustomRequestSnackBar from './ViewerControls/CustomRequests/AddCustomRequestSnackBar';
import { SET_MODIFICATIONS } from 'types/actions/Modification.actions';
import lodash from 'lodash';
import Chameleon from 'shared/utility/Chameleon';
import { findRoomKeyByRevitRoomName } from 'services/rooms/rooms.service';
import { useGoToRoom } from './hooks/useGoToRoom';
import jwtAxios from 'services/auth/jwt-auth';
import { useHomeStatus } from 'pages/homes/hooks/useHomeStatus';
import NavigationSnackBar from './ViewerControls/NavigationSnackBar/NavigationSnackBar';

declare const Autodesk: any;

interface BasicForgeViewerProps {
  modelUrn: string;
  readOnly?: boolean;
}

const extNameBimWalk = 'Autodesk.BimWalk';
const personHeight = 1.7;
const topViewCutHeight = 2.0;
const extNameMeasure = 'Autodesk.Measure';
const extSection = 'Autodesk.Section';

const movableCategories: Array<string> = [
  'Revit Electrical Fixtures',
  'Revit Lighting Devices',
  'Revit Lighting Fixtures'
];

const categoryHost = {
  'Revit Electrical Fixtures': 'Revit Walls',
  'Revit Lighting Devices': 'Revit Walls',
  'Revit Lighting Fixtures': 'Revit Ceilings'
};

export const BasicForgeViewer: React.FC<BasicForgeViewerProps> = ({ modelUrn, readOnly }) => {
  const [viewerInstance, setViewerInstance] = useState<Autodesk.Viewing.GuiViewer3D>(null);
  const viewerInstanceRef = useRef<Autodesk.Viewing.GuiViewer3D>(null);
  const navMapInstanceRef = useRef<Autodesk.Viewing.GuiViewer3D>(null);
  const navConeTarget = useRef<THREE.Mesh>(null);
  const [editCustomRequestPosition, setEditCustomRequestPosition] = useState<THREE.Vector2>(
    new THREE.Vector2(0, 0)
  );
  const [editMenuVisible, setEditMenuVisible] = useState<boolean>(false);
  const [editCustomRequestMenuVisible, setEditCustomRequestMenuVisible] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const isShowMarkersOnElements = useRef<boolean>(false);
  const currentAddedElementCatagory = useRef<string>(null);

  const isBaseModelLoaded = useRef(false);
  const isCollisionActive = useRef(false);
  const kitchenMaterialChanged = useRef(false);
  const modificationsLoaded = useRef(false);
  const modifications = useRef<IModification[]>([]);
  const linkedFileNamesToDbids = useRef({});
  const assets = useRef<IAsset[]>([]);
  const isPlacingAsset = useRef(false);
  const placingAssetUrn = useRef(null);
  const placingAssetCategory = useRef(null);
  const selectedLight = useRef<IElementInfo>(null);
  const customizerExtension = useRef(null);
  const roomsExtension = useRef(null);
  const isMovingElement = useRef(false);
  const movingHostDbId = useRef(null);
  const movingHostCategory = useRef(null);
  const movingElementDbId = useRef(null);
  const movingElementName = useRef(null);
  const movingElementInfo = useRef(null);
  const movingElementModelId = useRef(null);
  const movingElementCenter = useRef(null);
  const movingElementCategory = useRef(null);

  const isMouseDown = useRef(false);
  const floorLevel = useRef(null);
  const currentClosestFloorToCamera = useRef<number>(null);
  const currentRoom = useRef<IRoomInfo>(null);
  const homeRef = useRef(null);
  const customRequestModeRef = useRef(null);
  const modelUrnRef = useRef(modelUrn);
  const listenersMap = useRef({});
  const personHeightFromFloorLevel = useRef(null);
  const [viewerReady, setViewerReady] = useState(false);
  const [toolTipInfo, setToolTipInfo] = useState<ToolTipInfo>({ info: '', type: 'info' });
  const [, setShowToolTipWindow] = useState<boolean>(false);
  const [previewMode] = usePreviewMode();
  const fetchedAssetsRef = useRef([]);
  const { handleMenuItemClick } = useGoToRoom();
  const { isHomeLockedForChanges } = useHomeStatus();
  const isHomeLockedForChangesRef = useRef<boolean>(false);

  const reduxDispatch = useDispatch();
  const { messages } = useIntl();

  const { templateId, selectedElements, isTopView } = useSelector<AppState, AppState['viewer']>(
    ({ viewer }) => viewer
  );

  const {
    mode: customRequestMode,
    markerToRemove,
    customRequests
  } = useSelector<AppState, AppState['customRequest']>(({ customRequest }) => customRequest);

  const { items: modificationsFromStore, itemsFetched: modificationsFetched } = useSelector<
    AppState,
    AppState['modification']
  >(({ modification }) => modification);

  const { items: fetchedAssets } = useSelector<AppState, AppState['asset']>(({ asset }) => asset);

  const { item: home } = useSelector<AppState, AppState['home']>(
    ({ home: homeInstance }) => homeInstance
  );

  const moveExtension = useRef(null);
  const markerExtension = useRef(null);
  const materialSwapExtension = useRef(null);

  const getPackagesByRoom = () => {
    const packagesAndRoom = Object.keys(linkedFileNamesToDbids.current).filter((x) =>
      x.includes('package')
    );

    const packages = packagesAndRoom.map((x) => x.split('-')[1]).map((x) => x.split('.')[0]);
    const room = packagesAndRoom.map((x) => x.split('-')[0]);
    const roomsAndPackages = [];
    for (let i = 0; i < room.length; i++) {
      const name = Object.keys(linkedFileNamesToDbids.current).find(
        (x) => x.includes(room[i]) && x.includes(packages[i])
      );

      if (roomsAndPackages.length > 0 && roomsAndPackages?.find((x) => x.roomid === room[i])) {
        const index = roomsAndPackages.findIndex((x) => x.roomid === room[i]);
        roomsAndPackages[index].package.push({
          name: packages[i],
          dbids: linkedFileNamesToDbids.current[name]
        });
      } else {
        roomsAndPackages.push({
          roomid: room[i],
          package: [
            { name: 'STANDARD' },
            { name: packages[i], dbids: linkedFileNamesToDbids.current[name] }
          ]
        });
      }
    }

    return roomsAndPackages;
  };

  const initalPackagesHideAndShow = () => {
    const rooms = customizerExtension.current.rooms as IRoomInfo[];

    // Hiding all packages
    rooms.forEach((room: IRoomInfo) => {
      room.packages?.forEach((roomPackage) => {
        if (roomPackage.name !== 'STANDARD') {
          showPackage(room.category, roomPackage.name, false);
          console.log('---- HIDING PACKAGE -----');
          console.log(room.category, roomPackage.name);
        }
      });
    });

    // Showing just selected packages
    homeRef.current?.selectedPackages?.forEach(async (selectedPackage) => {
      console.log('---- SHOWING PACKAGE -----');
      console.log(selectedPackage.packageId);
      const packagesToShow = homeRef.current.modelTemplate.packages.find(
        (x) => x._id === selectedPackage.packageId
      ) as IRoomPackage;

      if (packagesToShow) {
        showPackage(packagesToShow.room, packagesToShow.name, true);
        console.log(packagesToShow.name);
      }
    });
  };

  const getRooms = async () => {
    const _rooms: IRoomInfo[] = await roomsExtension.current.getRooms();

    for (let i = 0; i < _rooms.length; i++) {
      const roomsAndPackages = getPackagesByRoom();
      const roomPackage = roomsAndPackages.find((x) => x.roomid === _rooms[i].category);

      if (roomPackage) {
        _rooms[i].packages = roomPackage.package;
      } else {
        _rooms[i].packages = [
          {
            name: 'STANDARD',
            price: 0,
            title: 'Standard',
            summary: '',
            description: '',
            room: _rooms[i].category,
            categories: []
          }
        ];
      }
    }

    customizerExtension.current.setRooms(_rooms);
    reduxDispatch(setModelRooms(_rooms));

    // Draw roomsBboxs
    // customizerExtension.current.drawBBoxHelper(_rooms.map((x) => x.boundingBox));

    const rooms_list = _rooms.map((room) => room.category);

    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.ROOMS_DATA_LOADED, CONTEXTS.MODEL, {
      rooms_list
    });
  };

  // adds event listener to element and takes care of removing any previous listeners
  // if any. used when reloading the model to prevent multiple listeners to be attached
  const handleListener = (el, eventName, func, key = null) => {
    const eventKey = key || eventName;
    if (listenersMap.current[eventKey]) {
      el.removeEventListener(eventName, listenersMap.current[eventKey]);
    }

    el.addEventListener(eventName, func);
    listenersMap.current[eventKey] = func;
  };

  const displayViewer = () => {
    const viewerOptions = {
      env: 'AutodeskProduction',
      api: 'derivativeV2',
      // env: 'AutodeskProduction2',
      // api: 'streamingV2',
      getAccessToken: async function (onTokenReady) {
        const forgeAccessToken = await getForgeAccessToken();
        const token = forgeAccessToken.access_token;
        const timeInSeconds = forgeAccessToken.expires_in;
        onTokenReady(token, timeInSeconds);
      }
    };

    const customizerExtName = Customizer.register();
    const RoomsExtName = Rooms.register();
    const MoveExtName = MoveExtension.register();
    const MarkersExtName = Markers.register();
    const MaterialSwapExtName = MaterialSwap.register();
    const MarkUpExtName = MarkUp3DExtension.register();

    const viewerContainer = document.getElementById('viewerContainer');
    const viewerContainer2 = document.getElementById('viewerContainer2');

    const config = {
      extensions: [
        extNameBimWalk,
        RoomsExtName,
        MoveExtName,
        extNameMeasure,
        extSection,
        MaterialSwapExtName,
        MarkUpExtName
      ]
    };

    // We are using GuiViewer3D insted of Viewer3D due to THREE.TransformControls dependency on Move extension
    const viewer = new Autodesk.Viewing.GuiViewer3D(viewerContainer, config);
    const viewer2 = new Autodesk.Viewing.GuiViewer3D(viewerContainer2, config);
    // const factory = new Autodesk.Viewing.MultiViewerFactory();
    // const viewer = factory.createViewer(viewerContainer, config, Autodesk.Viewing.GuiViewer3D);
    // const viewer2 = factory.createViewer(viewerContainer2, config, Autodesk.Viewing.GuiViewer3D);

    viewerInstanceRef.current = viewer;
    navMapInstanceRef.current = viewer2;
    viewerInstanceRef.current.loadExtension(customizerExtName, {
      selectionChangedCallback: selectionChanged,
      showToolTipCallBack: showToolTip,
      updateSelectionPositionCallBack: (e) => moveCurrentSelectedElementToPosition(e),
      onEndDraggingCallBack: onEndDraggingCallBack,
      cancelDragging: cancelDragging
    });

    viewerInstanceRef.current.loadExtension(MarkersExtName, {
      onMarkerClick,
      onMarkerPlaced
    });

    const fn = () => {
      viewer.navigation.toPerspective();

      // Increase the field of view by reducing the focal lenght (default is 24mm)
      viewer.navigation.setFocalLength(16);

      const customExt = viewer.getExtension(customizerExtName);
      customizerExtension.current = customExt;

      const roomsExt = viewer.getExtension(RoomsExtName);
      roomsExtension.current = roomsExt;

      const moveExt = viewer.getExtension(MoveExtName);
      moveExtension.current = moveExt;

      const markersExt = viewer.getExtension(MarkersExtName);
      markerExtension.current = markersExt;

      const materialSwapExt = viewer.getExtension(MaterialSwapExtName);
      materialSwapExtension.current = materialSwapExt;

      const markUpExt = viewer.getExtension(MarkUpExtName);
      console.log(markUpExt);
    };

    handleListener(viewer, Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, fn);

    const canvasClickFn = (event) => {
      moveExtension.current.handleClick(event, viewer);
    };

    handleListener(viewer.canvasWrap, 'click', canvasClickFn, 'canvasWrap.click');

    setViewerInstance(viewer);

    window.addEventListener('click', () => {
      setEditMenuVisible(false);
    });

    window.addEventListener('keydown', () => {
      setEditMenuVisible(false);
    });

    Autodesk.Viewing.Initializer(viewerOptions, () => {
      viewer.start();
      viewer2.start(null, null, null, null, {
        webglInitParams: {
          alpha: true
        }
      });

      const aecProfileSettings = Autodesk.Viewing.ProfileSettings.AEC;
      const customProfileSettings = Autodesk.Viewing.ProfileSettings.clone(aecProfileSettings);

      // Following line hides the first person camera instructions popup
      customProfileSettings.settings.bimWalkToolPopup = false;
      // Disabling the anti aliasing increases the performance and the result still great
      customProfileSettings.settings.antialiasing = false;
      const customProfile = new Autodesk.Viewing.Profile(customProfileSettings);
      viewer.setProfile(customProfile);

      // Changing highlight color to be less prominent
      viewer.impl.setSelectionColor(new THREE.Color(0.5, 0.5, 0.5));
      viewer.impl.selectionMaterialBase.opacity = 0.02;
      viewer.impl.selectionMaterialTop.opacity = 0.02;

      loadModel(viewer);
    });
  };

  const onMarkerClick = (marker) => {
    // if (marker.data.customRequestId) {
    //   const biService = BIService.getInstance();
    //   biService.logEvent(EVENT_TYPES.SHOW_REQUEST, CONTEXTS.REQUESTS, {
    //     request_id: marker.data.customRequestId
    //   });
    //   reduxDispatch(setCustomRequestViewMode(CustomRequestMode.CLOSE));
    //   reduxDispatch(getCustomRequestComments(marker.data.customRequestId));
    //   reduxDispatch(getCustomRequest(marker.data.customRequestId));
    // }
  };

  const onMarkerPlaced = (marker: IMarker) => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.REQUEST_ON_ELEMENT, CONTEXTS.REQUESTS, {
      element_id: marker.data.dbId
    });

    reduxDispatch(setSelectedMarker(marker));
    dispatch({
      type: ViewerEvents.CameraInfoRequest,
      payload: CameraInfoRespondTo.CUSTOM_REQUEST
    });
  };

  useBus(ViewerEvents.SelectClosestFloor, (e) => {
    if (currentClosestFloorToCamera.current) {
      viewerInstanceRef.current.select(currentClosestFloorToCamera.current);
    }
  });

  useBus(ViewerEvents.ShowHightLights, (e) => {
    isShowMarkersOnElements.current = e.isActive;

    if (isShowMarkersOnElements.current) {
      drawMarkersOnSelectableElements();
    } else {
      drawMarkersOnSelectableElements();
    }
  });

  useBus(ViewerEvents.SelectWallTiles, (e) => {
    if (!currentRoom.current) return;
    const roomName = currentRoom.current.properties.find(
      (x) => x.displayName === 'Name'
    ).displayValue;

    const dbidsGroupMember = Customizer.getWallsByGroupName(roomName);
    if (dbidsGroupMember && dbidsGroupMember.length > 0)
      viewerInstanceRef.current.select(dbidsGroupMember[0].dbId);
  });

  useBus(ViewerEvents.CameraInfoRespForCustomRequest, (e) => {
    reduxDispatch(setCameraInfo(e.payload));
  });

  useBus(
    ViewerEvents.AddElement,
    (e) => {
      const biService = BIService.getInstance();
      if (ForgeUtils.isSwitch(e.payload.name)) {
        if (selectedLight.current) {
          addSwitchModeAfterLightSelection();
        }
        currentAddedElementCatagory.current = 'Switches';
        console.log(e.payload);

        biService.logEvent(EVENT_TYPES.ADD_ASSET_CATALOG_SELECTION, CONTEXTS.VIEW, {
          category: currentAddedElementCatagory.current,
          name: e.payload.name
        });

        return;
      }

      if (selectedElements?.length > 0 && ForgeUtils.isOutlet(selectedElements[0].name)) {
        console.log(selectedElements[0]);
        console.log(e.payload);

        if (
          ForgeUtils.isDoubleOutlet(e.payload.name) &&
          ForgeUtils.isDoubleOutlet(selectedElements[0].name)
        )
          return;

        if (
          ForgeUtils.isSingleOutlet(e.payload.name) &&
          ForgeUtils.isSingleOutlet(selectedElements[0].name)
        )
          return;

        if (ForgeUtils.isOutlet(e.payload.name)) {
          if (ForgeUtils.isSingleOutlet(e.payload.name)) {
            biService.logEvent(EVENT_TYPES.REPLACE_ASSET, CONTEXTS.VIEW, {
              category: 'Single Outlet'
            });

            changeOutLetType(false);
          } else if (ForgeUtils.isDoubleOutlet(e.payload.name)) {
            biService.logEvent(EVENT_TYPES.REPLACE_ASSET, CONTEXTS.VIEW, {
              category: 'Double Outlet'
            });

            changeOutLetType(true);
          }
        }

        return;
      }

      if (ForgeUtils.isOutlet(e.payload.name)) {
        const oneSocketOutletUrn = fetchedAssets.find((x) => x.socketAmount === 1).urn;
        const doubleSocketOutletUrn = fetchedAssets.find((x) => x.socketAmount === 2).urn;

        isPlacingAsset.current = true;
        customizerExtension.current.isPlacingAsset = true;
        if (ForgeUtils.isSingleOutlet(e.payload.name)) {
          placingAssetUrn.current = oneSocketOutletUrn;
        } else if (ForgeUtils.isDoubleOutlet(e.payload.name)) {
          placingAssetUrn.current = doubleSocketOutletUrn;
        }
        if (ForgeUtils.isSingleOutlet(e.payload.name)) {
          currentAddedElementCatagory.current = 'Single Outlet';
        } else if (ForgeUtils.isDoubleOutlet(e.payload.name)) {
          currentAddedElementCatagory.current = 'Double Outlet';
        }

        biService.logEvent(EVENT_TYPES.ADD_ASSET_CATALOG_SELECTION, CONTEXTS.VIEW, {
          category: currentAddedElementCatagory.current,
          name: e.payload.name
        });

        placingAssetCategory.current = AssetCategory.OUTLETS;
        dispatch({
          type: ViewerEvents.ValidationMessage,
          payload: {
            type: messageSeverity.INFO,
            message: String(messages['actions.placeAnElement'])
          }
        });
      }
    },
    [selectedElements, fetchedAssets]
  );

  async function onFirstLoadFinished(viewer) {
    function onViewerResized() {
      // Without this, the Forge viewer stops working properly when resized.
      viewer.resize();
    }
    new ResizeObserver(onViewerResized).observe(viewer.container);

    // Setting default BimWalk distance from floor
    const bimWalkExt = viewer.getExtension(extNameBimWalk);
    bimWalkExt.tool.navigator.configuration.cameraDistanceFromFloor = personHeight;
    bimWalkExt.tool.navigator.enableGravity(false);

    // Disable walking forward/backward by using the mouse scroll wheel
    bimWalkExt.tool.navigator.handleWheelInput = function () {
      return true;
    };

    viewer.setContextMenu(null);

    // const keydown = viewer.impl.controls.handleKeyDown.bind(viewer.impl.controls);
    // viewer.impl.controls.handleKeyDown = function (e) {
    //   const ext = viewer.getExtension(extSection);
    //   if (ext.getSectionPlanes().length > 0 && viewer.getSelection().length === 0) {
    //     //return; // Prevents disabling section plane by pressing any key
    //   }

    //   keydown(e);
    // };
  }

  useEffect(() => {
    fetchedAssetsRef.current = fetchedAssets;
  }, [fetchedAssets]);

  useEffect(() => {
    isHomeLockedForChangesRef.current = isHomeLockedForChanges;
  }, [isHomeLockedForChanges]);

  useEffect(() => {
    if (viewerReady && !previewMode && isTopView) {
      // start from the living room which is the first room in the list
      handleMenuItemClick(0);
      const chameleon = Chameleon.getInstance();
      chameleon.track('editor_mode');
      setTimeout(() => {
        viewerInstanceRef.current.resize();
      }, 20);
    }
  }, [previewMode]);

  useEffect(() => {
    if (!viewerInstanceRef.current?.container) {
      displayViewer();
      reduxDispatch(getAssets());
    }
  }, []);

  useEffect(() => {
    if (templateId) {
      reduxDispatch(getRoomCameras(templateId));
    }
  }, [templateId]);

  useEffect(() => {
    if (home) {
      homeRef.current = home;
    }
  }, [home]);

  useEffect(() => {
    if (home) {
      reduxDispatch(getCustomRequests(homeRef.current?._id));
    }
  }, [home?._id]);

  useEffect(() => {
    viewerInstanceRef.current.container.addEventListener('mousemove', (e) => {
      if (isMouseDown.current) setEditMenuVisible(false);
    });

    viewerInstanceRef.current.canvas.addEventListener('mousedown', (e) => {
      if (customRequestModeRef.current === CustomRequestMode.EDIT && !readOnly) {
        isMouseDown.current = true;
        const rect = viewerInstanceRef.current.canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        setEditCustomRequestPosition(new THREE.Vector2(x, y));
        setEditCustomRequestMenuVisible(true);
      }
    });

    viewerInstanceRef.current.container.addEventListener('mouseup', (e) => {
      isMouseDown.current = false;
    });

    return () => {
      viewerInstanceRef.current.container.removeEventListener('mousemove', (e) => {});
      viewerInstanceRef.current.canvas.removeEventListener('mousedown', (e) => {});
      viewerInstanceRef.current.container.removeEventListener('mouseup', (e) => {});
    };
  }, []);

  useEffect(() => {
    customRequestModeRef.current = customRequestMode;

    if (customRequestModeRef.current === CustomRequestMode.CLOSE) {
      // viewerInstanceRef.current?.setNavigationLock(false);

      setEditCustomRequestMenuVisible(false);
    }
  }, [customRequestMode, viewerInstanceRef.current]);

  useEffect(() => {
    // prevent running it on mount
    if (modelUrnRef.current !== modelUrn) {
      // if home already has a model template assigned we need to run a fullModelReload
      if (home?.modelTemplate) {
        markerExtension.current.reload();
        fullModelReload();
      } else {
        reloadModel();
      }

      modelUrnRef.current = modelUrn;
    }
  }, [modelUrn, home]);

  const setTopView = (viewer) => {
    reduxDispatch(setIsTopView(true));

    viewer.setNavigationLock(false);
    setEditMenuVisible(false);
    // moveExtension.current.stopMoving(); TODO: To be analyzed later (JEPS)
    // Deactivate first person mode
    setFirstPersonView(false, false);
    // Prepare the camera as top view
    viewer.navigation.setView(new THREE.Vector3(0, 0, 1), new THREE.Vector3(0, 0, 0));
    // Set Y(+) axis as UP vector to always get the same model orientation
    viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 1, 0));
    // Fit view to model
    viewer.fitToView(null, null, true);
    // Lock the viewer to prevent zooming, orbiting, etc
    viewer.setNavigationLock(true);

    applySectionCut(viewer);
  };

  const applySectionCut = (viewer) => {
    const ext = viewer.getExtension(extSection);
    if (floorLevel.current) {
      const cutHeight = floorLevel.current + topViewCutHeight * 3.2808399;

      (ext as any).setSectionPlane(
        new THREE.Vector3(0, 0, 1),
        new THREE.Vector3(0, 0, cutHeight),
        false
      );

      // Remove section plane controls UI
      viewer.impl.removeOverlayScene('gizmo');

      (ext as any).displaySectionHatches(false);

      // console.log('asjkfnasjfasjkfnajsfnkansfj');
      // const section3D = viewer.scene.getObjectByName('section3D');
      // console.log(section3D);
      // if (section3D) viewer.scene.remove(section3D);

      // const section2D = viewer.sceneAfter.getObjectByName('section2D');
      // console.log(section2D);
      // if (section2D) viewer.sceneAfter.remove(section2D);
    }
  };

  const setFirstPersonView = (active: boolean, moveToOrigin: boolean) => {
    if (!viewerInstance) return;
    setEditMenuVisible(false);
    const extension = viewerInstance.getExtension(extNameBimWalk);
    if (active) {
      reduxDispatch(setIsTopView(false));
      const ext = viewerInstance.getExtension(extSection);
      (ext as any).deactivate(false);

      viewerInstance.setNavigationLock(false);
      if (moveToOrigin) {
        viewerInstance.navigation.setView(
          new THREE.Vector3(0, 0, personHeightFromFloorLevel.current),
          new THREE.Vector3(0, 1, personHeightFromFloorLevel.current)
        );
      }
      (extension as any).activate();
      // Set Z(+) axis as UP vector
      viewerInstance.navigation.setCameraUpVector(new THREE.Vector3(0, 0, 1));
      dispatch({
        type: ViewerEvents.onFirstPersonView
      });
    } else {
      (extension as any).deactivate();
    }
  };

  const applyPackageCategories = async (
    roomName: string,
    packageCategories: { [key: string]: ICategoryOption }
  ) => {
    if (roomName.includes('Kitchen') || roomName.includes('מטבח')) {
      const kitchenColorCategoryOption =
        packageCategories &&
        (packageCategories.Color ||
          packageCategories.Colors ||
          packageCategories.Material ||
          packageCategories['גוון ארונות']);

      if (kitchenColorCategoryOption) {
        const kitchenDbIds = await ForgeUtils.getDbIdsByProperty(
          viewerInstanceRef.current,
          'Kitchen Parameter',
          'Cabinet'
        );

        const kitchenMaterial = {
          id: kitchenColorCategoryOption._id,
          textureFileUrl: kitchenColorCategoryOption.imageUrl,
          reflectivity: 0,
          realWorldSize: 80
        };

        kitchenDbIds.forEach((dbId) => {
          customizerExtension.current.changeMaterialByElementId(dbId, kitchenMaterial);
        });

        kitchenMaterialChanged.current = true;
      } else if (kitchenMaterialChanged.current) {
        const kitchenDbIds = await ForgeUtils.getDbIdsByProperty(
          viewerInstanceRef.current,
          'Kitchen Parameter',
          'Cabinet'
        );
        kitchenDbIds.forEach((dbId) => {
          customizerExtension.current.restoreMaterialByElementId(dbId);
        });
        kitchenMaterialChanged.current = false;
      }
    }
  };

  const showPackage = (roomId: string, packageName: string, visibily: boolean) => {
    // If its standard package we hide all the packages and show base again for the room
    const rooms = customizerExtension.current.rooms as IRoomInfo[];

    const roomPackage = rooms
      .find((x) => x.category === roomId)
      .packages?.find((x) => x.name === packageName);

    const fullPackage = home?.modelTemplate.packages.find(
      (x) => x.name === roomPackage.name && x.room === x.room
    );

    if (packageName === 'STANDARD') {
      rooms
        .find((x) => x.category === roomId)
        .packages?.forEach((element) => {
          element.dbids?.dbidsToShow?.forEach((element) => {
            viewerInstanceRef.current.impl.visibilityManager.setNodeOff(element.dbid, true);
          });

          element.dbids?.dbidsToHideFromBase?.forEach((element) => {
            viewerInstanceRef.current.impl.visibilityManager.setNodeOff(element, false);
          });
        });

      // Otherwise we show the package and hide the base
    } else {
      rooms
        .find((x) => x.category === roomId)
        .packages?.forEach((element) => {
          element.dbids?.dbidsToShow?.forEach((element) => {
            viewerInstanceRef.current.impl.visibilityManager.setNodeOff(element.dbid, true);
          });

          element.dbids?.dbidsToHideFromBase?.forEach((element) => {
            viewerInstanceRef.current.impl.visibilityManager.setNodeOff(element, false);
          });
        });

      roomPackage.dbids?.dbidsToShow?.forEach((element) => {
        viewerInstanceRef.current.impl.visibilityManager.setNodeOff(element.dbid, !visibily);
      });

      roomPackage.dbids?.dbidsToHideFromBase?.forEach((element) => {
        viewerInstanceRef.current.impl.visibilityManager.setNodeOff(element, visibily);
      });
    }

    const homeSelectedPackages = home?.selectedPackages.filter(
      (s) => s.packageId === fullPackage?._id
    );

    const categoriesSelectionData: { [key: string]: ICategoryOption } = {};
    if (homeSelectedPackages) {
      homeSelectedPackages.forEach((p) => {
        const category = fullPackage?.categories?.find((x) => x._id === p?.categoryId);
        if (category) {
          const selectedOption = category.options.find((x) => x._id === p.optionId);
          categoriesSelectionData[category.name] = selectedOption;
        }
      });
    }

    applyPackageCategories(roomId, categoriesSelectionData);
    drawMarkersOnSelectableElements();
  };

  const selectFloorByCategory = (category: string) => {
    viewerInstanceRef.current.clearSelection();
    const dbids = floorsDbidsByCategory.get(category);
    if (!dbids) {
      console.log('Categorynot found!');
      return;
    }
    viewerInstanceRef.current.select(dbids);
  };

  const getFloorDbidsByCategory = (category: string): number => {
    const dbids = floorsDbidsByCategory.get(category);
    if (!dbids) {
      console.log('Categorynot found!');
      return;
    }
    return dbids;
  };

  const getFloorCatagories = (category: string): string[] => {
    const names = Array.from(floorsDbidsByCategory.keys());
    if (!names) {
      console.log('Categorynot found!');
      return;
    }
    return names;
  };

  const createPackagesMap = async (viewer: Autodesk.Viewing.GuiViewer3D) => {
    const externalIdMapping = await ForgeUtils.getExternalIdMappingAsync(viewer.model);

    const linkedDbIdsMap = {};
    const linkedFileNamesMap = {};
    const baseModelRevitIdMap: Map<number, number> = new Map();
    // get all dbids from the viewer's instance tree
    const dbIds = Object.keys(viewer.model.getInstanceTree().nodeAccess.dbIdToIndex).map(Number);

    // get the externalId of all the dbids
    const props: Autodesk.Viewing.PropertyResult[] = await ForgeUtils.getBulkProperties(
      viewer.model,
      dbIds,
      ['externalId', 'ElementId', 'Group']
    );
    const hidingBaseDbids: Map<IRoomPackage, number[]> = new Map();
    const groupIdsMap: Map<number, number[]> = new Map();

    // loop through the externalIds and find the ones who have '/' - it means that this element is from linked file
    // example of externalId - xxx/yyy - when xxx is the id of the linked file and yyy the id of the element
    props.forEach((prop) => {
      const externalId = prop.externalId;
      const elementId = prop.properties.find((x) => x.displayName === 'ElementId');

      let revitId = elementId?.displayValue.toString();

      if (revitId?.includes('/')) {
        revitId = elementId?.displayValue.toString().split('/')[1];
      }

      if (externalId && externalId.includes('/')) {
        const linkedFileId = externalIdMapping[externalId.split('/')[0]];
        if (!linkedDbIdsMap[linkedFileId]) {
          linkedDbIdsMap[linkedFileId] = [];
        }

        linkedDbIdsMap[linkedFileId].push({
          dbid: prop.dbId,
          revitId: Number(revitId)
        });
      }
      // revit id also in base Model
      else {
        baseModelRevitIdMap.set(Number(revitId), prop.dbId);

        homeRef.current?.modelTemplate?.packages?.forEach((packageItem: IRoomPackage) => {
          if (packageItem.revitIdsToHideFromBase?.includes(revitId)) {
            // hidingBaseDbids.set(Number(revitId), prop.dbId);
            if (!hidingBaseDbids.has(packageItem)) {
              hidingBaseDbids.set(packageItem, [prop.dbId]);
            } else {
              const dbids = hidingBaseDbids.get(packageItem);
              dbids.push(prop.dbId);
              hidingBaseDbids.set(packageItem, dbids);
            }
          }
        });

        // we will use the group id to find all the elements that are in the same group
        const group = prop.properties.find((x) => x.displayName === 'Group');
        if (group) {
          const groupId = Number(group.displayValue);
          if (!groupIdsMap.has(groupId)) {
            groupIdsMap.set(groupId, []);
          }
          const dbids = groupIdsMap.get(groupId);
          dbids.push(prop.dbId);
          groupIdsMap.set(groupId, dbids);
        }
      }
    });

    // we will translate the groupIds map to use group names instead of group ids
    const groupNamesMap: Map<string, number[]> = new Map();
    const groupIds = Array.from(groupIdsMap.keys());
    const groupProps: Autodesk.Viewing.PropertyResult[] = await ForgeUtils.getBulkProperties(
      viewer.model,
      groupIds,
      ['Type Name']
    );

    groupProps.forEach((prop) => {
      const groupName = prop.properties[0].displayValue as string;
      const dbids = groupIdsMap.get(prop.dbId);
      groupNamesMap.set(groupName, dbids);
    });

    // we translate the map from using file ids, to filenames.

    const linksDbIds = Object.keys(linkedDbIdsMap).map(Number);
    let linksProps: Autodesk.Viewing.PropertyResult[] = [];
    if (linksDbIds.length > 0)
      linksProps = await ForgeUtils.getBulkProperties(viewer.model, linksDbIds, ['Type Name']);

    linksProps?.forEach(async (prop) => {
      const baseModelDbids = [];
      const fileName = prop.properties[0].displayValue as string;
      if (!fileName.includes('package')) {
        return;
      }

      linkedDbIdsMap[prop.dbId].forEach((element) => {
        const isInBaseModel = baseModelRevitIdMap.has(element.revitId);

        if (isInBaseModel) {
          const baseModelDbid = baseModelRevitIdMap.get(element.revitId);
          baseModelDbids.push(baseModelDbid);
        }
      });

      if (typeof prop.properties[0].displayValue === 'string') {
        const packageName = prop.properties[0].displayValue.replace('.rvt', '');
        hidingBaseDbids.forEach((dbids, packageItem) => {
          if (packageName.includes(packageItem.room) && packageName.includes(packageItem.name)) {
            baseModelDbids.push(...dbids);
          }
        });
      }

      linkedFileNamesMap[prop.properties[0].displayValue] = {
        dbidsToShow: linkedDbIdsMap[prop.dbId],
        dbidsToHideFromBase: baseModelDbids
      };

      reduxDispatch(SetLinkedFileMap(linkedFileNamesMap));
    });

    // we will loop through the groups and check if the packages includes the group name
    // if it does, we will add the group dbids to the dbidsToHideFromBase array of the package
    groupNamesMap.forEach((dbids, groupName) => {
      homeRef.current?.modelTemplate?.packages?.forEach((packageItem: IRoomPackage) => {
        if (packageItem.revitIdsToHideFromBase?.includes(groupName)) {
          //get the keys of the linkedFileNamesMap and loop through them to find the one that includes the package name
          const linkedFileNames = Object.keys(linkedFileNamesMap);
          linkedFileNames.forEach((linkedFileName) => {
            if (
              linkedFileName.includes(packageItem.room) &&
              linkedFileName.includes(packageItem.name)
            ) {
              linkedFileNamesMap[linkedFileName].dbidsToHideFromBase.push(...dbids);
            }
          });
        }
      });
    });

    linkedFileNamesToDbids.current = linkedFileNamesMap;
    console.log('linkedFileNamesMap', linkedFileNamesMap);

    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.PACKAGES_CREATED, CONTEXTS.MODEL, {
      linkedFileNames: Object.keys(linkedFileNamesMap)
    });
  };

  const setPackagesDbidsUnSeletcable = () => {
    const unSelectableDbids = [];
    const packagesDbids = Object.keys(linkedFileNamesToDbids.current).filter((x) =>
      x.includes('package')
    );

    packagesDbids.forEach((element) => {
      const packageDbids = linkedFileNamesToDbids.current[element].dbidsToShow;
      packageDbids.forEach((element) => {
        unSelectableDbids.push(element.dbid);
      });
    });

    customizerExtension.current.removePackageDbidsFromSelectableDbids(unSelectableDbids);
  };

  const disableKeyboardEvents = () => {
    document.addEventListener(
      'keydown',
      function (event) {
        if (
          event.key === 'q' ||
          event.key === 'Q' ||
          event.key === 'h' ||
          event.key === 'H' ||
          event.key === 'f' ||
          event.key === 'F'
        ) {
          event.stopPropagation(); // Stops the event from propagating further
        }
      },
      true
    );

    // const _canvas = viewerInstanceRef.current.canvas;
    // _canvas.addEventListener(
    //   'dblclick',
    //   function (event) {
    //     viewerInstanceRef.current.navigation.event.preventDefault();
    //     return false;
    //   },
    //   { capture: true }
    // );

    // viewerInstanceRef.current.setClickConfig('left', 'double-click', ['doNothing']);

    //     const config = {
    //       "click": {
    //           "onObject": ["selectOnly"],
    //           "offObject": ["deselectAll"]
    //       },
    //       "clickAlt": {
    //           "onObject": ["setCOI"],
    //           "offObject": ["setCOI"]
    //       },
    //       "clickCtrl": {
    //           "onObject": ["selectToggle"]
    //           // don't deselect if user has control key down https://jira.autodesk.com/browse/LMV-1852
    //           //"offObject": ["deselectAll"]
    //       },
    //       "clickShift": {
    //           "onObject": ["selectToggle"]
    //           // don't deselect if user has shift key down https://jira.autodesk.com/browse/LMV-1852
    //           //"offObject": ["deselectAll"]
    //       },
    // }

    // viewerInstanceRef.current.setCanvasClickBehavior({
    //   click: true,
    //   clickAlt: true,
    //   clickCtrl: true,
    //   clickShift: true,
    //   doubleClick: true,
    //   doubleClickAlt: true,
    //   doubleClickCtrl: true,
    //   doubleClickShift: true
    // });

    // viewerInstanceRef.current.navigation.FIT_TO_VIEW_VERTICAL_OFFSET = 0;
    // viewerInstanceRef.current.canvas.addEventListener('dblclick', (e) => {
    //   console.log('asas');
    // });

    const keyboardTool = viewerInstanceRef.current.toolController.getTool('hotkeys');
    const originalHandleKeyEvent = keyboardTool.handleKeyDown.bind(keyboardTool);

    keyboardTool.handleKeyDown = function (event) {
      if (
        event.key === 'q' ||
        event.key === 'Q' ||
        event.key === 'h' ||
        event.key === 'H' ||
        event.key === 'f' ||
        event.key === 'F'
      ) {
        return true;
      }
      return originalHandleKeyEvent(event);
    };
  };

  const loadModel = (viewer: Autodesk.Viewing.GuiViewer3D) => {
    const newurn = process.env.REACT_APP_LOCAL_MODEL_URN || modelUrn;

    const urn = 'urn:' + newurn;

    const startTime = performance.now();

    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.MODEL_LOAD_STARTED, CONTEXTS.MODEL, {
      urn: modelUrn,
      homeId: home?._id,
      homeNumber: home?.homeNumber,
      project: home?.project,
      model: home?.model,
      modelTemplateName: home?.modelTemplate.name
    });

    //@ts-ignore
    navMapInstanceRef.current.setBackgroundOpacity(0.7);

    if (home?.forceModelCache) {
      Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS['If-Modified-Since'] =
        'Sat, 29 Oct 1994 19:43:31 GMT';
    }

    Autodesk.Viewing.Document.load(urn, async (doc: any) => {
      const defaultModel = doc.getRoot().getDefaultGeometry();
      const masterViewModel = doc.getRoot().getDefaultGeometry(true);

      // load the base model
      await loadModelGeometry(viewer, doc, defaultModel, '_isBase', {
        skipHiddenFragments: false,
        preserveView: false,
        keepCurrentModels: true
      });

      isBaseModelLoaded.current = true;
      reduxDispatch(setBaseModelLoaded(true));
      onFirstLoadFinished(viewer);
      initEnvSettings(viewer);
      createWallsCollisionDetection(viewer);
      setWallsCollisionActive(true);

      // get the rooms ids from the base model, and load only the rooms within the masterView to get the rooms data (bounding boxes)
      const roomsIds = await roomsExtension.current.getRoomDbIds(defaultModel);
      await loadModelGeometry(viewer, doc, masterViewModel, '_isMaster', {
        skipHiddenFragments: false,
        keepCurrentModels: true,
        ids: roomsIds,
        globalOffset: viewer.model.getGlobalOffset()
      });

      delete Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS['If-Modified-Since'];

      await createPackagesMap(viewer);

      //get rooms and hide them
      await getRooms();

      // cache the model dbids so the searches will be faster
      await customizerExtension.current.cacheModelDbids();

      //Hide model texts
      customizerExtension.current.hideModelTexts();

      await calcFloorLevel();

      // swap the model materials - currently swapping only walls material to be white
      // this extension loads new model and wait for it to be loaded (geometry + root) and takes the material from there
      try {
        await materialSwapExtension.current.swapMaterials(
          viewer,
          customizerExtension.current.wallsDbids,
          customizerExtension.current.ceilingsDbids
        );
        biService.logEvent(EVENT_TYPES.MATERIALS_SWAP_COMPLETED, CONTEXTS.MODEL, { success: true });
      } catch (error) {
        console.warn('error swapping materials', error);
        biService.logEvent(EVENT_TYPES.MATERIALS_SWAP_COMPLETED, CONTEXTS.MODEL, {
          success: false,
          error: error.message
        });
      }

      // we may have default materials (tiles) in our configured home, so we are applying everything on load
      try {
        await applyDefaultMaterials(viewer);
        biService.logEvent(EVENT_TYPES.DEFAULT_MATERIALS_APPLIED, CONTEXTS.MODEL, {
          success: true
        });
      } catch (error) {
        console.warn('error applying default materials', error);
        biService.logEvent(EVENT_TYPES.DEFAULT_MATERIALS_APPLIED, CONTEXTS.MODEL, {
          success: false
        });
      }

      // the user may have saved modifications from past use, so we are applying everything on load
      try {
        applyModifications(viewer);
        biService.logEvent(EVENT_TYPES.MODIFICATIONS_APPLIED, CONTEXTS.MODEL, { success: true });
      } catch (error) {
        console.warn('error applying modifications', error);
        biService.logEvent(EVENT_TYPES.MODIFICATIONS_APPLIED, CONTEXTS.MODEL, { success: false });
      }

      initalPackagesHideAndShow();
      setPackagesDbidsUnSeletcable();

      disableKeyboardEvents();
      drawMarkersOnSelectableElements();

      setTopView(viewer);

      // saves the last selected item in the local storage so we can restore it after reloading the page
      // helps when selecting a supplier after selecting the floor
      const savedSelection = localStorage.getItem('tweaks_viewer_selection');
      if (savedSelection) {
        viewerInstanceRef.current.select(JSON.parse(savedSelection));
        localStorage.removeItem('tweaks_viewer_selection');
      }

      // loading another viewer which is the navigation map
      const idsToLoad = customizerExtension.current.floorDbids.concat(
        customizerExtension.current.wallsDbids
      );
      await loadModelGeometry(navMapInstanceRef.current, doc, defaultModel, '_navMap', {
        skipHiddenFragments: false,
        keepCurrentModels: true,
        ids: idsToLoad
      });

      setTopView(navMapInstanceRef.current);

      // initialize settings and replacing materials
      navMapInstanceRef.current.setGroundShadow(false);
      navMapInstanceRef.current.setGroundReflection(false);
      const white = new THREE.Vector4(1, 1, 1, 1); // This represents the color white
      for (const dbId of customizerExtension.current.floorDbids) {
        navMapInstanceRef.current.setThemingColor(dbId, white);
      }
      const gray = new THREE.Vector4(0.5, 0.5, 0.5, 1);
      for (const dbId of customizerExtension.current.wallsDbids) {
        navMapInstanceRef.current.setThemingColor(dbId, gray);
      }
      addNavigationSphere();

      // setting the viewer to be ready
      setViewerReady(true);
      const endTime = performance.now();
      const timePassedInSeconds = (endTime - startTime) / 1000;
      console.log(`Loaded Time passed: ${timePassedInSeconds} seconds`);

      if (!previewMode) {
        // start from living room which is the first menu item
        handleMenuItemClick(0);
        const chameleon = Chameleon.getInstance();
        chameleon.track('editor_mode');
      }

      if (home?.forceModelCache) {
        reduxDispatch(
          updateHome(
            home._id,
            {
              forceModelCache: false
            },
            null,
            true
          )
        );
      }

      handleMenuItemClick(0);

      biService.logEvent(EVENT_TYPES.MODEL_LOAD_COMPLETED, CONTEXTS.MODEL, {
        urn: modelUrn,
        //time passed in seconds with 2 decimal points
        timePassedInSeconds: timePassedInSeconds.toFixed(2)
      });
    });
  };

  const addNavigationSphere = () => {
    const sphereGeometry = new THREE.SphereGeometry(1.3);
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);

    const coneGeometry = new THREE.CylinderGeometry(0.8, 2.2, 2.8, 15, 15);
    const coneMaterial = new THREE.MeshBasicMaterial({
      color: 0xaaaaaa,
      transparent: true,
      opacity: 0.8
    });
    const coneMesh = new THREE.Mesh(coneGeometry, coneMaterial);
    coneMesh.position.y = -2.8;
    //@ts-ignore
    navConeTarget.current = new THREE.Group();
    navConeTarget.current.add(sphereMesh);
    navConeTarget.current.add(coneMesh);

    navMapInstanceRef.current.overlays.addScene('navSphere');

    navMapInstanceRef.current.overlays.addMesh(navConeTarget.current, 'navSphere');
    viewerInstanceRef.current.addEventListener(
      Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      updateNavMapConus
    );
  };

  const loadModelGeometry = async (viewer, doc, model, modelName, options) => {
    return new Promise(function (resolve, reject) {
      const fn = function (ev) {
        if (ev.model[modelName]) {
          const biService = BIService.getInstance();
          biService.logEvent(EVENT_TYPES.MODEL_LOADED, CONTEXTS.MODEL, {
            urn: modelUrn,
            model_type: modelName
          });
          resolve(ev.model);
        }
      };

      handleListener(viewer, Autodesk.Viewing.GEOMETRY_LOADED_EVENT, fn);

      viewer.loadDocumentNode(doc, model, options).then((model) => (model[modelName] = true));
    });
  };

  const calcFloorLevel = async () => {
    let sum = 0;
    // loop over customizerExtension.current.floorDbids and find the one that is closest to modelCenter
    for (const floorDbid of customizerExtension.current.floorDbids) {
      const floorBoundingBox = customizerExtension.current.getBoundingBox(floorDbid);
      sum += floorBoundingBox.max.z;
    }

    floorLevel.current = sum / customizerExtension.current.floorDbids.length;
    personHeightFromFloorLevel.current = floorLevel.current + personHeight * 3.2808399;
  };

  const initEnvSettings = (viewer: Autodesk.Viewing.GuiViewer3D) => {
    viewer.setEnvMapBackground(true);
    viewer.setDisplayEdges(false);
    viewer.setQualityLevel(true, true);
    viewer.setOptimizeNavigation(false);
    //viewer.setGroundShadow(true);
    //viewer.setGroundReflection(true);
    viewer.setProgressiveRendering(false);
    viewer.hideLines(true);
    viewer.hidePoints(true);
    //viewer.impl.toggleShadows(true);
    //viewer.impl.renderer().setAOOptions( 10, 0.1 );
    // viewer.impl.setShadowLightDirection(new THREE.Vector3(-1,2,1));
    Autodesk.Viewing.Private.LightPresets.push({
      name: 'My Custom Light',
      path: 'InfinityPool',
      tonemap: 1,
      E_bias: -0.8,
      directLightColor: [1, 0.84, 0.7],
      lightDirection: [0.1, -0.55, 1],
      ambientColor: [0.5, 0.5, 0.5],
      lightMultiplier: 1.2,
      bgColorGradient: [226, 244, 255, 156, 172, 180],
      darkerFade: false,
      saoIntensity: 0.2,
      saoRadius: 4,
      type: 'logluv'
    });

    viewer.setLightPreset(Autodesk.Viewing.Private.LightPresets.length - 1);
  };

  const setWallsCollisionActive = (isActive: boolean) => {
    isCollisionActive.current = isActive;
  };

  const createWallsCollisionDetection = (viewer: Autodesk.Viewing.GuiViewer3D) => {
    let camera = viewer.navigation.getCamera();
    let prevPosition = camera.position.clone();

    const fn = async (e) => {
      if (isCollisionActive.current) {
        // Set the movement distance
        const MIN_DISTANCE_FROM_WALL = 2;

        // Get the current camera position and direction
        camera = viewer.navigation.getCamera();
        const position = camera.position;

        const target = camera.target;

        const direction = new THREE.Vector3().subVectors(target, position).normalize();

        // Create a THREE.Ray object
        const ray = new THREE.Ray(position, direction);

        // Perform a ray intersection test
        const intersection = viewer.impl.rayIntersect(ray, false);

        if (intersection) {
          // Check if the intersection point is within the movement distance
          if (intersection.distance < MIN_DISTANCE_FROM_WALL) {
            // The intersection point is within the movement distance, so don't move the camera
            const props = await getPropertiesAsync(viewer, intersection.dbId);
            const category = props.properties.find(
              (x) => x.displayName === 'Category'
            ).displayValue;

            // viewer.navigation.setTarget(position);
            // viewer.navigation.setPosition(intersection.point.add(direction.clone().multiplyScalar(MIN_DISTANCE_FROM_WALL)));
            if (category !== 'Revit Doors') {
              viewer.navigation.setPosition(prevPosition);
            } else {
              prevPosition = position.clone();
            }
          } else {
            prevPosition = position.clone();
          }
        }
      }
    };

    viewerInstanceRef.current.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, fn);
  };

  const updateNavMapConus = async (e) => {
    if (navConeTarget.current) {
      const camera = viewerInstanceRef.current.navigation.getCamera();
      const target = camera.target;

      const newDirect = new THREE.Vector3().subVectors(target, camera.position);
      newDirect.z = 0;
      newDirect.normalize();
      const angle = Math.atan2(newDirect.y, newDirect.x);
      navConeTarget.current.rotation.z = angle + Math.PI / 2;

      navConeTarget.current.position.set(camera.position.x, camera.position.y, camera.position.z);
      navMapInstanceRef.current.impl.invalidate(true, true);
    }
  };

  const getPropertiesAsync = (viewer, dbId): Promise<any> => {
    return new Promise((resolve, reject) => {
      viewer.getProperties(
        dbId,
        (result) => resolve(result),
        (error) => reject(error)
      );
    });
  };

  const reloadModel = () => {
    if (!viewerInstance) return;

    setEditMenuVisible(false);

    setViewerReady(false);
    isBaseModelLoaded.current = false;
    reduxDispatch(setBaseModelLoaded(false));
    const loadedModels = viewerInstance.getAllModels();
    let numOfModelsUnloaded = 0;
    const counter = () => {
      numOfModelsUnloaded++;
      if (numOfModelsUnloaded === loadedModels.length) {
        viewerInstance.removeEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, counter);
        loadModel(viewerInstance);
      }
    };
    viewerInstance.addEventListener(Autodesk.Viewing.MODEL_UNLOADED_EVENT, counter);
    loadedModels.forEach((model) => {
      viewerInstance.unloadModel(model);
    });
  };

  const fullModelReload = (saveSelection = false) => {
    if (saveSelection) {
      const selection = viewerInstance.getSelection();
      if (selection) localStorage.setItem('tweaks_viewer_selection', JSON.stringify(selection));
    }

    reduxDispatch(resetModificationStore());
    reduxDispatch(
      getHome(home._id, () => {
        reduxDispatch(
          getModifications(home._id, () => {
            reloadModel();
          })
        );
      })
    );
  };

  const takeScreenshot = (setBlobInStore = false) => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.TOOL_CLICKED, CONTEXTS.TOOLS, {
      tool_type: 'screenshot',
      is_active: true
    });

    // We apply a x2 to duplicate the viewer size and get a better image quality
    const width = viewerInstance.container.clientWidth * (setBlobInStore ? 1 : 2);
    const height = viewerInstance.container.clientHeight * (setBlobInStore ? 1 : 2);

    viewerInstance.getScreenShot(width, height, (blob) => {
      if (setBlobInStore) {
        reduxDispatch(setViewerScreenshot(blob));
      } else {
        // trigger download
        const link = document.createElement('a');
        link.href = blob;

        const fileName = 'Tweaks_Screenshot_' + moment().format('YYYY_MM_DD_HH_mm_ss');
        link.setAttribute('download', fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
    });
  };

  useBus(ViewerEvents.SelectFloorByCategory, (e) => selectFloorByCategory(e.payload), [
    viewerInstance
  ]);

  useBus(ViewerEvents.FocusOnElement, (e) => {
    // const cameraInfo = viewerInstanceRef.current.getState();
    // console.log(cameraInfo);
    const camState = {
      seedURN: '',
      objectSet: [
        {
          id: [],
          idType: 'lmv',
          isolated: [],
          hidden: [],
          explodeScale: 0
        }
      ],
      viewport: {
        name: '',
        eye: [14.73300876945805, -5.102284848685368, 4.543961848516541],
        target: [14.726599929475613, -5.783753104933112, 5.275781459142495],
        up: [0.006882062877304892, 0.7317872503038603, 0.6814983914164937],
        worldUpVector: [0, 0, 1],
        pivotPoint: [0, 0, 0],
        distanceToOrbit: -6.707984053730238,
        aspectRatio: 2.5441176470588234,
        projection: 'perspective',
        isOrthographic: false,
        fieldOfView: 73.73979529168804
      },
      renderOptions: {
        environment: 'My Custom Light',
        ambientOcclusion: {
          enabled: true,
          radius: 13.123359580052492,
          intensity: 1
        },
        toneMap: {
          method: 1,
          exposure: -0.8,
          lightMultiplier: 0.2630344058337938
        },
        appearance: {
          ghostHidden: true,
          ambientShadow: true,
          antiAliasing: true,
          progressiveDisplay: false,
          swapBlackAndWhite: false,
          displayLines: false,
          displayPoints: false
        }
      },
      cutplanes: []
    };

    viewerInstanceRef.current.restoreState(camState, null, false);
  });

  useBus(
    ViewerEvents.ShowPackage,
    (selectedPackage) => {
      showPackage(selectedPackage.room, selectedPackage.name, true);
    },
    [viewerInstance, home?.selectedPackages]
  );

  useBus(ViewerEvents.CameraTop, () => setTopView(viewerInstance), [viewerInstance]);
  useBus(ViewerEvents.CameraFirstPerson, () => setFirstPersonView(true, true), [viewerInstance]);

  useBus(ViewerEvents.TakeScreenshot, (e) => takeScreenshot(e?.payload?.setBlobInStore), [
    viewerInstance
  ]);
  useBus(ViewerEvents.GoToRoom, (e) => goToRoom(e.payload), [viewerInstance]);

  useBus(ViewerEvents.ChangeTile, (e) => changeTile(e.payload.data, e.payload?.selectedIds), [
    viewerInstance
  ]);

  useBus(ViewerEvents.ReloadModel, (e) => fullModelReload(e.payload?.saveSelection), [
    viewerInstance,
    modelUrn
  ]);

  useBus(ViewerEvents.AddAsset, (e) => addAsset(e.payload), [viewerInstance]);

  useBus(ViewerEvents.CameraInfoRequest, (e) => cameraInfoRequest(e.payload), [viewerInstance]);

  useBus(ViewerEvents.HighlightCategory, (e) => highlightCategory(e.payload), [viewerInstance]);

  useBus(
    ViewerEvents.ElementDeleted,
    (e) => deleteElement(e.payload.selectedElement, viewerInstance),
    [viewerInstance]
  );

  useBus(
    ViewerEvents.FurnitureHide,
    async (e) => {
      await customizerExtension.current.changeFurnitureVisibility(e.isActive);

      if (!e.isActive) {
        initalPackagesHideAndShow();
      }
    },
    [viewerInstance]
  );

  useBus(
    ViewerEvents.MeasureActivate,
    (e) => {
      const extension = viewerInstance.getExtension(extNameMeasure) as any;
      if (e.isActive) {
        extension.setActive(true);
        extension.setUnits('cm');
      } else {
        extension.measureTool.deleteMeasurements();
        extension.setActive(false);
      }

      const biService = BIService.getInstance();
      biService.logEvent(EVENT_TYPES.TOOL_CLICKED, CONTEXTS.TOOLS, {
        tool_type: 'measurement',
        is_active: e.isActive
      });
    },
    [viewerInstance]
  );

  useBus(
    ViewerEvents.MoveStart,
    async (e) => {
      const elementDbId = (e.payload as IElementInfo).dbId;
      const elementCategory = (e.payload as IElementInfo).category;
      const elementModelId = (e.payload as IElementInfo).modelId;
      movingHostCategory.current = categoryHost[elementCategory];
      movingElementName.current = (e.payload as IElementInfo).name;
      movingElementInfo.current = e.payload as IElementInfo;
      const bBox = customizerExtension.current.getBoundingBox(elementDbId, elementModelId);
      const tolerance = 0.1 * 3.2808399; // 5 cm

      const host = (await customizerExtension.current.getMostIntersectedCeiling(
        movingHostCategory.current,
        bBox,
        tolerance
      )) as IIntersectElement;
      const sanitizedName = movingElementName.current.replace(/ *\[[^)]*\] */g, '');

      // NOTE: We compare revitFamilyName by using includes function (instead of ===) because family types used when
      //       creating the .rvt models do not include "_2021" suffix on their names. Good enough for now anyways.
      const asset = assets.current.find(
        (x) =>
          x.originalName.toUpperCase() === sanitizedName.toUpperCase() ||
          x.revitFamilyName.toUpperCase().includes(sanitizedName.toUpperCase())
      );

      if (!asset) {
        dispatch({
          type: ViewerEvents.ValidationMessage,
          payload: {
            type: messageSeverity.WARNING,
            message: String(messages['tweaks.viewer.warning.unknownAssetMovementAttempt'])
          }
        });

        return;
      }

      if (!host) {
        dispatch({
          type: ViewerEvents.ValidationMessage,
          payload: {
            type: messageSeverity.WARNING,
            message: String(messages['tweaks.viewer.warning.noHostFound'])
          }
        });

        dispatch(ViewerEvents.MoveEnd);
        return;
      }

      if (
        (e.payload as IElementInfo).category === 'Revit Electrical Fixtures' &&
        (e.payload as IElementInfo).closestRoom?.category.toLowerCase().includes('kitchen')
      ) {
        dispatch({
          type: ViewerEvents.ValidationMessage,
          payload: {
            type: messageSeverity.WARNING,
            message: String(messages['tweaks.viewer.warning.kitchenElectricalLock'])
          }
        });

        dispatch(ViewerEvents.MoveEnd);
        return;
      }

      let candidateHosts = await customizerExtension.current.getHostDbids(
        movingHostCategory.current
      );

      if (candidateHosts.length === 0) {
        console.log('Not Host Candiates Found');
        dispatch(ViewerEvents.MoveEnd);
        return;
      }

      candidateHosts = candidateHosts.filter((x) => x !== host.dbId);

      // Candidate hosts highlighting
      const highlightInfo = candidateHosts.map((dbId) => {
        return {
          modelId: host.modelId,
          dbId: dbId,
          color: new THREE.Vector4(1, 1, 1, 0.8)
        };
      });

      // Current host highlighting
      highlightInfo.push({
        modelId: host.modelId,
        dbId: host.dbId,
        color: new THREE.Vector4(0.9, 1, 0.9, 0.8)
      });

      // Element being moved highlighting
      highlightInfo.push({
        modelId: elementModelId,
        dbId: elementDbId,
        color: new THREE.Vector4(1, 0.4, 0.2, 0.8)
      });

      // customizerExtension.current.setBlinkingElements(highlightInfo);
      //

      customizerExtension.current.draggingDbId = elementDbId;
      customizerExtension.current.draggingModelId = elementModelId;
      customizerExtension.current.movingElementCenter = movingElementCenter.current;
      customizerExtension.current.movingElementCategory = elementCategory;
      customizerExtension.current.movingElementInfo = movingElementInfo.current;

      movingHostDbId.current = host.dbId;
      movingElementDbId.current = elementDbId;
      movingElementModelId.current = elementModelId;
      movingElementCenter.current = bBox
        .getCenter()
        .add(ForgeUtils.getGlobalOffset(viewerInstance));
      movingElementCategory.current = elementCategory;

      // viewerInstance.select();
      // viewerInstance.setNavigationLock(true);

      customizerExtension.current.onMoveStart(host.dbId);
      isMovingElement.current = true;
      dispatch({
        type: ViewerEvents.DisableInteractions,
        payload: true
      });
    },
    [viewerInstance]
  );

  const drawMarkersOnSelectableElements = () => {
    if (isShowMarkersOnElements.current) {
      markerExtension.current.drawMarkersOnSelectableDbids();
    } else {
      markerExtension.current.hideMarkersOnSelectableDbids();
    }
  };

  const showMarkersOnSelectableDbids = (visible: boolean) => {
    isShowMarkersOnElements.current = visible;
    drawMarkersOnSelectableElements();
  };

  const cancelDragging = () => {
    console.log('Move End');

    drawMarkersOnSelectableElements();
    customizerExtension.current.setBlinkingElements([]);
    viewerInstanceRef.current.select();

    viewerInstanceRef.current.setNavigationLock(false);
    customizerExtension.current.draggingDbId = null;
    customizerExtension.current.isDraggingElement = false;
    selectedLight.current = null;

    setEditMenuVisible(false);

    dispatch({
      type: ViewerEvents.DisableInteractions,
      payload: false
    });

    setTimeout(() => {
      isMovingElement.current = false;
    }, 1000);
  };

  useBus(
    ViewerEvents.MoveEnd,
    () => {
      cancelDragging();
    },
    [viewerInstance]
  );

  const deleteElement = (deletedElement: IElementInfo, viewer: Autodesk.Viewing.GuiViewer3D) => {
    reduxDispatch(setDeletedElementData(deletedElement));

    const baseModel = viewer.getAllModels().filter((m: any) => m._isBase !== undefined);

    if (deletedElement.modelId === baseModel[0].id) {
      // Deleting an element in the base model
      viewer.impl.visibilityManager.setNodeOff(deletedElement.dbId, true);
      removeFromCache(deletedElement.dbId, baseModel[0]);
    } else {
      // Unloading a model
      const models = viewer.getAllModels();
      const model = models.filter((m) => m.id === deletedElement.modelId)[0];
      viewer.unloadModel(model);
      removeFromCache(deletedElement.dbId, model);
    }
  };

  useBus(
    ViewerEvents.SetCamera,
    (e) => {
      setFirstPersonView(true, false);
      viewerInstance.restoreState(e.payload);
    },
    [viewerInstance]
  );

  const goToRoom = (room: IRoomInfo) => {
    const currentClosestFloor = customizerExtension.current.getClosestFloor(room);
    currentClosestFloorToCamera.current = currentClosestFloor;
    currentRoom.current = room;

    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.GO_TO_ROOM, CONTEXTS.VIEW, {
      room_name: room.category,
      room_key: findRoomKeyByRevitRoomName(room.category)
    });

    const chameleon = Chameleon.getInstance();
    const roomKeyArr = findRoomKeyByRevitRoomName(room.category)?.split('.');
    const roomKey = roomKeyArr ? roomKeyArr[roomKeyArr.length - 1] : '';
    chameleon.track('go_to_room_' + roomKey);

    // TODO : Dont use room name , use button text as key word , ex : kitchen
    showToolTip(room.name);

    viewerInstance.setNavigationLock(false);
    setEditMenuVisible(false);

    // TODO order of calling setFirstPersonView and navigating to a room is important
    // for each case
    if (room.camera) {
      viewerInstance.restoreState(room.camera.cameraInfo, null, true);
      setFirstPersonView(true, false);
    } else {
      roomsExtension.current.goToRoom(room, personHeightFromFloorLevel.current);
      setFirstPersonView(true, false);
    }

    // TODO this is here to fix positioning the first time first person view is set
    // as it takes the user to the center of the model instead of the chosen room
    // setTimeout(() => {
    //   if (room.camera) {
    //     viewerInstance.restoreState(room.camera.cameraInfo, null, true);
    //   } else {
    //     roomsExtension.current.goToRoom(room, personHeightFromFloorLevel.current);
    //   }
    // }, 200);
  };

  const addSwitchModeAfterLightSelection = () => {
    setEditMenuVisible(false);

    placingAssetUrn.current = fetchedAssetsRef.current.find((x) => x.name === 'Single pole').urn;
    placingAssetCategory.current = 'Outlets';
    customizerExtension.current.isPlacingAsset = true;
    isPlacingAsset.current = true;

    dispatch({
      type: ViewerEvents.ValidationMessage,
      payload: {
        type: messageSeverity.INFO,
        message: String('Please select a wall to add a switch to')
      }
    });
  };

  const onShowSwitch = () => {
    setEditMenuVisible(false);
    if (selectedElements[0].category === 'Revit Lighting Fixtures') {
      const selectedElementPos = ForgeUtils.getPosition(
        selectedElements[0].dbId,
        selectedElements[0].modelId,
        viewerInstanceRef.current
      );

      const connectedElementPos: THREE.Vector3[] = [];

      selectedElements[0].circutConnectedElements.forEach((element) => {
        const position: THREE.Vector3 = ForgeUtils.getPosition(
          element.dbId,
          element.model.id,
          viewerInstanceRef.current
        );
        connectedElementPos.push(position);
      });

      customizerExtension.current.drawLinesBetweenPointsAndOrigin(
        selectedElementPos,
        connectedElementPos
      );

      const hightLightelements: IBlinkingElement[] = [];
      selectedElements[0].circutConnectedElements.forEach((element) => {
        hightLightelements.push({
          modelId: element.model.id,
          dbId: element.dbId,
          color: new THREE.Vector4(1, 0.4, 0.2, 0.7)
        });
      });
      highLightElement(hightLightelements);
      setTimeout(() => {
        customizerExtension.current.clearLinesInConnectedLinesScene();
      }, 10000);
    }
  };

  const onShowLight = () => {
    setEditMenuVisible(false);

    if (
      selectedElements[0].category === 'Revit Electrical Fixtures' &&
      ForgeUtils.isSwitch(selectedElements[0].name) &&
      selectedElements[0].circutConnectedElements?.length > 0
    ) {
      const hightLightelements: IBlinkingElement[] = [];
      hightLightelements.push({
        modelId: selectedElements[0].circutConnectedElements[0].model.id,
        dbId: selectedElements[0].circutConnectedElements[0].dbId,
        color: new THREE.Vector4(1, 0.4, 0.2, 0.7)
      });

      const selectedElementPos = ForgeUtils.getPosition(
        selectedElements[0].dbId,
        selectedElements[0].modelId,
        viewerInstanceRef.current
      );

      const connectedElementPos: THREE.Vector3[] = [];

      selectedElements[0].circutConnectedElements.forEach((element) => {
        const position: THREE.Vector3 = ForgeUtils.getPosition(
          element.dbId,
          element.model.id,
          viewerInstanceRef.current
        );
        connectedElementPos.push(position);
      });
      customizerExtension.current.drawLinesBetweenPointsAndOrigin(
        selectedElementPos,
        connectedElementPos
      );
      highLightElement(hightLightelements);
      setTimeout(() => {
        customizerExtension.current.clearLinesInConnectedLinesScene();
      }, 10000);
    }
  };

  const onAddLight = () => {
    setEditMenuVisible(false);
  };

  const changeOutLetType = async (nextOutLetType: boolean) => {
    if (
      selectedElements.length > 0 &&
      selectedElements[0].category === 'Revit Electrical Fixtures' &&
      isPlacingAsset.current === false
    ) {
      if (isPlacingAsset.current) return;

      const oneSocketOutletUrn = fetchedAssets.find((x) => x.socketAmount === 1).urn;
      const doubleSocketOutletUrn = fetchedAssets.find((x) => x.socketAmount === 2).urn;
      const biService = BIService.getInstance();

      let nextTypeUrn = '';

      if (nextOutLetType === true) {
        singleSocketOutLetNames.map((x) => {
          if (selectedElements[0].name.includes(x)) {
            nextTypeUrn = doubleSocketOutletUrn;
          }
        });
      }
      if (nextOutLetType === false) {
        doubleSocketOutLetNames.map((x) => {
          if (selectedElements[0].name.includes(x)) {
            nextTypeUrn = oneSocketOutletUrn;
          }
        });
      }

      if (nextTypeUrn === '') return;
      isPlacingAsset.current = true;
      setEditMenuVisible(false);
      const globalOffset = ForgeUtils.getGlobalOffset(viewerInstance);

      const modelPosition = ForgeUtils.getDbisPositions(
        [selectedElements[0].dbId],
        viewerInstance,
        selectedElements[0].modelId
      )[0].add(globalOffset);

      const hiptpointPosition =
        customizerExtension.current.selectedElementHostHitPoint.add(globalOffset);

      const oldModification = modifications.current.find(
        (x) => x.attrs?.modelId === selectedElements[0].modelId
      );

      deleteElement(selectedElements[0], viewerInstance);

      const originalDeletedDbid =
        oldModification?.dbIdRef !== undefined
          ? oldModification?.dbIdRef
          : selectedElements[0].dbId;

      const newSocketPosition = new THREE.Vector3(
        customizerExtension.current.hostNormal.y !== 0 ? modelPosition.x : hiptpointPosition.x,
        customizerExtension.current.hostNormal.x !== 0 ? modelPosition.y : hiptpointPosition.y,
        modelPosition.z
      );

      await addAssetInPlace(
        nextTypeUrn,
        newSocketPosition, // More percised hit point
        customizerExtension.current.selectedElementHostAngle, // more percised point
        selectedElements[0].revitId,
        selectedElements[0].closestRoom.category || '',
        originalDeletedDbid
      );
    }
  };

  const addToCache = async (model, category?: string) => {
    category = 'Revit Electrical Fixtures';
    const elementToAdd = await customizerExtension.current.getDbIdsAndModelByCategory(
      category,
      model
    );

    customizerExtension.current.updateCacheModelDbids(elementToAdd, 'Add');
    drawMarkersOnSelectableElements();
  };

  const removeFromCache = async (dbid, model: Autodesk.Viewing.Model) => {
    const elementToRemove: IDbidWithModelId = { dbId: dbid, model: model };
    customizerExtension.current.updateCacheModelDbids([elementToRemove], 'Remove');
    drawMarkersOnSelectableElements();
  };

  const applyModifications = async (viewer: Autodesk.Viewing.GuiViewer3D) => {
    const modificationsFoundInViewer: IModification[] = [];
    if (isBaseModelLoaded.current && modificationsLoaded.current) {
      for (let i = 0; i < modifications.current.length; i++) {
        const modification = modifications.current[i];

        const doesDbidExistsInModel = await ForgeUtils.doesDbidExistsInViewer(
          modification.dbId,
          modification.revitId,
          viewer
        );

        if (doesDbidExistsInModel || modification.type === MODIFICATION_TYPE.ADD) {
          modificationsFoundInViewer.push(modification);

          switch (modification.type) {
            case MODIFICATION_TYPE.MOVE:
              {
                const offset = new THREE.Vector3(
                  modification.attrs.offsetX,
                  modification.attrs.offsetY,
                  modification.attrs.offsetZ
                );

                moveExtension.current.applyElementOffset(modification.dbId, offset);
              }
              break;
            case MODIFICATION_TYPE.TILE:
              customizerExtension.current.changeMaterialByElementId(
                modification.dbId,
                modification.attrs
              );
              break;
            case MODIFICATION_TYPE.ADD:
              customizerExtension.current
                .loadModelInPosition(
                  modification.attrs.modelUrn,
                  modification.attrs.hitPoint,
                  modification.attrs.angle,
                  [],
                  modification.attrs.modelId
                )
                .then((model) => {
                  addToCache(model);
                });
              break;
            case MODIFICATION_TYPE.DELETE:
              // Deleting an element in the base model
              viewer.impl.visibilityManager.setNodeOff(modification.dbId, true);
              removeFromCache(modification.dbId, viewer.model);
              break;
            case MODIFICATION_TYPE.LIGHT:
              {
                if (modification.attrs?.fixtureAttrs) {
                  applyLightModidcation(modification, viewer, modification.attrs.modelId);
                }

                if (modification.attrs?.switches) {
                  modification.attrs?.switches?.forEach((switchData) => {
                    applySwitchModification(switchData, viewer, modification.attrs.modelId);
                  });
                }
              }
              break;

            default:
              break;
          }
        } else {
          jwtAxios.delete(`/homes/${home._id}/modification/${modification._id}`);
          console.log(modification._id, 'Modification Not Found In Viewer ... deleting'); // When submiting later on we dont want to sumbit modifications that are not in the viewer
        }
      }

      reduxDispatch({ type: SET_MODIFICATIONS, payload: modificationsFoundInViewer });
    }
  };

  const applyLightModidcation = (lightData, viewer: Autodesk.Viewing.Viewer3D, modelId: number) => {
    switch (lightData.attrs.fixtureType) {
      case MODIFICATION_TYPE.ADD: {
        // const addAttr = switchData.attrs as IAddModificationAttrs;
        // customizerExtension.current.loadModelInPosition(
        //   addAttr.modelUrn,
        //   addAttr.hitPoint,
        //   addAttr.angle,
        //   addAttr.modelId
        // );
        break;
      }
      case MODIFICATION_TYPE.DELETE: {
        viewer.impl.visibilityManager.setNodeOff(lightData.dbId, true);
        removeFromCache(lightData.dbId, viewer.model);
        break;
      }
      case MODIFICATION_TYPE.MOVE: {
        const offset = new THREE.Vector3(
          lightData.attrs.fixtureAttrs.offsetX,
          lightData.attrs.fixtureAttrs.offsetY,
          lightData.attrs.fixtureAttrs.offsetZ
        );

        moveExtension.current.applyElementOffset(lightData.dbId, offset, modelId);
        break;
      }
      default:
    }
  };

  const applySwitchModification = (switchData, viewer, modelId) => {
    switch (switchData.type) {
      case MODIFICATION_TYPE.ADD: {
        const addAttr = switchData.attrs as IAddModificationAttrs;
        customizerExtension.current
          .loadModelInPosition(
            addAttr.modelUrn,
            new THREE.Vector3(addAttr.hitPoint.x, addAttr.hitPoint.y, addAttr.hitPoint.z),
            addAttr.angle,
            addAttr.revitProperties,
            addAttr.modelId
          )
          .then((addedModel) => {
            addToCache(addedModel, 'Revit Electrical Fixtures');
          });
        break;
      }
      case MODIFICATION_TYPE.DELETE: {
        viewer.impl.visibilityManager.setNodeOff(switchData.dbId, true);
        removeFromCache(switchData.dbId, viewer.model);
        break;
      }
      case MODIFICATION_TYPE.MOVE: {
        const offset = new THREE.Vector3(
          switchData.attrs.offsetX,
          switchData.attrs.offsetY,
          switchData.attrs.offsetZ
        );

        moveExtension.current.applyElementOffset(switchData.dbId, offset, modelId);
        break;
      }
      default:
        break;
    }
  };

  const elementMoved = ({ dbIds, offset, model, modifcationType }) => {
    reduxDispatch(
      setMovedElementsData({
        dbIds,
        offset: {
          x: offset.x,
          y: offset.y,
          z: offset.z
        },
        scale: viewerInstance.model.getUnitScale(),
        model,
        modificationType: modifcationType
      })
    );
    console.info('Basic Forge Viewer ELEMENT MOVED ', dbIds, offset);
  };

  useBus(
    ViewerEvents.ElementMoved,
    debounce((e) => elementMoved(e.payload), 250),
    [viewerInstance]
  );

  useBus(
    ViewerEvents.ResetRoomModifications,
    (e) => {
      const biService = BIService.getInstance();
      biService.logEvent(EVENT_TYPES.RESET_ROOM, CONTEXTS.SUMMARY, {
        room_name: e.payload.room.category
      });

      reduxDispatch(
        resetModifications(e.payload.roomModifications, viewerInstanceRef.current, () => {
          drawMarkersOnSelectableElements();
          resetAllPackages(e.payload.room);
          applyTilesDefaultMaterials(e.payload.roomModifications);
        })
      );
    },
    [viewerInstance]
  );

  const resetAllPackages = (room: IRoomInfo) => {
    const updateHome: IHome = lodash.cloneDeep(homeRef.current);

    console.log(homeRef.current?.selectedPackages);

    homeRef.current?.selectedPackages?.forEach(async (roomPackage) => {
      const packagesInRoom = homeRef.current.modelTemplate.packages.find(
        (x) => x._id === roomPackage.packageId && x.room === room.category
      ) as IRoomPackage;

      if (packagesInRoom && packagesInRoom.name !== 'STANDARD') {
        console.log('Reseting Packages To Standard : ' + packagesInRoom.name);

        showPackage(packagesInRoom.room, 'STANDARD', true);
        updateHome.selectedPackages = updateHome.selectedPackages.filter(
          (x) => x.packageId !== packagesInRoom._id
        );
      }
    });

    const currentSelectedPackages = updateHome.selectedPackages.map((p) => ({
      modelTemplateId: p.modelTemplateId,
      packageId: p.packageId,
      categoryId: p.categoryId || undefined,
      optionId: p.optionId || undefined
    }));

    reduxDispatch(updateSelectedPackages(updateHome._id, currentSelectedPackages));
  };

  const changeTile = (tileInfo, selectedIds = null) => {
    customizerExtension.current.changeMaterial(tileInfo, selectedIds);
  };

  const addAsset = (assetInfo: IAsset) => {
    isPlacingAsset.current = true;
    placingAssetUrn.current = assetInfo.urn;
    placingAssetCategory.current = assetInfo.category;
    customizerExtension.current.isPlacingAsset = true;
  };

  const cameraInfoRequest = (respondTo: CameraInfoRespondTo) => {
    const cameraInfo = viewerInstance.getState({ viewport: true });

    switch (respondTo) {
      case CameraInfoRespondTo.ROOM_CAMERA:
        dispatch({
          type: ViewerEvents.CameraInfoRespForRoomCamera,
          payload: cameraInfo
        });
        break;
      case CameraInfoRespondTo.CUSTOM_REQUEST:
        dispatch({
          type: ViewerEvents.CameraInfoRespForCustomRequest,
          payload: cameraInfo
        });
        break;
    }
  };

  const highlightCategory = (categoryName) => {
    customizerExtension.current.setBlinkingElements([]);
    customizerExtension.current.getDbIdsByCategoryAllModels(categoryName).then((modelsDbIds) => {
      let highlightInfo = [];
      for (let i = 0; i < modelsDbIds.length; i++) {
        // eslint-disable-next-line @typescript-eslint/no-loop-func
        const modelHighlightInfo = modelsDbIds[i].dbIds.map((id) => {
          return {
            modelId: modelsDbIds[i].modelId,
            dbId: id,
            color: new THREE.Vector4(1, 0.4, 0.2, 0.7)
          };
        });

        highlightInfo = highlightInfo.concat(modelHighlightInfo);
      }

      customizerExtension.current.setBlinkingElements(highlightInfo);

      setTimeout(() => {
        customizerExtension.current.setBlinkingElements([]);
      }, 2000);
    });
  };

  const highLightElement = (elements: IBlinkingElement[]) => {
    customizerExtension.current.setBlinkingElements(elements);
    setTimeout(() => {
      customizerExtension.current.setBlinkingElements([]);
    }, 2000);
  };

  const isTargetElementValid = (element: IElementInfo) => {
    let requiredCategory;
    switch (placingAssetCategory.current) {
      case AssetCategory.SWITCHES:
      case AssetCategory.OUTLETS:
        requiredCategory = 'Revit Walls';
        break;
      case AssetCategory.LIGHTING:
        requiredCategory = 'Revit Ceilings';
        break;
    }

    if (element.category !== requiredCategory) {
      dispatch({
        type: ViewerEvents.ValidationMessage,
        payload: {
          type: messageSeverity.WARNING,
          message: String(
            messages[AssetCategoryProperties[placingAssetCategory.current].wrongTarget]
          )
        }
      });

      return false;
    }

    return true;
  };

  const onEndDraggingCallBack = (selectedElements: IElementInfo, selectionEvent) => {
    modifyElementAfterMovement([selectedElements], selectionEvent);
  };

  const modifyElementAfterMovement = async (selectedElements: IElementInfo[], event) => {
    // if (isMovingElement.current) {
    // if (movingHostDbId.current !== selectedElements[0].dbId) {
    // Check if new host element category is valid
    if (
      movingHostCategory.current !== 'Revit Walls' &&
      movingHostCategory.current !== 'Revit Ceilings'
    ) {
      dispatch({
        type: ViewerEvents.ValidationMessage,
        payload: {
          type: messageSeverity.WARNING,
          message: String(messages['tweaks.viewer.warning.invalidTargetHost'])
        }
      });

      event.target.select();
      return;
    }

    const sanitizedName = movingElementName.current.replace(/ *\[[^)]*\] */g, '');

    // NOTE: We compare revitFamilyName by using includes function (instead of ===) because family types used when
    //       creating the .rvt models do not include "_2021" suffix on their names. Good enough for now anyways.
    const asset = assets.current.find(
      (x) =>
        x.originalName.toUpperCase() === sanitizedName.toUpperCase() ||
        x.revitFamilyName.toUpperCase().includes(sanitizedName.toUpperCase())
    );

    if (!asset) {
      dispatch({
        type: ViewerEvents.ValidationMessage,
        payload: {
          type: messageSeverity.WARNING,
          message: String(messages['tweaks.viewer.warning.unknownAssetMovementAttempt'])
        }
      });

      event.target.select();
      return;
    }

    if (
      asset.category === AssetCategory.OUTLETS &&
      selectedElements[0].closestRoom?.category.toLowerCase().includes('kitchen')
    ) {
      dispatch({
        type: ViewerEvents.ValidationMessage,
        payload: {
          type: messageSeverity.WARNING,
          message: String(messages['tweaks.viewer.warning.kitchenElectricalLock'])
        }
      });

      return;
    }

    // const validationRulesSuccess = await checkValidationRules(
    //   selectedElements[0].hitPoint,
    //   movingElementCategory.current,
    //   event.target
    // );

    // if (!validationRulesSuccess) {
    //   event.target.select();
    //   return;
    // } else {
    // No validation errors, so we proceed with the delete and add strategy

    const offset = getMovedElementOffset(selectedElements);

    const dbIds = [movingElementDbId.current];
    const models = viewerInstanceRef.current.getAllModels();
    const model = models.filter((m) => m.id === movingElementModelId.current)[0];
    let modifcationType = MODIFICATION_TYPE.MOVE;

    if (ForgeUtils.isSwitch(movingElementInfo.current.name)) {
      modifcationType = MODIFICATION_TYPE.LIGHT;
    }

    if (movingElementCategory.current === 'Revit Lighting Fixtures') {
      modifcationType = MODIFICATION_TYPE.LIGHT;
    }

    dispatch({
      type: ViewerEvents.ElementMoved,
      payload: { dbIds, offset, model, modifcationType }
    });

    dispatch(ViewerEvents.MoveEnd);
  };

  const getMovedElementOffset = (selectedElements: IElementInfo[]) => {
    let offset: THREE.Vector3;
    if (movingElementCategory.current === 'Revit Lighting Fixtures') {
      offset = new THREE.Vector3(
        selectedElements[0].hitPoint.x - movingElementCenter.current.x,
        selectedElements[0].hitPoint.y - movingElementCenter.current.y,
        0
      );
    } else {
      let y = selectedElements[0].hitPoint.y - movingElementCenter.current.y;
      y = y * Math.abs(Math.sin(selectedElements[0].angle));
      let x = selectedElements[0].hitPoint.x - movingElementCenter.current.x;
      x = x * Math.abs(Math.cos(selectedElements[0].angle));

      offset = new THREE.Vector3(
        x,
        y,
        selectedElements[0].hitPoint.z - movingElementCenter.current.z
      );
    }

    return offset;
  };

  const showToolTip = (selectedItemName: string) => {
    const foundKey = Array.from(toolTipElementNameAndInfo.keys()).find((key) =>
      selectedItemName.toLocaleLowerCase().includes(key.toLocaleLowerCase())
    );

    const info = toolTipElementNameAndInfo.get(foundKey);
    if (info) {
      setShowToolTipWindow(true);
      setToolTipInfo(info);
    }
  };

  const addCustomRequest = (selectedElement: IElementInfo) => {
    reduxDispatch(setCustomRequestViewMode(CustomRequestMode.VIEW));
    // viewerInstanceRef.current.setNavigationLock(true);
    markerExtension.current.addMarkerOnClick({
      x: selectedElement.hitPoint.x,
      y: selectedElement.hitPoint.y,
      z: selectedElement.hitPoint.z,
      data: {
        dbId: selectedElement.dbId
      }
    });
  };

  const selectionChanged = async (selectedElements: IElementInfo[], event) => {
    if (isMovingElement.current || isHomeLockedForChangesRef.current) return;

    if (customRequestModeRef.current === CustomRequestMode.EDIT && !readOnly) {
      addCustomRequest(selectedElements[0]);
    }

    if (isPlacingAsset.current) {
      if (selectedElements.length !== 1) return;

      if (!isTargetElementValid(selectedElements[0])) return;

      if (
        placingAssetCategory.current === AssetCategory.OUTLETS &&
        selectedElements[0].closestRoom?.category.toLowerCase().includes('kitchen')
      ) {
        dispatch({
          type: ViewerEvents.ValidationMessage,
          payload: {
            type: messageSeverity.WARNING,
            message: String(messages['tweaks.viewer.warning.kitchenElectricalLock'])
          }
        });

        return;
      }

      let placingCategory;
      switch (placingAssetCategory.current) {
        case AssetCategory.SWITCHES:
        case AssetCategory.OUTLETS:
          placingCategory = 'Revit Electrical Fixtures';
          break;
        case AssetCategory.LIGHTING:
          placingCategory = 'Revit Lighting Fixtures';
          break;
      }

      const validationRulesSuccess = await checkValidationRules(
        selectedElements[0].hitPoint,
        placingCategory,
        event.target
      );

      if (!validationRulesSuccess) {
        event.target.select();
        isPlacingAsset.current = false;
        customizerExtension.current.isPlacingAsset = false;
        placingAssetUrn.current = null;
        setEditMenuVisible(false);
        return;
      }

      const biService = BIService.getInstance();
      biService.logEvent(EVENT_TYPES.ADD_ASSET, CONTEXTS.VIEW, {
        category: currentAddedElementCatagory.current
      });

      await addAssetInPlace(
        placingAssetUrn.current,
        selectedElements[0].hitPoint,
        selectedElements[0].angle,
        selectedElements[0].revitId,
        selectedElements[0].closestRoom.category || ''
      );

      return;
    }

    if (
      (selectedElements.length > 0 &&
        selectedElements[0].category === 'Revit Electrical Fixtures') ||
      selectedElements[0].category === 'Revit Lighting Fixtures'
    ) {
      if (selectedElements[0].category === 'Revit Lighting Fixtures') {
        selectedLight.current = selectedElements[0];
      } else {
        selectedLight.current = null;
      }

      setEditMenuVisible(true);
    } else {
      setEditMenuVisible(false);
    }

    reduxDispatch(setSelectedElements(selectedElements));

    // moveExtension.current.stopMoving(); TODO: To be analyzed later (JEPS)

    if (selectedElements.length === 1 && movableCategories.includes(selectedElements[0].category)) {
      if (!selectedElements[0].subFamily) {
        if (!readOnly) {
          if (
            selectedElements[0].category === 'Revit Lighting Fixtures' &&
            selectedElements[0].isPendant
          ) {
            // moveExtension.current.startMoving(event, "XY"); TODO: To be analyzed later (JEPS)
          } else {
            // moveExtension.current.startMoving(event); TODO: To be analyzed later (JEPS)
          }
        }
      } else {
        const model = event.selections[0].model;
        // It is a family sub element, so select the parent element
        event.target.select(selectedElements[0].subFamily, model);
      }
    }
  };

  const addAssetInPlace = async (
    assetUrn: string,
    assetPosition: THREE.Vector3,
    angle: number,
    hostId: number,
    room: string,
    prevDbid?: number // Used if we delete old outlet and change it to new one
  ) => {
    isPlacingAsset.current = true;
    customizerExtension.current.isPlacingAsset = true;
    placingAssetUrn.current = assetUrn;

    let modificationType: MODIFICATION_TYPE = MODIFICATION_TYPE.ADD;

    // We just adding light
    if (placingAssetCategory.current === AssetCategory.LIGHTING) {
      console.log('adding just light');
      modificationType = MODIFICATION_TYPE.LIGHT;
    }

    // We selected light and we adding a switch to it
    if (placingAssetCategory.current === AssetCategory.OUTLETS && selectedLight.current) {
      console.log('adding just switch');
      modificationType = MODIFICATION_TYPE.LIGHT;
    }

    let revitProps: IRevitProperty;
    if (selectedLight.current) {
      const props = await getPropertiesAsync(viewerInstanceRef.current, selectedLight.current.dbId);
      const circutProps = props.properties.find((x) => x.displayName === 'מספר מעגל');

      if (circutProps) {
        revitProps = {
          name: circutProps.attributeName,
          category: circutProps.displayCategory,
          value: circutProps.displayValue
        };
      }
    }
    dispatch(ViewerEvents.AddAssetStart);
    customizerExtension.current
      .loadModelInPosition(assetUrn, assetPosition, angle, [revitProps])
      .then(async (addedModel: Autodesk.Viewing.Model) => {
        const elementName = fetchedAssetsRef.current.find((x) => x.urn === assetUrn).originalName;

        let attrs;
        let cost = CalcModificationPrice(elementName, MODIFICATION_TYPE.ADD);
        // Adding a Light
        if (placingAssetCategory.current === AssetCategory.LIGHTING) {
          const revitProps: IRevitProperty = {
            name: 'מספר מעגל',
            category: 'Identity Data',
            value: ''
          };

          const fixturesAttrs: IAddModificationAttrs = {
            hitPoint: assetPosition,
            angle: angle,
            modelId: addedModel.id.toString(),
            modelUrn: assetUrn,
            hostId: hostId,
            revitProperties: [revitProps]
          };

          modificationType = MODIFICATION_TYPE.LIGHT;
          const lightAttr: ILightModificationAttrs = {
            fixtureAttrs: fixturesAttrs,
            fixtureType: MODIFICATION_TYPE.ADD
          };
          attrs = lightAttr;

          cost = CalcModificationPrice(elementName, MODIFICATION_TYPE.ADD);
          // Adding a Switch To A Light
        } else if (
          placingAssetCategory.current === AssetCategory.OUTLETS &&
          selectedLight.current
        ) {
          const attr: IAddModificationAttrs = {
            hitPoint: assetPosition,
            angle: angle,
            modelId: addedModel.id.toString(),
            modelUrn: assetUrn,
            hostId: hostId,
            revitProperties: [revitProps]
          };

          const switchModifications: IModification = {
            type: MODIFICATION_TYPE.ADD,
            cost: CalcModificationPrice(elementName, MODIFICATION_TYPE.ADD),
            home: home._id,
            room: room,
            attrs: attr
          };

          modificationType = MODIFICATION_TYPE.LIGHT;
          attrs = {
            switches: [switchModifications]
          };

          cost = 0;
        }
        // Adding other objects
        else {
          modificationType = MODIFICATION_TYPE.ADD;
          attrs = {
            hostId: hostId,
            hitPoint: assetPosition,
            angle: angle,
            modelId: addedModel.id,
            modelUrn: assetUrn
          };

          cost = CalcModificationPrice(elementName, MODIFICATION_TYPE.ADD);
        }

        const modification: IModification = {
          type: modificationType,
          attrs: attrs,
          cost: cost,
          room: room,
          home: home._id
        };

        if (modificationType === MODIFICATION_TYPE.LIGHT) {
          modification.dbId = selectedLight.current.dbId;
          modification.revitId = selectedLight.current.revitId;
        }

        if (selectedLight.current) {
          modification.dbId = selectedLight.current.dbId;
          modification.revitId = selectedLight.current.revitId;
        }

        if (prevDbid) {
          modification.dbIdRef = prevDbid;
        }

        reduxDispatch(processModification(modification));

        customizerExtension.current.isPlacingAsset = false;

        placingAssetUrn.current = null;
        setEditMenuVisible(false);
        isPlacingAsset.current = false;
        addToCache(addedModel);
        dispatch(ViewerEvents.AddAssetCompleted);
      });
    return;
  };

  const moveCurrentSelectedElementToPosition = (selectedElements: IElementInfo[]) => {
    let offset;
    if (movingElementCategory.current === 'Revit Lighting Fixtures') {
      offset = new THREE.Vector3(
        selectedElements[0].hitPoint.x - movingElementCenter.current.x,
        selectedElements[0].hitPoint.y - movingElementCenter.current.y,
        0
      );
    } else {
      let y = selectedElements[0].hitPoint.y - movingElementCenter.current.y;
      y = y * Math.abs(Math.sin(selectedElements[0].angle));
      let x = selectedElements[0].hitPoint.x - movingElementCenter.current.x;
      x = x * Math.abs(Math.cos(selectedElements[0].angle));

      offset = new THREE.Vector3(
        x,
        y,
        selectedElements[0].hitPoint.z - movingElementCenter.current.z
      );
    }

    moveExtension.current.applyElementOffset(
      movingElementDbId.current,
      offset,
      movingElementModelId.current
    );
  };

  // useEffect(() => {
  //   if (selectedElements?.length > 0) {
  //     if (markerExtension.current?.isEnabled()) {
  //       markerExtension.current.addMarkerOnClick({
  //         x: selectedElements[0].hitPoint.x,
  //         y: selectedElements[0].hitPoint.y,
  //         z: selectedElements[0].hitPoint.z,
  //         data: {
  //           dbId: selectedElements[0].dbId
  //         }
  //       });
  //       markerExtension.current.setEnabled(false);
  //     }

  //     const biService = BIService.getInstance();
  //     biService.logEvent(EVENT_TYPES.SELECT_ELEMENT, CONTEXTS.MODEL, {
  //       element_category: selectedElements[0].category
  //     });
  //   }
  //   // For debugging purposes uncomment this
  //   console.info('Selected elements: ', selectedElements);
  // }, [selectedElements]);

  // useEffect(() => {
  //   if (customRequestMode === CustomRequestMode.EDIT) {
  //     markerExtension.current.setEnabled(true);
  //   }
  // }, [customRequestMode]);

  useEffect(() => {
    if (customRequests?.length >= 0 && markerExtension.current) {
      markerExtension.current.loadMarkers(
        customRequests.map((customRequest) => {
          return {
            x: customRequest.locationX,
            y: customRequest.locationY,
            z: customRequest.locationZ,
            _id: customRequest._id,
            data: {
              dbId: customRequest.dbId,
              customRequestId: customRequest._id
            },
            status:
              customRequest.status === CustomRequestStatus.ACTIVE
                ? MarkerStatus.OPEN
                : MarkerStatus.CLOSED
          };
        })
      );
    }
  }, [customRequests, markerExtension.current]);

  useEffect(() => {
    if (markerToRemove) {
      markerExtension.current.removeMarker(markerToRemove);
    }
  }, [markerToRemove]);

  useEffect(() => {
    if (modificationsFetched) {
      modificationsLoaded.current = true;
      modifications.current = modificationsFromStore;
    }
  }, [modificationsFetched, modificationsFromStore]);

  useEffect(() => {
    assets.current = fetchedAssets;
  }, [fetchedAssets]);

  const checkValidationRules = async (location: THREE.Vector3, category: string, viewer) => {
    const globalOffset = viewer.model.getData().globalOffset;
    const offsetVec = new THREE.Vector3(globalOffset.x, globalOffset.y, globalOffset.z);

    const locNoOffset = location.clone().sub(offsetVec);

    const errors = [];
    const errorHighlightColor = new THREE.Vector4(1, 0, 0, 0.8);
    const checksToHighlight = [];

    switch (category) {
      case 'Revit Electrical Fixtures':
        // Floor
        const floorCheck = await minDistanceCheck(locNoOffset, 'Revit Floors', 0.3);
        if (!floorCheck.valid) {
          errors.push(String(messages['tweaks.viewer.warning.floorTooClose']));
          checksToHighlight.push(floorCheck);
        }

        // Ceiling
        const ceilingCheck = await minDistanceCheck(locNoOffset, 'Revit Ceilings', 0.3);
        if (!ceilingCheck.valid) {
          errors.push(String(messages['tweaks.viewer.warning.ceilingTooClose']));
          checksToHighlight.push(ceilingCheck);
        }

        // Door
        const doorCheck = await minDistanceCheck(locNoOffset, 'Revit Doors', 0.15);
        if (!doorCheck.valid) {
          errors.push(String(messages['tweaks.viewer.warning.doorTooClose']));
          checksToHighlight.push(doorCheck);
        }

        // Window
        const windowCheck = await minDistanceCheck(locNoOffset, 'Revit Windows', 0.15);
        if (!windowCheck.valid) {
          errors.push(String(messages['tweaks.viewer.warning.windowTooClose']));
          checksToHighlight.push(windowCheck);
        }
        break;
      default:
        break;
    }

    for (let i = 0; i < errors.length; i++) {
      dispatch({
        type: ViewerEvents.ValidationMessage,
        payload: {
          type: messageSeverity.ERROR,
          message: errors[i]
        }
      });
    }

    for (let i = 0; i < checksToHighlight.length; i++) {
      customizerExtension.current.setTemporalHighlight([
        {
          modelId: checksToHighlight[i].modelId,
          dbId: checksToHighlight[i].dbId,
          color: errorHighlightColor
        }
      ]);
    }

    return errors.length === 0;
  };

  const minDistanceCheck = async (
    location: THREE.Vector3,
    category: string,
    minDistance: number
  ) => {
    const result = { valid: true, dbId: null, modelId: null };
    const closestElement = await customizerExtension.current.getClosestElementByCategoryAndLocation(
      category,
      location
    );
    if (closestElement) {
      const distanceInMeters = closestElement.distance / 3.2808399;
      if (distanceInMeters < minDistance) {
        result.valid = false;
        result.dbId = closestElement.dbId;
        result.modelId = closestElement.modelId;
      }
    }
    return result;
  };

  const resetElement = (selectedElements: IElementInfo[]) => {
    const mod = getElementModification([selectedElements[0]]);

    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.RESET_ASSET, CONTEXTS.VIEW, {
      category: selectedElements[0].category
    });

    if (mod) {
      reduxDispatch(
        resetModifications([mod], viewerInstanceRef.current, () => {
          drawMarkersOnSelectableElements();
        })
      );
    }
  };

  const applyDefaultMaterials = async (viewer) => {
    const floorDbids = customizerExtension.current.floorDbids;

    for (let i = 0; i < floorDbids.length; i++) {
      applyFloorsDefaultMaterials(floorDbids[i], viewer);
    }

    for (const [key, value] of Customizer.wallsAndFloorGroups.entries()) {
      if (/\bwall?\b|-/gi.test(key)) {
        for (let i = 0; i < value.length; i++) {
          applyWallsDefaultMaterials(value[i].dbId, viewer);
        }
      }
    }
  };

  const applyTilesDefaultMaterials = async (modifications: IModification[]) => {
    for (let i = 0; i < modifications.length; i++) {
      if (
        modifications[i].type === MODIFICATION_TYPE.TILE &&
        modifications[i].attrs?.elementType?.toLowerCase() === 'floor'
      ) {
        applyFloorsDefaultMaterials(modifications[i].dbId, viewerInstanceRef.current);
      }

      if (
        modifications[i].type === MODIFICATION_TYPE.TILE &&
        modifications[i].attrs?.elementType?.toLowerCase() === 'wall'
      ) {
        applyWallsDefaultMaterials(modifications[i].dbId, viewerInstanceRef.current);
      }
    }
  };

  const applyWallsDefaultMaterials = async (dbId, viewer) => {
    if (homeRef.current?.defaultMaterials?.bathroomWalls) {
      const wallsMat = {
        id: homeRef.current.defaultMaterials?.bathroomWalls._id,
        textureFileUrl: homeRef.current.defaultMaterials?.bathroomWalls.imgUrl,
        reflectivity: 0,
        realWorldSize:
          REAL_WORLD_SIZE_MAP[homeRef.current.defaultMaterials?.bathroomWalls.size] || 60
      };

      customizerExtension.current.changeMaterialByElementId(dbId, wallsMat);
    }
  };

  const applyFloorsDefaultMaterials = async (
    dbId: number,
    viewer: Autodesk.Viewing.GuiViewer3D
  ) => {
    if (homeRef.current?.defaultMaterials?.floors) {
      const floorsMat = {
        id: homeRef.current.defaultMaterials?.floors._id,
        textureFileUrl: homeRef.current.defaultMaterials?.floors.imgUrl,
        reflectivity: 0,
        realWorldSize: REAL_WORLD_SIZE_MAP[homeRef.current.defaultMaterials?.floors.size] || 60
      };
      customizerExtension.current.changeMaterialByElementId(dbId, floorsMat);
    }

    if (homeRef.current?.defaultMaterials?.bathroomFloors) {
      const bathroomFloorsMat = {
        id: homeRef.current.defaultMaterials?.bathroomFloors._id,
        textureFileUrl: homeRef.current.defaultMaterials?.bathroomFloors.imgUrl,
        reflectivity: 0,
        realWorldSize:
          REAL_WORLD_SIZE_MAP[homeRef.current.defaultMaterials?.bathroomFloors.size] || 60
      };
      const props = await getPropertiesAsync(viewer, dbId);
      const elementTypeProp = props.properties.find(
        (x) => x.displayName === 'floorType'
      )?.displayValue;
      if (elementTypeProp && elementTypeProp.toString().toLowerCase().includes('wet')) {
        customizerExtension.current.changeMaterialByElementId(dbId, bathroomFloorsMat);
      }
    }
  };

  const handleDeleteConfirm = () => {
    setIsConfirmationDialogOpen(false);
    const assetType = ForgeUtils.isOutlet(selectedElements[0].name)
      ? AssetCategory.OUTLETS
      : AssetCategory.SWITCHES;
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.DELETE_ASSET, CONTEXTS.VIEW, {
      category: assetType,
      name: selectedElements[0].name,
      dbid: selectedElements[0].dbId,
      modelId: selectedElements[0].modelId
    });

    console.log('delete asset');

    dispatch({
      type: ViewerEvents.ElementDeleted,
      payload: {
        selectedElement: selectedElements[0]
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          height: '100%',
          borderRadius: 0,
          overflow: 'hidden',
          zIndex: 0,
          ...(!previewMode ? { borderLeft: '1px solid #97979799' } : {})
        }}>
        <AddCommentCustomRequest
          menuPosition={editCustomRequestPosition}
          visible={editCustomRequestMenuVisible}
          setEditCustomRequestMenuVisible={setEditCustomRequestMenuVisible}
        />
        {!readOnly && <AddCustomRequestSnackBar />}
        {!viewerReady && !previewMode && (
          <div>
            <AppLoader />
          </div>
        )}

        {previewMode ? (
          <PixelStreamingViewer
            key={'viewerPreview'}
            previewMode={previewMode}
            viewerReady={viewerReady}
          />
        ) : null}
        <NavigationSnackBar />
        <Box
          sx={{
            position: 'absolute',
            bottom: '30px',
            right: '30px'
          }}>
          <Box
            sx={{
              position: 'relative',
              zIndex: 3,
              display: viewerReady && !previewMode && !isTopView ? 'block' : 'none'
            }}>
            <IconButton
              sx={{
                justifyContent: 'flex-end',
                float: 'right',
                top: '50px',
                right: '4px'
              }}
              onClick={() => {
                dispatch({
                  type: ViewerEvents.CameraTop
                });
              }}>
              <OpenInFullOutlined
                sx={{
                  fill: '#252525'
                }}
              />
            </IconButton>
          </Box>
          <Box
            id="viewerContainer2"
            sx={{
              position: 'relative',
              height: '210px',
              width: '210px',
              zIndex: 2,
              overflow: 'hidden',
              background: 'none!important',
              borderRadius: '20px',
              pointerEvents: 'none',
              opacity: viewerReady && !previewMode && !isTopView ? 1 : 0
            }}></Box>
        </Box>
        <Box
          sx={{
            opacity: viewerReady && !previewMode ? 1 : 0
          }}
          id="viewerContainer"></Box>
      </Box>

      {!previewMode && viewerReady ? (
        <>
          <Catelog />
        </>
      ) : null}

      {isConfirmationDialogOpen ? (
        <AppConfirmDialog
          open={isConfirmationDialogOpen}
          onDeny={() => setIsConfirmationDialogOpen(false)}
          onConfirm={handleDeleteConfirm}
          title={<IntlMessages id="viewer.controls.delete.confirm" />}
          dialogTitle={<IntlMessages id="viewer.controls.delete" />}
        />
      ) : null}

      <ElementEditMenu
        selectedOutlet={selectedElements?.length > 0 ? selectedElements[0] : null}
        onMoveStart={() => {
          dispatch({
            type: ViewerEvents.MoveStart,
            payload: selectedElements[0]
          });
          setEditMenuVisible(false);
        }}
        onDelete={() => {
          setIsConfirmationDialogOpen(true);
          setEditMenuVisible(false);
        }}
        onShowSwitch={() => {
          onShowSwitch();
        }}
        onShowLight={() => {
          onShowLight();
        }}
        onAddSwitch={() => {
          const biService = BIService.getInstance();
          biService.logEvent(EVENT_TYPES.OPEN_CATALOG_DIALOG, CONTEXTS.VIEW, {
            category: AssetCategory.SWITCHES
          });
          reduxDispatch(openCatelogDialog('switches'));
        }}
        onReplaceOutLet={() => {
          const biService = BIService.getInstance();
          biService.logEvent(EVENT_TYPES.OPEN_CATALOG_DIALOG, CONTEXTS.VIEW, {
            category: AssetCategory.OUTLETS
          });
          reduxDispatch(openCatelogDialog('outlets'));
        }}
        onCloseMenu={() => {
          setEditMenuVisible(false);
        }}
        isDrawerOpen={editMenuVisible}
        onResetElement={() => {
          resetElement(selectedElements);
        }}
      />

      {/* {homeRef.current && (
        <Box
          style={{
            position: 'fixed',
            top: 100,
            right: 100,
            zIndex: 10,
            borderRadius: 10,
            padding: '1px 0 1px 0',
            backgroundColor: 'white'
          }}>
          <CustomRequestsWrapper home={homeRef.current} />
        </Box>
      )} */}
    </>
  );
};

export default BasicForgeViewer;
