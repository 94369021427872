import {
  GET_CUSTOM_REQUESTS,
  CREATE_CUSTOM_REQUEST,
  ADD_CUSTOM_REQUEST_COMMENT,
  GET_CUSTOM_REQUEST,
  CLOSE_CUSTOM_REQUEST,
  DELETE_CUSTOM_REQUEST,
  UPDATE_CUSTOM_REQUEST,
  CUSTOM_REQUEST_ADD_COMMENT,
  CUSTOM_REQUEST_GET_COMMENTS,
  RESET_CUSTOM_REQUEST_STORE,
  CUSTOM_REQUEST_SET_MODE,
  CUSTOM_REQUEST_SELECTED_MARKER,
  CUSTOM_REQUEST_REMOVE_MARKER,
  CUSTOM_REQUEST_CAMERA_INFO
} from 'types/actions/CustomRequest.actions';

import { AppActions } from '../../types';
import {
  ICustomRequest,
  ICustomRequestComment,
  CustomRequestMode
} from 'types/models/CustomRequest';
import { IMarker } from 'shared/extensions/tweaks-markers';

const initialState: {
  customRequests: ICustomRequest[] | null;
  customRequest: ICustomRequest | null;
  customRequestComments: ICustomRequestComment[] | null;
  customRequestComment: ICustomRequestComment | null;
  mode: CustomRequestMode | null;
  selectedMarker: IMarker | null;
  markerToRemove: IMarker | null;
  cameraInfo: any | null;
  lastDeletedCommentId: string | null;
} = {
  customRequests: null,
  customRequest: null,
  customRequestComments: null,
  customRequestComment: null,
  mode: CustomRequestMode.CLOSE,
  selectedMarker: null,
  markerToRemove: null,
  cameraInfo: null,
  lastDeletedCommentId: null
};

const Viewer = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_CUSTOM_REQUESTS:
      return {
        ...state,
        customRequests: action.payload
      };

    case CREATE_CUSTOM_REQUEST:
      return {
        ...state,
        selectedMarker: null,
        customRequests: state.customRequests
          ? [...state.customRequests, action.payload]
          : [action.payload]
      };

    case GET_CUSTOM_REQUEST:
      return {
        ...state,
        customRequest: action.payload,
        customRequests:
          state?.customRequests?.map((item) => {
            return item._id === action.payload._id ? action.payload : item;
          }) || []
      };

    case ADD_CUSTOM_REQUEST_COMMENT:
      return {
        ...state,
        customRequestComment: action.payload
      };

    case CLOSE_CUSTOM_REQUEST:
      return {
        ...state,
        customRequest: action.payload,
        customRequests: state.customRequests.map((item) => {
          return item._id === action.payload._id ? action.payload : item;
        })
      };

    case DELETE_CUSTOM_REQUEST:
      return {
        ...state,
        customRequest: action.payload
      };

    case CUSTOM_REQUEST_GET_COMMENTS:
      return {
        ...state,
        customRequestComments: action.payload
      };

    case CUSTOM_REQUEST_ADD_COMMENT:
      return {
        ...state,
        customRequestComment: action.payload
      };

    case UPDATE_CUSTOM_REQUEST:
      return {
        ...state,
        customRequests: state.customRequests.map((request) => {
          // Check if request has the comment with matching id
          if (request.comments.some((comment) => comment._id === action.payload._id)) {
            // If it does, map the comments and update the one with matching id
            return {
              ...request,
              comments: request.comments.map((comment) =>
                comment._id === action.payload._id
                  ? { ...comment, comment: action.payload.comment }
                  : comment
              )
            };
          }
          // If it doesn't, return the request as is
          else {
            return request;
          }
        })
      };

    case CUSTOM_REQUEST_SET_MODE:
      return {
        ...state,
        mode: action.payload
      };

    case CUSTOM_REQUEST_SELECTED_MARKER:
      return {
        ...state,
        selectedMarker: action.payload
      };

    case CUSTOM_REQUEST_CAMERA_INFO:
      return {
        ...state,
        cameraInfo: action.payload
      };

    case CUSTOM_REQUEST_REMOVE_MARKER:
      return {
        ...state,
        selectedMarker: null,
        customRequests: state.customRequests.filter((x) => x._id !== action.payload._id),
        markerToRemove: action.payload,
        lastDeletedCommentId: action.payload._id
      };

    case RESET_CUSTOM_REQUEST_STORE:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

export default Viewer;
