import { AlertColor } from '@mui/material';
import { VariantType } from 'notistack';

export enum ToolTipEnum {
  INFO,
  WARNING,
  ERROR
}

export const authRole = {
  admin: ['user', 'admin'],
  user: ['user']
};

export enum RoutePermittedRole {
  Admin = 'admin',
  User = 'owner',
  Builder = 'builder'
}

type Dictionary = {
  [key: string]: string;
};

export const singleSocketOutLetNames: string[] = [
  'TWKS_Single_outlet',
  'שקע בודד',
  'Single outlet'
];
export const doubleSocketOutLetNames: string[] = [
  'TWKS_Double_outlet',
  'שקע כפול',
  'Double outlet'
];

export const bathRoomNames: string[] = ['bath', 'ח.רחצה', 'bathroom', 'toilet'];

export const switchesNames: string[] = ['מפסק בודד', 'TWKS_Switch_single_pole', 'single pole'];
const dbNamesDict: Dictionary = {
  TWKS_Single_outlet: 'electricity_outlet',
  שקע: 'electricity_outlet',
  'מפסק בודד': 'electricity_outlet',
  TWKS_Double_outlet: 'electricity_outlet',
  TWKS_Switch_single_pole: 'electricity_outlet',
  lighting_fixture: 'electricity_outlet',
  פנדל: 'electricity_outlet'
};

export const floorsDbidsByCategory: Map<string, number> = new Map([
  ['Living Room', 7389],
  ['Bedroom 1', 14231],
  ['Bedroom 2', 14262],
  ['Bathroom', 7436],
  ['Den', 7404],
  ['Master Bathroom', 7424],
  ['Master Bedroom', 14248]
]);

export type ToolTipInfo = {
  info: string;
  type: AlertColor;
};

export const toolTipElementNameAndInfo: Map<string, ToolTipInfo> = new Map([
  [
    'bed',
    {
      info: 'Do you have a King size bed? Consider adjusting the bedside outlets accordingly',
      type: 'info'
    }
  ],
  [
    'מטבח',
    {
      info: 'Many buyers in this community opted to add a kitchen island, browse upgraded kitchens here',
      type: 'warning'
    }
  ],
  [
    'Wardrobe',
    {
      info: 'Not enough storage? Check out our walk-in closet upgrade',
      type: 'info'
    }
  ],
  [
    'catalogue',
    {
      info: 'We’ve noticed you opted for earthy tones and finishes for the guest bathroom, did you know you can opt for the same tiles in all other bathrooms?',
      type: 'info'
    }
  ],
  [
    'ח.רחצה',
    {
      info: 'Need more sockets? Check out our package of additional items in your bathroom (shaving machine, hairdryer, electric toothbrush etc).',
      type: 'info'
    }
  ]
]);

export const getDbNameForItem = (name: string) => {
  const dictKey = Object.keys(dbNamesDict).find((key) => name.includes(key));
  return dictKey ? dbNamesDict[dictKey] : '';
};

export const getInitialUrl = (role: RoutePermittedRole) => {
  const map = {
    [RoutePermittedRole.User]: '/home',
    [RoutePermittedRole.Builder]: '/projects',
    [RoutePermittedRole.Admin]: '/admin/projects'
  };

  // return signin url if no role can be matched
  return map[role] || '/signin';
};

export const pageSize = 12;

export const LS_AUTH_KEY = 'twksx_auth';

export const messageSeverity = {
  SUCCESS: 'success' as VariantType,
  ERROR: 'error' as VariantType,
  INFO: 'info' as VariantType,
  WARNING: 'warning' as VariantType
};

export const MessageEvents = {
  SuccessfullySaved: 'twks_successfully_saved',
  SuccessfullyUpdated: 'twks_successfully_updated',
  Error: 'twks_error'
};

export const ViewerEvents = {
  DeleteElementConfirmation: 'twks_delete_element_confirmation',
  PreviewModeOn: 'twks_preview_mode_on',
  PreviewModeOff: 'twks_preview_mode_off',
  CameraTop: 'twks_camera_top',
  CameraFirstPerson: 'twks_camera_first_person',
  SetCamera: 'twks_set_camera',
  onFirstPersonView: 'twks_camera_first_person_change',
  TakeScreenshot: 'twks_take_screenshot',
  GoToRoom: 'twks_go_to_room',
  ElementMoved: 'twks_element_moved',
  ElementDeleted: 'twks_element_deleted',
  ChangeTile: 'twks_change_tile',
  AddAsset: 'twks_add_asset',
  AddAssetCompleted: 'twks_add_asset_completed',
  ValidationMessage: 'twks_validation_message',
  ReloadModel: 'twks_reload_model',
  AddAssetStart: 'twks_add_asset_start',
  AddOutletOptions: 'twks_add_outlet_options',
  AddLightingOptions: 'twks_add_lighting_options',
  AddSwitchOptions: 'twks_add_switch_options',
  AddSwitch: 'twks_add_switch',
  CloseAssetOptions: 'twks_close_asset_options',
  FurnitureShow: 'twks_furniture_show',
  FurnitureHide: 'twks_furniture_hide',
  ShowHightLights: 'twks_show_highlights',
  MeasureActivate: 'twks_measure_activate',
  MeasureDeactivate: 'twks_measure_deactivate',
  HighlightCategory: 'twks_highlight_category',
  MoveStart: 'twks_move_start',
  MoveEnd: 'twks_move_end',
  openCatalogRequest: 'twks_open_catalog_request',
  AddCustomRequest: 'twks_add_custom_request',
  SeeAllCustomRequest: 'twks_see_all_custom_request',
  CameraInfoRequest: 'twks_camera_info_request',
  CameraInfoRespForRoomCamera: 'twks_camera_info_resp_for_room_camera',
  CameraInfoRespForCustomRequest: 'twks_camera_info_resp_for_custom_request',
  CancelAddAsset: 'twks_cancel_add_asset',
  CompleteAddAsset: 'twks_complete_add_asset',
  SelectAsset: 'twks_select_asset',
  FindBBoxSiblings: 'twks_find_bbox_siblings',
  BBoxSiblingsFound: 'twks_bbox_siblings_found',
  BedRoomsFound: 'twks_bedrooms_found',
  ShowPackage: 'twks_show_package',
  ApplyFloorTiles: 'twks_apply_floor_tiles',
  SelectFloorByCategory: 'twks_select_floor_by_category',
  FocusOnElement: 'twks_focus_on_element',
  DisableInteractions: 'twks_disable_interactions',
  SelectClosestFloor: 'twks_select_closest_floor',
  SelectWallTiles: 'twks_select_wall_tiles',
  ResetRoomModifications: 'twks_reset_room_modifications',
  AddElement: 'twks_add_element',
  DeleteElement: 'twks_delete_element',
  setNightMode: 'twks_set_night_mode',
  setTourPlayMode: 'twks_set_tour_play_mode'
};

export const RightMenuEvents = {
  OpenSection: 'twks_right_menu_open_section',
  CloseSection: 'twks_right_menu_close_section'
};

export const RightMenuSections = {
  HomeTemplates: 'twks_right_menu_section_home_templates',
  SupplierSelection: 'twks_right_menu_section_supplier_selection',
  DesignTweaks: 'twks_right_menu_section_design_tweaks',
  Modifications: 'twks_right_menu_section_modifications',
  CustomRequests: 'twks_right_menu_section_custom_requests'
};

export const TemplateSelectionEvents = {
  SelectedTemplate: 'twks_template_selection_selected_template'
};

export const MOVE_OUTLET_COST = 0;

export const REAL_WORLD_SIZE_MAP = {
  '10x10': 10,
  '20x20': 20,
  '30x30': 30,
  '60/60': 60,
  '80/80': 80,
  '100/100': 100,
  '33/33': 33,
  '30/30': 30,
  '25/75': 25,
  '30/60': 30,
  '25/70': 25,
  '20/60': 20,
  '20/50': 20
};

export enum EVENT_TYPES {
  PAGE_VIEW = 'page_view',
  LOGIN_ATTEMP = 'login_attemp',
  LOGOUT = 'logout',
  FORGOT_PASSWORD = 'forgot_password',
  SEND_PASSWORD = 'send_password',
  SET_PASSWORD = 'set_password',
  RESET = 'reset',
  SUBMIT_RESET = 'submit_reset',
  SELECT_TEMPLATE = 'select_template',
  SUBMIT_TEMPLATE = 'submit_template',
  START_TWEAKING = 'start_tweaking',
  SUBMIT_HOME = 'submit_home',
  SUBMIT_SHOW_DIALOG = 'submit_show_dialog',
  MODEL_LOAD_STARTED = 'model_load_started',
  MODEL_LOAD_COMPLETED = 'model_load_completed',
  MODEL_LOADED = 'model_loaded',
  ROOMS_DATA_LOADED = 'rooms_data_loaded',
  MATERIALS_SWAP_COMPLETED = 'materials_swap_completed',
  DEFAULT_MATERIALS_APPLIED = 'default_materials_applied',
  GO_TO_ROOM = 'go_to_room',
  VIEW_MODE = 'view_mode',
  TOOL_CLICKED = 'tool_clicked',
  EXIT_MODE = 'exit_mode',
  APPLY_TILE = 'apply_tile',
  SELECT_TILE = 'select_tile',
  SELECT_ELEMENT = 'select_element',
  SELECT_SUPPLIER = 'select_supplier',
  CLOSE_APP = 'close_app',
  SWITCH_TAB = 'switch_tab',
  REQUEST_ON_ELEMENT = 'request_on_element',
  SHOW_REQUEST = 'show_request',
  ADD_REQUEST = 'add_request',
  MODIFICATIONS_APPLIED = 'modifications_applied',
  PACKAGES_CREATED = 'packages_created',
  CACHING_COMPLETED = 'caching_completed',
  DELETE_REQUEST = 'delete_request',
  VIEW_MODE_CHANGE = 'view_mode_change',
  SUMMARY_CLICKED = 'summary_clicked',
  ROOM_CLICKED = 'room_clicked',
  ICON_MENU_CLICKED = 'icon_menu_clicked',
  CHANGE_LANGUAGE = 'change_language',
  PACKAGE_SELECTED = 'package_selected',
  PACKAGE_CLICKED = 'package_clicked',
  PACKAGE_MODAL_OPEN = 'package_modal_open',
  PACKAGE_OPTION_SELECTED = 'package_option_selected',
  ADD_ASSET_CATALOG_SELECTION = 'add_asset_catalog_selection',
  REPLACE_ASSET = 'replace_asset',
  RESET_ASSET = 'reset_asset',
  OPEN_CATALOG_DIALOG = 'open_catalog_dialog',
  RESET_ROOM = 'reset_room',
  ADD_ASSET = 'add_asset',
  DELETE_ASSET = 'delete_asset',
  SELECT_UPGRADE = 'select_upgrade',
  CLICK_START = 'click_start',
  MATCHMAKER_LOADING = 'matchmaker_loading',
  MATCHMAKER_MATCHED = 'matchmaker_matched',
  VIDEO_INITIALIZED = 'video_initialized',
  AFK_IN_PREVIEW_MODE = 'afk_in_preview_mode',
  AFK_IN_EDITOR_MODE = 'afk_in_editor_mode',
  EMIT_EVENT_TO_UNREAL = 'emit_event_to_unreal',
  SHOW_MESSAGE = 'show_message'
}

export enum CONTEXTS {
  PIXEL_STREAMING = 'pixel_streaming',
  LOGIN = 'login',
  VIEW = 'view',
  HOME = 'home',
  MODEL = 'model',
  TOOLS = 'tools',
  TILES = 'tiles',
  REQUESTS = 'requests',
  SUMMARY = 'summary',
  ICON_MENU = 'icon_menu'
}
