import { IHome } from 'types/models/Home';

export const GET_HOME = 'GET_HOME';
export const UPDATE_HOME = 'UPDATE_HOME';
export const RESET_HOME_STORE = 'RESET_HOME_STORE';
export const LOADING_HOME = 'LOADING_HOME';

export interface GetHomeAction {
  type: typeof GET_HOME;
  payload: IHome;
}

export interface UpdateHomeAction {
  type: typeof UPDATE_HOME;
  payload: IHome;
}

export interface ResetHomeStoreAction {
  type: typeof RESET_HOME_STORE;
}

export interface LoadingHomeAction {
  type: typeof LOADING_HOME;
}

export type HomeActionTypes =
  | GetHomeAction
  | UpdateHomeAction
  | ResetHomeStoreAction
  | LoadingHomeAction;
