export enum ThemeStyle {
  MODERN = 'modern',
  STANDARD = 'standard'
}

export enum ThemeStyleRadius {
  MODERN = 30,
  STANDARD = 16
}

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum LayoutType {
  FULL_WIDTH = 'full-width',
  BOXED = 'boxed',
  FRAMED = 'framed'
}

export enum MenuStyle {
  DEFAULT = 'default',
  STANDARD = 'standard',
  ROUNDED = 'rounded',
  ROUNDED_REVERSE = 'rounded-reverse',
  CURVED_MENU = 'curved-menu'
}

export enum LayoutDirection {
  RTL = 'rtl',
  LTR = 'ltr'
}

export enum NavStyle {
  DEFAULT = 'default',
  BIT_BUCKET = 'bit_bucket',
  STANDARD = 'standard',
  DRAWER = 'drawer',
  MINI = 'mini',
  MINI_SIDEBAR_TOGGLE = 'mini-sidebar-toggle',
  HEADER_USER = 'user-header',
  HEADER_USER_MINI = 'user-mini-header',
  H_DEFAULT = 'h-default',
  HOR_HEADER_FIXED = 'hor-header-fixed',
  HOR_DARK_LAYOUT = 'hor-dark-layout'
}

export enum FooterType {
  FIXED = 'fixed',
  FLUID = 'fluid'
}

export enum HeaderType {
  FLUID = 'fluid',
  FIXED = 'fixed'
}

export enum RouteTransition {
  NONE = 'none',
  SLIDE_LEFT = 'slideLeft',
  SLIDE_RIGHT = 'slideRight',
  SLIDE_UP = 'slideUp',
  SLIDE_DOWN = 'slideDown'
}

export enum Fonts {
  LIGHT = '300',
  REGULAR = '400',
  MEDIUM = '500',
  SEMI_BOLD = '600',
  BOLD = '700'
}

export enum AuthType {
  JWT_AUTH = 'jwt_auth'
}
