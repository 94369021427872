import { Box, Tooltip, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as RiScreenshot2Fill } from '../../../../assets/icon/photo-camera-add.svg';
import { ReactComponent as BiRuler } from '../../../../assets/icon/ruler-1.svg';
import { ReactComponent as GiSofa } from '../../../../assets/icon/sofa_1.svg';
import { ReactComponent as DayModeSwitch } from '../../../../assets/icon/day-mode-switches.svg';
import { ReactComponent as NightModeSwitch } from '../../../../assets/icon/night-mode-switches.svg';
import { ReactComponent as BiCommentDetail } from '../../../../assets/icon/custom-request.svg';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { CONTEXTS, EVENT_TYPES, ViewerEvents } from 'shared/constants/AppConst';
import { useAuthUser } from 'shared/utility/AuthHooks';
import IntlMessages from 'shared/utility/IntlMessages';
import { dispatch } from 'use-bus';
import { setCustomRequestViewMode } from 'redux/actions/CustomRequest';
import { CustomRequestMode } from 'types/models/CustomRequest';
import BIService from 'shared/utility/BIService';

import { findRoomKeyByRevitRoomName } from 'services/rooms/rooms.service';

// import './viewerControls.css';

export const ViewerTools = ({ isPreviewMode }) => {
  const reduxDispatch = useDispatch();
  const { mode } = useSelector<AppState, AppState['customRequest']>(
    ({ customRequest }) => customRequest
  );

  const [hideFurniture, setHideFurniture] = useState<boolean>(false);
  const [measureModel, setMeasureModel] = useState<boolean>(false);
  const [isNightMode, setIsNightMode] = useState<boolean>(false);
  const [hightLightVisibile, setHightLightVisibile] = useState(false);

  const { user } = useAuthUser();
  const selectedRoom = useSelector<AppState, AppState['viewer']['selectedRoom']>(
    (state) => state.viewer.selectedRoom
  );

  const { item: home } = useSelector<AppState, AppState['home']>(
    ({ home: homeInstance }) => homeInstance
  );

  const hasDevParam = useRef(false);
  const params = new URLSearchParams(window.location.search);
  hasDevParam.current = params.has('dev');

  const handleEnterCustomRequestEditMode = () => {
    if (mode === CustomRequestMode.CLOSE) {
      reduxDispatch(setCustomRequestViewMode(CustomRequestMode.EDIT));
      return;
    }
    reduxDispatch(setCustomRequestViewMode(CustomRequestMode.CLOSE));
  };

  useEffect(() => {
    // clear all active tools
    // escape key listener
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setMeasureModel(false);
        setHideFurniture(false);
        showHightLight(false);
        reduxDispatch(setCustomRequestViewMode(CustomRequestMode.CLOSE));
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const showHightLight = (visible: boolean) => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.TOOL_CLICKED, CONTEXTS.TOOLS, {
      tool_type: 'highlights',
      is_active: visible
    });
    setHightLightVisibile(visible);
    dispatch({
      type: ViewerEvents.ShowHightLights,
      isActive: visible
    });
  };

  useEffect(() => {
    dispatch({
      type: ViewerEvents.FurnitureHide,
      isActive: hideFurniture
    });
  }, [hideFurniture]);

  useEffect(() => {
    dispatch({
      type: ViewerEvents.MeasureActivate,
      isActive: measureModel
    });
  }, [measureModel]);

  useEffect(() => {
    dispatch({
      type: ViewerEvents.setNightMode,
      isActive: isNightMode
    });
  }, [isNightMode]);

  const StyledButton = ({ isSelected, ...props }) => (
    <Box
      sx={{
        '& .MuiButtonBase-root': {
          border: isSelected ? '1px solid #FF5A00' : '1px solid #EFECEB',
          background: isSelected
            ? 'linear-gradient(180deg, rgba(255, 238, 229, 0.00) 46.08%, rgba(255, 238, 229, 0.70) 100%), #FFF;'
            : 'transparent',
          boxShadow: isSelected ? ' 0px 1px 2px 0px rgba(165, 58, 0, 0.23);' : 'none',
          ':hover': {
            backgroundColor: isSelected ? 'inherit' : '#FAF8F7',
            border: isSelected ? '1px solid #FF5A00' : '1px solid #EFECEB'
          }
        }
      }}
      {...props}
    />
  );

  const takeScreenshot = () => {
    if (isPreviewMode) {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;

      const img = new Image();
      img.src = '/assets/images/TollBrothersPoweredByTweak.png';
      img.onload = function () {
        const videoParent = document.getElementById('streamingVideo') as HTMLVideoElement;
        const context = canvas.getContext('2d');
        const aspect = videoParent.videoWidth / videoParent.videoHeight;
        canvas.width = 1920;
        canvas.height = 1920 / aspect;

        context.drawImage(videoParent, 0, 0, canvas.width, canvas.height);
        context.drawImage(img, 60, canvas.height - img.height - 60, img.width, img.height);

        let nameAndHome = `${user?.displayName}'s_Home`;

        if (selectedRoom?.category) {
          const roomKeyArr = findRoomKeyByRevitRoomName(selectedRoom.category)?.split('.');
          const roomKey = roomKeyArr ? roomKeyArr[roomKeyArr.length - 1] : '';

          nameAndHome = `${user?.displayName}'s_Home_` + roomKey;
        }

        canvas.toBlob((blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = nameAndHome;

          link.click();

          URL.revokeObjectURL(link.href);
          canvas.remove();
        }, 'image/png');
      };
    } else {
      dispatch(ViewerEvents.TakeScreenshot);
    }
  };

  return (
    <>
      <Box
        sx={{
          zIndex: 1,
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
          '& .MuiButtonBase-root': {
            boxShadow: 'none',
            border: '1px solid #EFECEB',
            width: '48px',
            height: '48px',
            padding: 0
          }
        }}>
        {/* Measure */}
        <StyledButton isSelected={measureModel}>
          <Tooltip title={<IntlMessages id="viewer.controls.measure" />} placement={'bottom'}>
            <IconButton
              style={{
                padding: 0,
                width: '40px',
                height: '40px'
              }}
              aria-label="Measure tool"
              onClick={() => setMeasureModel(!measureModel)}>
              <BiRuler style={{ stroke: measureModel ? '#FF5A00' : '#949290' }} />
            </IconButton>
          </Tooltip>
        </StyledButton>
        {/* Take Screenshot */}

        <StyledButton isSelected={false}>
          <Tooltip
            title={<IntlMessages id="viewer.controls.share.screenshot" />}
            placement={'bottom'}>
            <IconButton
              style={{
                padding: 0,
                width: '40px',
                height: '40px'
              }}
              size="large"
              onClick={() => takeScreenshot()}>
              <RiScreenshot2Fill fontSize={32} style={{ stroke: '#949290' }} />
            </IconButton>
          </Tooltip>
        </StyledButton>

        {/* Custom request */}
        <StyledButton
          isSelected={mode === CustomRequestMode.EDIT}
          // sx={{
          //   opacity:
          //     user?.role === RoutePermittedRole.Builder ||
          //     home?.status === STATUS_TYPE.SUBMITTED ||
          //     home?.status === STATUS_TYPE.SUBMITTED_TO_BUILDER
          //       ? '0.6'
          //       : '1'
          // }}
        >
          <Tooltip title={<IntlMessages id="tweaks.add.customRequest" />} placement={'bottom'}>
            <IconButton
              aria-label="add-custom-request"
              // size="large"
              style={{
                padding: 0,
                width: '40px',
                height: '40px'
              }}
              onClick={handleEnterCustomRequestEditMode}>
              <BiCommentDetail
                fontSize={32}
                style={{ stroke: mode === CustomRequestMode.EDIT ? '#FF5A00' : '#949290' }}
              />
            </IconButton>
          </Tooltip>
        </StyledButton>

        {/* Show hide highlights*/}
        {/* <StyledButton isSelected={hightLightVisibile}>
          <Tooltip
            title={
              <IntlMessages
                id={
                  hightLightVisibile
                    ? 'viewer.controls.hideHightLight'
                    : 'viewer.controls.showHightLight'
                }
              />
            }
            placement={'bottom'}>
            <IconButton
              style={{
                padding: 0,
                width: '40px',
                height: '40px'
              }}
              aria-label="Hide/show furniture"
              onClick={() => {
                showHightLight(!hightLightVisibile);
              }}>
              <MagicWand
                fontSize={32}
                style={{ stroke: hightLightVisibile ? '#FF5A00' : '#949290' }}
              />
            </IconButton>
          </Tooltip>
        </StyledButton> */}
        {/* Show hide furniture */}
        <StyledButton isSelected={hideFurniture}>
          <Tooltip
            title={
              <IntlMessages id={hideFurniture ? 'viewer.controls.show' : 'viewer.controls.hide'} />
            }
            placement={'bottom'}>
            <IconButton
              style={{
                padding: 0,
                width: '40px',
                height: '40px'
              }}
              aria-label="Hide/show furniture"
              size="large"
              onClick={() => setHideFurniture(!hideFurniture)}>
              <GiSofa fontSize={32} style={{ stroke: hideFurniture ? '#FF5A00' : '#949290' }} />
            </IconButton>
          </Tooltip>
        </StyledButton>
        {/* Day/Night  */}
        <StyledButton isSelected={isNightMode}>
          <Tooltip
            title={<IntlMessages id={'viewer.controls.daynightswitch'} />}
            placement={'bottom'}>
            <IconButton
              style={{
                padding: 0,
                width: '40px',
                height: '40px'
              }}
              aria-label="Hide/show furniture"
              size="large"
              onClick={() => {
                setIsNightMode(!isNightMode);
              }}>
              {isNightMode ? (
                <NightModeSwitch fontSize={32} style={{ stroke: '#FF5A00' }} />
              ) : (
                <DayModeSwitch fontSize={32} style={{ stroke: '#FF5A00' }} />
              )}
            </IconButton>
          </Tooltip>
        </StyledButton>
      </Box>
    </>
  );
};
