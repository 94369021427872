import { Application } from '@yovelsapir/lib-pixelstreamingfrontend-ui-ue5.2';
import { MutableRefObject } from 'react';
import { CONTEXTS, EVENT_TYPES } from 'shared/constants/AppConst';
import BIService from 'shared/utility/BIService';

class EmitApiService {
  private static pixelStreamingRef: MutableRefObject<Application>;

  public static builder(_pixelStreamingRef: MutableRefObject<Application>) {
    EmitApiService.pixelStreamingRef = _pixelStreamingRef;
    return new EmitApiService();
  }

  public emitEventToUnreal(
    eventType: string,
    value: string | boolean | number,
    verificationId?: any
  ) {
    if (EmitApiService.pixelStreamingRef.current) {
      const biService = BIService.getInstance();
      biService.logEvent(EVENT_TYPES.EMIT_EVENT_TO_UNREAL, CONTEXTS.PIXEL_STREAMING, {
        value,
        eventType,
        verificationId
      });
      EmitApiService.pixelStreamingRef.current.stream.emitUIInteraction({
        type: eventType,
        value: value,
        verification_id: verificationId
      });
    }
  }
}

const emitApiServiceBuilder = EmitApiService.builder;

export default emitApiServiceBuilder;
