import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { STATUS_TYPE } from 'types/models/Home';
import { useResetHome } from './useResetHome';

type HomeStatus = 'disabled' | 'review' | 'available';

export const useHomeButtonStatus = (): HomeStatus => {
  const { item: _home } = useSelector<AppState, AppState['home']>((state) => state.home);
  const resetHomeInstance = useResetHome();

  const getButtonType = (): HomeStatus => {
    if (!_home) {
      return 'disabled';
    }

    if (
      _home.status === STATUS_TYPE.NEW ||
      _home.status === STATUS_TYPE.REJECTED ||
      _home.status === STATUS_TYPE.BACK_TO_OWNER
    ) {
      if (!resetHomeInstance.hasChanges) {
        return 'disabled';
      }

      return 'available';
    }

    return 'review';
  };

  return getButtonType();
};
