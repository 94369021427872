import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import { Fonts } from 'shared/constants/AppEnums';
import AppCard from 'shared/core/AppCard';
import IntlMessages from 'shared/utility/IntlMessages';

const ProjectHomeListDetail = ({ project }) => {
  return (
    <AppCard
      sxStyle={{
        height: 1,
        display: 'flex',
        flexDirection: 'column'
      }}
      contentStyle={{ padding: 0 }}>
      <Box>
        <Box
          style={{
            height: 300,
            backgroundImage: `url("${
              project?.imageUrl || '/assets/images/custom/default-project.png'
            }")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        />
      </Box>

      <Box
        sx={{
          pt: { xs: 4, xl: 6 },
          px: { xs: 4, xl: 6 }
        }}>
        <Box
          component="h4"
          sx={{
            mb: 4,
            fontSize: 16,
            fontWeight: Fonts.BOLD,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Box>{project?.projectName}</Box> <Box>{project?.address}</Box>
        </Box>

        <Divider
          sx={{
            my: 4
          }}
        />
        <Box
          sx={{
            color: 'text.secondary',
            fontWeight: Fonts.MEDIUM,
            fontSize: 14,
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center'
          }}>
          <Button
            component={Link}
            to={`/home/projects/${project._id}`}
            variant="contained"
            color="primary">
            <IntlMessages id="actions.goToProject" />
          </Button>
        </Box>
      </Box>
    </AppCard>
  );
};

export default ProjectHomeListDetail;
