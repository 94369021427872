import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';

const ConnectionTooltip = ({ children, title, content }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const connectionQuality = useSelector<AppState, AppState['viewer']['connectionQualityStatus']>(
    (state) => state.viewer.connectionQualityStatus
  );

  useEffect(() => {
    setShowTooltip(connectionQuality == 1);
  }, [connectionQuality]);

  const tooltipStyle = {
    display: showTooltip ? 'flex' : 'none',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    gap: '8px',
    position: 'absolute' as const,
    width: '202px',
    height: '49px',
    background: 'rgba(0, 0, 0, 0.75)',
    borderRadius: '4px',
    zIndex: 1000, // Ensure it's above other elements
    top: '100%', // Aligns the top of the tooltip with the bottom of the child
    right: '0%', // Center the tooltip
    transform: 'translateY(18px)' // Center alignment adjustment
  };

  const textContainerStyle = {
    width: '168px',
    height: '26px',
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center'
    // ... any other styles specific to the text container
  };

  const titleStyle = {
    fontStyle: 'normal',
    fontWeight: 700, // Bold for the title
    fontSize: '12px',
    lineHeight: '140%',
    textAlign: 'center' as const,
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center', // Center text vertically
    justifyContent: 'center' // Center text horizontally
    // ... other specific styles for the title
  };

  const contentStyle = {
    fontStyle: 'normal',
    fontWeight: 400, // Regular for the content
    fontSize: '12px',
    lineHeight: '140%',
    textAlign: 'center' as const,
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center', // Center text vertically
    justifyContent: 'center' // Center text horizontally
    // ... other specific styles for the content
  };

  return (
    <Box
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      position="relative">
      <Box style={tooltipStyle}>
        <div style={textContainerStyle}>
          <div style={titleStyle}>{title}</div>
          <div style={contentStyle}>{content}</div>
        </div>
      </Box>
      {children}
    </Box>
  );
};

export default ConnectionTooltip;
