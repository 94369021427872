import { IProject, IProjectFilter } from 'types/models/Project';

import {
  GET_PROJECT,
  GET_PROJECTS,
  GET_PROJECT_FILE,
  GET_PROJECT_FILES,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  RESET_PROJECT_STORE,
  LOADING_PROJECT
} from 'types/actions/Project.actions';

import { AppActions } from '../../types';
import { IFile } from 'types/models/File';

const initialState: {
  items: IProject[];
  filter: IProjectFilter;
  item: IProject;
  total: number;
  loading: boolean;
  projectFile: IFile;
  projectFiles: IFile[];
} = {
  items: [],
  filter: null,
  item: null,
  total: 0,
  loading: false,
  projectFile: null,
  projectFiles: []
};

const Project = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_PROJECT:
      return {
        ...state,
        item: action.payload,
        loading: false
      };
    case GET_PROJECTS:
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.totalCount,
        loading: false
      };
    case GET_PROJECT_FILE:
      return {
        ...state,
        projectFile: action.payload,
        loading: false
      };
    case GET_PROJECT_FILES:
      return {
        ...state,
        projectFiles: action.payload,
        loading: false
      };

    case CREATE_PROJECT:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case UPDATE_PROJECT:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case LOADING_PROJECT:
      return {
        ...state,
        loading: true
      };

    case RESET_PROJECT_STORE:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
export default Project;
