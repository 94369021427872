import { IModification } from 'types/models/Modification';
export const GET_MODIFICATIONS = 'GET_MODIFICATIONS';
export const ADD_MODIFICATION = 'ADD_MODIFICATION';
export const UPDATE_MODIFICATION = 'UPDATE_MODIFICATION';
export const DELETE_MODIFICATION = 'DELETE_MODIFICATION';
export const SET_MODIFICATIONS = 'SET_MODIFICATION';
export const RESET_MODIFICATION_STORE = 'RESET_MODIFICATION_STORE';
export const GET_LATEST_TILE_MODIFICATION_BY_ELEMENT_TYPE =
  'GET_LATEST_TILE_MODIFICATION_BY_ELEMENT_TYPE';
export const RESET_LATEST_TILE_MODIFICATION_BY_ELEMENT_TYPE =
  'RESET_LATEST_TILE_MODIFICATION_BY_ELEMENT_TYPE';
export const GET_TILE_MODIFICATION_BY_ELEMENT_DBID = 'GET_TILE_MODIFICATION_BY_ELEMENT_DBID';
export const RESET_TILE_MODIFICATION_BY_ELEMENT_DBID = 'RESET_TILE_MODIFICATION_BY_ELEMENT_DBID';
export const RESET_ALL_MODIFICATIONS = 'RESET_ALL_MODIFICATIONS';

export interface GetModificationsAction {
  type: typeof GET_MODIFICATIONS;
  payload: IModification[];
}

export interface SetModificationsAction {
  type: typeof SET_MODIFICATIONS;
  payload: IModification[];
}

export interface AddModificationAction {
  type: typeof ADD_MODIFICATION;
  payload: IModification;
}

export interface UpdateModificationAction {
  type: typeof UPDATE_MODIFICATION;
  payload: IModification;
}

export interface DeleteModificationAction {
  type: typeof DELETE_MODIFICATION;
  payload: string;
}

export interface ResetModificationStoreAction {
  type: typeof RESET_MODIFICATION_STORE;
}

export interface ResetAllModificationsAction {
  type: typeof RESET_ALL_MODIFICATIONS;
}

export interface ResetLatestTileModificationByElementTypeAction {
  type: typeof RESET_LATEST_TILE_MODIFICATION_BY_ELEMENT_TYPE;
}

export interface GetLatestTileModificationByElementTypeAction {
  type: typeof GET_LATEST_TILE_MODIFICATION_BY_ELEMENT_TYPE;
  payload: IModification;
}

export interface GetTileModificationByElementDbIdAction {
  type: typeof GET_TILE_MODIFICATION_BY_ELEMENT_DBID;
  payload: IModification;
}

export interface ResetTileModificationByElementDbIdAction {
  type: typeof RESET_TILE_MODIFICATION_BY_ELEMENT_DBID;
}

export type ModificationActionTypes =
  | GetModificationsAction
  | AddModificationAction
  | SetModificationsAction
  | ResetModificationStoreAction
  | UpdateModificationAction
  | DeleteModificationAction
  | GetLatestTileModificationByElementTypeAction
  | ResetLatestTileModificationByElementTypeAction
  | ResetAllModificationsAction
  | GetTileModificationByElementDbIdAction
  | ResetTileModificationByElementDbIdAction;
