import { ForgeUtils } from 'shared/utility/ForgeUtilities/ForgeUtils';
import { IRoomInfo } from 'types/models/ForgeViewer';

export class Rooms extends Autodesk.Viewing.Extension {
  rooms: number[] | null;

  constructor(viewer, options) {
    super(viewer, options);
    this.viewer = viewer;
    this.rooms = null;
  }

  load() {
    console.info('Tweaksx Rooms extension has been loaded');
    return true; // This returning flag represents the load success
  }

  unload() {
    console.info('Tweaksx Rooms extension has been unloaded');
    return true; // This returning flag represents the unload success
  }

  static register() {
    const extensionName = 'TweaksxRooms';
    Autodesk.Viewing.theExtensionManager.registerExtension(extensionName, Rooms);
    return extensionName;
  }

  getRoomDbIds = async () => {
    return new Promise((resolve, reject) => {
      this.viewer.search(
        '"Revit Rooms"',
        async (dbIds) => {
          try {
            this.viewer.model.getBulkProperties(
              dbIds,
              { propFilter: ['ElementId', 'Area'] },
              (props) => {
                const actualRooms = props
                  .filter(
                    (prop) =>
                      prop.properties.length === 2 &&
                      !prop.properties[0].displayValue.toString().includes('/') &&
                      prop.properties[1].displayValue !== 0
                  )
                  .map((prop) => prop.dbId);

                this.rooms = actualRooms;
                resolve(actualRooms);
              },
              (error) => reject(error)
            );
          } catch (error) {
            reject(error);
          }
        },
        (error) => reject(error),
        ['Category'],
        // @ts-ignore
        { searchHidden: true }
      );
    });
  };

  async getRooms() {
    const data: IRoomInfo[] = [];

    try {
      const roomDbIds = this.rooms || ((await this.getRoomDbIds()) as any);
      const models = this.viewer.getAllModels();
      const props: Autodesk.Viewing.PropertyResult[] = await ForgeUtils.getBulkProperties(
        models[1],
        roomDbIds,
        false
      );
      props.forEach((prop) => {
        // @ts-ignore
        const roomInfo: IRoomInfo = {};
        // @ts-ignore
        roomInfo.id = prop.externalId;
        roomInfo.dbId = prop.dbId;
        // @ts-ignore
        roomInfo.name = prop.name;
        // Keeping all the properties just in case
        // @ts-ignore
        roomInfo.properties = prop.properties;
        // @ts-ignore
        roomInfo.category = prop.properties.find((x) => x.displayName === 'Name')?.displayValue;
        roomInfo.boundingBox = this.getBoundingBox(prop.dbId);
        data.push(roomInfo);
      });
    } catch (ex) {
      throw new Error('Failed to extract room data');
    }

    // Sorting result
    data.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });

    //unload the second model becuase we don't need it anymore after extracting the rooms data
    const models = this.viewer.getAllModels();
    for (const model of models) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (model._isMaster) {
        this.viewer.unloadModel(model);
        break;
      }
    }

    return data;
  }

  goToRoom(room: IRoomInfo, height) {
    const bbox = room.boundingBox;
    const center = bbox.getCenter();
    const position = new THREE.Vector3(center.x, center.y, 0);
    position.add(new THREE.Vector3(0, 0, height));
    const target = position.clone().add(new THREE.Vector3(0, 1, 0));
    this.viewer.navigation.setView(position, target);

    // Set Z(+) axis as UP vector
    this.viewer.navigation.setCameraUpVector(new THREE.Vector3(0, 0, 1));
  }

  async hideRoomsBoundingBoxes() {
    const roomsDbIds = (await this.getRoomDbIds()) as any;
    for (let i = 0; i < roomsDbIds.length; i++) {
      this.viewer.impl.visibilityManager.setNodeOff(roomsDbIds[i], true);
    }
  }

  getBoundingBox(dbId) {
    const models = this.viewer.getAllModels();
    const model = models[1];
    const it = model.getInstanceTree();
    const fragList = model.getFragmentList();
    const bounds = new THREE.Box3();

    it.enumNodeFragments(
      dbId,
      (fragId) => {
        const box = new THREE.Box3();
        fragList.getWorldBounds(fragId, box);
        bounds.union(box);
      },
      true
    );

    return bounds;
  }
}
