import { useState } from 'react';
import Box from '@mui/material/Box';
import IntlMessages from 'shared/utility/IntlMessages';
import { Button, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IProject } from 'types/models/Project';
import { Fonts } from 'shared/constants/AppEnums';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { messageSeverity } from 'shared/constants/AppConst';
import AppGridContainer from 'shared/core/AppGridContainer';
import UsersSelector from './UsersSelector';
import { IUser } from 'types/models/User';
import { sendNotifications } from 'redux/actions/Project';
import { ISendNotificationRequest } from 'types/models/Notification';
import LoadingButton from '@mui/lab/LoadingButton';

export interface INotificationsFormProps {
  project: IProject;
  users: IUser[];
}

const NotificationsForm = (props: INotificationsFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { messages } = useIntl();
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [notificationTemplate, setNotificationTemplate] = useState<string>('welcome');

  const [notificationSubject, setNotificationSubject] = useState('');
  const [notificationContent, setNotificationContent] = useState('');

  const isFormValid = () => {
    const hasReceivers = selectedUsers.length > 0;

    if (hasReceivers) {
      if (notificationTemplate === 'welcome') return true;
      if (notificationTemplate === 'message' || notificationTemplate === 'system_message')
        return notificationSubject.length > 0 && notificationContent.length > 0;
    }
  };

  const onSubjectChange = (e) => {
    setNotificationSubject(e.target.value);
  };

  const onContentChange = (e) => {
    setNotificationContent(e.target.value);
  };

  const onSubmit = async () => {
    if (!isFormValid) return;
    setSubmitting(true);
    const data: ISendNotificationRequest = {
      userIds: selectedUsers.map((u) => u._id),
      projectId: props.project._id,
      template: notificationTemplate,
      subject: notificationSubject,
      content: notificationContent
    };

    const callback = () => {
      navigate(`/projects/${props.project._id}`);
      enqueueSnackbar(String(messages['notifications.sent']), {
        variant: messageSeverity.SUCCESS,
        autoHideDuration: 3000
      });
    };

    dispatch(sendNotifications(data, callback));
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        component="h2"
        sx={{
          fontSize: 16,
          color: 'text.primary',
          fontWeight: Fonts.SEMI_BOLD,
          mb: {
            xs: 2,
            lg: 4
          }
        }}>
        <IntlMessages id={'common.notifications'} />
      </Box>

      <aside>
        <AppGridContainer>
          <Grid item xs={12} md={6}>
            <div style={{ height: 'calc(100vh - 370px)', minHeight: 392 }}>
              <InputLabel>
                <IntlMessages id="common.users" />
              </InputLabel>
              <UsersSelector
                loading={false}
                users={props.users}
                onSelectionChanged={(selection) => {
                  setSelectedUsers(selection);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                mb: {
                  xs: 2,
                  lg: 4
                }
              }}>
              <InputLabel>
                <IntlMessages id="notifications.template" />
              </InputLabel>
              <Select
                onChange={(event) => {
                  setNotificationTemplate(event.target.value);
                }}
                value={notificationTemplate}>
                <MenuItem value={'welcome'}>
                  <IntlMessages id="notifications.template.welcome" />
                </MenuItem>
                <MenuItem value={'message'}>
                  <IntlMessages id="notifications.template.message" />
                </MenuItem>
                <MenuItem value={'system_message'}>
                  <IntlMessages id="notifications.template.system_message" />
                </MenuItem>
              </Select>
            </Box>

            {(notificationTemplate === 'message' || notificationTemplate === 'system_message') && (
              <>
                <Box
                  sx={{
                    mb: {
                      xs: 2,
                      lg: 4
                    }
                  }}>
                  <TextField
                    fullWidth={true}
                    id="outlined-multiline-flexible"
                    label={String(messages['notifications.subject'])}
                    onChange={onSubjectChange}
                    value={notificationSubject}
                    error={notificationSubject === ''}
                  />
                </Box>
                <Box
                  sx={{
                    mb: {
                      xs: 0
                    }
                  }}>
                  <TextField
                    fullWidth={true}
                    multiline
                    rows={11}
                    minRows={11}
                    label={String(messages['notifications.message'])}
                    onChange={onContentChange}
                    value={notificationContent}
                    error={notificationContent === ''}
                  />
                </Box>
              </>
            )}
          </Grid>
        </AppGridContainer>

        <Grid item xs={12} style={{ marginTop: '2rem' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}>
            <LoadingButton
              sx={{
                position: 'relative',
                minWidth: 100
              }}
              color="primary"
              variant="contained"
              disabled={!isFormValid()}
              onClick={() => {
                onSubmit();
              }}
              loading={submitting}>
              <IntlMessages id="common.send" />
            </LoadingButton>
            <Button
              sx={{
                position: 'relative',
                minWidth: 100,
                ml: 2.5
              }}
              color="primary"
              variant="outlined"
              onClick={() => navigate(`/projects/${props.project._id}`)}>
              <IntlMessages id="actions.back" />
            </Button>
          </Box>
        </Grid>
      </aside>
    </Box>
  );
};

export default NotificationsForm;
