import {
  Fonts,
  FooterType,
  HeaderType,
  LayoutDirection,
  LayoutType,
  MenuStyle,
  NavStyle,
  RouteTransition,
  ThemeMode,
  ThemeStyle,
  ThemeStyleRadius
} from 'shared/constants/AppEnums';
import Cookies from 'js-cookie';
import languageData, { LanguageProps } from 'shared/core/AppLngSwitcher/data';
import moment from 'moment';
import { momentHeConfig } from '../Utils';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    success: true;
    review: true;
    draft: true;
    danger: true;
    disabled: true;
  }
}

const urlLang =
  window.location.pathname.indexOf('/he/') !== -1
    ? languageData.find((item) => item.languageId === 'hebrew')
    : window.location.pathname.indexOf('/en/') !== -1
    ? languageData.find((item) => item.languageId === 'english')
    : null;

if (urlLang?.locale === 'he') {
  moment.locale('he', momentHeConfig);
  moment.locale('he');
}

const cookieAppLang = Cookies.get('appLang');
export const appLangData =
  urlLang ||
  ((cookieAppLang
    ? JSON.parse(cookieAppLang)
    : languageData.find((item) => item.languageId === 'english')) as LanguageProps);

export const textLight = {
  primary: '#252525',
  secondary: 'rgb(107, 114, 128)',
  disabled: 'rgb(149, 156, 169)'
};

export const textDark = {
  primary: 'rgb(255,255,255)',
  secondary: 'rgb(229, 231, 235)',
  disabled: 'rgb(156, 163, 175)'
};

export const backgroundDark = {
  paper: '#2B3137',
  default: '#1F2527'
};

export const backgroundLight = {
  paper: '#FFFFFF',
  default: '#F4F7FE'
};

const cardRadius = ThemeStyleRadius.STANDARD;
export const defaultTheme: any = {
  theme: {
    spacing: 4,
    cardRadius: cardRadius,
    direction: appLangData.locale === 'he' ? LayoutDirection.RTL : LayoutDirection.LTR, //ltr, rtl
    palette: {
      mode: ThemeMode.LIGHT,
      background: {
        paper: '#FFFFFF',
        default: '#F6F6F6'
      },
      primary: {
        main: '#ff5a00',
        contrastText: '#fff',
        default: '#ff5a00',
        hover: '#FFEEE5'
      },
      secondary: {
        main: '#F36F02'
      },
      success: {
        modal: '#10C900',
        main: '#009F10',
        light: '#D9F5E5'
      },
      warning: {
        main: '#FF5252',
        light: '#FFECDC'
      },
      text: textLight,
      gray: {
        50: '#fafafa',
        100: '#F5F6FA',
        200: '#edf2f7',
        300: '#E0E0E0',
        400: '#c5c6cb',
        500: '#A8A8A8',
        600: '#666666',
        700: '#4a5568',
        800: '#201e21',
        900: '#1a202c',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161'
      }
    },
    status: {
      danger: 'orange'
    },
    divider: 'rgba(224, 224, 224, 1)',
    typography: {
      fontFamily: ['Heebo', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 400,
      h1: {
        fontSize: 22,
        fontWeight: 600
      },
      h2: {
        fontSize: 20,
        fontWeight: 500
      },
      h3: {
        fontSize: 18,
        fontWeight: 500
      },
      h4: {
        fontSize: 16,
        fontWeight: 500
      },
      h5: {
        fontSize: 14,
        fontWeight: 500
      },
      h6: {
        fontSize: 12,
        fontWeight: 500
      },
      subtitle1: {
        fontSize: 14
      },
      subtitle2: {
        fontSize: 16
      },
      body1: {
        fontSize: 14
      },
      body2: {
        fontSize: 12
      }
    },
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius
          }
        }
      },
      MuiCardLg: {
        styleOverrides: {
          root: {
            // apply theme's border-radius instead of component's default
            borderRadius:
              cardRadius === ThemeStyleRadius.STANDARD
                ? ThemeStyleRadius.STANDARD
                : ThemeStyleRadius.MODERN + 20
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            boxShadow: '0px 10px 10px 4px rgba(0, 0, 0, 0.04)',
            '& .MuiCardContent-root:last-of-type': {
              paddingBottom: 16
            }
          }
        }
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              transition: 'all 0.3s ease',
              ':hover': {
                transform: 'scale(1.02)'
              },
              ':disabled': {
                opacity: 0.6
              }
            }
          },
          {
            props: { variant: 'outlined' },
            style: {
              transition: 'all 0.3s ease',
              ':hover': {
                transform: 'scale(1.02)'
              },
              ':disabled': {
                opacity: 0.6
              }
            }
          },
          {
            props: { variant: 'draft' },
            style: {
              transition: 'all 0.3s ease',
              background: '#5A55F4',
              lineHeight: 1,
              color: '#FFFFFF',
              ':hover': {
                transform: 'scale(1.02)',
                backgroundColor: '#7976FF'
              },
              ':active': {
                backgroundColor: '#3E3AAD'
              },
              ':disabled': {
                color: '#FFFFFFBB',
                background: '#AFA9E8'
              }
            }
          },
          {
            props: { variant: 'success' },
            style: {
              background: '#10C900',
              color: 'white',
              transition: 'all 0.3s ease',
              ':hover': {
                background: '#10C900BB',
                transform: 'scale(1.02)'
              },
              ':disabled': {
                background:
                  'linear-gradient(89.91deg, rgba(71, 215, 60, 0.82) 18.96%, rgba(85, 221, 73, 0.87) 94.94%);',
                '> .MuiTypography-root': {
                  opacity: 0.6
                }
              }
            }
          },
          {
            props: { variant: 'danger' },
            style: {
              background: '#FF0A0A',
              color: 'white',
              transition: 'all 0.3s ease',
              ':hover': {
                background: '#FF0A0ADF',
                color: 'white',
                transform: 'scale(1.02)'
              },
              ':disabled': {
                '> .MuiTypography-root': {
                  opacity: 0.6
                }
              },
              ':active': {
                backgroundColor: '#FF0A0ABB'
              }
            }
          },
          {
            props: { variant: 'review' },
            style: {
              background: '#FFEEE5',
              color: '#FF5A00',
              transition: 'all 0.3s ease',
              ':hover': {
                background: '#FFEEE5BB',
                color: '#FF5A00BB',
                transform: 'scale(1.02)'
              },
              ':disabled': {
                '> .MuiTypography-root': {
                  opacity: 0.6
                }
              },
              ':active': {
                backgroundColor: '#FFD7BE'
              }
            }
          },
          {
            props: { variant: 'disabled' },
            style: {
              background: '#FAF8F7',
              color: '#C3C3C3',
              transition: 'all 0.3s ease',
              ':hover': {
                background: '#FAF8F7',
                color: '#949290',
                transform: 'scale(1.02)'
              },
              ':disabled': {
                '> .MuiTypography-root': {
                  opacity: 0.6
                }
              },
              ':active': {
                backgroundColor: '#FFD7BE'
              }
            }
          }
        ],
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
            // boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
            textTransform: 'capitalize'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 9
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: Fonts.REGULAR
          }
        }
      }
    }
  }
};

export interface SidebarData {
  sidebarBgColor: string;
  sidebarTextColor: string;
  sidebarHeaderColor: string;
  sidebarMenuSelectedBgColor: string;
  sidebarMenuSelectedTextColor: string;
  mode: string;
}

export const DarkSidebar: SidebarData = {
  sidebarBgColor: '#313541',
  sidebarTextColor: '#fff',
  sidebarHeaderColor: '#313541',
  sidebarMenuSelectedBgColor: '#F4F7FE',
  sidebarMenuSelectedTextColor: 'rgba(0, 0, 0, 0.87)',
  mode: ThemeMode.DARK
};
export const LightSidebar: SidebarData = {
  sidebarBgColor: '#fff',
  sidebarTextColor: 'rgba(0, 0, 0, 0.60)',
  sidebarHeaderColor: '#fff',
  sidebarMenuSelectedBgColor: '#F4F7FE',
  sidebarMenuSelectedTextColor: 'rgba(0, 0, 0, 0.87)',
  mode: ThemeMode.LIGHT
};
const defaultConfig = {
  sidebar: {
    borderColor: '#757575',
    menuStyle: MenuStyle.DEFAULT,
    isSidebarBgImage: false,
    sidebarBgImage: 1,
    colorSet: LightSidebar
  },
  themeStyle: ThemeStyle.STANDARD,
  themeMode: ThemeMode.LIGHT,
  navStyle: NavStyle.MINI,
  layoutType: LayoutType.FRAMED,
  footerType: FooterType.FLUID,
  headerType: HeaderType.FIXED,
  rtAnim: RouteTransition.NONE,
  footer: false,
  locale: appLangData,
  rtlLocale: ['ar', 'he']
};
export default defaultConfig;
