import React from 'react';
import Divider from '@mui/material/Divider';
import IntlMessages from 'shared/utility/IntlMessages';
import Box from '@mui/material/Box';
import { Fonts } from 'shared/constants/AppEnums';
import AppCard from 'shared/core/AppCard';
import { IProject } from 'types/models/Project';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useAuthUser } from 'shared/utility/AuthHooks';
import { RoutePermittedRole } from 'shared/constants/AppConst';

interface IProjectDetailInfo {
  project: IProject;
}

const ProjectDetailInfo: React.FC<IProjectDetailInfo> = ({ project }) => {
  const { user } = useAuthUser();
  return (
    <AppCard
      sxStyle={{
        height: 1,
        display: 'flex',
        flexDirection: 'column'
      }}
      contentStyle={{ padding: 0 }}>
      <Box
        sx={{
          position: 'relative',
          flex: 1,
          display: 'flex',
          '& .widthFull': {
            width: '100%'
          }
        }}>
        <img
          className="widthFull"
          src={
            project?.imageUrl
              ? project?.imageUrl + '?t=' + project.updatedAt
              : '/assets/images/custom/default-project.png'
          }
          alt="illustration"
        />
      </Box>

      <Box
        sx={{
          pt: { xs: 4, xl: 6 },
          px: { xs: 4, xl: 6 }
        }}>
        <Box
          component="h4"
          sx={{
            mb: 4,
            fontSize: 16,
            fontWeight: Fonts.BOLD
          }}>
          {project?.address}
        </Box>
        {(user?.role === RoutePermittedRole.Admin || user?.role === RoutePermittedRole.Builder) && (
          <>
            <Divider
              sx={{
                my: 4
              }}
            />
            <Box
              sx={{
                color: 'text.secondary',
                fontWeight: Fonts.MEDIUM,
                fontSize: 14,
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center'
              }}>
              <Button
                component={Link}
                to={`/projects/${project._id}/notifications`}
                variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: 'secondary'
                }}>
                <IntlMessages id="common.notifications" />
              </Button>
              <Button
                sx={{
                  ml: 2.5
                }}
                component={Link}
                to={`/projects/edit/${project._id}`}
                variant="contained"
                color="primary">
                <IntlMessages id="actions.edit" />
              </Button>
            </Box>
          </>
        )}
      </Box>
    </AppCard>
  );
};

export default ProjectDetailInfo;
