import { useEffect, useState } from 'react';
import Upload from '../../thirdParty/reactDropzone/components/UploadModern';
import Box from '@mui/material/Box';
import { useDropzone } from 'react-dropzone';
import FileRow from '../../thirdParty/reactDropzone/components/FileRow';
import AppList from 'shared/core/AppList';
import IntlMessages from 'shared/utility/IntlMessages';
import { Button } from '@mui/material';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { useDispatch } from 'react-redux';
import jwtAxios from 'services/auth/jwt-auth';
import { useNavigate } from 'react-router-dom';
import { IProject } from 'types/models/Project';
import { Fonts } from 'shared/constants/AppEnums';
import TextField from '@mui/material/TextField';
import { IModel } from 'types/models/Model';
import { showMessage } from 'redux/actions';
import axios from 'axios';

export interface IModelsDropzone {
  project: IProject;
  model?: IModel;
  onEdit?: () => void;
}

const ModelsDropzone = ({ project, model, onEdit }: IModelsDropzone) => {
  const dropzone = useDropzone({
    accept: ['.rvt', '.zip']
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [modelName, setModelName] = useState(model?.name || '');
  const [filesReady, setFilesReady] = useState(false);

  const onSubmit = async () => {
    dispatch(fetchStart());
    try {
      const fileToUpload = uploadedFiles[0];

      // 1. Get Signed URLs
      const response = await jwtAxios.post(`projects/${project._id}/getSignedUrlsForUpload`, {
        project: project.projectName,
        fileName: fileToUpload.name,
        modelName: modelName
      });

      const s3urls = response.data.urls;
      const length = fileToUpload.size;
      const chunk = Math.ceil(length / s3urls.length);

      // 2. Upload Chunks to Signed URLs
      for (let index = 0; index < s3urls.length; index++) {
        const start = chunk * index;
        let end = chunk * (index + 1);
        end = end > length ? length : end;

        const payload = fileToUpload.slice(start, end);

        try {
          await axios.put(s3urls[index], payload, {
            headers: {
              'Content-Type': 'application/octet-stream'
            },
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
            timeout: 1000 * 60 * 20
          });
        } catch (error) {
          console.error('Error uploading chunk:', index);
          console.error('Start:', start, 'End:', end, 'Size:', end - start);
          console.error('Signed URL:', s3urls[index]);

          if (error.response) {
            console.error('Response', error.response);
          } else if (error.request) {
            console.error('Request:', error.request);
          } else {
            console.error('Error message:', error.message);
          }

          throw error;
        }
        console.log('Uploaded chunk', index);
      }

      const postParams = {
        project: project._id,
        name: modelName,
        fileName: fileToUpload.name,
        uploadKey: response.data.uploadKey,
        bucketKey: response.data.bucketKey
      };

      if (model) {
        await jwtAxios.put(`projects/${project._id}/models/${model._id}`, postParams);
        onEdit();
        dispatch(showMessage('Model updated successfully'));
      } else {
        await jwtAxios.post(`projects/${project._id}/models`, postParams);
        navigate(`/projects/${project._id}`);
      }
      dispatch(fetchSuccess());
      setFilesReady(false);
      dropzone.acceptedFiles.splice(0, dropzone.acceptedFiles.length);
    } catch (error) {
      console.warn(error);
      dispatch(fetchError('Something went wrong'));
    }
  };

  useEffect(() => {
    setUploadedFiles(dropzone.acceptedFiles);
    if (dropzone.acceptedFiles.length > 0) {
      setFilesReady(true);
    }
  }, [dropzone.acceptedFiles]);

  const onDeleteUploadFile = (file: any) => {
    dropzone.acceptedFiles.splice(dropzone.acceptedFiles.indexOf(file), 1);
    setUploadedFiles([...dropzone.acceptedFiles]);
    if (dropzone.acceptedFiles.length === 0) {
      setFilesReady(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModelName(event.target.value);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        component="h2"
        sx={{
          fontSize: 16,
          color: 'text.primary',
          fontWeight: Fonts.SEMI_BOLD,
          mb: {
            xs: 2,
            lg: 4
          }
        }}>
        <IntlMessages id={model ? 'project.actions.editModel' : 'project.actions.addModel'} />
      </Box>
      <Box
        sx={{
          mb: {
            xs: 2,
            lg: 4
          }
        }}>
        <TextField
          name="modelName"
          label={<IntlMessages id="models.name" />}
          value={modelName}
          onChange={handleChange}
        />
      </Box>

      <Upload uploadText={<IntlMessages id="actions.upload.revitFile" />} dropzone={dropzone} />
      <aside>
        <AppList
          data={uploadedFiles}
          renderRow={(file, index) => (
            <FileRow key={index + file.path} file={file} onDeleteUploadFile={onDeleteUploadFile} />
          )}
        />
        <Box
          style={{ marginTop: '1rem' }}
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
          {filesReady && modelName && (
            <Button
              style={{ marginRight: '1rem' }}
              variant="contained"
              color="primary"
              onClick={() => {
                onSubmit();
              }}>
              <IntlMessages id="common.save" />
            </Button>
          )}

          <Button
            color="primary"
            variant="outlined"
            onClick={() => navigate(`/projects/${project._id}`)}>
            <IntlMessages id="actions.back" />
          </Button>
        </Box>
      </aside>
    </Box>
  );
};

export default ModelsDropzone;
