import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const CustomReview = React.lazy(() => import('./homes/CustomReview'));
const ProjectsList = React.lazy(() => import('./projects/ProjectListAdmin'));
const CustomModelUploadWrapper = React.lazy(() => import('./models/CustomModelUploadWrapper'));
const UsersListAdmin = React.lazy(() => import('./users/UsersListAdmin'));

export const adminConfig = [
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/projects',
    element: <ProjectsList />
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ['/admin/projects/:id/units/:home_id/custom-review'],
    element: <CustomReview />
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: ['/admin/projects/:id/units/:home_id/custom-model'],
    element: <CustomModelUploadWrapper />
  },
  {
    permittedRole: RoutePermittedRole.Admin,
    path: '/admin/users',
    element: <UsersListAdmin />
  }
];
