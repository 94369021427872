import { Typography } from '@mui/material';
import AppConfirmDialog from 'shared/core/AppConfirmDialog';
import IntlMessages from 'shared/utility/IntlMessages';

type Props = {
  handleOnConfirm: () => void;
  openInReviewModal: boolean;
  setOpenInReviewModal: (open: boolean) => void;
};

export const InReviewModal = ({
  openInReviewModal,
  setOpenInReviewModal,
  handleOnConfirm
}: Props) => {
  return (
    <AppConfirmDialog
      open={openInReviewModal}
      onDeny={() => {
        setOpenInReviewModal(false);
      }}
      onConfirm={handleOnConfirm}
      confirmCopy={<IntlMessages id="common.contactUs" />}
      denyCopy={<IntlMessages id="common.cancel" />}
      dialogTitle={
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: 18,
            marginInline: '52px'
          }}>
          <IntlMessages id="actions.inReview.title" />
        </Typography>
      }
      title={
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: 14,
            textAlign: 'center',
            maxWidth: 508,
            mx: 'auto',
            mt: 6
          }}>
          <IntlMessages id="actions.inReview.content" values={{ builder: 'XXXX', br: <br /> }} />
        </Typography>
      }
    />
  );
};
