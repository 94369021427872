import { useEffect, useState } from 'react';
import { getOwnerHomes } from 'redux/actions';
import { IHome } from 'types/models/Home';

import { Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Fonts } from 'shared/constants/AppEnums';
import AppGridContainer from 'shared/core/AppGridContainer';
import Grid from '@mui/material/Grid';
import ProjectHomeListDetail from './ProjectHomeListDetail';
import { IProject } from 'types/models/Project';
import { getUniqueListBy, getEntityId } from 'shared/utility/Utils';
import AppLoader from 'shared/core/AppLoader';

const ProjectsHome = () => {
  const [homes, setHomes] = useState<IHome[]>(null);
  const [projects, setProjects] = useState<IProject[]>(null);

  useEffect(() => {
    getOwnerHomes().then((data) => {
      if (data.length > 0) {
        setHomes(data);
        setProjects(
          getUniqueListBy(
            data.map((item) => {
              return item.project;
            }),
            '_id'
          )
        );
      }
    });
  }, []);

  if (homes === null) {
    return <AppLoader />;
  }

  return (
    <>
      {homes?.length === 1 ? (
        <Navigate
          // @ts-ignore
          to={`/home/projects/${getEntityId(homes?.[0]?.project)}`}
        />
      ) : (
        <>
          <Box
            component="h3"
            sx={{
              color: 'text.primary',
              mb: { xs: 4, sm: 4, xl: 6 },
              fontSize: 16,
              fontWeight: Fonts.BOLD
            }}>
            My Projects
          </Box>
          <AppGridContainer>
            {projects.map((project) => (
              <Grid item xs={12} md={6}>
                <ProjectHomeListDetail project={project} />
              </Grid>
            ))}
          </AppGridContainer>
        </>
      )}
    </>
  );
};

export default ProjectsHome;
