import { Button, Checkbox, Divider, FormControlLabel, InputAdornment } from '@mui/material';
import IntlMessages from 'shared/utility/IntlMessages';
import Box from '@mui/material/Box';
import { Field, Form } from 'formik';
import AppTextField from 'shared/core/AppFormComponents/AppTextField';

const ModelTemplateForm = () => {
  return (
    <Form autoComplete="off">
      <Box style={{ margin: '15px 10px' }}>
        <AppTextField
          size="small"
          name="title"
          fullWidth
          label={<IntlMessages id="tweaks.model.title" />}
        />
      </Box>
      <Box style={{ margin: '15px 10px' }}>
        <AppTextField
          size="small"
          name="description"
          fullWidth
          multiline
          rows={4}
          label={<IntlMessages id="tweaks.description" />}
        />
      </Box>
      <Box style={{ margin: '15px 10px' }}>
        <AppTextField
          size="small"
          name="fullDescription"
          fullWidth
          multiline
          rows={8}
          label={<IntlMessages id="tweaks.fullDescription" />}
        />
      </Box>
      <Box style={{ margin: '15px 10px' }}>
        <AppTextField
          size="small"
          name="cost"
          fullWidth
          label={<IntlMessages id="common.cost" />}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IntlMessages id="tweaks.currencySymbol" />
              </InputAdornment>
            )
          }}
        />
      </Box>

      <Box style={{ margin: '0px 10px' }} sx={{ color: 'text.secondary' }}>
        <IntlMessages id="tweaks.modelTemplate.upgrades" />
        <Divider />
      </Box>

      <Box
        style={{ margin: 10, height: '1.5rem' }}
        sx={{
          mb: { xs: 3, xl: 4 },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { sm: 'center' }
        }}>
        <Field
          as={FormControlLabel}
          type="checkbox"
          name="upgrades.layout"
          control={<Checkbox />}
          style={{ marginRight: '5x' }}
          label={<IntlMessages id="tweaks.modelTemplate.upgrades.layout" />}
        />
      </Box>

      <Box
        style={{ margin: 10, height: '1.5rem' }}
        sx={{
          mb: { xs: 3, xl: 4 },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { sm: 'center' }
        }}>
        <Field
          as={FormControlLabel}
          type="checkbox"
          name="upgrades.electricity"
          control={<Checkbox />}
          style={{ marginRight: '5x' }}
          label={<IntlMessages id="tweaks.modelTemplate.upgrades.electricity" />}
        />
      </Box>

      <Box
        style={{ margin: 10, height: '1.5rem' }}
        sx={{
          mb: { xs: 3, xl: 4 },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { sm: 'center' }
        }}>
        <Field
          as={FormControlLabel}
          type="checkbox"
          name="upgrades.lighting"
          control={<Checkbox />}
          style={{ marginRight: '5x' }}
          label={<IntlMessages id="tweaks.modelTemplate.upgrades.lighting" />}
        />
      </Box>

      <Box
        style={{ margin: 10, height: '1.5rem' }}
        sx={{
          mb: { xs: 3, xl: 4 },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { sm: 'center' }
        }}>
        <Field
          as={FormControlLabel}
          type="checkbox"
          name="upgrades.sanitary"
          control={<Checkbox />}
          style={{ marginRight: '5x' }}
          label={<IntlMessages id="tweaks.modelTemplate.upgrades.sanitary" />}
        />
      </Box>

      <Box
        style={{ margin: 10, height: '1.5rem' }}
        sx={{
          mb: { xs: 3, xl: 4 },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { sm: 'center' }
        }}>
        <Field
          as={FormControlLabel}
          type="checkbox"
          name="upgrades.kitchen"
          control={<Checkbox />}
          style={{ marginRight: '5x' }}
          label={<IntlMessages id="tweaks.modelTemplate.upgrades.kitchen" />}
        />
      </Box>

      <Box style={{ margin: 10 }}>
        <Button
          size="small"
          sx={{
            position: 'relative',
            minWidth: 100
          }}
          color="primary"
          variant="contained"
          type="submit">
          <IntlMessages id="common.saveChanges" />
        </Button>
      </Box>
    </Form>
  );
};

export default ModelTemplateForm;
