import React from 'react';
import { RoutePermittedRole } from 'shared/constants/AppConst';

const HomeDetail = React.lazy(() => import('./homeDetail'));
const ManageHomes = React.lazy(() => import('./manageHomes'));
const UnitReview = React.lazy(() => import('./manageHomes/UnitReview'));
const UnitView = React.lazy(() => import('./manageHomes/UnitView'));

export const homesConfig = [
  {
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Builder],
    path: '/projects/:id/units',
    element: <ManageHomes />
  },
  {
    permittedRole: [RoutePermittedRole.Builder, RoutePermittedRole.User, RoutePermittedRole.Admin],
    path: ['/projects/:project_id/units/:home_id'],
    element: <HomeDetail />
  },
  {
    permittedRole: RoutePermittedRole.Builder,
    path: ['/projects/:project_id/units/:home_id/review'],
    element: <UnitReview />
  },
  {
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.Builder],
    path: ['/projects/:project_id/units/:home_id/view'],
    element: <UnitView />
  }
];
