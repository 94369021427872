const ConStatus = ({ state }) => {
  // Assigning fill colors based on the state
  const fillColors = {
    1: ['#949290', 'none', 'none'], // Only top path filled
    2: ['#949290', '#949290', 'none'], // Top and middle paths filled
    3: ['#949290', '#949290', '#949290'] // All paths filled
  };

  const [fillTop, fillMiddle, fillBottom] = fillColors[state];

  return (
    <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="36" height="36" rx="4" fill="white" />
      <path
        d="M13 24.3334H11.3333C10.8733 24.3334 10.5 23.9601 10.5 23.5001V21.0001C10.5 20.5401 10.8733 20.1667 11.3333 20.1667H13C13.46 20.1667 13.8333 20.5401 13.8333 21.0001V23.5001C13.8333 23.9601 13.46 24.3334 13 24.3334Z"
        fill={fillTop}
        stroke="#949290"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8335 24.3335H17.1668C16.7068 24.3335 16.3335 23.9602 16.3335 23.5002V17.6668C16.3335 17.2068 16.7068 16.8335 17.1668 16.8335H18.8335C19.2935 16.8335 19.6668 17.2068 19.6668 17.6668V23.5002C19.6668 23.9602 19.2935 24.3335 18.8335 24.3335Z"
        fill={fillMiddle}
        stroke="#949290"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6665 24.3334H22.9998C22.5398 24.3334 22.1665 23.9601 22.1665 23.5001V13.5001C22.1665 13.0401 22.5398 12.6667 22.9998 12.6667H24.6665C25.1265 12.6667 25.4998 13.0401 25.4998 13.5001V23.5001C25.4998 23.9601 25.1265 24.3334 24.6665 24.3334Z"
        fill={fillBottom}
        stroke="#949290"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ConStatus;
