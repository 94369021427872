import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetHome } from 'redux/actions';
import { AppState } from 'redux/store';
import { EVENT_TYPES, CONTEXTS, ViewerEvents, RightMenuEvents } from 'shared/constants/AppConst';
import AppConfirmDialog from 'shared/core/AppConfirmDialog';
import BIService from 'shared/utility/BIService';
import IntlMessages from 'shared/utility/IntlMessages';
import { IRoomInfo } from 'types/models/ForgeViewer';
import { MODIFICATION_TYPE } from 'types/models/Modification';
import { dispatch as busDispatch } from 'use-bus';

export const useResetHome = () => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const { item: homeInstance } = useSelector<AppState, AppState['home']>(({ home }) => home);

  const dispatch = useDispatch();

  const { items: modifications } = useSelector<AppState, AppState['modification']>(
    ({ modification }) => modification
  );

  const handleOnConfirm = () => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.SUBMIT_RESET, CONTEXTS.HOME, {
      btn_clicked: 'yes'
    });

    dispatch(
      resetHome(homeInstance._id, () => {
        busDispatch({
          type: ViewerEvents.ReloadModel,
          payload: {
            saveSelection: true
          }
        });
        busDispatch({
          type: RightMenuEvents.CloseSection
        });
      })
    );

    setIsConfirmationDialogOpen(false);
  };

  const resetRoomModification = (room: IRoomInfo) => {
    let roomModifications = modifications.filter(
      (modification) => modification.room === room?.category
    );

    if (room.category === 'Global') {
      roomModifications = modifications.filter(
        (modification) =>
          modification.type === MODIFICATION_TYPE.TILE &&
          modification.attrs?.elementType?.toLowerCase() === 'floor'
      );
    }

    const roomTiles = modifications.filter(
      (modification) =>
        modification.room === room?.category &&
        modification.type === MODIFICATION_TYPE.TILE &&
        modification.attrs?.elementType?.toLowerCase() === 'wall'
    );

    roomModifications = [...roomModifications, ...roomTiles];

    setIsConfirmationDialogOpen(false);
    busDispatch({
      type: ViewerEvents.ResetRoomModifications,
      payload: {
        roomModifications: roomModifications,
        room: room
      }
    });
  };

  const resetHomeConfirmationModal = useCallback(() => {
    return (
      <AppConfirmDialog
        open={isConfirmationDialogOpen}
        onDeny={() => {
          const biService = BIService.getInstance();
          biService.logEvent(EVENT_TYPES.SUBMIT_RESET, CONTEXTS.HOME, {
            btn_clicked: 'no'
          });

          setIsConfirmationDialogOpen(false);
        }}
        onConfirm={handleOnConfirm}
        title={<IntlMessages id="actions.areYouSure.reset" />}
        dialogTitle={<IntlMessages id="actions.resetTweaks" />}
      />
    );
  }, [isConfirmationDialogOpen]);

  const resetRoomConfirmationModal = useCallback(
    (room: IRoomInfo) => {
      return (
        <AppConfirmDialog
          type="revert"
          open={isConfirmationDialogOpen}
          onDeny={() => {
            const biService = BIService.getInstance();
            biService.logEvent(EVENT_TYPES.SUBMIT_RESET, CONTEXTS.HOME, {
              btn_clicked: 'no'
            });

            setIsConfirmationDialogOpen(false);
          }}
          confirmCopy={<IntlMessages id="common.reset" />}
          denyCopy={<IntlMessages id="common.cancel" />}
          onConfirm={() => resetRoomModification(room)}
          title={<IntlMessages id="actions.areYouSure.reset" />}
          dialogTitle={<IntlMessages id="actions.resetTweaks" />}
        />
      );
    },
    [isConfirmationDialogOpen]
  );

  return useMemo(
    () => ({
      resetRoomConfirmationModal,
      isConfirmationDialogOpen,
      setIsConfirmationDialogOpen,
      hasChanges: modifications.length > 0 || homeInstance?.selectedPackages?.length > 0,
      resetHomeModifications: handleOnConfirm,
      resetRoomModification
    }),
    [
      resetRoomConfirmationModal,
      resetHomeConfirmationModal,
      isConfirmationDialogOpen,
      modifications.length,
      homeInstance?.selectedPackages?.length
    ]
  );
};
