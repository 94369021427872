import { IUser } from 'types/models/User';

import {
  GET_USERS,
  GET_USER,
  CREATE_USER,
  UPDATE_USER,
  RESET_USER_STORE,
  LOADING_USERS
} from 'types/actions/User.actions';

import { AppActions } from '../../types';

const initialState: {
  items: IUser[];
  item: IUser;
  total: number;
  loading: boolean;
} = {
  items: [],
  item: null,
  total: 0,
  loading: false
};

const User = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        item: action.payload
      };
    case GET_USERS:
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.totalCount,
        loading: false
      };

    case CREATE_USER:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_USER:
      return {
        ...state,
        item: action.payload
      };

    case RESET_USER_STORE:
      return {
        ...initialState
      };

    case LOADING_USERS:
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
};
export default User;
