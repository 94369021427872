import { Box, Button, Typography } from '@mui/material';
import { usePreviewMode } from '../../../../pixel_streaming/usePreviewMode';
import { useDispatch, useSelector } from 'react-redux';
import { setIsTopView } from 'redux/actions/Viewer';
import BIService from 'shared/utility/BIService';
import { CONTEXTS, EVENT_TYPES } from 'shared/constants/AppConst';
import IntlMessages from 'shared/utility/IntlMessages';
import { ReactComponent as Sync } from '../../../../assets/icon/sync-synchronize-circle.svg';
import ConStatus from './connection-status';
import { ReactComponent as Cloud } from '../../../../assets/icon/cloud-setting.svg';
import { useEffect, useState } from 'react';
import ConnectionTooltip from './ConnectionTooltip';
import { AppState } from 'redux/store';

export const ViewerMode = () => {
  const reduxDispatch = useDispatch();
  const [previewMode, setPreviewMode] = usePreviewMode();
  const [isSyncing, setIsSyncing] = useState<Boolean>(false);
  const connectionQuality = useSelector<AppState, AppState['viewer']['connectionQualityStatus']>(
    (state) => state.viewer.connectionQualityStatus
  );

  const togglePreviewModeHandler = () => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.VIEW_MODE_CHANGE, CONTEXTS.VIEW, {
      mode: previewMode ? 'Editor' : 'Preview'
    });
    setPreviewMode(!previewMode);
    reduxDispatch(setIsTopView(true));
  };

  const urlParams = new URLSearchParams(window.location.search);
  const editorButton = urlParams.has('editor');

  useEffect(() => {
    const interval = setInterval(
      () => {
        setIsSyncing((isSyncing) => !isSyncing);
      },
      isSyncing ? 2000 : 10000
    );

    return () => {
      clearInterval(interval);
    };
  }, [isSyncing]);

  const getTitle = () => {
    switch (connectionQuality) {
      case 1:
        return 'Unstable Connection';
      case 2:
        return 'Regular Connection';
      case 3:
        return 'Good Connection';
    }
  };

  const getContent = () => {
    switch (connectionQuality) {
      case 1:
        return 'Rendering experience: low.';
      case 2:
        return 'Rendering experience: medium.';
      case 3:
        return 'Rendering experience: high.';
    }
  };

  return (
    <Box className="viewer-mode" sx={{ gap: '8px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '4px',
          alignItems: 'center',
          justifyContent: 'center',
          width: '80px'
        }}>
        <Typography variant="h6" sx={{ fontWeight: '500', fontSize: '14px', color: '#949290' }}>
          {isSyncing ? (
            <IntlMessages id="tweaks.editor.sync" />
          ) : (
            <IntlMessages id="tweaks.editor.synced" />
          )}
        </Typography>
        <Box
          sx={{
            height: '24px',
            animation: isSyncing ? 'spin 2s linear infinite' : 'none',
            '@Keyframes spin': {
              '0%': {
                transform: 'rotate(0deg)'
              },
              '100%': {
                transform: 'rotate(360deg)'
              }
            }
          }}>
          {isSyncing ? <Sync stroke="#949290" /> : <Cloud stroke="#949290" />}
        </Box>
      </Box>

      <ConnectionTooltip title={getTitle()} content={getContent()}>
        <Box>
          <ConStatus state={connectionQuality} />
        </Box>
      </ConnectionTooltip>
      {/* <Button
        variant="contained"
        sx={{
          height: '40px',
          fontWeight: '500',
          borderRadius: '88px',
          boxShadow: '0px 1px 2px 0px rgba(165, 58, 0, 0.23)'
        }}
        onClick={() => {}}>
        <IntlMessages id="tweaks.save.control.title" />
      </Button> */}

      {editorButton && (
        <Button
          variant="contained"
          sx={{
            height: '40px',
            fontSize: '14px',
            fontWeight: '500',
            color: '#0A0A0A',
            borderRadius: '88px',
            boxShadow: 'none',
            backgroundColor: '#EFECEB',
            ':hover': {
              backgroundColor: '#EFECEB',
              boxShadow: '0px 1px 1px 0px rgba(165, 58, 0, 0.23)'
            }
          }}
          onClick={togglePreviewModeHandler}>
          {previewMode ? (
            <IntlMessages id="tweaks.editor.editorMode" />
          ) : (
            <IntlMessages id="tweaks.editor.previewMode" />
          )}
        </Button>
      )}
    </Box>
  );
};
