import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import jwtAxios, { setAuthToken } from './index';
import { AuthUser } from 'types/models/AuthUser';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { CONTEXTS, LS_AUTH_KEY } from 'shared/constants/AppConst';
import Chameleon from 'shared/utility/Chameleon';
import BIService from 'shared/utility/BIService';
import { EVENT_TYPES } from 'shared/constants/AppConst';
import { IUser } from 'types/models/User';
import { useIntercom } from 'react-use-intercom';
import { appLangData } from 'shared/utility/AppContextProvider/defaultConfig';
import { useSearchParams } from 'react-router-dom';

interface JWTAuthContextProps {
  user: AuthUser | null | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface SignUpProps {
  displayName: string;
  email: string;
  password: string;
}

interface SignInProps {
  email: string;
  password: string;
}

interface JWTAuthActionsProps {
  signUpUser: (data: SignUpProps) => void;
  signInUser: (data: SignInProps) => void;
  logout: () => void;
}

const JWTAuthContext = createContext<JWTAuthContextProps>({
  user: null,
  isAuthenticated: false,
  isLoading: true
});
const JWTAuthActionsContext = createContext<JWTAuthActionsProps>({
  signUpUser: () => {},
  signInUser: () => {},
  logout: () => {}
});

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

interface JWTAuthProviderProps {
  children: ReactNode;
}

const JWTAuthProvider: React.FC<JWTAuthProviderProps> = ({ children }) => {
  const [jWTAuthData, setJWTAuthData] = useState<JWTAuthContextProps>({
    user: undefined,
    isAuthenticated: false,
    isLoading: true
  });
  const { boot } = useIntercom();
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  useEffect(() => {
    if (params && params?.get('jwt')) {
      const jwt = params.get('jwt');
      localStorage.setItem(LS_AUTH_KEY, jwt);
      params.delete('jwt');
      window.location.replace(location.pathname);
    }

    const getAuthUser = () => {
      const token = localStorage.getItem(LS_AUTH_KEY);
      if (token) setAuthToken(token);
      jwtAxios
        .get('/auth')
        .then(({ data }) => {
          setJWTAuthData({
            user: data.user,
            isLoading: false,
            isAuthenticated: true
          });

          const user: IUser = data.user;
          Chameleon.getInstance(user);

          const biService = BIService.getInstance();
          biService.setUser(user);
          boot({
            userId: user.id,
            email: user.email,
            name: user.displayName,
            userHash: user.intercomHmac,
            languageOverride: appLangData.locale,
            alignment: appLangData.locale === 'he' ? 'left' : 'right'
          });
        })
        .catch(() =>
          setJWTAuthData({
            user: undefined,
            isLoading: false,
            isAuthenticated: false
          })
        );
    };

    getAuthUser();
  }, []);

  const signInUser = async ({ email, password }: { email: string; password: string }) => {
    dispatch(fetchStart());
    try {
      const biService = BIService.getInstance();
      biService.logEvent(EVENT_TYPES.LOGIN_ATTEMP, CONTEXTS.LOGIN, {
        email: email
      });

      const { data } = await jwtAxios.post('/auth/login', { email, password });
      localStorage.setItem(LS_AUTH_KEY, data.auth);
      setAuthToken(data.auth);
      setJWTAuthData({
        user: data.user,
        isAuthenticated: true,
        isLoading: false
      });

      const user: IUser = data.user;
      Chameleon.getInstance(user);

      biService.setUser(user);
      boot({
        userId: user.id,
        email: user.email,
        name: user.displayName,
        userHash: user.intercomHmac,
        languageOverride: appLangData.locale,
        alignment: appLangData.locale === 'he' ? 'left' : 'right'
      });
      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...jWTAuthData,
        isAuthenticated: false,
        isLoading: false
      });
      dispatch(fetchError('Something went wrong'));
    }
  };

  const signUpUser = async ({
    displayName,
    email,
    password
  }: {
    displayName: string;
    email: string;
    password: string;
  }) => {
    dispatch(fetchStart());
    try {
      const { data } = await jwtAxios.post('/auth/register', {
        displayName,
        email,
        password
      });
      localStorage.setItem(LS_AUTH_KEY, data.auth);
      setAuthToken(data.auth);
      setJWTAuthData({
        user: data.user,
        isAuthenticated: true,
        isLoading: false
      });

      dispatch(fetchSuccess());
    } catch (error) {
      setJWTAuthData({
        ...jWTAuthData,
        isAuthenticated: false,
        isLoading: false
      });
      dispatch(fetchError('Something went wrong'));
    }
  };

  const logout = async () => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.LOGOUT, CONTEXTS.LOGIN);
    biService.reset();

    await jwtAxios.get('/auth/logout');
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false
    });
    localStorage.removeItem(LS_AUTH_KEY);
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...jWTAuthData
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          logout
        }}>
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthProvider;
