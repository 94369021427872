import { useState } from 'react';
import AppContentView from 'shared/core/AppContentView';
import AppHeader from './AppHeader';
import clsx from 'clsx';
import MiniSidebarWrapper from './MiniSidebarWrapper';
import AppFixedFooter from './AppFixedFooter';
import { useLayoutContext } from 'shared/utility/AppContextProvider/LayoutContextProvider';
import { LayoutType } from 'shared/constants/AppEnums';
import MiniSidebarContainer from './MiniSidebarContainer';
import { Box } from '@mui/material';

const MiniSidebar = () => {
  const [isCollapsed, setCollapsed] = useState(true);
  const { footer, layoutType, headerType, footerType } = useLayoutContext();

  return (
    <MiniSidebarContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED
      })}>
      <MiniSidebarWrapper
        className={clsx('miniSidebarWrapper', {
          'mini-sidebar-collapsed': isCollapsed,
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
          appMainFixedHeader: headerType === 'fixed'
        })}>
        <Box className="mainContent <AppHeader/>">
          <AppHeader setCollapsed={setCollapsed} isCollapsed={isCollapsed} />
          <AppContentView />
          <AppFixedFooter />
        </Box>
      </MiniSidebarWrapper>
    </MiniSidebarContainer>
  );
};

export default MiniSidebar;
