import { Box } from '@mui/material';
import BasicForgeViewer from 'pages/dashboards/Model/basicForgeViewer';

const ReadOnlyViewer = ({ modelUrn }) => {
  return (
    <>
      <Box
        sx={{
          position: 'sticky',
          height: {
            xs: 'calc(100vh - 130px)',
            sm: 'calc(100vh - 130px)',
            md: 'calc(100vh - 130px)',
            lg: 'calc(100vh - 130px)',
            xl: 'calc(100vh - 190px)'
          },
          top: {
            xs: 90,
            sm: 90,
            md: 90,
            lg: 90,
            xl: 90
          }
        }}>
        <BasicForgeViewer modelUrn={modelUrn} readOnly={true} />
      </Box>
    </>
  );
};

export default ReadOnlyViewer;
