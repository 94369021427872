import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import {
  GET_SUPPLIERS,
  GET_SUPPLIER_PRODUCTS_BY_TYPE,
  RESET_SUPPLIER_STORE,
  LOADING_SUPPLIER
} from 'types/actions/Supplier.actions';
import jwtAxios from 'services/auth/jwt-auth';

export const getSuppliers = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_SUPPLIER });
    jwtAxios.get(`/projects/suppliers/list`).then((data) => {
      dispatch({ type: GET_SUPPLIERS, payload: data.data });
    });
  };
};

export const getSupplierProductsByType = (id: string, type: string, subType: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get(`/projects/suppliers/list/${id}/products/${type}?subType=${subType}`)
      .then((data) => {
        dispatch({ type: GET_SUPPLIER_PRODUCTS_BY_TYPE, payload: data.data });
      });
  };
};

export const resetSupplierStore = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: RESET_SUPPLIER_STORE });
  };
};

export const getProduct = (id) => {
  return jwtAxios.get(`/projects/products/${id}`).then((response) => {
    return response.data;
  });
};

export const seedSupplierData = () => {
  return jwtAxios.get(`/projects/suppliers/load`);
};

export const getAdminSupplierProductsByType = (id: string, type: string, subType: string) => {
  return jwtAxios
    .get(`/projects/suppliers/list/${id}/products/${type}?subType=${subType}`)
    .then((response) => {
      return response.data;
    });
};
