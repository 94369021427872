export const OPEN_CATALOG_DIALOG = 'OPEN_CATALOG_DIALOG';
export const CLOSE_CATALOG__DIALOG = 'CLOSE_CATALOG__DIALOG';

export const catelogNames = ['floor', 'walltiles', 'outlets', 'switches', 'appliances'] as const;

export type CatelogName = (typeof catelogNames)[number];

export type CatelogOpenActionType = {
  type: typeof OPEN_CATALOG_DIALOG;
  payload: {
    catelogName: CatelogName;
  };
};

export type CatelogCloseActionType = {
  type: typeof CLOSE_CATALOG__DIALOG;
};

export type CatelogActionTypes = CatelogOpenActionType | CatelogCloseActionType;
