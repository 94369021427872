import { TbSofa, TbHome, TbToiletPaper, TbToolsKitchen2, TbBath, TbBed } from 'react-icons/tb';
import { CgSmartHomeWashMachine } from 'react-icons/cg';
import { RiSafe2Line } from 'react-icons/ri';
import { IconBaseProps } from 'react-icons/lib';
import { Flatten } from 'types/common.types';

// Map revit room names to our room names (this is used for the room selection dropdown and the exact key found by a recursive search)
export const roomsKeyWhitelist = {
  global: 'tweaks.rooms.global',
  גלובאלי: 'tweaks.rooms.global',
  כביסה: {
    מסתור: 'tweaks.rooms.laundry_balcony',
    פינת: 'tweaks.rooms.laundry_room',
    default: 'tweaks.rooms.laundry_room'
  },
  laundry: 'tweaks.rooms.laundry_room',
  מרפסת: 'tweaks.rooms.balcony',
  balcony: 'tweaks.rooms.balcony',
  פרוזדור: 'tweaks.rooms.corridor',
  corridor: 'tweaks.rooms.corridor',
  ילדים: 'tweaks.rooms.children_room',
  children: 'tweaks.rooms.children_room',
  משפחה: 'tweaks.rooms.family_room',
  מגורים: 'tweaks.rooms.livingroom',
  living: 'tweaks.rooms.livingroom',
  מטבח: 'tweaks.rooms.kitchen',
  kitchen: 'tweaks.rooms.kitchen',
  toilet: 'tweaks.rooms.guest_toilet',
  אורחים: 'tweaks.rooms.guest_toilet',
  mamad: 'tweaks.rooms.mamad',
  ממד: 'tweaks.rooms.mamad',
  ארונות: 'tweaks.rooms.walk_in_closet',
  entrance: 'tweaks.rooms.entrance',
  entry: 'tweaks.rooms.entrance',
  utility: 'tweaks.rooms.utility_room',
  flex: 'tweaks.rooms.flex',
  foyer: 'tweaks.rooms.foyer',
  garage: 'tweaks.rooms.garage',
  great: 'tweaks.rooms.great_room',
  loft: 'tweaks.rooms.loft',
  porch: 'tweaks.rooms.porch',
  retreat: 'tweaks.rooms.retreat',
  pantry: 'tweaks.rooms.pantry',
  'w.i.c': 'tweaks.rooms.walk_in_closet',
  shower: 'tweaks.rooms.shower',
  exterior: 'tweaks.rooms.exterior',
  שינה: {
    הורים: 'tweaks.rooms.master_bedroom',
    מאסטר: 'tweaks.rooms.master_bedroom',
    ממד: 'tweaks.rooms.mamad',
    ' 1': 'tweaks.rooms.bedroom_1',
    ' 2': 'tweaks.rooms.bedroom_2',
    ' 3': 'tweaks.rooms.bedroom_3',
    ' 4': 'tweaks.rooms.bedroom_4',
    ' 5': 'tweaks.rooms.bedroom_5',
    default: 'tweaks.rooms.bedroom'
  },
  bedroom: {
    parents: 'tweaks.rooms.master_bedroom',
    master: 'tweaks.rooms.master_bedroom',
    primary: 'tweaks.rooms.primary_bedroom',
    ' 1': 'tweaks.rooms.bedroom_1',
    ' 2': 'tweaks.rooms.bedroom_2',
    ' 3': 'tweaks.rooms.bedroom_3',
    ' 4': 'tweaks.rooms.bedroom_4',
    ' 5': 'tweaks.rooms.bedroom_5',
    default: 'tweaks.rooms.bedroom'
  },
  dining: {
    casual: 'tweaks.rooms.casual_dining',
    default: 'tweaks.rooms.dining'
  },
  רחצה: {
    הורים: 'tweaks.rooms.master_bathroom',
    מאסטר: 'tweaks.rooms.master_bathroom',
    כללי: {
      ' 1': 'tweaks.rooms.general_bathroom_1',
      ' 2': 'tweaks.rooms.general_bathroom_2',
      ' 3': 'tweaks.rooms.general_bathroom_3',
      ' 4': 'tweaks.rooms.general_bathroom_4',
      default: 'tweaks.rooms.general_bathroom'
    },
    default: 'tweaks.rooms.general_bathroom'
  },
  bathroom: {
    parents: 'tweaks.rooms.master_bathroom',
    ' 1': 'tweaks.rooms.bathroom_1',
    ' 2': 'tweaks.rooms.bathroom_2',
    ' 3': 'tweaks.rooms.bathroom_3',
    ' 4': 'tweaks.rooms.bathroom_4',
    default: 'tweaks.rooms.general_bathroom'
  },
  bath: {
    parents: 'tweaks.rooms.master_bathroom',
    primary: 'tweaks.rooms.primary_bathroom',
    ' 1': 'tweaks.rooms.bathroom_1',
    ' 2': 'tweaks.rooms.bathroom_2',
    ' 3': 'tweaks.rooms.bathroom_3',
    ' 4': 'tweaks.rooms.bathroom_4',
    default: 'tweaks.rooms.general_bathroom'
  },
  default: 'tweaks.rooms.default_room'
} as const;

type FlattenAllValuesInDeepObjectKeyValue = Flatten<typeof roomsKeyWhitelist>[keyof Flatten<
  typeof roomsKeyWhitelist
>];

export const findRoomKeyByRevitRoomName = (
  revitRoomName: string,
  _roomKeysWhitelist = roomsKeyWhitelist
): FlattenAllValuesInDeepObjectKeyValue => {
  if (!revitRoomName) {
    throw new Error('revitRoomName is required');
  }
  const key = Object.keys(_roomKeysWhitelist).find((_roomWhiteListKey) =>
    revitRoomName.toLowerCase().includes(_roomWhiteListKey)
  );

  if (!key) {
    if (_roomKeysWhitelist.default) {
      return _roomKeysWhitelist.default;
    }
    throw new Error(`revitRoomName: ${revitRoomName} is not found in roomsKeyWhitelist`);
  }

  const roomKeyIsFound = typeof _roomKeysWhitelist[key] === 'string';

  if (!roomKeyIsFound) {
    return findRoomKeyByRevitRoomName(revitRoomName, _roomKeysWhitelist[key]);
  }

  return _roomKeysWhitelist[key];
};

export const splitRoomKeyByRevitRoomName = (revitRoomName: string): string => {
  if (!revitRoomName) {
    throw new Error('revitRoomName is required');
  }

  const roomKeyId = findRoomKeyByRevitRoomName(revitRoomName);
  const roomKeyParts = roomKeyId.split('.');
  return roomKeyParts[roomKeyParts.length - 1];
};

type ExtractValues<T> = T extends object ? T[keyof T] | ExtractValues<T[keyof T]> : never;

export type RoomKeysValues = ExtractValues<typeof roomsKeyWhitelist> | 'all';

export type RoomKeyId = RoomKeysValues | null;

export const RoomIconByRoomKey = ({
  roomKey,
  props
}: {
  roomKey: RoomKeyId;
  props?: IconBaseProps;
}) => {
  const getIcon = () => {
    switch (roomKey) {
      case 'all':
      case 'tweaks.rooms.loft':
      case 'tweaks.rooms.flex':
      case 'tweaks.rooms.livingroom': {
        return TbSofa(props);
      }

      case 'tweaks.rooms.general_bathroom':
      case 'tweaks.rooms.primary_bathroom':
      case 'tweaks.rooms.bathroom_1':
      case 'tweaks.rooms.bathroom_2':
      case 'tweaks.rooms.bathroom_3':
      case 'tweaks.rooms.bathroom_4':
      case 'tweaks.rooms.shower':
      case 'tweaks.rooms.master_bathroom': {
        return TbBath(props);
      }

      case 'tweaks.rooms.guest_toilet': {
        return TbToiletPaper(props);
      }

      case 'tweaks.rooms.master_bedroom':
      case 'tweaks.rooms.primary_bedroom':
      case 'tweaks.rooms.bedroom':
      case 'tweaks.rooms.bedroom_1':
      case 'tweaks.rooms.bedroom_2':
      case 'tweaks.rooms.bedroom_3':
      case 'tweaks.rooms.bedroom_4':
      case 'tweaks.rooms.bedroom_5':
      case 'tweaks.rooms.children_room': {
        return TbBed(props);
      }

      case 'tweaks.rooms.mamad': {
        return RiSafe2Line(props);
      }

      case 'tweaks.rooms.utility_room': {
        return CgSmartHomeWashMachine(props);
      }

      case 'tweaks.rooms.pantry':
      case 'tweaks.rooms.kitchen': {
        return TbToolsKitchen2(props);
      }
      case 'tweaks.rooms.default_room': {
        return TbHome(props);
      }

      default: {
        return TbHome(props);
      }
    }
  };

  return getIcon();
};
