import { store } from 'App';
import { useDispatch } from 'react-redux';
import { ForgeUtils } from 'shared/utility/ForgeUtilities/ForgeUtils';

type CatelogItem = {
  name: string;
  description: string;
  price: number;
  image?: string;
};

export class CatelogService {
  reduxDispatch = useDispatch();

  getCatelogItems() {
    const state = store.getState();
    const catelogName = this.getCatelogName();

    if (catelogName === 'switches') {
      const items = state.asset.items.filter((x) => ForgeUtils.isSwitch(x.originalName));

      return items.map<CatelogItem>((asset) => ({
        name: asset.name,
        description: asset.category,
        price: asset.cost,
        image: asset.thumbnailUrl
      }));
    }

    if (catelogName === 'outlets') {
      const items = state.asset.items.filter((x) => ForgeUtils.isOutlet(x.originalName));

      return items.map<CatelogItem>((asset) => ({
        name: asset.name,
        description: asset.category,
        price: asset.cost,
        image: asset.thumbnailUrl
      }));
    }

    // if (catelogName === 'lighting') {
    //   const items = state.asset.items.filter((x) => ForgeUtils.isLight(x.originalName, x.category));

    //   return items.map<CatelogItem>((asset) => ({
    //     name: asset.name,
    //     description: asset.category,
    //     price: asset.cost,
    //     image: asset.thumbnailUrl
    //   }));
    // }
    if (catelogName === 'floor') {
    }
    return [];
  }

  filterCatelogItems(filter: string) {
    return this.getCatelogItems().filter((item) =>
      item.name.toLowerCase().includes(filter.toLowerCase())
    );
  }

  sortCatelogItemsByPrice(price: number, order: 'asc' | 'desc') {
    return this.getCatelogItems().sort((a, b) => {
      if (order === 'asc') {
        return a.price - b.price;
      }
      return b.price - a.price;
    });
  }

  getStore() {
    return store.getState();
  }

  getCatelogName() {
    return this.getStore().catelog.catelogName;
  }
}
