import { IProject } from './Project';
import { IModelTemplate } from './ModelTemplate';
import { ISupplier, ITile } from './Supplier';

export enum STATUS_TYPE {
  NEW = 'New',
  SUBMITTED = 'Submitted',
  BACK_TO_OWNER = 'Back to owner',
  SUBMITTED_TO_BUILDER = 'Submitted to builder',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}

export interface SelectedPackage {
  modelTemplateId: string;
  packageId: string;
  categoryId?: string;
  optionId?: string;
  _id?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface IHome {
  _id?: string;
  currency: 'ILS' | 'USD' | 'CAD' | 'EUR';
  homeNumber: string;
  modelTemplate: IModelTemplate;
  model: string;
  project?: string | IProject;
  homeOwner?: any;
  status?: STATUS_TYPE;
  supplier?: ISupplier;
  defaultMaterials?: {
    floors: ITile;
    bathroomWalls: ITile;
    bathroomFloors: ITile;
  };
  upgrades: [
    {
      roomKeyId: string;
      price: number;
      name: string;
    }
  ];
  cost?: number;
  forceModelCache?: boolean;
  selectedPackages?: SelectedPackage[];
  submissionDueDate: string;
}

export interface ICreateHome {
  homeNumber: string;
  ownerFullName?: string;
  ownerEmail?: string;
  model?: any;
}
