import { IHome } from 'types/models/Home';

import { GET_HOME, UPDATE_HOME, RESET_HOME_STORE, LOADING_HOME } from 'types/actions/Home.actions';

import { AppActions } from '../../types';

const initialState: {
  item: IHome;
  loading: boolean;
} = {
  item: null,
  loading: false
};

const Home = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_HOME:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case UPDATE_HOME:
      return {
        ...state,
        item: action.payload,
        loading: false
      };

    case LOADING_HOME:
      return {
        ...state,
        loading: true
      };

    case RESET_HOME_STORE:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
export default Home;
