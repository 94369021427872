import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import {
  GET_PROJECT,
  GET_PROJECTS,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  RESET_PROJECT_STORE,
  LOADING_PROJECT,
  ADD_HOMES_TO_PROJECT,
  GET_PROJECT_FILES,
  GET_PROJECT_FILE
} from 'types/actions/Project.actions';
import { fetchError, fetchSuccess, showMessage } from './Common';
import { IProject, IProjectFilter } from 'types/models/Project';
import jwtAxios from 'services/auth/jwt-auth';
import { appIntl } from 'shared/utility/helper/Utils';
import { ICreateHome } from 'types/models/Home';
import { ISendNotificationRequest } from 'types/models/Notification';

export const getProjects = (filter: IProjectFilter) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_PROJECT });
    jwtAxios
      .get('/projects', {
        params: { filter }
      })
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_PROJECTS, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const createProject = (project: IProject, callback?: (data: IProject) => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_PROJECT });
    jwtAxios
      .post('/projects/new', { ...project })
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: CREATE_PROJECT, payload: data.data });
        dispatch(showMessage(messages['project.message.created'] as string));
        if (callback) callback(data.data);
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const updateProject = (project: IProject, callback?: (data: IProject) => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    // dispatch({ type: LOADING_PROJECT });
    jwtAxios
      .put(`/projects/${project._id}`, {
        projectName: project.projectName,
        address: project.address,
        imageUrl: project.imageUrl
      })
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_PROJECT, payload: data.data });
        dispatch(showMessage(messages['project.message.updated'] as string));
        if (callback) callback(data.data);
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const sendNotifications = (data: ISendNotificationRequest, callback = null) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post('/notifications/send-emails', { ...data })
      .then(() => {
        dispatch(fetchSuccess());
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const addHomes = (project: IProject, homes: ICreateHome[], callback = null) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_PROJECT });
    jwtAxios
      .post(`/projects/${project._id}/homes`, homes)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: ADD_HOMES_TO_PROJECT, payload: data.data });
        dispatch(showMessage(messages['project.message.updated'] as string));
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getProject = (id: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_PROJECT });
    jwtAxios
      .get(`/projects/${id}`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_PROJECT, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const resetProjectStore = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: RESET_PROJECT_STORE });
  };
};

export const getAllProjects = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_PROJECT });
    jwtAxios
      .get('/projects/admin/all')
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_PROJECTS, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const deleteModelTemplate = (projectId, modelTemplateId): Promise<string> => {
  return jwtAxios.delete(`/projects/${projectId}/model-templates/${modelTemplateId}`);
};

export const getProjectFileUploadLink = (
  projectId
): Promise<{ signedUrl: string; fileUrl: string }> => {
  return jwtAxios.get(`/projects/${projectId}/project-files/upload-link`).then((response) => {
    return response.data;
  });
};

export const createProjectFile = (
  projectId,
  data: { label: string; project: IProject; url: string }
) => {
  return jwtAxios.post(`/projects/${projectId}/project-files`, data);
};

export const createProjectImage = (projectId, data: { project: IProject; url: string }) => {
  return jwtAxios.post(`/projects/${projectId}/project-image`, data);
};

export const getProjectFiles = (projectId: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_PROJECT });
    jwtAxios
      .get(`/projects/${projectId}/project-files`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_PROJECT_FILES, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};
export const getProjectFile = (projectId: string, fileId: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_PROJECT });
    jwtAxios
      .get(`/projects/${projectId}/project-files/${fileId}`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_PROJECT_FILE, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const updateProjectFile = (
  projectId,
  projectFileId,
  data: { label?: string; url?: string }
) => {
  return jwtAxios.put(`/projects/${projectId}/project-files/${projectFileId}`, data);
};

export const getProjectImageUploadLink = (
  id,
  fileExtension
): Promise<{ signedUrl: string; fileUrl: string }> => {
  return jwtAxios.get(`/projects/${id}/image-upload-link/${fileExtension}`).then((response) => {
    return response.data;
  });
};

export const editProjectImageUploadLink = (
  projectId
): Promise<{ signedUrl: string; fileUrl: string }> => {
  return jwtAxios.get(`/projects/${projectId}/edit/image-upload-link`).then((response) => {
    return response.data;
  });
};
