import React from 'react';
import orange from '@mui/material/colors/orange';
import { useAuthMethod, useAuthUser } from 'shared/utility/AuthHooks';
import { Box, Typography, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Fonts, LayoutDirection } from 'shared/constants/AppEnums';
import { ReactComponent as LogoIcon } from '../../../../../assets/icon/tweaksLogoIconOrange.svg';
import { ReactComponent as ProfileIcon } from '../../../../../assets/icon/profile.svg';
import IntlMessages from 'shared/utility/IntlMessages';
import { appLangData } from 'shared/utility/AppContextProvider/defaultConfig';
import { DirectionChevronRight } from 'shared/core/DirectionsIcons/DirectionChevronRight';
import { BiChevronDown } from 'react-icons/bi';
import Cookies from 'js-cookie';
import languageData, { LanguageProps } from 'shared/core/AppLngSwitcher/data';
import BIService from 'shared/utility/BIService';
import { CONTEXTS, EVENT_TYPES } from 'shared/constants/AppConst';

interface UserInfoProps {
  color?: string;
}

const UserInfo: React.FC<UserInfoProps> = ({ color = 'text.secondary' }) => {
  const { logout } = useAuthMethod();
  const { user } = useAuthUser();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [langAnchorEl, setLangAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.ICON_MENU_CLICKED, CONTEXTS.ICON_MENU, {
      is_open: true
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.ICON_MENU_CLICKED, CONTEXTS.ICON_MENU, {
      is_open: false
    });
    setAnchorEl(null);
  };

  const handleLangClick = (event: any) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleLangClose = () => {
    setLangAnchorEl(null);
  };

  const changeLanguage = (language: LanguageProps, locale: string) => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.CHANGE_LANGUAGE, CONTEXTS.ICON_MENU, {
      language: language
    });
    Cookies.set('appLang', JSON.stringify(language));
    setTimeout(() => {
      window.location.href = window.location.href.replace(
        '/' + locale + '/',
        '/' + language.locale + '/'
      );
    }, 500);

    setLangAnchorEl(null);
  };
  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          py: 3,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        className="user-info-view">
        <Box sx={{ py: 0.5 }}>
          {user.photoURL ? (
            <Avatar
              sx={{
                height: 40,
                width: 40,
                fontSize: 24,
                backgroundColor: orange[500]
              }}
              src={user.photoURL}
            />
          ) : (
            <Box
              style={{
                position: 'relative',
                marginInlineEnd: 28,
                marginInlineStart: 5,
                display: 'flex',
                alignItems: 'center'
              }}>
              <Box width={40} height={40}>
                <LogoIcon
                  style={{
                    width: 80,
                    height: 80,
                    position: 'absolute',
                    right: theme.direction === LayoutDirection.RTL ? '-50%' : '0',
                    top: '-50%'
                  }}
                />
              </Box>
              <BiChevronDown
                style={{
                  fontSize: 16,
                  zIndex: 1
                }}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: { xs: 'calc(100% - 62px)', xl: 'calc(100% - 72px)' },
            marginInlineStart: 4,
            color: color
          }}
          className="user-info">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Box
              sx={{
                mb: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: 16,
                fontWeight: Fonts.MEDIUM,
                color: 'inherit'
              }}
              component="span">
              {user.displayName ? user.displayName : 'Admin User '}
            </Box>
            <Box
              sx={{
                ml: 3,
                color: 'inherit',
                display: 'flex'
              }}>
              <ExpandMoreIcon />
            </Box>
          </Box>
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '> .MuiPaper-root': {
            width: 280,
            maxWidth: '100%',
            boxShadow: '2px 4px 4px 6px rgba(0, 0, 0, 0.25);'
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            height: 50,
            alignItems: 'center',
            pl: 4,
            backgroundColor: 'transparent'
          }}>
          <ProfileIcon />
          <Typography fontSize={14} fontWeight={700}>
            {user.displayName ? user.displayName : 'Admin User '}
          </Typography>
        </Box>

        <MenuItem
          onClick={() => {}}
          disabled
          sx={{
            height: 50
          }}>
          <IntlMessages id="common.edit.profile" />
        </MenuItem>

        <MenuItem
          onClick={handleLangClick}
          sx={{
            height: 50,
            display: 'flex',
            gap: 2
          }}>
          <IntlMessages id="common.language" />
          <img
            style={{
              objectFit: 'cover',
              width: 24,
              height: 24,
              borderRadius: '100%'
            }}
            src={`/assets/images/countries/${appLangData.locale === 'en' ? 'gb' : 'il'}.png`}
            alt={appLangData.locale === 'en' ? 'gb' : 'il'}
          />
          <DirectionChevronRight
            sx={{ marginLeft: 'auto', fontSize: 18, direction: theme.direction }}
          />
        </MenuItem>
        <MenuItem
          onClick={logout}
          sx={{
            height: 50
          }}>
          <IntlMessages id="common.logout" />
          <DirectionChevronRight sx={{ marginLeft: 'auto', fontSize: 18 }} />
        </MenuItem>
      </Menu>

      <Menu
        id="language-menu"
        anchorEl={langAnchorEl}
        keepMounted
        open={Boolean(langAnchorEl)}
        onClose={handleLangClose}
        sx={{
          marginInlineStart: 2,
          '> .MuiPaper-root': {
            width: 280,
            maxWidth: '100%',
            boxShadow: '2px 4px 4px 6px rgba(0, 0, 0, 0.25);'
          }
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: theme.direction === 'rtl' ? 'left' : 'right'
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingInlineStart: 4,
            height: 50
          }}>
          <Typography fontSize={14} fontWeight={700}>
            <IntlMessages id="common.language.change" />
          </Typography>
        </Box>
        {languageData.map((lang) => (
          <MenuItem
            key={lang.locale}
            onClick={() => {
              changeLanguage(lang, appLangData.locale);
            }}
            disabled={appLangData.locale === lang.locale}
            sx={{
              height: 50,
              display: 'flex',
              gap: 2,
              ':hover': {
                backgroundColor: '#FFEEE5'
              },
              ...(appLangData.locale === lang.locale
                ? {
                    backgroundColor: '#FFEEE5'
                  }
                : {})
            }}>
            <img
              style={{
                objectFit: 'cover',
                width: 24,
                height: 24,
                borderRadius: '100%'
              }}
              src={`/assets/images/countries/${lang.locale === 'en' ? 'gb' : 'il'}.png`}
              alt={lang.locale === 'en' ? 'gb' : 'il'}
            />
            <IntlMessages id={`common.language.${lang.locale}`} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default UserInfo;
