export interface Common {
  error: string;
  loading: boolean;
  isAppDrawerOpen: boolean;
  updatingContent: boolean;
  message: string;
}

export interface IPaginatedResults<T> {
  items: T[];
  totalCount: number;
}

export enum CameraInfoRespondTo {
  ROOM_CAMERA = 'Room Camera',
  CUSTOM_REQUEST = 'Custom Request'
}
