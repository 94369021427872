import { Application } from '@yovelsapir/lib-pixelstreamingfrontend-ui-ue5.2';
import { MutableRefObject, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import emitApiServiceBuilder from './services/emit-api.service';
import { splitRoomKeyByRevitRoomName } from 'services/rooms/rooms.service';
import useBus from 'use-bus';
import { ViewerEvents } from 'shared/constants/AppConst';
import { CustomRequestMode } from 'types/models/CustomRequest';

export const useUnrealEventListener = (pixelStreamingRef: MutableRefObject<Application>) => {
  const selectedRoom = useSelector<AppState, AppState['viewer']['selectedRoom']>(
    (state) => state.viewer.selectedRoom
  );

  const [isFirstTime, setIsFirstTime] = useState(true);

  const {
    mode: customRequestMode,
    customRequests,
    lastDeletedCommentId
  } = useSelector<AppState, AppState['customRequest']>(({ customRequest }) => customRequest);

  const emitApiService = emitApiServiceBuilder(pixelStreamingRef);

  useEffect(() => {
    if (selectedRoom?.id) {
      const roomName = splitRoomKeyByRevitRoomName(selectedRoom.category);
      console.log('roomName', roomName);
      emitApiService.emitEventToUnreal('roomId', roomName);
    }
  }, [selectedRoom?.id]);

  useEffect(() => {
    emitApiService.emitEventToUnreal(
      'setCustomRequestMode',
      customRequestMode == CustomRequestMode.EDIT
    );
  }, [customRequestMode]);

  useEffect(() => {
    console.log(lastDeletedCommentId);

    emitApiService.emitEventToUnreal('removeComment', JSON.stringify({ id: lastDeletedCommentId }));
  }, [lastDeletedCommentId]);

  useEffect(() => {
    if (isFirstTime && customRequestMode == CustomRequestMode.EDIT) {
      setIsFirstTime(false);
      console.log('!!!!!!', isFirstTime);

      emitApiService.emitEventToUnreal(
        'addComments',
        JSON.stringify({
          comments: customRequests.map((e) =>
            Object({
              id: e._id,
              x: e.locationX,
              y: e.locationY,
              z: e.locationZ
            })
          )
        })
      );
    }
  }, [customRequestMode, isFirstTime, customRequests]);

  // useEffect(() => {
  //   emitApiService.emitEventToUnreal(
  //     'addComments',
  //     JSON.stringify({
  //       comments: customRequests.map((e) =>
  //         Object({
  //           id: e._id,
  //           x: e.locationX,
  //           y: e.locationY,
  //           z: e.locationZ
  //         })
  //       )
  //     })
  //   );
  // }, []);

  useBus(
    ViewerEvents.ShowPackage,
    (selectedPackage) => {
      const eventName =
        splitRoomKeyByRevitRoomName(selectedPackage.room) + '-' + selectedPackage.name;
      console.log('eventName', eventName);
      emitApiService.emitEventToUnreal('packageName', eventName);
    },
    []
  );

  useBus(
    ViewerEvents.MeasureActivate,
    (e) => {
      emitApiService.emitEventToUnreal('measureActivate', e.isActive);
    },
    []
  );

  useBus(
    ViewerEvents.setNightMode,
    (e) => {
      emitApiService.emitEventToUnreal('setNightMode', e.isActive);
    },
    []
  );

  useBus(
    ViewerEvents.FurnitureHide,
    (e) => {
      emitApiService.emitEventToUnreal('setShowFurniture', !e.isActive);
    },
    []
  );

  useBus(ViewerEvents.ApplyFloorTiles, (e) => {
    console.log('assign texture');
    console.log(e);
    emitApiService.emitEventToUnreal('setFloorTexture', JSON.stringify(e.payload.data));
  });

  useBus(ViewerEvents.openCatalogRequest, (e) => {
    emitApiService.emitEventToUnreal('openCatalogRequest', JSON.stringify(e.payload.data));
  });

  return null;
};
