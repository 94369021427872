import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useRoutes, useLocation } from 'react-router-dom';
import AppSuspense from 'shared/core/AppSuspense';
import AppFooter from '../AppLayout/components/AppFooter';
import AppErrorBoundary from '../AppErrorBoundary';
import Box from '@mui/material/Box';
import AppContentViewWrapper from './AppContentViewWrapper';
import { SxProps } from '@mui/system';
import { useAuthUser } from 'shared/utility/AuthHooks';
import { anonymousStructure, authorizedStructure, unAuthorizedStructure } from '../../../pages';
import generateRoutes from 'shared/utility/RouteGenerator';
import { getInitialUrl } from 'shared/constants/AppConst';
import useMediaQuery from '@mui/material/useMediaQuery';
import DesktopMessage from '../DesktopMessage';

interface AppContentViewProps {
  sxStyle?: SxProps;
}

const AppContentView: React.FC<AppContentViewProps> = ({ sxStyle }) => {
  const { user, isAuthenticated } = useAuthUser();

  const location = useLocation();
  const isViewer = location.pathname.includes('units') && user?.role === 'owner';

  const routesToSkip = ['/'];

  useEffect(() => {
    const prevPath = localStorage.getItem('tweaks_prev_path') || '';

    if (prevPath !== location.pathname && !routesToSkip.includes(location.pathname)) {
      localStorage.setItem('tweaks_prev_path', location.pathname);
    }
  }, [location]);

  const routes = useRoutes(
    generateRoutes({
      isAuthenticated: isAuthenticated,
      userRole: user?.role,
      unAuthorizedStructure,
      authorizedStructure,
      anonymousStructure
    })
  );

  const matches = useMediaQuery('(max-width:1023px)');

  return (
    <AppContentViewWrapper>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          p: isViewer ? 0 : { xs: 5, md: 7.5, xl: 12.5 },

          ...sxStyle
        }}
        className="app-content">
        <AppSuspense>
          {matches ? (
            <DesktopMessage />
          ) : (
            <AppErrorBoundary>
              {routes}
              <Routes>
                <Route path="/" element={<Navigate to={getInitialUrl(user?.role)} />} />
                <Route path="*" element={<></>} />
              </Routes>
            </AppErrorBoundary>
          )}
        </AppSuspense>
      </Box>
      <AppFooter />
    </AppContentViewWrapper>
  );
};

export default AppContentView;
