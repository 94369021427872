import { AllSettings } from '@yovelsapir/lib-pixelstreamingfrontend-ue5.2';
import { isLocalhost } from 'shared/utility/Utils';

export const psConfig: Partial<AllSettings> = {
  ss: isLocalhost ? 'wss://pixel.demo.tweaks.ai/ws' : 'wss://pixel.demo.tweaks.ai/ws',
  TimeoutIfIdle: true,
  AFKTimeout: 300,
  preferSFU: true,
  AutoConnect: !isLocalhost,
  AutoPlayVideo: !isLocalhost,
  StartVideoMuted: true,
  GamepadInput: false,
  XRControllerInput: false,
  MatchViewportRes: true,
  UseMic: false,
  HoveringMouse: true,
  WebRTCMaxBitrate: 12000,
  WebRTCMinBitrate: 2500,
  WebRTCFPS: 30
};
