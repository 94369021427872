import { TableRow, TableCell } from '@mui/material';
import IntlMessages from 'shared/utility/IntlMessages';

const EmptyListItem = () => {
  return (
    <TableRow>
      <TableCell style={{ textAlign: 'center', padding: 40, borderBottom: 0 }} colSpan={2}>
        <IntlMessages id="tweaks.noRoomsFound" />
      </TableCell>
    </TableRow>
  );
};

export default EmptyListItem;
