import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  List,
  ListItem,
  Modal,
  Typography
} from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import IntlMessages from 'shared/utility/IntlMessages';
import { ReactComponent as InformationCircle } from '../../../assets/icon/information-circle.svg';
import Scrollbars from 'react-custom-scrollbars-2';
import SubmittingAnimation from '../../../assets/animations/SubmittingAnimation.gif';
import { useTotalCosts } from 'pages/homes/hooks/useTotalCosts';
import { NumbersService } from 'services/numbers/number.service';
import { useJWTAuth } from 'services/auth/jwt-auth/JWTAuthProvider';

interface roomAndTotalCost {
  roomName: string;
  roomTotalCost: number;
}

enum SubmitState {
  SUBMIT = 'submit',
  SUBMITTING = 'submitting',
  FINISH = 'finish'
}

const SubmitDesignModal = ({ onDoneSubmit, onCancelSubmit }) => {
  const [submitState, setSubmitState] = useState<SubmitState>(SubmitState.SUBMIT);
  const buttonVariant = submitState === SubmitState.SUBMITTING ? 'disabled' : 'contained';
  const buttonRef = useRef<HTMLButtonElement>(null);
  const totalCost = useTotalCosts();
  const user = useJWTAuth();

  const buttonText = useMemo(() => {
    switch (submitState) {
      case SubmitState.SUBMIT:
        return <IntlMessages id="common.submit" />;
      case SubmitState.SUBMITTING:
        return <IntlMessages id="tweaks.submitting" />;
      case SubmitState.FINISH:
        return <IntlMessages id="tweaks.done" />;
    }
  }, [submitState]);

  const titleText = useMemo(() => {
    switch (submitState) {
      case SubmitState.SUBMIT:
        return <IntlMessages id="tweaks.submit.oneStep" values={{ br: <br /> }} />;
      case SubmitState.SUBMITTING:
        return <IntlMessages id="tweaks.submit.oneStep" values={{ br: <br /> }} />;
      case SubmitState.FINISH:
        return <IntlMessages id="tweaks.submit.builidingBegun" values={{ br: <br /> }} />;
    }
  }, [submitState]);

  const downloadHousePlans = async () => {
    const url =
      'https://tweaks-suppliers.s3.amazonaws.com/floorplans/Edgewood+East+Floor+Plan+9.30.pdf';
    const filename = 'EdgewoodEastFloorPlan.pdf';

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('There was an error downloading the file: ', error);
    }
  };

  const goToNextStep = () => {
    if (submitState === SubmitState.FINISH) {
      onDoneSubmit();
      return;
    }
    setSubmitState(SubmitState.SUBMITTING);
    setTimeout(() => {
      setSubmitState(SubmitState.FINISH);
    }, 4000);
  };

  const RoomCostDetails = (roomName: string, roomCost: number) => {
    return (
      <>
        <ListItem
          sx={{
            width: '100%',
            height: '30px',
            p: '0px'
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}>
            <Typography>{roomName}</Typography>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 600
              }}>
              + {NumbersService.formatNumberToCurrency(roomCost)}
            </Typography>
          </Box>
        </ListItem>
      </>
    );
  };

  return (
    <Modal open={true} onClose={undefined}>
      <Box
        sx={{
          position: 'absolute' as const,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          p: '34px 40px 30px 40px',
          borderRadius: '8px',
          width: '566px',
          height: '550px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '21px',
            lineHeight: '25px',
            alignSelf: 'center',
            alignContent: 'center',
            mb: '31px',
            textAlign: 'center',
            lineBreak: 'auto',
            color: submitState === SubmitState.SUBMITTING ? '#EFECEB' : '#000000'
          }}>
          {titleText}
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: '350px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}>
          {submitState === SubmitState.SUBMIT && (
            <>
              <Box
                sx={{
                  width: '100%',
                  height: '230px',
                  flexShrink: 0,
                  backgroundColor: '#FAF8F7',
                  borderRadius: '8px',

                  p: '16px 0px 0px 0px'
                }}>
                <Typography
                  sx={{
                    color: '#33302E',
                    fontSize: '18px',
                    fontWeight: 600,
                    mb: '20px',
                    height: '19px',
                    ml: '16px',
                    mr: '16px'
                  }}>
                  {user.user.displayName}
                </Typography>
                <Box
                  sx={{
                    height: '90px'
                  }}>
                  <Scrollbars autoHide dir="ltr" width={'100%'}>
                    <List
                      sx={{
                        height: '90px',
                        ml: '16px',
                        mr: '16px'
                      }}>
                      {totalCost.roomsAndCosts.map((room) => {
                        return RoomCostDetails(room.roomName, room.cost);
                      })}
                    </List>
                  </Scrollbars>
                </Box>
                <Divider
                  sx={{
                    mt: '20px',
                    mb: '3px',
                    borderStyle: 'dashed',
                    borderWidth: '1px 0 0 0',
                    ml: '16px',
                    mr: '16px'
                  }}
                />
                <Box
                  sx={{
                    height: '47px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    ml: '16px',
                    mr: '16px'
                  }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500
                    }}>
                    <IntlMessages id="tweaks.totals" />
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      fontWeight: 600
                    }}>
                    {NumbersService.formatNumberToCurrency(totalCost.total)}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  height: '72px',
                  borderRadius: '8px',
                  backgroundColor: '#EAF1FF',
                  mb: '24px',
                  display: 'flex',
                  p: '16px',
                  gap: '8px'
                }}>
                <Box sx={{ width: '32px', height: '32px' }}>
                  <InformationCircle />
                </Box>
                <Typography
                  sx={{
                    color: '#726F6E',
                    fontSize: '14px'
                  }}>
                  <IntlMessages id="tweaks.warning.onceSubmitted" />
                </Typography>
              </Box>
            </>
          )}
          {(submitState === SubmitState.FINISH || submitState === SubmitState.SUBMITTING) && (
            <>
              <Box
                sx={{
                  width: '100%',
                  height: '287px',
                  backgroundColor: '#F5F1F0',
                  borderRadius: '8px'
                }}>
                <Card
                  sx={{
                    boxShadow: 'none',
                    p: '10px',
                    backgroundColor: '#F5F1F0'
                  }}>
                  <CardMedia component="img" image={SubmittingAnimation}></CardMedia>
                </Card>
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '44px',
            gap: '8px',
            display: 'flex',
            justifyContent: 'center'
          }}>
          {(submitState === SubmitState.FINISH || submitState === SubmitState.SUBMIT) && (
            <Button
              onClick={() => {
                if (submitState === SubmitState.SUBMIT) {
                  onCancelSubmit();
                } else {
                  downloadHousePlans();
                }
              }}
              sx={{
                width: '174px',
                height: '44px',
                fontSize: '14px',
                fontWeight: 500,
                boxShadow: '0px 0px 0px 0px rgba(165, 58, 0, 0.23);',
                borderRadius: '88px',
                color: '#33302E',
                backgroundColor: '#EFECEB'
              }}>
              {submitState === SubmitState.SUBMIT ? (
                <IntlMessages id="common.keepEditing" />
              ) : (
                <IntlMessages id="tweaks.downloadHousePlans" />
              )}
            </Button>
          )}
          <Button
            ref={buttonRef}
            variant={buttonVariant}
            sx={{
              width: '174px',
              height: '44px',
              fontSize: '14px',
              fontWeight: 500,
              borderRadius: '88px',
              animation:
                submitState === SubmitState.SUBMITTING
                  ? 'width-increase 4s linear forwards'
                  : 'none',
              background:
                submitState === SubmitState.SUBMITTING
                  ? 'linear-gradient(to right, #EFECEB 0%, #EFECEB 100% , #FAF8F7 100%);'
                  : '#FF5A00',
              '@keyframes width-increase': {
                '0%': {
                  backgroundSize: '0%'
                },
                '100%': {
                  backgroundSize: '100%'
                }
              },
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
              boxShadow:
                submitState === SubmitState.SUBMIT
                  ? ' 0px 1px 2px 0px rgba(165, 58, 0, 0.23);'
                  : ' 0px 0px 0px 0px rgba(165, 58, 0, 0.23);'
            }}
            onClick={goToNextStep}>
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SubmitDesignModal;
