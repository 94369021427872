import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as LogoText } from '../../../../../assets/icon/tweaksLogo.svg';
interface AppLogoProps {
  color?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ color }) => {
  return (
    <Box
      sx={{
        height: { xs: 56, sm: 70 },
        display: 'flex',
        alignItems: 'center',
        maxWidth: '10em',
        '& svg': {
          height: { xs: 50, sm: 55 }
        }
      }}
      className="app-logo">
      <LogoText />
    </Box>
  );
};

export default AppLogo;
