import { PDFDocument, PDFFont } from 'pdf-lib';
import { MutableRefObject, useRef, useState } from 'react';
import { Coords, DraggableImageElement, DraggableTextElement } from 'types/common.types';
//@ts-ignore
import multiLagFont from '../../assets/fonts/NotoSansHebrew.ttf';
import fontkit from '@pdf-lib/fontkit';
import { downloadFile } from 'shared/utility/Utils';

export const usePdf = (projectFileUrl: string, signatureDataUrl) => {
  const defaultFontBuffer = useRef<ArrayBuffer>();
  const [modifiedPdfFiles, setModifiedPdfFile] = useState<Uint8Array[]>([]);
  const getLastModifiedPdfFile = modifiedPdfFiles[modifiedPdfFiles.length - 1];
  const signatureWidth = 36;
  const signatureHeight = 18;

  const getDraggableElementCoordsRelatedToThePdfDocument = (
    pdfPageProxy: any,
    elementRef: MutableRefObject<any>,
    draggableElementsCoords: Coords,
    relativeWidth: number,
    relativeHeight: number
  ) => {
    const [lastX, lastY] = [draggableElementsCoords.lastX, draggableElementsCoords.lastY];
    const [, , originalWidth, originalHeight] = pdfPageProxy.view;
    const currentPage = Math.max(0, Math.floor(lastY / relativeHeight));
    const layerX_LeftSideOfElement = (originalWidth / relativeWidth) * lastX; // calculate the x position relative to the pdf after he got scaled from his original size, and position it in the middle of the cursor
    const getTopOffsetOfScroll = lastY - relativeHeight * currentPage;
    const layerY_AnchorTopOfElement =
      originalHeight - (originalHeight / relativeHeight) * getTopOffsetOfScroll; // calculate the y position relative to the pdf after he got scaled from his original size, and position it in the middle of the cursor
    const layerY_AnchorBottomOfElement =
      layerY_AnchorTopOfElement - elementRef.current.clientHeight;
    return { x: layerX_LeftSideOfElement, y: layerY_AnchorBottomOfElement, currentPage };
  };

  const loadPdfDocument = async () => {
    let exitingPdfBytes;
    if (getLastModifiedPdfFile) {
      exitingPdfBytes = getLastModifiedPdfFile;
    } else {
      exitingPdfBytes = await fetch(projectFileUrl)
        .then((data) => data.arrayBuffer())
        .catch(() => {
          // @TODO: error handling
          throw new Error('Failed to fetch pdf file');
        });
    }

    const pdfDocument = await PDFDocument.load(exitingPdfBytes);
    pdfDocument.registerFontkit(fontkit);

    if (!defaultFontBuffer.current) {
      defaultFontBuffer.current = await fetch(multiLagFont).then((res) => res.arrayBuffer());
    }

    const font = await pdfDocument.embedFont(defaultFontBuffer.current);

    return {
      pdfDocument,
      font
    };
  };

  const loadPdfPage = async (pdfDocument: PDFDocument, font: PDFFont, pageIndex: number) => {
    const pdfPage = pdfDocument.getPages()[pageIndex];
    pdfPage.setFont(font);

    return pdfPage;
  };

  const savePdf = async (pdfDocument: PDFDocument) => {
    const pdfBytes = await pdfDocument.save();
    setModifiedPdfFile((prev) => [...prev, pdfBytes]);
  };

  const addSignatures = async (
    pdfDocumentService: Awaited<ReturnType<typeof loadPdfDocument>>,
    draggableImageElements: DraggableImageElement[]
  ) => {
    if (draggableImageElements.length === 0) return [];

    const draggableElementsPromiseList = draggableImageElements.map(async (d) => {
      const elemntPdfPage = await loadPdfPage(
        pdfDocumentService.pdfDocument,
        pdfDocumentService.font,
        d.pageNumber - 1
      );

      const image = await pdfDocumentService.pdfDocument.embedPng(d.previewUrl);

      elemntPdfPage.drawImage(image, {
        x: d.x + 4.8,
        y: d.y + 18,
        width: signatureWidth,
        height: signatureHeight
      });
    });

    return draggableElementsPromiseList;
  };

  const addTexts = async (
    pdfDocumentService: Awaited<ReturnType<typeof loadPdfDocument>>,
    draggableTextElement: DraggableTextElement[]
  ) => {
    if (draggableTextElement.length === 0) return [];

    const draggableElementsPromiseList = draggableTextElement.map(async (d) => {
      const elemntPdfPage = await loadPdfPage(
        pdfDocumentService.pdfDocument,
        pdfDocumentService.font,
        d.pageNumber - 1
      );

      elemntPdfPage.drawText(d.value, {
        x: d.x + 4.8,
        y: d.y + 14,
        size: 10
      });
    });

    return draggableElementsPromiseList;
  };

  const saveElementsInPdf = async (
    getDraggableElementsCoordsList: (DraggableImageElement | DraggableTextElement)[]
  ) => {
    const pdfDocumentService = await loadPdfDocument();

    const signatures = getDraggableElementsCoordsList.flatMap((e) =>
      e.type === 'IMAGE' ? [e] : []
    );
    const texts = getDraggableElementsCoordsList.flatMap((e) => (e.type === 'TEXT' ? [e] : []));

    await Promise.all([
      addSignatures(pdfDocumentService, signatures),
      addTexts(pdfDocumentService, texts)
    ]);

    await savePdf(pdfDocumentService.pdfDocument);
  };

  const downloadLastFile = async (fileName: string) => {
    const blob = new Blob([getLastModifiedPdfFile], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const pdfDocumentService = await loadPdfDocument();
    console.log(pdfDocumentService.pdfDocument);
    downloadFile(url, fileName, false);
  };

  const resetPdfDocument = () => {
    setModifiedPdfFile([]);
  };

  const undoLastModifiedPdf = () => {
    if (modifiedPdfFiles.length > 0) {
      setModifiedPdfFile((prev) => prev.slice(0, -1));
    }
  };

  return {
    addSignatures,
    modifiedPdfFiles,
    getLastModifiedPdfFile,
    undoLastModifiedPdf,
    resetPdfDocument,
    signatureDataUrl,
    addTexts,
    getDraggableElementCoordsRelatedToThePdfDocument,
    saveElementsInPdf,
    downloadLastFile
  };
};
