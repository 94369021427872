import { useEffect, useState } from 'react';
import Upload from '../../thirdParty/reactDropzone/components/UploadModern';
import Box from '@mui/material/Box';
import { useDropzone } from 'react-dropzone';
import FileRow from '../../thirdParty/reactDropzone/components/FileRow';
import AppList from 'shared/core/AppList';
import IntlMessages from 'shared/utility/IntlMessages';
import { Button } from '@mui/material';
import {
  createProjectFile,
  fetchStart,
  fetchSuccess,
  getProjectFileUploadLink,
  updateProjectFile
} from 'redux/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IProject } from 'types/models/Project';
import { Fonts } from 'shared/constants/AppEnums';
import TextField from '@mui/material/TextField';
import { IFile } from 'types/models/File';
import { s3Upload } from 'services/s3';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { messageSeverity } from 'shared/constants/AppConst';

export interface IModelsDropzone {
  project: IProject;
  file?: IFile;
  onEdit?: () => void;
}

const FilesDropzone = ({ project, file, onEdit }: IModelsDropzone) => {
  const dropzone = useDropzone({
    accept: '.pdf',
    multiple: false
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { messages } = useIntl();

  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [fileLabel, setFileLabel] = useState(file?.label || '');
  const [filesReady, setFilesReady] = useState(false);

  const updateFile = (data) => {
    updateProjectFile(project._id, file._id, data).then(() => {
      dispatch(fetchSuccess());
      navigate(`/projects/${project._id}`);
      enqueueSnackbar(String(messages['common.successfullySaved']), {
        variant: messageSeverity.SUCCESS,
        autoHideDuration: 3000
      });
    });
  };
  const onSubmit = async () => {
    dispatch(fetchStart());
    if (file) {
      if (uploadedFiles.length < 1) {
        updateFile({ label: fileLabel });
        return;
      }

      // if new file was uploaded
      getProjectFileUploadLink(project._id).then((s3Url) => {
        s3Upload(s3Url.signedUrl, uploadedFiles[0], 'application/pdf').then(() => {
          updateFile({ url: s3Url.fileUrl, label: fileLabel });
        });
      });
    } else {
      // Create project file
      getProjectFileUploadLink(project._id).then((s3Url) => {
        s3Upload(s3Url.signedUrl, uploadedFiles[0], 'application/pdf').then(() => {
          const data = {
            label: fileLabel,
            project: project,
            url: s3Url.fileUrl
          };

          createProjectFile(project._id, data).then(() => {
            dispatch(fetchSuccess());
            navigate(`/projects/${project._id}`);
            enqueueSnackbar(String(messages['common.successfullySaved']), {
              variant: messageSeverity.SUCCESS,
              autoHideDuration: 3000
            });
          });
        });
      });
    }
  };

  useEffect(() => {
    setUploadedFiles(dropzone.acceptedFiles);
    if (dropzone.acceptedFiles.length > 0) {
      setFilesReady(true);
    }
  }, [dropzone.acceptedFiles]);

  const onDeleteUploadFile = (file: any) => {
    dropzone.acceptedFiles.splice(dropzone.acceptedFiles.indexOf(file), 1);
    setUploadedFiles([...dropzone.acceptedFiles]);
    if (dropzone.acceptedFiles.length === 0) {
      setFilesReady(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileLabel(event.target.value);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        component="h2"
        sx={{
          fontSize: 16,
          color: 'text.primary',
          fontWeight: Fonts.SEMI_BOLD,
          mb: {
            xs: 2,
            lg: 4
          }
        }}>
        <IntlMessages id={file ? 'project.actions.editFile' : 'project.actions.addFile'} />
      </Box>
      <Box
        sx={{
          mb: {
            xs: 2,
            lg: 4
          }
        }}>
        <TextField
          name="fileLabel"
          label={<IntlMessages id="common.label" />}
          value={fileLabel}
          onChange={handleChange}
        />
      </Box>

      <Upload uploadText={<IntlMessages id="actions.upload.projectFile" />} dropzone={dropzone} />
      <aside>
        <AppList
          data={uploadedFiles}
          renderRow={(file, index) => (
            <FileRow key={index + file.path} file={file} onDeleteUploadFile={onDeleteUploadFile} />
          )}
        />
        <Box
          style={{ marginTop: '1rem' }}
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
          {((filesReady && fileLabel) || (file && fileLabel !== file.label)) && (
            <Button
              style={{ marginRight: '1rem' }}
              variant="contained"
              color="primary"
              onClick={() => {
                onSubmit();
              }}>
              <IntlMessages id="common.save" />
            </Button>
          )}

          <Button
            color="primary"
            variant="outlined"
            onClick={() => navigate(`/projects/${project._id}`)}>
            <IntlMessages id="actions.back" />
          </Button>
        </Box>
      </aside>
    </Box>
  );
};

export default FilesDropzone;
