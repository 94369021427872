import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import IntlMessages from '../../utility/IntlMessages';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { Fonts } from '../../../shared/constants/AppEnums';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  // eslint-disable-next-line no-undef
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AppConfirmDialogProps {
  type?: 'default' | 'confirm' | 'revert';
  dialogTitle: string | ReactNode;
  open: boolean;
  onDeny: (isOpen: boolean) => void;
  title: string | ReactNode;
  onConfirm: () => void;
  confirmCopy?: string | ReactNode;
  denyCopy?: string | ReactNode;
  hideDeny?: boolean;
}

const AppConfirmDialog: React.FC<AppConfirmDialogProps> = ({
  type = 'default',
  open,
  onDeny,
  onConfirm,
  title,
  dialogTitle,
  confirmCopy,
  denyCopy,
  hideDeny = false
}) => {
  return (
    <Dialog TransitionComponent={Transition} open={open} onClose={() => onDeny(false)}>
      <DialogTitle
        component="h4"
        variant="h4"
        id="alert-dialog-title"
        sx={{
          backgroundColor: '#FBFBFB',
          height: '60px'
        }}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent id="alert-dialog-description">{title}</DialogContent>
      <DialogActions
        sx={{
          pb: 5,
          px: 6,
          display: 'flex',
          justifyContent: 'center',
          '& .MuiButton-root': {
            width: 185,
            maxWidth: '100%',
            height: 44,
            color: 'black',
            borderColor: 'black',
            transition: 'all 0.3s ease',
            ':hover': {
              transform: 'scale(1.02)',
              borderColor: 'black',
              backgroundColor: '#00000011'
            },
            ':disabled': {
              opacity: 0.6
            },
            '&.confirm-btn': {
              ...(type === 'confirm'
                ? {
                    backgroundColor: 'success.modal',
                    borderColor: 'success.modal',
                    color: 'white'
                  }
                : {}),
              ...(type === 'revert'
                ? {
                    backgroundColor: 'warning.main',
                    borderColor: 'warning.main',
                    color: 'white'
                  }
                : {})
            }
          }
        }}>
        {!hideDeny && (
          <Button
            variant="outlined"
            sx={{
              fontWeight: Fonts.MEDIUM
            }}
            onClick={() => onDeny(false)}>
            {denyCopy || <IntlMessages id="common.no" />}
          </Button>
        )}
        <Button
          variant="outlined"
          className="confirm-btn"
          sx={{
            fontWeight: Fonts.MEDIUM
          }}
          onClick={onConfirm}
          autoFocus>
          {confirmCopy || <IntlMessages id="common.yes" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppConfirmDialog;
