import { Button, Grid } from '@mui/material';
import AppCard from 'shared/core/AppCard';
import IntlMessages from 'shared/utility/IntlMessages';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { dispatch as busDispatch } from 'use-bus';
import { ViewerEvents } from 'shared/constants/AppConst';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { setViewerScreenshot } from 'redux/actions/Viewer';
import { getModelTemplateImageUploadLink } from 'redux/actions/Model';
import { s3Upload } from 'services/s3';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import PreviewIcon from '@mui/icons-material/Preview';

const ModelTemplateImage = ({ modelTemplate, onUpdate }) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [thumbnail, setThumbnail] = useState(
    modelTemplate?.imageUrl || '/assets/images/custom/default-project.png'
  );
  const { viewerScreenshotBlobUrl } = useSelector<AppState, AppState['viewer']>(
    ({ viewer }) => viewer
  );

  const takeScreenshot = () => {
    busDispatch({
      type: ViewerEvents.TakeScreenshot,
      payload: {
        setBlobInStore: true
      }
    });
  };

  const onCancel = () => {
    dispatch(setViewerScreenshot(null));
  };

  const onSave = (blobUrl) => {
    setSaving(true);
    getModelTemplateImageUploadLink(modelTemplate._id).then((s3Url) => {
      fetch(blobUrl).then((r) =>
        r.blob().then((blob) => {
          s3Upload(s3Url.signedUrl, blob).then(() => {
            onUpdate({ imageUrl: s3Url.fileUrl });
            setThumbnail(s3Url.fileUrl + '?t=' + Date.now());
            dispatch(setViewerScreenshot(null));
            setSaving(false);
          });
        })
      );
    });
  };

  return (
    <AppCard title={<IntlMessages id="tweaks.model.image" />}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          {viewerScreenshotBlobUrl && (
            <PreviewIcon style={{ position: 'absolute', marginTop: 2, marginLeft: 2 }} />
          )}

          <img
            src={viewerScreenshotBlobUrl || thumbnail}
            alt={`thumbnail${saving ? ' saving' : ''}`}
          />
        </Grid>
        <Grid item xs={4}>
          {!viewerScreenshotBlobUrl && (
            <Button size="small" onClick={takeScreenshot} color="primary" variant="contained">
              <CameraAltIcon />
            </Button>
          )}

          {viewerScreenshotBlobUrl && (
            <>
              <LoadingButton
                onClick={() => {
                  onSave(viewerScreenshotBlobUrl);
                }}
                color="primary"
                variant="contained"
                size="small"
                loading={saving}>
                <IntlMessages id="common.save" />
              </LoadingButton>
              <Button
                onClick={onCancel}
                style={{ marginTop: 12 }}
                color="primary"
                variant="outlined"
                size="small"
                disabled={saving}>
                <IntlMessages id="common.cancel" />
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default ModelTemplateImage;
