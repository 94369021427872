import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import jwtAxios from 'services/auth/jwt-auth';
import { GET_ASSETS, LOADING_ASSET } from 'types/actions/Asset.actions';

export const getAssets = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_ASSET });
    jwtAxios.get(`/projects/assets/get-all`).then((data) => {
      dispatch({ type: GET_ASSETS, payload: data.data });
    });
  };
};
