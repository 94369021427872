/* eslint-disable no-console */
// @ts-nocheck

import TWEEN from '@tweenjs/tween.js';

import {
  IElementInfo,
  IRoomInfo,
  IBlinkingElement,
  IIntersectElement,
  IElectricCircutConnectedElementWithProps
} from 'types/models/ForgeViewer';
import { v4 as uuidv4 } from 'uuid';
import BIService from 'shared/utility/BIService';
import { CONTEXTS, EVENT_TYPES } from 'shared/constants/AppConst';
import {
  EDGE_AXIS,
  ForgeUtils,
  getDistaceLimit,
  IDbidWithModelId as IDbidWithModel
} from 'shared/utility/ForgeUtilities/ForgeUtils';
import { IRevitProperty } from 'types/models/Modification';
import { Vector3 } from 'three/src/Three';

const extensionName = 'TweaksxCustomizer';

export class Customizer extends Autodesk.Viewing.Extension {
  public isDraggingElement = false;

  public draggingDbId: number | null = null;

  public isPlacingAsset = false;

  public draggingModelId: string | null = null;

  public draggingModelbBox = new THREE.Box3();

  public draggingModelCategory = '';

  public movingElementCenter: THREE.Vector3 | null;

  public movingElementCategory: string;

  public hostInfo: IElementInfo;

  private hostWalls: number[] = [];

  public movingElementInfo: IElementInfo[] = [];

  private hostBbox: THREE.Box3;

  private hostNormal: THREE.Vector3;

  private currentHoveredDbid = -1;

  private intersectionPoint: THREE.Vector3 | null = null;

  private selectedElementHostAngle = 0;

  private ceilingsDbids: number[] = [];

  private floorDbid: number;

  private floorDbids: number[] = [];

  private selectedElementHostHitPoint: THREE.Vector3;

  private wallsDbids: number[] = [];

  private wallDbidsWithRoomInfo: { dbId: number; revitId: number; roomInfo: IRoomInfo } = [];

  private intersecteDbids: number[] = [];

  private electricFixturesDbidsWithModelId: IDbidWithModel[] = [];

  private switchesDbidsWithProps: IElectricCircutConnectedElementWithProps[] = [];

  private lightsDbidsWithProps: IElectricCircutConnectedElementWithProps[] = [];

  private selectableDbids: number[] = []; // All Selectable dbids , outlets and lights

  private electricFixturesDbidsOnHostedWall: IDbidWithModel[] = []; // All electric fixtures dbids on current selected wall

  private electricFixturesBBoxOnHostedWall: THREE.Vector3[] = [];

  private wallSnapLines: THREE.Line | null = null;

  private wallEdgeLines: THREE.Line[] = [];

  private selectionEvent;

  private hostFragmentsForDragging: [] = [];

  private doorsDbidsWithModel: IDbidWithModel[] = [];

  private windowsDbidsWithModel: IDbidWithModel[] = [];

  private addedElementsWithModelId: IDbidWithModel[] = [];

  private ceilingsDbids: IDbidWithModel[] = [];

  private wallLines: THREE.Line | null = null;

  private isSnapDrawn = false;

  private wallEdgeLimit: THREE.Line[] = [];

  private arrowHelper: THREE.ArrowHelper = null;

  private draggingElementOriginalPosition: THREE.Vector3 = null;

  private currentEnteredRoom: IRoomInfo = null;

  public rooms: IRoomInfo[] = [];

  public static viewerInstance: Autodesk.Viewing.GuiViewer3D;

  public static floorGroups: Map<string, { revitId: number; dbId: number }[]> = new Map();

  public static wallsGroups: Map<string, { revitId: number; dbId: number }[]> = new Map();

  public static wallsAndFloorGroups: Map<string, { revitId: number; dbId: number }[]> = new Map();

  constructor(viewer, options) {
    super(viewer, options);
    this.viewer = viewer;
    Customizer.viewerInstance = viewer;
    this._selectionChangedCallback = options.selectionChangedCallback;
    this._showToolTip = options.showToolTipCallBack;
    this._updateSelectionPositionCallBack = options.updateSelectionPositionCallBack;
    this.onEndDraggingCallBack = options.onEndDraggingCallBack;
    this.cancelDragging = options.cancelDragging;
    this.pointer = null;
    this.hitPoint = new THREE.Vector3(0, 0, 0);
    this.originalMaterials = {};
    this.rooms = [];
    this.blinkingElements = [];
  }

  drawBBoxHelper(bboxs: THREE.Box3[]) {
    const linesMaterial = new THREE.LineBasicMaterial({
      color: new THREE.Color(0, 0, 0),
      transparent: true,
      depthWrite: false,
      depthTest: true,
      linewidth: 10
    });

    const lineGroups = [];

    bboxs.forEach((bbox) => {
      const geometry = new THREE.Geometry();

      const { min, max } = bbox;

      geometry.vertices.push(new THREE.Vector3(min.x, min.y, min.z));
      geometry.vertices.push(new THREE.Vector3(max.x, min.y, min.z));

      geometry.vertices.push(new THREE.Vector3(max.x, min.y, min.z));
      geometry.vertices.push(new THREE.Vector3(max.x, min.y, max.z));

      geometry.vertices.push(new THREE.Vector3(max.x, min.y, max.z));
      geometry.vertices.push(new THREE.Vector3(min.x, min.y, max.z));

      geometry.vertices.push(new THREE.Vector3(min.x, min.y, max.z));
      geometry.vertices.push(new THREE.Vector3(min.x, min.y, min.z));

      geometry.vertices.push(new THREE.Vector3(min.x, max.y, max.z));
      geometry.vertices.push(new THREE.Vector3(max.x, max.y, max.z));

      geometry.vertices.push(new THREE.Vector3(max.x, max.y, max.z));
      geometry.vertices.push(new THREE.Vector3(max.x, max.y, min.z));

      geometry.vertices.push(new THREE.Vector3(max.x, max.y, min.z));
      geometry.vertices.push(new THREE.Vector3(min.x, max.y, min.z));

      geometry.vertices.push(new THREE.Vector3(min.x, max.y, min.z));
      geometry.vertices.push(new THREE.Vector3(min.x, max.y, max.z));

      geometry.vertices.push(new THREE.Vector3(min.x, min.y, min.z));
      geometry.vertices.push(new THREE.Vector3(min.x, max.y, min.z));

      geometry.vertices.push(new THREE.Vector3(max.x, min.y, min.z));
      geometry.vertices.push(new THREE.Vector3(max.x, max.y, min.z));

      geometry.vertices.push(new THREE.Vector3(max.x, min.y, max.z));
      geometry.vertices.push(new THREE.Vector3(max.x, max.y, max.z));

      geometry.vertices.push(new THREE.Vector3(min.x, min.y, max.z));
      geometry.vertices.push(new THREE.Vector3(min.x, max.y, max.z));

      const lines = new THREE.Line(geometry, linesMaterial, THREE.LinePieces);

      lineGroups.push(lines);

      if (!this.viewer.overlays.hasScene('boundingBox')) {
        this.viewer.overlays.addScene('boundingBox');
      }

      this.viewer.impl.addOverlay('boundingBox', lines);

      this.viewer.impl.invalidate(true, true, true);
    });
  }

  removePackageDbidsFromSelectableDbids(packageDbids: number[]) {
    this.selectableDbids = this.selectableDbids.filter((x) => !packageDbids.some((y) => y === x));
  }

  async getParentDbid(dbid: number, model: Autodesk.Viewing.Model) {
    return new Promise((resolve, reject) => {
      model.getBulkProperties(
        [dbid],
        {
          propFilter: ['parent'],
          ignoreHidden: false
        },
        async (props) => {
          resolve(props[0].properties.find((x) => x.displayName === 'parent').displayValue);
        }
      );
    });
  }

  // We had to get only the parent of each element to draw a circle on it . (mark extension drawMarkersOnSelectableDbids)
  // If we chose all selectable elements some children will also have a drawn circle and it wil look weird
  async getSelectableDbidsWithModel(): Promise<IDbidWithModel[]> {
    return new Promise<IDbidWithModel[]>(
      (resolve, reject) => {
        this.viewer.model.getBulkProperties(
          this.selectableDbids,
          {
            propFilter: ['parent'],
            ignoreHidden: false
          },
          async (result) => {
            const parentOfSelectableDbids: [] = [];
            result.forEach(async (element) => {
              // Only choose parent dbid
              const elementParent = element.properties.find(
                (x) => x.displayName === 'parent'
              ).displayValue;
              const doesHaveParentDbid = this.selectableDbids.some((x) => x === elementParent);
              if (!doesHaveParentDbid) {
                if (!parentOfSelectableDbids.some((x) => x === element.dbId)) {
                  parentOfSelectableDbids.push(element.dbId);
                }
              }
            });

            const parentOfSelectableDbidsWithModel: IDbidWithModel[] = [];
            parentOfSelectableDbids.forEach((element) => {
              const viewerModel = this.viewer.model;

              if (!viewerModel.getInstanceTree().isNodeOff(element)) {
                parentOfSelectableDbidsWithModel.push({
                  dbId: element,
                  model: viewerModel
                });
              }
            });

            if (this.addedElementsWithModelId.length === 0) {
              resolve(parentOfSelectableDbidsWithModel);
            } else {
              // Only choose parent dbid
              for (let i = 0; i < this.addedElementsWithModelId.length; i++) {
                const element = this.addedElementsWithModelId[i];
                const props = await Customizer.getPropertiesAsync(element.dbId, element.model);
                const parent = props.properties.find(
                  (x) => x.displayName === 'parent'
                ).displayValue;

                if (!this.addedElementsWithModelId.some((x) => x.dbId === parent)) {
                  if (!element.model.getInstanceTree().isNodeOff(element.dbId)) {
                    parentOfSelectableDbidsWithModel.push({
                      dbId: element.dbId,
                      model: element.model
                    });
                  }
                }
                if (i === this.addedElementsWithModelId.length - 1) {
                  resolve(parentOfSelectableDbidsWithModel);
                }
              }
            }
          }
        );
      },
      (error) => {
        reject(error); // Reject the promise with the error
      }
    );
    // });
  }

  load() {
    this.viewer.canvasWrap.addEventListener('mouseup', async (event) => {
      if (event.button === 0) {
        const screenPoint = {
          x: event.clientX,
          y: event.clientY
        };
        const viewport = this.viewer.navigation.getScreenViewport();
        const n = {
          x: (screenPoint.x - viewport.left) / viewport.width,
          y: (screenPoint.y - viewport.top) / viewport.height
        };

        this.hitPoint = this.viewer.utilities.getHitPoint(n.x, n.y);

        if (this.isDraggingElement) {
          const biService = BIService.getInstance();
          biService.logEvent(EVENT_TYPES.MOVE_ASSET_END, CONTEXTS.VIEW, {
            category: this.movingElementCategory,
            dbId: this.draggingDbId
          });

          this.onMoveEnd();
        }
      } else if (event.button === 2) {
        console.log(event.button);
        if (this.isDraggingElement) {
          this.cancelDragElement();
        }
      }
    });

    this.viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, (e) =>
      this.onSelectionChanged(e, this.isDraggingElement)
    );

    this.viewer.container.addEventListener('mousemove', this.onMouseMove);
    this.viewer.container.addEventListener('mousemove', (e) => {
      this.dragElement(e);
      this.hoverOnElement(e);
    });

    this.animateStart();

    console.info('Tweaksx Customizer extension has been loaded');
    return true; // This returning flag represents the load success
  }

  onMoveEnd() {
    if (!this.viewer.overlays.hasScene('snap-scene')) {
      this.viewer.overlays.addScene('snap-scene');
    } else {
      this.viewer.overlays.clearScene('snap-scene');
    }

    this.didCacheDraggingData = false;

    const draggedElementHost = this.hostInfo;
    draggedElementHost.hitPoint = this.intersectionPoint.add(
      ForgeUtils.getGlobalOffset(this.viewer)
    );

    const markUpExt = this.viewer.getExtension('TweaksxMarkUp');
    markUpExt.clearAllLinesAndLabels();

    this.arrowHelper = null;
    this.viewer.show(this.hostInfo.dbId);

    const extension = this.viewer.getExtension(extensionName);
    extension.onEndDraggingCallBack(draggedElementHost, this.selectionEvent);
  }

  cancelDragElement() {
    this.isDraggingElement = false;

    if (!this.viewer.overlays.hasScene('snap-scene')) {
      this.viewer.overlays.addScene('snap-scene');
    } else {
      this.viewer.overlays.clearScene('snap-scene');
    }

    if (!this.viewer.overlays.hasScene('edge-scene')) {
      this.viewer.overlays.addScene('edge-scene');
    } else {
      this.viewer.overlays.clearScene('edge-scene');
    }

    if (!this.viewer.overlays.hasScene('connected-lines-scene')) {
      this.viewer.overlays.addScene('connected-lines-scene');
    }

    const markUpExt = this.viewer.getExtension('TweaksxMarkUp');
    markUpExt.clearAllLinesAndLabels();

    this.arrowHelper = null;

    ForgeUtils.moveModelToHitPoint(
      this.draggingDbId,
      this.draggingModelId,
      this.hostInfo.angle,
      this.viewer,
      this.draggingElementOriginalPosition
    );

    const extension = this.viewer.getExtension(extensionName);
    extension.cancelDragging();
  }

  // drawArrowHelper(ray: THREE.Ray, distance: number) {
  //   // this.clearLinesInConnectedLinesScene();
  //   const pointA = ray.origin;
  //   const pointB = ray.origin.clone().add(ray.direction.clone().multiplyScalar(distance));

  //   this.drawLinesBetweenPointsAndOrigin(pointA, [pointB]);
  //   // if (this.arrowHelper === null) {
  //   //   this.arrowHelper = new THREE.ArrowHelper(ray.direction, ray.origin, distance, 0xff0000);
  //   //   this.viewer.overlays.addMesh(this.arrowHelper, 'connecting-line');
  //   // } else {
  //   //   this.arrowHelper.position.copy(ray.origin);
  //   //   this.arrowHelper.setLength(distance);
  //   //   this.arrowHelper.setDirection(ray.direction);
  //   // }
  // }

  async onMoveStart(hostDbid) {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.MOVE_ASSET_START, CONTEXTS.VIEW, {
      category: this.movingElementCategory,
      dbId: this.draggingDbId
    });

    this.hostInfo = await this.getElementInfo([hostDbid], this.viewer, this.viewer.model);

    this.hostInfo.angle = this.selectedElementHostAngle;

    const bBox = ForgeUtils.getBoundingBoxDbids(this.hostWalls, this.viewer.model.id, this.viewer);

    this.hostFragmentsForDragging = ForgeUtils.getFragmentsByDbid(
      this.hostInfo.dbId,
      this.draggingModelId,
      this.viewer
    );

    this.hostBbox = bBox;
    this.WallLine = null;

    let elementsInBBox: [] = [];
    if (
      this.draggingModelCategory === 'Revit Electrical Fixtures' ||
      this.draggingModelCategory === 'Revit Lighting Fixtures'
    ) {
      elementsInBBox = await this.getElementsPropsInBBoxesWithFilters(this.draggingModelCategory, [
        bBox
      ]);
    }

    // outlet can have children dbids so we need to get the parent one
    this.electricFixturesDbidsOnHostedWall = [];
    this.draggingModelbBox = ForgeUtils.getBoundingBox(
      this.draggingDbId,
      this.draggingModelId,
      this.viewer
    );

    const center = this.draggingModelbBox.getCenter();
    this.draggingElementOriginalPosition = center;

    const elementsParent = [];
    elementsInBBox.forEach((element) => {
      const parentId = element.properties.find((x) => x.displayName === 'parent').displayValue;
      const doesExists = elementsInBBox.some((x) => x.dbId === parentId);

      // it doesnt find a parent so we assume its the parent of all the objects
      if (!doesExists) {
        elementsParent.push(element);
      }
    });

    const samePlaneOutLets: IDbidWithModel[] = [];
    // outlet can also be in the other side of the wall so we is in the same wall side
    elementsParent.forEach((element) => {
      const elementBBox = ForgeUtils.getBoundingBox(element.dbId, element.model.id, this.viewer);
      const selectedDbidsBBox = ForgeUtils.getBoundingBox(
        this.draggingDbId,
        this.draggingModelId,
        this.viewer
      );
      const distanceFromElement = this.hostBbox
        .getCenter()
        .sub(elementBBox.getCenter())
        .multiply(this.hostNormal);
      const distanceFromSelectedElement = this.hostBbox
        .getCenter()
        .sub(selectedDbidsBBox.getCenter())
        .multiply(this.hostNormal);

      const dotProduct = distanceFromElement.dot(distanceFromSelectedElement);

      if (dotProduct >= 0) {
        if (element.dbId !== this.draggingDbId || element.model.id !== this.draggingModelId) {
          samePlaneOutLets.push({ dbId: element.dbId, model: element.model });
        }
      }
    });

    this.electricFixturesDbidsOnHostedWall = samePlaneOutLets; // TODO: Filter all back ones

    // Getting all the bounding boxes of the outlets on the wall
    this.electricFixturesBBoxOnHostedWall = [];
    this.electricFixturesDbidsOnHostedWall.forEach((element) => {
      const bbox = ForgeUtils.getBoundingBox(element.dbId, element.model.id, this.viewer);
      this.electricFixturesBBoxOnHostedWall.push(bbox.getCenter());
    });

    if (!this.viewer.overlays.hasScene('snap-scene')) {
      this.viewer.overlays.addScene('snap-scene');
    } else {
      this.viewer.overlays.clearScene('snap-scene');
    }

    if (!this.viewer.overlays.hasScene('edge-scene')) {
      this.viewer.overlays.addScene('edge-scene');
    } else {
      this.viewer.overlays.clearScene('edge-scene');
    }

    if (!this.viewer.overlays.hasScene('connected-lines-scene')) {
      this.viewer.overlays.addScene('connected-lines-scene');
    }

    this.isDraggingElement = true;
  }

  async cacheAllLightsDbids(categoriesMap) {
    const filteredModels = this.getFilteredModelsFromViewer();

    const lightingFixturesDbidsWithModelId = categoriesMap['Revit Lighting Fixtures'];

    let lightDbidsArray = [];
    if (lightingFixturesDbidsWithModelId) {
      for (let i = 0; i < filteredModels.length; i++) {
        const lightDbids = await Customizer.getBulkProperties(
          lightingFixturesDbidsWithModelId.map((x) => x.dbId),
          ['מספר מעגל', 'ElementId'],
          filteredModels[i]
        );

        for (let m = 0; m < lightDbids.length; m++) {
          lightDbids[m].model = filteredModels[i];
        }

        lightDbidsArray = lightDbidsArray.concat(lightDbids);
      }
    }

    const filteredLights = lightDbidsArray.filter(
      (x) => !x.properties[0].displayValue.includes('/')
    );

    filteredLights.forEach((element) => {
      const connectedElementWithProps = this.getConnectedElementsWithProps(element, element.model);
      for (let i = 0; i < connectedElementWithProps.length; i++) {
        this.lightsDbidsWithProps.push(connectedElementWithProps[i]);
      }
    });
  }

  // action : Remove , Add
  async updateCacheModelDbids(elementWithModelIdAndDbid: IDbidWithModel[], action: string) {
    if (action === 'Remove') {
      elementWithModelIdAndDbid.forEach((element) => {
        this.addedElementsWithModelId = this.addedElementsWithModelId.filter(
          (x) => x.model.id !== element.model.id
        );

        if (typeof element.model.id === 'number') {
          this.electricFixturesDbidsWithModelId = this.electricFixturesDbidsWithModelId.filter(
            (x) => x.dbId !== element.dbId
          );

          this.switchesDbidsWithProps = this.switchesDbidsWithProps.filter(
            (x) => x.dbId !== element.dbId
          );
        } else {
          this.electricFixturesDbidsWithModelId = this.electricFixturesDbidsWithModelId.filter(
            (x) => x.model.id !== element.model.id
          );

          this.switchesDbidsWithProps = this.switchesDbidsWithProps.filter(
            (x) => x.model.id !== element.model.id
          );
        }

        if (typeof element.model.id === 'number') {
          this.selectableDbids = this.selectableDbids.filter((x) => x !== element.dbId);
        }
      });
    } else if (action === 'Add') {
      elementWithModelIdAndDbid.forEach((element) => {
        this.addedElementsWithModelId.push(element);
        this.electricFixturesDbidsWithModelId.push(element);
      });
      const selectableDbids = await this.getOuletsAndSwitchsWithCircutNumber(
        elementWithModelIdAndDbid
      );

      this.selectableDbids = [...this.selectableDbids, ...selectableDbids.electricFixturesDbids];
      this.switchesDbidsWithProps = [...this.switchesDbidsWithProps, ...selectableDbids.switches];
    }
  }

  getOuletsAndSwitchsWithCircutNumber = async (
    elementsWithDbidsAndModelId: IDbidWithModel[]
  ): { switches: switchesDbidsWithProps; electricFixturesDbids: number[] } => {
    const electricFixturesDbids: number[] = [];
    const switchesDbidsWithProps: IElectricCircutConnectedElementWithProps[] = [];

    for (let i = 0; i < elementsWithDbidsAndModelId.length; i++) {
      const model = this.getFilteredModelsFromViewer().find(
        (x) => x.id === elementsWithDbidsAndModelId[i].model.id
      ) as Autodesk.Viewing.Model;

      if (model !== undefined) {
        const name = model.getInstanceTree().getNodeName(elementsWithDbidsAndModelId[i].dbId);
        if (name !== undefined) {
          if (ForgeUtils.isOutlet(name)) {
            electricFixturesDbids.push(elementsWithDbidsAndModelId[i].dbId);
          }

          if (ForgeUtils.isSwitch(name)) {
            electricFixturesDbids.push(elementsWithDbidsAndModelId[i].dbId);

            const props = await Customizer.getPropertiesAsync(
              elementsWithDbidsAndModelId[i].dbId,
              model
            );

            // if (!props.properties[0].displayValue.toString().includes('/')) {
            const connectElementWithProps = this.getConnectedElementsWithProps(props, model);

            for (let i = 0; i < connectElementWithProps.length; i++) {
              switchesDbidsWithProps.push(connectElementWithProps[i]);
            }
          }
        }
      }
    }

    return { switches: switchesDbidsWithProps, electricFixturesDbids: electricFixturesDbids };
  };

  getConnectedElementsWithProps(
    element,
    model: Autodesk.Viewing.Model
  ): IElectricCircutConnectedElementWithProps[] {
    const switchesDbidsWithProps: IElectricCircutConnectedElementWithProps[] = [];

    if (
      !element.properties
        .find((x) => x.displayName === 'ElementId')
        .displayValue.toString()
        .includes('/')
    ) {
      const circutNumber = element.properties.find((x) => x.displayName === 'מספר מעגל');

      // getting connceted elements to circut number
      if (circutNumber?.displayValue !== undefined && circutNumber?.displayValue !== '') {
        let switchesNumber;
        if (circutNumber.displayValue.includes('/')) {
          switchesNumber = circutNumber.displayValue.split('/')[1];
        } else {
          switchesNumber = circutNumber.displayValue;
        }

        if (switchesNumber !== undefined && switchesNumber.length > 0) {
          const switches = switchesNumber.split(',');
          let connectedElements: [] = [];

          if (circutNumber.displayValue.includes('/')) {
            switches.forEach((element) => {
              connectedElements.push(circutNumber.displayValue.split('/')[0] + '/' + element);
            });
          } else {
            connectedElements = switches;
          }

          const revitId = element.properties.find(
            (x) => x.displayName === 'ElementId'
          ).displayValue;

          switchesDbidsWithProps.push({
            dbId: element.dbId,
            model: model,
            circutNumber: circutNumber.displayValue.split('/')[0],
            revitId: revitId,
            ConnectedElemtsIds: connectedElements
          });
        }
      }
      // no circut number , we return empty array of connected elements
      else if (circutNumber?.displayValue !== undefined || circutNumber?.displayValue === '') {
        const revitId = element.properties.find((x) => x.displayName === 'ElementId').displayValue;

        switchesDbidsWithProps.push({
          dbId: element.dbId,
          model: model,
          circutNumber: circutNumber.displayValue.split('/')[0],
          revitId: revitId,
          ConnectedElemtsIds: []
        });
      }
    }

    return switchesDbidsWithProps;
  }

  public static getGroupDbidsOfSelectedFloor(dbid: number): { dbId: number; revitId: number }[] {
    const foundDbidGroupName = [...Customizer.floorGroups.values()].find((x) =>
      x.some((y) => y.dbId === dbid)
    );

    if (foundDbidGroupName !== undefined) {
      return foundDbidGroupName;
    } else {
      return [];
    }
  }

  public static getWallsGroupNameByDbid(dbIdToFind: number): string {
    for (const [key, valueArray] of this.wallsAndFloorGroups.entries()) {
      for (const obj of valueArray) {
        if (obj.dbId === dbIdToFind) {
          return key;
        }
      }
    }

    return '';
  }

  public static getWallsByGroupName(roomName: string): { dbId: number; revitId: number }[] {
    const foundGroup = Array.from(this.wallsAndFloorGroups.keys()).find(
      (key) => key.includes(roomName) && key.toLowerCase().includes('wall')
    );

    return this.wallsAndFloorGroups.get(foundGroup);
  }

  averageZPosition(vectors: Vector3[]) {
    let sumZ = 0;
    const count = vectors.length;

    for (let i = 0; i < count; i++) {
      sumZ += vectors[i].z;
    }

    return sumZ / count;
  }

  getClosestRoom(wallDbid: number): IRoomInfo {
    const wallBbbox = ForgeUtils.getBoundingBox(wallDbid, this.viewer.model.id, this.viewer);

    const wallCenter = wallBbbox.getCenter();

    if (this.rooms) {
      let closestRoom = this.rooms[0] as IRoomInfo;
      let roomDistance = this.rooms[0].boundingBox.distanceToPoint(wallCenter);

      for (let i = 1; i < this.rooms.length; i++) {
        const distance = this.rooms[i].boundingBox.distanceToPoint(wallCenter);
        if (distance < roomDistance) {
          closestRoom = this.rooms[i];
          roomDistance = distance;
        }
      }

      return closestRoom;
    }
  }

  async cacheModelDbids() {
    console.log('CACHING MODEL DBIDS');
    this.viewer.impl.invalidate(true, true, true);
    const startTime = performance.now();

    const categoriesMap = await ForgeUtils.getDbIdsAndModelMappedByCategory(this.viewer);
    this.categoriesMap = categoriesMap;

    let unFilteredElectricFixturesDbidsWithModelId =
      categoriesMap['Revit Electrical Fixtures'] || [];

    const possibleFloors = categoriesMap['Revit Floors'] || [];

    const floorsThatAreCeleings: [] = [];
    const floorsThatAreWalls: IDbidWithModel[] = [];
    const filteredFloorsDbids: [] = [];

    for (let i = 0; i < possibleFloors.length; i++) {
      const position = ForgeUtils.getBoundingBox(
        possibleFloors[i].dbId,
        possibleFloors[i].model.id,
        this.viewer
      );

      if (
        position.getCenter().x !== 0 ||
        position.getCenter().y !== 0 ||
        position.getCenter().z !== 0
      ) {
        const getFloorProps = await Customizer.getPropertiesAsync(
          possibleFloors[i].dbId,
          possibleFloors[i].model
        );
        if (
          getFloorProps.properties
            .find((x) => x.displayName === 'ElementId')
            .displayValue.includes('/')
        ) {
          floorsThatAreCeleings.push(possibleFloors[i].dbId);
        } else {
          if (
            getFloorProps.properties.find((x) => x.displayName === 'Height Offset From Level')
              ?.displayValue < 10
          ) {
            filteredFloorsDbids.push(possibleFloors[i].dbId);
          }
          // case it's not a floor but a horizontal wall in the bathroom
          else {
            floorsThatAreWalls.push({ dbId: possibleFloors[i].dbId, model: this.viewer.model });
          }
        }
      }
    }

    this.floorDbids = filteredFloorsDbids;

    this.viewer.impl.invalidate(true, true, true);
    let doorsDbidsWithModel = categoriesMap['Revit Doors'] || [];
    let windowsDbidsWithModel = categoriesMap['Revit Windows'] || [];
    let ceilingsDbids = categoriesMap['Revit Ceilings'] || [];
    let wallsDbids = categoriesMap['Revit Walls'] || [];

    wallsDbids = [...wallsDbids, ...floorsThatAreWalls];

    await this.cacheAllLightsDbids(categoriesMap);

    const filtetedElectricFixturesDbidsWithModelId: [] = [];

    unFilteredElectricFixturesDbidsWithModelId.forEach((element) => {
      const position = ForgeUtils.getBoundingBox(element.dbId, element.model.id, this.viewer);

      if (
        position.getCenter().x !== 0 ||
        position.getCenter().y !== 0 ||
        position.getCenter().z !== 0
      ) {
        filtetedElectricFixturesDbidsWithModelId.push(element);
      }
    });

    const filtetedWallsDbids: [] = [];

    wallsDbids.forEach((element) => {
      const position = ForgeUtils.getBoundingBox(element.dbId, element.model.id, this.viewer);

      if (
        position.getCenter().x !== 0 ||
        position.getCenter().y !== 0 ||
        position.getCenter().z !== 0
      ) {
        filtetedWallsDbids.push(element.dbId);
      }
    });

    const filtetedDoorsDbidsWithModel: [] = [];

    doorsDbidsWithModel.forEach((element) => {
      const position = ForgeUtils.getBoundingBox(element.dbId, element.model.id, this.viewer);

      if (
        position.getCenter().x !== 0 ||
        position.getCenter().y !== 0 ||
        position.getCenter().z !== 0
      ) {
        filtetedDoorsDbidsWithModel.push(element);
      }
    });

    let filteredCeilingsDbids: [] = [];

    ceilingsDbids.forEach((element) => {
      const position = ForgeUtils.getBoundingBox(element.dbId, element.model.id, this.viewer);

      if (
        position.getCenter().x !== 0 ||
        position.getCenter().y !== 0 ||
        position.getCenter().z !== 0
      ) {
        filteredCeilingsDbids.push(element.dbId);
      }
    });

    filteredCeilingsDbids = [...filteredCeilingsDbids, ...floorsThatAreCeleings];

    const filtetedWindowsDbidsWithModel: [] = [];

    windowsDbidsWithModel.forEach((element) => {
      const position = ForgeUtils.getBoundingBox(element.dbId, element.model.id, this.viewer);

      if (
        position.getCenter().x !== 0 ||
        position.getCenter().y !== 0 ||
        position.getCenter().z !== 0
      ) {
        filtetedWindowsDbidsWithModel.push(element);
      }
    });

    doorsDbidsWithModel = filtetedDoorsDbidsWithModel;
    wallsDbids = filtetedWallsDbids;

    const wallProps = await Customizer.getBulkProperties(
      filtetedWallsDbids,
      ['ElementId'],
      this.viewer.model
    );

    const wallsAndFloors = [...this.floorDbids, ...wallsDbids];
    Customizer.floorGroups = await this.getDbdisByGroup(this.viewer.model, this.floorDbids);
    Customizer.wallGroups = await this.getDbdisByGroup(this.viewer.model, wallsDbids);
    Customizer.wallsAndFloorGroups = await this.getDbdisByGroup(this.viewer.model, wallsAndFloors);

    wallProps.forEach((wallProp) => {
      const revitId = wallProp.properties.find((x) => x.displayName === 'ElementId').displayValue;
      if (!revitId.includes('/')) {
        const wallWithRoom = this.getClosestRoom(wallProp.dbId);
        if (wallWithRoom !== undefined) {
          this.wallDbidsWithRoomInfo.push({
            dbId: wallProp.dbId,
            revitId: Number(revitId),
            roomInfo: wallWithRoom
          });
        }
      }
    });

    windowsDbidsWithModel = filtetedWindowsDbidsWithModel;
    ceilingsDbids = filteredCeilingsDbids;

    unFilteredElectricFixturesDbidsWithModelId = filtetedElectricFixturesDbidsWithModelId;
    this.electricFixturesDbidsWithModelId = unFilteredElectricFixturesDbidsWithModelId;

    const selectableDbids = await this.getOuletsAndSwitchsWithCircutNumber(
      unFilteredElectricFixturesDbidsWithModelId
    );

    this.lightsDbidsWithProps.forEach((element) => {
      selectableDbids.electricFixturesDbids.push(element.dbId);
    });

    this.selectableDbids = selectableDbids.electricFixturesDbids;
    this.switchesDbidsWithProps = [...selectableDbids.switches];

    this.doorsDbidsWithModel = doorsDbidsWithModel;
    this.windowsDbidsWithModel = windowsDbidsWithModel;
    this.ceilingsDbids = ceilingsDbids;
    this.wallsDbids = wallsDbids;

    console.log('DONE CACHING MODEL DBIDS');
    const endTime = performance.now();
    const timePassedInSeconds = (endTime - startTime) / 1000;
    console.log(`Time passed: ${timePassedInSeconds} seconds`);

    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.CACHING_COMPLETED, CONTEXTS.MODEL, {
      timePassedInSeconds: timePassedInSeconds
    });
  }

  drawSnapLines(edgePoints: THREE.Vector3[]) {
    const edgePointsClone = JSON.parse(JSON.stringify(edgePoints));
    if (edgePointsClone && this.wallSnapLines === null && edgePointsClone.length > 0) {
      const outletsBBox = [];

      this.electricFixturesDbidsOnHostedWall.forEach((element) => {
        const bbox = ForgeUtils.getBoundingBox(element.dbId, element.model.id, this.viewer);
        outletsBBox.push(bbox.getCenter());
      });

      outletsBBox.forEach((element) => {
        edgePointsClone[0].z = element.z;
        edgePointsClone[1].z = element.z;

        if (this.hostNormal.y !== 0) {
          edgePointsClone[0].y += 0.01 * this.hostNormal.y;
          edgePointsClone[1].y += 0.01 * this.hostNormal.y;
        }

        if (this.hostNormal.x !== 0) {
          edgePointsClone[0].x += 0.01 * this.hostNormal.x;
          edgePointsClone[1].x += 0.01 * this.hostNormal.x;
        }

        const material = new THREE.LineBasicMaterial({ color: 0x000000 });
        const geometry = new THREE.BufferGeometry().setFromPoints([
          edgePointsClone[0],
          edgePointsClone[1]
        ]);

        this.viewer.overlays.addMesh(new THREE.Line(geometry, material), 'snap-scene');
      });
    }
  }

  drawEdgeLinesOnWall(
    edgePoints: THREE.Vector3[],
    hitPoint: THREE.Vector3,
    edgeAxis: EDGE_AXIS,
    faceNormal: THREE.Vector3
  ) {
    if (edgePoints && edgePoints.length > 0) {
      edgePoints[0].z = hitPoint.z;
      edgePoints[1].z = hitPoint.z;

      if (edgeAxis === EDGE_AXIS.X) {
        edgePoints[0].x = hitPoint.x;
      }

      if (edgeAxis === EDGE_AXIS.Y) {
        edgePoints[0].y = hitPoint.y;
      }

      if (this.wallEdgeLimit.length === 0) {
        const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
        const geometry = new THREE.BufferGeometry().setFromPoints([edgePoints[0], edgePoints[1]]);

        this.wallEdgeLimit.push(new THREE.Line(geometry, material));

        this.wallEdgeLimit.forEach((element) => {
          this.viewer.overlays.addMesh(element, 'edge-scene');
        });
      } else {
        const positions = this.wallEdgeLimit[0].geometry.attributes.position.array;
        for (let i = 0; i < positions.length; i++) {
          positions[i] = edgePoints[i];
        }
        this.wallEdgeLines[0].geometry.attributes.position = positions;
        // this.wallEdgeLimit[0].geometry.setFromPoints( [ edgePoints[0] , edgePoints[1] ] );
        this.wallEdgeLines[0].geometry.attributes.position.needsUpdate = true;

        // this.viewer.impl.invalidate(true, true , true);
      }
    }
  }

  clearAllHighLights() {
    const models = this.viewer.getAllModels();
    models.forEach((model) => {
      this.viewer.clearThemingColors(model);
    });
  }

  hoverOnElement(event) {
    if (this.isDraggingElement) return;
    const intersect = ForgeUtils.getHitTestWithFilters(event, this.viewer, this.selectableDbids);

    if (intersect && intersect.dbId && this.currentHoveredDbid !== intersect.dbId) {
      this.clearAllHighLights();
      const color = new THREE.Vector4(1, 0, 0, 0.5);
      this.viewer.setThemingColor(intersect.dbId, color, intersect.model, true);
      this.currentHoveredDbid = intersect.dbId;
    }

    if (intersect === null || intersect.dbId === null) {
      this.clearAllHighLights();
      this.currentHoveredDbid = -1;
    }
  }

  getSideWallsIntersection = (
    raycastpointwithoffset: THREE.Vector3,
    intersection: Autodesk.Viewing.Private.HitTestResult
  ): {
    leftEdgePosition: THREE.Vector3;
    rightEdgePosition: THREE.Vector3;
  } => {
    let leftEdgePosition = null;
    let rightEdgePosition = null;
    // Check if theres wall on right side than the new wall edge position is the wall intersection point
    const raycaster = new THREE.Raycaster();
    raycaster.set(
      raycastpointwithoffset,
      new THREE.Vector3(-intersection.face.normal.y, intersection.face.normal.x, 0)
    );

    const intersectsRightSide = this.viewer.impl.rayIntersect(
      raycaster.ray,
      false,
      this.wallsDbids.filter((id) => id !== this.hostInfo.dbId)
    );

    if (intersectsRightSide) {
      rightEdgePosition = intersectsRightSide.point.clone();
    }

    // Check if theres wall on left side than the new wall edge position is the wall intersection point
    raycaster.set(
      raycastpointwithoffset,
      new THREE.Vector3(intersection.face.normal.y, -intersection.face.normal.x, 0)
    );

    const intersectsLeftSide = this.viewer.impl.rayIntersect(
      raycaster.ray,
      false,
      this.wallsDbids.filter((id) => id !== this.hostInfo.dbId)
    );

    if (intersectsLeftSide) {
      leftEdgePosition = intersectsLeftSide.point.clone();
    }

    return { leftEdgePosition, rightEdgePosition };
  };

  getEdgePoint = (
    intersection: Autodesk.Viewing.Private.HitTestResult,
    wallTopLeftRightBottomEdges: Vector3[]
  ): Three.Vector3[] => {
    const offset = 0.01;
    const rayCastWithOffsetToCheckIfTheresSideWall = new THREE.Vector3(
      intersection.point.x + offset * intersection.face.normal.x,
      intersection.point.y + offset * intersection.face.normal.y,
      intersection.point.z + offset * intersection.face.normal.z
    );

    let leftEdgePosition = new THREE.Vector3(
      wallTopLeftRightBottomEdges[0].x,
      wallTopLeftRightBottomEdges[0].y,
      intersection.point.z
    );

    let rightEdgePosition = new THREE.Vector3(
      wallTopLeftRightBottomEdges[1].x,
      wallTopLeftRightBottomEdges[1].y,
      intersection.point.z
    );

    let topEdgePosition = new THREE.Vector3(
      intersection.point.x,
      intersection.point.y,
      wallTopLeftRightBottomEdges[0].z
    );

    let bottomEdgePosition = new THREE.Vector3(
      intersection.point.x,
      intersection.point.y,
      wallTopLeftRightBottomEdges[1].z
    );

    // Right Ray Cast
    const raycaster = new THREE.Raycaster();
    if (intersection.face.normal.z === 0) {
      raycaster.set(
        rayCastWithOffsetToCheckIfTheresSideWall,
        new THREE.Vector3(
          -intersection.face.normal.y,
          intersection.face.normal.x + intersection.face.normal.z,
          0
        )
      );
    } else {
      raycaster.set(rayCastWithOffsetToCheckIfTheresSideWall, new THREE.Vector3(1, 0, 0));
    }

    const intersectsRightSide = this.viewer.impl.rayIntersect(
      raycaster.ray,
      false,
      this.wallsDbids.filter((id) => id !== this.hostInfo.dbId)
    );

    if (intersectsRightSide) {
      const distanceToRightEdge = intersection.point.distanceTo(rightEdgePosition);

      if (distanceToRightEdge > intersectsRightSide.distance) {
        // console.log('take right intersection point ' + intersectsRightSide.dbId);
        rightEdgePosition = intersectsRightSide.point.clone();
        // console.log(intersectsRightSide.point);
      } else {
        // console.log('leave right edge point');
      }
    }

    // Left Ray Cast
    if (intersection.face.normal.z === 0) {
      raycaster.set(
        rayCastWithOffsetToCheckIfTheresSideWall,
        new THREE.Vector3(intersection.face.normal.y, -intersection.face.normal.x, 0)
      );
    } else {
      raycaster.set(rayCastWithOffsetToCheckIfTheresSideWall, new THREE.Vector3(-1, 0, 0));
    }

    const intersectsLeftSide = this.viewer.impl.rayIntersect(
      raycaster.ray,
      false,
      this.wallsDbids.filter((id) => id !== this.hostInfo.dbId)
    );

    if (intersectsLeftSide) {
      const distanceToLeftPosition = intersection.point.distanceTo(leftEdgePosition);

      if (distanceToLeftPosition > intersectsLeftSide.distance) {
        // console.log('take left intersection point : ' + intersectsLeftSide.dbId);
        leftEdgePosition = intersectsLeftSide.point.clone();
        // console.log(intersectsLeftSide.point);
      } else {
        // console.log('take left edge point');
      }
    }

    // Top Ray Cast
    if (intersection.face.normal.z === 0) {
      raycaster.set(rayCastWithOffsetToCheckIfTheresSideWall, new THREE.Vector3(0, 0, 1));
    } else {
      raycaster.set(rayCastWithOffsetToCheckIfTheresSideWall, new THREE.Vector3(0, -1, 0));
    }

    const intersectsTopSide = this.viewer.impl.rayIntersect(
      raycaster.ray,
      false,
      this.ceilingsDbids.concat(this.wallsDbids).filter((id) => id !== this.hostInfo.dbId)
    );

    if (intersectsTopSide) {
      const distanceToTopPosition = intersection.point.distanceTo(topEdgePosition);

      if (distanceToTopPosition > intersectsTopSide.distance || intersection.face.normal.z !== 0) {
        // console.log('take left intersection point : ' + intersectsLeftSide.dbId);
        topEdgePosition = intersectsTopSide.point.clone();
      } else {
        // console.log('take left edge point');
      }
    }

    // console.log(intersection.face.normal);
    // Bottom Ray Cast
    let floorsDbis = this.floorDbids;
    if (intersection.face.normal.z === 0) {
      raycaster.set(rayCastWithOffsetToCheckIfTheresSideWall, new THREE.Vector3(0, 0, -1));
    } else {
      raycaster.set(rayCastWithOffsetToCheckIfTheresSideWall, new THREE.Vector3(0, 1, 0));
      floorsDbis = this.wallsDbids.filter((id) => id !== this.hostInfo.dbId);
    }

    const intersectsBottomSide = this.viewer.impl.rayIntersect(raycaster.ray, false, floorsDbis);

    if (intersectsBottomSide) {
      const distanceToBottomPosition = intersection.point.distanceTo(bottomEdgePosition);

      if (
        distanceToBottomPosition > intersectsBottomSide.distance ||
        intersection.face.normal.z !== 0
      ) {
        // console.log('take left intersection point : ' + intersectsLeftSide.dbId);
        bottomEdgePosition = intersectsBottomSide.point.clone();
      } else {
        // console.log('take left edge point');
      }
    }

    this.isDrawn = true;
    return [leftEdgePosition, rightEdgePosition, topEdgePosition, bottomEdgePosition];
  };

  isDrawn: bool = false;

  drawSphereInbBoxEdges = (bbox: Vector3[]) => {
    const sphereGeometry = new THREE.SphereGeometry(1, 32, 32);
    const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

    const sphereGeometry2 = new THREE.SphereGeometry(1, 32, 32);
    const sphereMaterial2 = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    const sphere2 = new THREE.Mesh(sphereGeometry2, sphereMaterial2);

    sphere.position.set(bbox[0].x, bbox[0].y, bbox[0].z);
    sphere2.position.set(bbox[1].x, bbox[1].y, bbox[1].z);

    this.viewer.overlays.addMesh(sphere, 'edge-scene');
    this.viewer.overlays.addMesh(sphere2, 'edge-scene');
  };

  didCacheDraggingData = false;

  wallTopLeftRightBottomEdges;

  // Get the 3D point from the mouse position with filter
  dragElement(event) {
    if (this.isDraggingElement === false) return;

    const intersection = ForgeUtils.getHitTestWithFilters(
      event,
      this.viewer,
      this.intersecteDbids.concat(this.ceilingsDbids)
    );
    if (
      !intersection ||
      !this.hostWalls.includes(intersection.dbId) ||
      (this.draggingModelCategory === 'Revit Ceilings' &&
        this.wallsDbids.includes(intersection.dbId))
    )
      return;

    const elementPosition = new THREE.Vector3(
      intersection.point.x,
      intersection.point.y,
      intersection.point.z
    );

    let angle = 1;
    if (intersection.face) angle = ForgeUtils.getAngleFromFace(intersection.face);

    // we only drag the element if the angle is the same as the host wall
    if (angle !== this.hostInfo.angle) return;

    if (intersection) {
      console.log(intersection.dbId);
      const limitDistanceByElement = getDistaceLimit(this.movingElementInfo.name);

      if (!this.didCacheDraggingData) {
        this.wallTopLeftRightBottomEdges = ForgeUtils.getLeftTopRightBottomBBoxPositions(
          this.hostBbox,
          intersection
        );

        this.didCacheDraggingData = true;
      }
      if (
        this.wallSnapLines === null &&
        this.draggingModelCategory === 'Revit Electrical Fixtures'
      ) {
        this.drawSnapLines(this.wallTopLeftRightBottomEdges);
      }
      // side edge detection using x or y depending on the wall normal
      const edgeAxis: EDGE_AXIS = intersection.face.normal.x === 0 ? EDGE_AXIS.X : EDGE_AXIS.Y;

      const positions = this.getEdgePoint(intersection, this.wallTopLeftRightBottomEdges);
      // this.drawSphereInbBoxEdges(wallTopLeftRightBottomEdges);

      const leftEdgePosition = positions[0];
      const rightEdgePosition = positions[1];
      const topEdgePosition = positions[2];
      const bottomEdgePosition = positions[3];

      // // // Too Close to wall edge positions
      const isTooCloseToLeftEdge = ForgeUtils.isPointTooCloseToEdge(
        intersection.point,
        leftEdgePosition,
        edgeAxis,
        limitDistanceByElement.WALLEDGE
      );

      const isTooCloseToRightEdge = ForgeUtils.isPointTooCloseToEdge(
        intersection.point,
        rightEdgePosition,
        edgeAxis,
        limitDistanceByElement.WALLEDGE
      );

      const isTooCloseToBottomEdge = ForgeUtils.isPointTooCloseToEdge(
        intersection.point,
        bottomEdgePosition,
        this.hostNormal.z !== 0 ? EDGE_AXIS.Y : EDGE_AXIS.Z,
        limitDistanceByElement.WALLEDGE
      );

      const isTooCloseToTopEdge = ForgeUtils.isPointTooCloseToEdge(
        intersection.point,
        topEdgePosition,
        this.hostNormal.z !== 0 ? EDGE_AXIS.Y : EDGE_AXIS.Z,
        this.hostNormal.z !== 0 ? limitDistanceByElement.WALLEDGE : limitDistanceByElement.CEILINGS
      );

      const isTooCloseToFloor = ForgeUtils.isPointTooCloseToEdge(
        intersection.point,
        bottomEdgePosition,
        this.hostNormal.z !== 0 ? EDGE_AXIS.Y : EDGE_AXIS.Z,
        limitDistanceByElement.FLOORS
      );

      // // If its too close to left position than disable the drag
      if (isTooCloseToLeftEdge) {
        if (intersection.face.normal.x === 0 && intersection.face.normal.z === 0)
          elementPosition.x =
            leftEdgePosition.x - limitDistanceByElement.WALLEDGE * intersection.face.normal.y;
        if (intersection.face.normal.y === 0 && intersection.face.normal.z === 0)
          elementPosition.y =
            leftEdgePosition.y + limitDistanceByElement.WALLEDGE * intersection.face.normal.x;
        if (intersection.face.normal.z !== 0)
          elementPosition.x = leftEdgePosition.x + limitDistanceByElement.WALLEDGE;
        console.log('too close too left edge');
      }

      // // If its too close to right position than disable the drag
      if (isTooCloseToRightEdge) {
        if (intersection.face.normal.x === 0 && intersection.face.normal.z === 0)
          elementPosition.x =
            rightEdgePosition.x + limitDistanceByElement.WALLEDGE * intersection.face.normal.y;
        if (intersection.face.normal.y === 0 && intersection.face.normal.z === 0)
          elementPosition.y =
            rightEdgePosition.y - limitDistanceByElement.WALLEDGE * intersection.face.normal.x;
        if (intersection.face.normal.z !== 0)
          elementPosition.x = rightEdgePosition.x - limitDistanceByElement.WALLEDGE;
        console.log('too close too right edge');
      }

      if (isTooCloseToBottomEdge && this.draggingModelCategory === 'Revit Lighting Fixtures') {
        if (intersection.face.normal.z !== 0)
          elementPosition.y = bottomEdgePosition.y - limitDistanceByElement.WALLEDGE;
        console.log('too close too bottom edge');
      }

      if (isTooCloseToTopEdge) {
        if (intersection.face.normal.z === 0) {
          elementPosition.z = topEdgePosition.z - limitDistanceByElement.CEILINGS;
        }
        if (intersection.face.normal.z !== 0)
          elementPosition.y = topEdgePosition.y + limitDistanceByElement.WALLEDGE;
        console.log('too close too top edge');
      }

      this.electricFixturesBBoxOnHostedWall.forEach((item) => {
        const diffrence = ForgeUtils.calcOffsetCenterBased(item, intersection.point, angle);
        if (Math.abs(diffrence.z) < 0.3) {
          elementPosition.z = item.z;
        }
      });

      if (isTooCloseToFloor && intersection.face.normal.z === 0) {
        elementPosition.z = bottomEdgePosition.z + limitDistanceByElement.FLOORS;
        console.log('too close too floor edge');
      }

      const tooCloseToDoors = ForgeUtils.isToCloseToDbids(
        elementPosition,
        this.doorsDbidsWithModel,
        limitDistanceByElement.DOORS,
        this.viewer
      );

      const tooCloseToWindows = ForgeUtils.isToCloseToDbids(
        elementPosition,
        this.windowsDbidsWithModel,
        limitDistanceByElement.WINDOWS,
        this.viewer
      );

      const tooCloseToOutletsOrLights = ForgeUtils.isToCloseToDbids(
        elementPosition,
        this.electricFixturesDbidsOnHostedWall,
        limitDistanceByElement.OULETS,
        this.viewer,
        true
      );

      if (tooCloseToOutletsOrLights || tooCloseToDoors || tooCloseToWindows) {
        return;
      }

      const extension = this.viewer.getExtension('TweaksxMarkUp');

      let rightEdgePoint = new THREE.Vector3(
        rightEdgePosition.x,
        rightEdgePosition.y,
        elementPosition.z
      );

      let leftEdgePoint = new THREE.Vector3(
        leftEdgePosition.x,
        leftEdgePosition.y,
        elementPosition.z
      );

      let bottomEdgePoint = new THREE.Vector3(
        elementPosition.x,
        elementPosition.y,
        bottomEdgePosition.z
      );

      let topEdgePoint = new THREE.Vector3(elementPosition.x, elementPosition.y, topEdgePosition.z);

      if (this.hostNormal.z !== 0) {
        leftEdgePoint = positions[0];
        rightEdgePoint = positions[1];
        bottomEdgePoint = positions[3];
        topEdgePoint = positions[2];

        if (isTooCloseToBottomEdge || isTooCloseToTopEdge) {
          leftEdgePoint.y = elementPosition.y;
          rightEdgePoint.y = elementPosition.y;
        }

        if (isTooCloseToLeftEdge || isTooCloseToRightEdge) {
          bottomEdgePoint.x = elementPosition.x;
          topEdgePoint.x = elementPosition.x;
        }
      }

      const distToRightEdge = elementPosition.distanceTo(rightEdgePoint) / 3.2808399;
      const distToLeftEdge = elementPosition.distanceTo(leftEdgePoint) / 3.2808399;
      const distToTopEdge = elementPosition.distanceTo(topEdgePoint) / 3.2808399;
      const distToBottomEdge = elementPosition.distanceTo(bottomEdgePoint) / 3.2808399;

      if (extension.createdLinesAndLabels.length === 0) {
        const distanceLines: LineAndLabel[] = [
          {
            lineEdge1: elementPosition,
            lineEdge2: rightEdgePoint,
            label: 'cm ' + (distToRightEdge * 100).toFixed(1).toString(),
            id: 'linea',
            direction: 'horizontal'
          },
          {
            lineEdge1: elementPosition,
            lineEdge2: leftEdgePoint,
            label: 'cm ' + (distToLeftEdge * 100).toFixed(1).toString(),
            id: 'lineb',
            direction: 'horizontal'
          },
          {
            lineEdge1: elementPosition,
            lineEdge2: bottomEdgePoint,
            label: 'cm ' + (distToBottomEdge * 100).toFixed(1).toString(),
            id: 'linec',
            direction: 'horizontal'
          },
          {
            lineEdge1: elementPosition,
            lineEdge2: topEdgePoint,
            label: 'cm ' + (distToTopEdge * 100).toFixed(1).toString(),
            id: 'lined',
            direction: 'horizontal'
          }
        ];

        extension.addLinesAndLabel(distanceLines);
      } else {
        const distanceLines: LineAndLabel[] = [
          {
            lineEdge1: elementPosition,
            lineEdge2: leftEdgePoint,
            label:
              // distToRightEdge < distToLeftEdge
              'cm ' + (distToLeftEdge * 100).toFixed(1).toString(),
            // : distToLeftEdge.toFixed(3).toString(),
            id: 'linea'
          },
          {
            lineEdge1: elementPosition,
            lineEdge2: rightEdgePoint,
            label:
              // distToRightEdge < distToLeftEdge
              'cm ' + (distToRightEdge * 100).toFixed(1).toString(),
            // : distToLeftEdge.toFixed(3).toString(),
            id: 'lineb'
          },
          {
            lineEdge1: elementPosition,
            lineEdge2: bottomEdgePoint,
            label:
              this.draggingModelCategory === 'Revit Lighting Fixtures'
                ? 'cm ' + (distToBottomEdge * 100).toFixed(1).toString()
                : 'cm ' + (distToBottomEdge * 100).toFixed(1).toString(),
            id: 'linec'
          },
          {
            lineEdge1: elementPosition,
            lineEdge2: topEdgePoint,
            label: 'cm ' + (distToTopEdge * 100).toFixed(1).toString(),
            id: 'lined'
          }
        ];

        extension.updateLinesAndLabels(distanceLines, this.hostNormal);
      }

      const modelFinalPosition = elementPosition.clone();
      if (this.hostNormal.z !== 0) {
        const elementCenter = this.draggingModelbBox.getCenter();
        const aoffset = elementCenter.z - elementPosition.z;
        modelFinalPosition.z = elementPosition.z + aoffset;
      }

      ForgeUtils.moveModelToHitPoint(
        this.draggingDbId,
        this.draggingModelId,
        angle,
        this.viewer,
        modelFinalPosition
      );
      this.intersectionPoint = elementPosition;
    }
  }

  intersectObjects(clientX, clientY, objects, camera, recursive) {
    const _pointerVector = new THREE.Vector3();
    const _pointerDir = new THREE.Vector3();
    const _ray = new THREE.Raycaster();

    // Convert client to viewport coords (in [-1,1]^2)
    const x = (clientX / camera.clientWidth) * 2 - 1;
    const y = -(clientY / camera.clientHeight) * 2 + 1; // y-direction flips between canvas and viewport coords

    if (camera.isPerspective) {
      _pointerVector.set(x, y, 0.5);
      _pointerVector.unproject(camera);
      _ray.set(camera.position, _pointerVector.sub(camera.position).normalize());
    } else {
      _pointerVector.set(x, y, -1);
      _pointerVector.unproject(camera);
      _pointerDir.set(0, 0, -1);
      _ray.set(_pointerVector, _pointerDir.transformDirection(camera.matrixWorld));
    }

    const intersections = _ray.intersectObjects(objects, recursive);
    return intersections[0] ? intersections[0] : null;
  }

  unload() {
    console.info('Tweaksx Customizer extension has been unloaded');
    return true; // This returning flag represents the unload success
  }

  static register() {
    Autodesk.Viewing.theExtensionManager.registerExtension(extensionName, Customizer);
    return extensionName;
  }

  onMouseMove(e) {
    const viewer = e.target.viewer;
    if (viewer) {
      const ext = viewer.getExtension(extensionName);
      ext.pointer = e;
    }
  }

  getRoomWalls = async (groupName: string): { revitIds: number[]; dbIds: number[] } => {
    const walls = Customizer.wallsGroups.get(groupName);
    console.log(walls);
    if (walls !== undefined) {
      return {
        revitIds: walls.map((x) => x.revitId),
        dbIds: walls.map((x) => x.dbId)
      };
    } else {
      return {
        revitIds: [],
        dbIds: []
      };
    }
  };

  static getPropertiesAsync(dbId, model: Autodesk.Viewing.Model) {
    return new Promise((resolve, reject) => {
      model.getProperties(
        dbId,
        (result) => {
          const props = result;
          if (typeof model.id === 'number') {
            props.properties = result.properties;
          } else {
            if (model.revitProperites?.length > 0 && model.revitProperites[0] !== undefined) {
              props.properties.push({
                displayName: model.revitProperites[0].name,
                displayValue: model.revitProperites[0].value,
                displayCategory: model.revitProperites[0].category
              });
            }
          }

          resolve(props);
        },
        (error) => reject(error)
      );
    });
  }

  static getBulkProperties(
    dbIds: number[],
    filterCatgories: string,
    model: Autodesk.Viewing.Model
  ) {
    return new Promise((resolve, reject) => {
      model.getBulkProperties(
        dbIds,
        {
          propFilter: filterCatgories
        },
        (result) => {
          const props = result;

          if (typeof model.id === 'number') {
            props.properties = result.properties;
          } else {
            if (model.revitProperites?.length > 0 && model.revitProperites[0] !== undefined) {
              props.properties.push({
                displayName: model.revitProperites[0].name,
                displayValue: model.revitProperites[0].value,
                displayCategory: model.revitProperites[0].category
              });
            }
          }

          resolve(props);
        },
        (error) => reject(error)
      );
    });
  }

  async getElementInfo(
    dbIdArray: [],
    viewer: Autodesk.Viewing.GuiViewer3D,
    model: Autodesk.Viewing.Model
  ): IElementInfo[] {
    const selectedElements: IElementInfo[] = [];
    if (dbIdArray) {
      for (let i = 0; i < dbIdArray.length; i++) {
        const elementInfo: IElementInfo = {};
        const dbId = dbIdArray[i];

        elementInfo.dbId = dbId;
        elementInfo.modelId = model.id;
        const propData = await Customizer.getPropertiesAsync(dbId, model);

        console.log(dbId);
        console.log(propData);

        elementInfo.name = propData.name;

        let revitId = propData.properties.find((x) => x.displayName === 'ElementId').displayValue;
        if (revitId.includes('/')) {
          revitId = propData.properties
            .find((x) => x.displayName === 'ElementId')
            .displayValue.split('/')[1];
        }
        elementInfo.revitId = Number(revitId);

        elementInfo.category = propData.properties.find(
          (x) => x.displayName === 'Category'
        ).displayValue;

        elementInfo.parentDbid = propData.properties.find(
          (x) => x.displayName === 'parent'
        ).displayValue;

        elementInfo.subFamily = propData.properties.find(
          (x) => x.displayName === 'Sub Family'
        )?.displayValue;

        const categoryId = propData.properties.find(
          (x) => x.displayName === 'CategoryId'
        )?.displayValue;

        // OST_Floors,-2000032, Revit Walls -2000011
        if (categoryId === -2000032 || categoryId === -2000011) {
          // Selected element is a Revit Floor or a Revit Wall
          const elementGroupId = propData.properties.find(
            (x) => x.displayName === 'Group'
          )?.displayValue;
          if (elementGroupId) {
            const groupProps = await Customizer.getPropertiesAsync(elementGroupId, model);
            elementInfo.area = groupProps.properties.find(
              (x) => x.displayName === 'Area_Tweaks'
            ).displayValue;
          }
        }

        // OST_Floors,-2000032
        if (categoryId === -2000032) {
          const elementTypeProp = propData.properties.find(
            (x) => x.displayName.toLowerCase().replace(/\s+/g, '') === 'floortype'
          )?.displayValue;

          if (elementTypeProp && elementTypeProp.toString().toLowerCase().includes('wet')) {
            elementInfo.isWet = true;
          }
        }

        if (categoryId === -2000011) {
          elementInfo.isWet = true;
        }

        const ext = viewer.getExtension(extensionName);

        const globalOffset = viewer.model.getData().globalOffset;
        const offsetVec = new THREE.Vector3(globalOffset.x, globalOffset.y, globalOffset.z);
        const hitPointWithOffset = ext.hitPoint.clone().add(offsetVec);

        elementInfo.hitPoint = hitPointWithOffset;

        const pointData = viewer.clientToWorld(ext.pointer.canvasX, ext.pointer.canvasY, true);
        elementInfo.angle = 0;

        if (pointData) {
          // TODO: We are assuming walls cannot be diagonal, could be improved by using Math.atan2 probably (JEPS).
          const x = Math.round(pointData.face.normal.x);
          const y = Math.round(pointData.face.normal.y);

          if (x === 0 && y === -1) {
            elementInfo.angle = 0;
          } else if (x === 0 && y === 1) {
            elementInfo.angle = 2 * Math.PI * 0.5;
          } else if (x === 1 && y === 0) {
            elementInfo.angle = 2 * Math.PI * 0.25;
          } else if (x === -1 && y === 0) {
            elementInfo.angle = 2 * Math.PI * 0.75;
          }
        }

        if (ext.rooms) {
          let closestRoom = ext.rooms[0] as IRoomInfo;
          let roomDistance = ext.rooms[0].boundingBox.distanceToPoint(ext.hitPoint);

          for (let i = 1; i < ext.rooms.length; i++) {
            const distance = ext.rooms[i].boundingBox.distanceToPoint(ext.hitPoint);
            if (distance < roomDistance) {
              closestRoom = ext.rooms[i];
              roomDistance = distance;
            }
          }

          elementInfo.closestRoom = closestRoom;
          elementInfo.roomDistance = roomDistance;
        }

        // Check whether the family type is pendant or not.
        // TODO: Not sure if this is a solution for all the family types universe but works great with the test models we currently use.
        const hasHangHeight = propData.properties.some((x) => x.displayName === 'Hang Height');
        const hasCeilingAsWorkPlane = propData.properties
          .find((x) => x.displayName === 'Work Plane')
          ?.displayValue.toLowerCase()
          .includes('ceiling');
        elementInfo.isPendant = hasHangHeight || hasCeilingAsWorkPlane;

        selectedElements.push(elementInfo);
      }
    }
    console.log(selectedElements);

    return selectedElements;
  }

  async getCircutNumber(lightDbid: number, lightModel: Autodesk.Viewing.Model) {
    const propData = await Customizer.getPropertiesAsync(lightDbid, lightModel);

    const circutNumber = propData.properties.find(
      (x) => x.displayName === 'מספר מעגל'
    ).displayValue;
    return circutNumber;
  }

  async getSwitchesConnectedToLight(
    lightDbid: number,
    lightModel: Autodesk.Viewing.Model
  ): IElectricCircutConnectedElementWithProps[] {
    const circut = await this.getCircutNumber(lightDbid, lightModel);

    let circutNumber;
    let connectedSwitched;
    let switches;

    if (circut.includes('/')) {
      circutNumber = circut.split('/')[0] as string;
      connectedSwitched = circut.split('/')[1] as string;
      const switchesNumbers = connectedSwitched.split(',');
      switches = switchesNumbers.map((x) => circutNumber + '/' + x);
    } else {
      circutNumber = circut;
      connectedSwitched = circut;
      switches = [circut];
    }

    const findSwitchesWitchSameCircutId = this.switchesDbidsWithProps.filter(
      (x) => x.circutNumber === circutNumber
    );

    const connectedSwitches: IElectricCircutConnectedElementWithProps[] = [];

    switches.forEach((switchNum) => {
      findSwitchesWitchSameCircutId.forEach((switchWithProps) => {
        switchWithProps.ConnectedElemtsIds.forEach((switchedId) => {
          if (switchedId === switchNum) {
            connectedSwitches.push(switchWithProps);
          }
        });
      });
    });

    return connectedSwitches;
  }

  async getLightsConnectedToSwitch(
    switchDbid: number,
    switchModel: Autodesk.Viewing.Model
  ): IElectricCircutConnectedElementWithProps[] {
    const circut = await this.getCircutNumber(switchDbid, switchModel);

    let circutNumber;
    let connectedLights;
    let lights;

    if (circut.includes('/')) {
      circutNumber = circut.split('/')[0] as string;
      connectedLights = circut.split('/')[1] as string;
      const lightsNumbers = connectedLights.split(',');
      lights = lightsNumbers.map((x) => circutNumber + '/' + x);
    } else {
      circutNumber = circut;
      connectedLights = circut;
      lights = connectedLights.split(',');
    }

    const findLightConnctedToSwitch = this.lightsDbidsWithProps.filter(
      (x) => x.circutNumber === circutNumber
    );

    const connctedLights: IElectricCircutConnectedElementWithProps[] = [];

    lights.forEach((lightNum) => {
      findLightConnctedToSwitch.forEach((lightWithProps) => {
        lightWithProps.ConnectedElemtsIds.forEach((lightId) => {
          if (lightId === lightNum) {
            connctedLights.push(lightWithProps);
          }
        });
      });
    });

    return connctedLights;
  }

  isFloor(dbid) {
    return this.floorDbids.includes(dbid);
  }

  async onSelectionChanged(e, isDraggingElement: boolean) {
    if (isDraggingElement) return;
    let isValidSelection = false;
    let parentInfo;
    const extension = this.viewer.getExtension(extensionName);

    if (!this.viewer.overlays.hasScene('connected-lines-scene')) {
      this.viewer.overlays.addScene('connected-lines-scene');
    }

    const viewer = e.target;
    const dbId = e.selections[0]?.dbIdArray;
    const model = e.selections[0]?.model;
    this.currentHitPoint = e.selections[0]?.hitPoint;

    const selectedElements = await this.getElementInfo(dbId, viewer, model);
    if (selectedElements.length === 0 || selectedElements[0].category === undefined) {
      console.log('selectedElements is undefined');
      return;
    }

    extension._showToolTip(selectedElements[0].name);
    console.log(selectedElements[0]);

    const tweaksxCustomizerExt = viewer.getExtension(extensionName);

    this.draggingModelCategory = selectedElements[0]?.category;

    if (selectedElements === undefined) {
      console.log('selectedElements is undefined');
      return;
    }

    if (
      (selectedElements[0].category === 'Revit Electrical Fixtures' &&
        !ForgeUtils.isOutlet(selectedElements[0].name)) ||
      ((selectedElements[0].category === 'Revit Lighting Fixtures' ||
        selectedElements[0].category === 'Revit Lighting Devices') &&
        !ForgeUtils.isSwitch(selectedElements[0].name, selectedElements[0].category) &&
        !ForgeUtils.isLight(selectedElements[0].name, selectedElements[0].category))
    ) {
      const parentName = ForgeUtils.getName(
        selectedElements[0].parentDbid,
        selectedElements[0].modelId,
        this.viewer
      );

      console.log(selectedElements[0].parentDbid);
      console.log(selectedElements[0].modelId);
      console.log(parentName);

      if (ForgeUtils.isOutlet(parentName) || ForgeUtils.isSwitch(parentName)) {
        const model = ForgeUtils.getModel(selectedElements[0].modelId, this.viewer);

        const parentInfo = await this.getElementInfo(
          [selectedElements[0].parentDbid],
          viewer,
          model
        );
        selectedElements[0] = parentInfo[0];
      }
    }

    // get properties
    if (
      ForgeUtils.isLight(selectedElements[0].name, selectedElements[0].category) &&
      this.selectableDbids.includes(selectedElements[0].dbId)
    ) {
      const switches = await this.getSwitchesConnectedToLight(selectedElements[0].dbId, model);
      selectedElements[0].circutConnectedElements = switches;
      isValidSelection = true;
    }

    if (
      ForgeUtils.isSwitch(selectedElements[0].name, selectedElements[0].category) &&
      this.selectableDbids.includes(selectedElements[0].dbId)
    ) {
      const lights = await this.getLightsConnectedToSwitch(selectedElements[0].dbId, model);
      selectedElements[0].circutConnectedElements = lights;
      isValidSelection = true;
    }

    if (
      ForgeUtils.isOutlet(selectedElements[0].name, selectedElements[0].category) &&
      this.selectableDbids.includes(selectedElements[0].dbId)
    ) {
      isValidSelection = true;
    }

    if (selectedElements[0].category === 'Revit Ceilings') {
      isValidSelection = true;
    }

    if (
      selectedElements[0].name.toLowerCase().includes('wall') ||
      this.isFloor(selectedElements[0].dbId)
    ) {
      isValidSelection = true;
    }

    if (isValidSelection === false) {
      console.log('Invalid Selection');
      return;
    }

    if (this.selectableDbids.includes(selectedElements[0].dbId)) {
      const biService = BIService.getInstance();
      biService.logEvent(EVENT_TYPES.SELECT_ELEMENT, CONTEXTS.MODEL, {
        name: selectedElements[0].name,
        category: selectedElements[0].category,
        dbId: selectedElements[0].dbId
      });
    }

    this.intersecteDbids = [];
    if (selectedElements[0].category === 'Revit Electrical Fixtures')
      this.intersecteDbids = this.wallsDbids;
    if (selectedElements[0].category === 'Revit Lighting Fixtures')
      this.intersecteDbids = this.ceilingsDbids.concat(this.wallsDbids);

    let hostHit = null;
    if (this.intersecteDbids.length > 0) {
      hostHit = ForgeUtils.getHitTestWithFilters(
        tweaksxCustomizerExt.pointer,
        this.viewer,
        this.intersecteDbids
      );
    }

    let hostCategory = '';

    if (selectedElements[0].category === 'Revit Lighting Fixtures') {
      hostCategory = 'Revit Ceilings';
    }

    if (selectedElements[0].category === 'Revit Electrical Fixtures') {
      hostCategory = 'Revit Walls';
    }

    let wallInfo = null;

    if (hostHit !== null) {
      this.selectedElementHostHitPoint = hostHit.point;
      wallInfo = await this.getElementInfo([hostHit.dbId], viewer, this.viewer.model);
      const wallAngle = ForgeUtils.getAngleFromFace(hostHit.face);
      if (hostHit.face === null) {
        console.log('No Face Found');
        return;
      }

      const nearBywalls = await ForgeUtils.getNearByWalls(
        hostHit.dbId,
        this.viewer.model.id,
        this.wallsDbids,
        hostHit.face.normal,
        this.viewer,
        100
      );

      this.hostNormal = hostHit.face.normal;

      nearBywalls.push(hostHit.dbId);
      this.hostWalls = nearBywalls;

      this.selectedElementHostAngle = wallAngle;
      if (selectedElements[0].category === 'Revit Lighting Fixtures') {
        const bBox = this.getBoundingBox(selectedElements[0].dbId, selectedElements[0].modelId);

        const tolerance = 0.01 * 3.2808399; // 5 cm
        const host = (await this.getMostIntersectedCeiling(
          'Revit Lighting Fixtures',
          bBox,
          tolerance
        )) as IIntersectElement;

        if (host) {
          this.hostNormal = new THREE.Vector3(0, 0, 1);
          this.selectedElementHostAngle = 1;
          this.hostWalls = [host.dbId];
        } else {
          console.log('No Host Ceiling Found');
          return;
        }
      }

      wallInfo[0].angle = wallAngle;
    }

    if (wallInfo === null && this.isDraggingElement === true) {
      console.log('Wall not selected while placement mode is active.');
      return;
    }

    this.selectionEvent = e;

    if (
      selectedElements.length > 0 &&
      selectedElements[0].category === 'Revit Electrical Fixtures' &&
      wallInfo !== null
    ) {
      ForgeUtils.faceCameraToWall(wallInfo[0], this.viewer);
    }

    extension._selectionChangedCallback(selectedElements, e);
  }

  getClosestRoomByLocation(location) {
    if (!this.rooms) return null;

    let closestRoom = this.rooms[0];
    let roomDistance = this.rooms[0].boundingBox.distanceToPoint(location);

    for (let i = 1; i < this.rooms.length; i++) {
      const distance = this.rooms[i].boundingBox.distanceToPoint(location);
      if (distance < roomDistance) {
        closestRoom = this.rooms[i];
        roomDistance = distance;
      }
    }

    return { closestRoom: closestRoom, roomDistance: roomDistance };
  }

  getClosestFloor(room: IRoomInfo): number {
    let closestIntersection = null;
    let largestIntersectRatio = -Infinity;

    const roomArea =
      (room.boundingBox.max.x - room.boundingBox.min.x) *
      (room.boundingBox.max.y - room.boundingBox.min.y);

    for (let i = 0; i < this.floorDbids.length; i++) {
      const floorBBox = this.getBoundingBox(this.floorDbids[i], this.viewer.model.id);

      const intersectArea = this.calculateIntersectArea(room.boundingBox, floorBBox);
      const intersectRatio = intersectArea / roomArea;

      if (intersectRatio > largestIntersectRatio) {
        closestIntersection = this.floorDbids[i];
        largestIntersectRatio = intersectRatio;
      }
    }

    return closestIntersection;
  }

  // Helper function to calculate 2D intersection area between two bounding boxes
  calculateIntersectArea(bbox1: THREE.Box3, bbox2: THREE.Box3): number {
    const x_overlap = Math.max(
      0,
      Math.min(bbox1.max.x, bbox2.max.x) - Math.max(bbox1.min.x, bbox2.min.x)
    );
    const y_overlap = Math.max(
      0,
      Math.min(bbox1.max.y, bbox2.max.y) - Math.max(bbox1.min.y, bbox2.min.y)
    );

    return x_overlap * y_overlap;
  }

  loadModelInPosition(
    modelUrn: string,
    position: THREE.Vector3,
    angle: number,
    revitProperites?: IRevitProperty[],
    modelId = null
  ) {
    return new Promise((resolve, reject) => {
      const urn = 'urn:' + modelUrn;

      const globalOffset = ForgeUtils.getGlobalOffset(this.viewer);
      Autodesk.Viewing.Document.load(urn, (doc: any) => {
        const defaultModel = doc.getRoot().getDefaultGeometry(true);

        this.viewer
          .loadDocumentNode(doc, defaultModel, {
            preserveView: true,
            keepCurrentModels: true,
            placementTransform: new THREE.Matrix4().makeRotationZ(angle).setPosition(position),
            globalOffset
          })
          .then(async (addedModel: Autodesk.Viewing.Model) => {
            addedModel.id = modelId || uuidv4();
            console.log('LOAD MODEL IN POSITION');
            if (
              typeof addedModel.id !== 'number' &&
              revitProperites.length > 0 &&
              revitProperites[0] !== undefined
            ) {
              addedModel.revitProperites = revitProperites;
            }

            resolve(addedModel);
          })
          .catch((e) => {
            console.warn('Error adding model', e);
            reject(null);
          });
      });
    });
  }

  getClosestRoomCenter(location) {
    if (!this.rooms) return null;

    let closestRoom = this.rooms[0];

    let roomDistance = this.rooms[0].boundingBox.getCenter().distanceTo(location);
    for (let i = 1; i < this.rooms.length; i++) {
      const distance = this.rooms[i].boundingBox.getCenter().distanceTo(location);

      if (distance < roomDistance) {
        closestRoom = this.rooms[i];
        roomDistance = distance;
      }
    }

    return { closestRoom: closestRoom, roomDistance: roomDistance };
  }

  async changeMaterial(materialInfo, selectedIds = null) {
    if (!selectedIds) selectedIds = this.viewer.getSelection();
    if (selectedIds.length > 0) {
      this.viewer.clearSelection();

      const biService = BIService.getInstance();
      biService.logEvent(EVENT_TYPES.APPLY_TILE, CONTEXTS.TILES, {
        tile_type: materialInfo.elementType,
        element_ids: selectedIds,
        product_id: materialInfo.id,
        product_name: materialInfo.name
      });

      const material = await getMaterial(this.viewer, materialInfo);

      selectedIds.forEach((itemId) => {
        console.log(itemId);
        this.applyTexture(itemId, material);
      });
    }
  }

  async changeMaterialAnimated(materialInfo) {
    const selectedIds = this.viewer.getSelection();
    if (selectedIds.length > 0) {
      this.viewer.clearSelection();
      const material = await getMaterial(this.viewer, materialInfo);
      selectedIds.forEach((itemId) => {
        applyTextureAndAnimate(itemId, this.viewer, material);
      });
    }
  }

  async changeMaterialByElementId(elementId: number, materialInfo) {
    const material = await getMaterial(this.viewer, materialInfo);
    this.applyTexture(elementId, material);
  }

  applyTexture = (elementId: number, texturedMaterial) => {
    const tree = this.viewer.model.getData().instanceTree;
    const elementFragIds = [];
    tree.enumNodeFragments(elementId, function (fragId) {
      elementFragIds.push(fragId);
    });

    for (let i = 0; i < elementFragIds.length; i++) {
      const fragment = elementFragIds[i];
      const fragProxy = this.viewer.impl.getFragmentProxy(this.viewer.model, fragment);

      if (!this.originalMaterials[fragment]) {
        //save the reference to original material
        this.originalMaterials[fragment] = this.viewer.model
          .getFragmentList()
          .getMaterial(fragment);
      }

      fragProxy.setMaterial(texturedMaterial);
      fragProxy.updateAnimTransform();
    }

    this.viewer.impl.invalidate(true, true);
  };

  restoreMaterialByElementId(elementId) {
    const tree = this.viewer.model.getData().instanceTree;
    const elementFragIds = [];
    tree.enumNodeFragments(elementId, function (fragId) {
      elementFragIds.push(fragId);
    });

    for (let i = 0; i < elementFragIds.length; i++) {
      const fragmentId = elementFragIds[i];

      if (this.originalMaterials[fragmentId]) {
        const fragProxy = this.viewer.impl.getFragmentProxy(this.viewer.model, fragmentId);

        fragProxy.setMaterial(this.originalMaterials[fragmentId]);
        fragProxy.updateAnimTransform();
      }
    }

    this.viewer.impl.invalidate(true);
  }

  checkIfEnteredARoom(room: IRoomInfo): boolean {
    const camera = this.viewer.getCamera();

    const collision = room.boundingBox.containsPoint(camera.position);

    if (collision) {
      return true;
    } else {
      return false;
    }
  }

  async getDbdisByGroup(
    model: Autodesk.Viewing.Model,
    dbidsToGroup: number[]
  ): Map<string, number[]> {
    const floorGroupProps = await Customizer.getBulkProperties(
      dbidsToGroup,
      ['Group', 'ElementId'],
      model
    );

    const onlyDbidsUnderGroup = floorGroupProps.filter((x) => x.properties.length > 1);

    const dbidsGroups: Map<string, number[]> = new Map();

    for (let i = 0; i < onlyDbidsUnderGroup.length; i++) {
      const groupProps = await Customizer.getBulkProperties(
        [onlyDbidsUnderGroup[i].properties[1].displayValue],
        ['Type Name'],
        model
      );

      const groupName = groupProps[0].properties[0].displayValue;

      if (dbidsGroups.has(groupName)) {
        dbidsGroups.get(groupName).push({
          revitId: Number(onlyDbidsUnderGroup[i].properties[0].displayValue),
          dbId: onlyDbidsUnderGroup[i].dbId
        });
      } else {
        dbidsGroups.set(groupName, [
          {
            revitId: Number(onlyDbidsUnderGroup[i].properties[0].displayValue),
            dbId: onlyDbidsUnderGroup[i].dbId
          }
        ]);
      }
    }

    return dbidsGroups;
  }

  async setRooms(
    rooms: IRoomInfo[],
    enteredRoomCallBack: (room: IRoomInfo, roomIndex: number) => void
  ) {
    this.rooms = rooms;
    this.currentEnteredRoom = null;

    this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, () => {
      const enteredRooms: { room: IRoomInfo; roomIndex: number }[] = [];

      for (let i = 0; i < this.rooms.length; i++) {
        const didEnterRoom = this.checkIfEnteredARoom(this.rooms[i]);

        if (didEnterRoom) {
          enteredRooms.push({ room: this.rooms[i], roomIndex: i });
        }
      }
      // console.log(enteredRooms);
      if (enteredRooms.length === 1) {
        if (this.currentEnteredRoom !== enteredRooms[0].room) {
          this.currentEnteredRoom = enteredRooms[0].room;
          enteredRoomCallBack(this.currentEnteredRoom, enteredRooms[0].roomIndex);
        }
      }
      if (enteredRooms.length > 1) {
        const closestRoom = this.getClosestRoomCenter(this.viewer.getCamera().position);
        if (this.currentEnteredRoom !== closestRoom.closestRoom) {
          this.currentEnteredRoom = closestRoom.closestRoom;

          enteredRoomCallBack(this.currentEnteredRoom, enteredRooms[0].roomIndex);
        }
      }

      if (enteredRooms.length === 0) {
        if (this.currentEnteredRoom !== null) {
          this.currentEnteredRoom = null;
          enteredRoomCallBack(null, -1);
        }
      }
    });
  }

  setBlinkingElements(blinkingElements: IBlinkingElement[]) {
    this.blinkingElements = blinkingElements;
    const models = this.viewer.getAllModels();
    models.map((model) => this.viewer.clearThemingColors(model));
  }

  animateStart() {
    const animate = (t) => {
      TWEEN.update(t);
      window.requestAnimationFrame(animate);
      this.handleBlinkingElements(t);
    };
    animate();
  }

  drawLinesBetweenPointsAndOrigin = (origin: THREE.Vector3, points: THREE.Vector3[]) => {
    points.forEach((point) => {
      const material = new THREE.LineBasicMaterial({ color: 0xff0000 });
      const geometry = new THREE.BufferGeometry().setFromPoints([origin, point]);

      this.viewer.overlays.addMesh(new THREE.Line(geometry, material), 'connected-lines-scene');
    });
  };

  clearLinesInConnectedLinesScene = () => {
    this.viewer.overlays.clearScene('connected-lines-scene');
  };

  handleBlinkingElements(t) {
    for (let i = 0; i < this.blinkingElements.length; i++) {
      const dbId = this.blinkingElements[i].dbId;
      const color: THREE.Vector4 = this.blinkingElements[i].color.clone();
      const sat = Math.abs(Math.sin(t / 300));
      color.setW(color.w * sat);

      const models = this.viewer.getAllModels();
      const model = models.filter((m) => m.id === this.blinkingElements[i].modelId)[0];
      this.viewer.setThemingColor(dbId, color, model, true);
    }
  }

  setTemporalHighlight(elements: IBlinkingElement[]) {
    elements.forEach((element) => {
      temporalColorAnimated(element.dbId, element.modelId, element.color, this.viewer);
    });
  }

  async hideModelTexts() {
    const elementsDbIds = this.categoriesMap['Revit Generic Models']?.map((x) => x.dbId);
    if (elementsDbIds && elementsDbIds.length > 0) {
      const props = await ForgeUtils.getBulkProperties(this.viewer.model, elementsDbIds, ['name']);
      props.forEach((prop) => {
        if (prop.name.startsWith('Model Text [')) {
          this.viewer.impl.visibilityManager.setNodeOff(prop.dbId, true);
        }
      });
    }
  }

  async changeFurnitureVisibility(hide: bool) {
    const furnitureDbIds = this.categoriesMap['Revit Furniture'];
    const genericModelsDbIds = this.categoriesMap['Revit Generic Models'] || [];
    const caseworkDbIds = this.categoriesMap['Revit Casework'] || [];
    const combinedDbIds = genericModelsDbIds.concat(caseworkDbIds).map((x) => x.dbId);
    const blocksFurniture = [];

    const props = await ForgeUtils.getBulkProperties(this.viewer.model, combinedDbIds, ['name']);
    props.forEach((prop) => {
      if (prop.name.startsWith('Blocks')) {
        blocksFurniture.push(prop.dbId);
      }
    });

    furnitureDbIds.push(...blocksFurniture);

    for (let i = 0; i < furnitureDbIds.length; i++) {
      this.viewer.impl.visibilityManager.setNodeOff(furnitureDbIds[i], hide);
    }

    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.TOOL_CLICKED, CONTEXTS.TOOLS, {
      tool_type: 'hide_furniture',
      is_active: hide
    });
  }

  async getDbIdsByCategory(category: string): number[] {
    return ForgeUtils.getDbIdsByProperty(this.viewer, 'Category', category);
  }

  getFilteredModelsFromViewer = () => {
    const models = this.viewer.getAllModels();
    return models.filter((model) => !model._isSwatch && !model._isMaster);
  };

  async getDbIdsAndModelByCategory(
    category: string,
    model?: Autodesk.Viewing.Model
  ): IDbidWithModel[] {
    return ForgeUtils.getDbIdsAndModelByProperty(this.viewer, 'Category', category, model);
  }

  async getMostIntersectedCeiling(
    category: string,
    bBox: THREE.Box3,
    tolerance: number
  ): IIntersectElement {
    let result: IIntersectElement;
    bBox.expandByScalar(tolerance);

    const candidates = [];

    let hostDbids = [];

    if (category === 'Revit Lighting Fixtures' || category === 'Revit Ceilings') {
      hostDbids = this.ceilingsDbids;
    } else {
      hostDbids = this.wallsDbids;
    }

    for (let i = 0; i < hostDbids.length; i++) {
      const hostBBox = this.getBoundingBox(hostDbids[i]);
      if (bBox.intersectsBox(hostBBox)) {
        const intersection = bBox.clone();
        intersection.intersect(hostBBox);
        const l = intersection.max.x - intersection.min.x;
        const d = intersection.max.y - intersection.min.y;
        const h = intersection.max.z - intersection.min.z;
        const volume = l * d * h;

        const candidate = {
          dbId: hostDbids[i],
          modelId: this.viewer.model.id,
          intersectionVolume: volume
        };
        candidates.push(candidate);
      }
    }
    if (candidates.length > 0) {
      const maxIntersectionVolume = Math.max(...candidates.map((o) => o.intersectionVolume));
      const bestCandidate = candidates.filter(
        (x) => x.intersectionVolume === maxIntersectionVolume
      )[0];
      result = {
        dbId: bestCandidate.dbId,
        modelId: bestCandidate.modelId,
        model: this.viewer.model
      };
    }

    return result;
  }

  getHostDbids = async (category: string): [] => {
    if (category === 'Revit Ceilings') {
      return this.ceilingsDbids;
    }

    if (category === 'Revit Walls') {
      return this.wallsDbids;
    }

    return [];
  };

  async getClosestElementByCategoryAndLocation(
    category: 'Revit Floors' | 'Revit Ceilings' | 'Revit Doors' | 'Revit Windows',
    location: THREE.Vector3
  ) {
    let closestDbId: number;
    let closestDistance: number;
    let dbids = [];

    switch (category) {
      case 'Revit Floors':
        dbids = this.floorDbids;
        break;
      case 'Revit Ceilings':
        dbids = this.ceilingsDbids;
        break;
      case 'Revit Doors':
        dbids = this.doorsDbidsWithModel.map((x) => x.dbId);
        break;
      case 'Revit Windows':
        dbids = this.windowsDbidsWithModel.map((x) => x.dbId);
        break;
      default:
        break;
    }

    for (let i = 0; i < dbids.length; i++) {
      const bBox = this.getBoundingBox(dbids[i]);
      const distance = bBox.distanceToPoint(location);
      if (closestDistance === undefined || closestDistance > distance) {
        closestDistance = distance;
        closestDbId = dbids[i];
      }
    }

    return {
      dbId: closestDbId,
      distance: closestDistance,
      modelId: this.viewer.model.id
    };
  }

  getBoundingBox(dbId: number, modelId: number) {
    let model;
    if (modelId === undefined) {
      model = this.viewer.model;
    } else {
      const models = this.getFilteredModelsFromViewer();
      model = models.filter((m) => m.id === modelId)[0];
    }

    const it = model.getInstanceTree();
    const fragList = model.getFragmentList();
    const bounds = new THREE.Box3();

    it.enumNodeFragments(
      dbId,
      (fragId) => {
        const box = new THREE.Box3();
        fragList.getWorldBounds(fragId, box);
        bounds.union(box);
      },
      true
    );

    return bounds;
  }

  async getElementsPropsInBBoxesWithFilters(category: string, BBoxs: THREE.Box3[]) {
    let dbIds = [];
    if (category === 'Revit Lighting Fixtures') {
      dbIds = this.lightsDbidsWithProps;
    } else if (category === 'Revit Electrical Fixtures') {
      dbIds = this.electricFixturesDbidsWithModelId;
    }

    // let dbIds = await this.getDbIdsAndModelByCategory(category);
    const matches = [];

    for (const dbId of dbIds) {
      const elBBox = ForgeUtils.getBoundingBox(dbId.dbId, dbId.model.id, this.viewer);

      if (elBBox) {
        // debugger;
        const intersects = BBoxs.some((bBox) => {
          return bBox.intersectsBox(elBBox);
        });

        if (intersects) {
          const elData = await Customizer.getPropertiesAsync(dbId.dbId, dbId.model);
          elData.model = dbId.model;
          matches.push(elData);
        }
      }
    }
    return matches;
  }
}

function getMaterial(viewer: Autodesk.Viewing.GuiViewer3D, materialInfo) {
  return new Promise((resolve, reject) => {
    let material =
      // @ts-ignore
      viewer.impl.matman()._materials[materialInfo.id];

    if (material !== null || material !== undefined) {
      material = new THREE.MeshStandardMaterial({});

      // TODO: Analyze a better way to load texture images, ImageUtils is deprecated.
      // Forge viewer uses ThreeJs version r71, hence this is not a problem by now.
      // add query param to prevent chrome cache issue
      // https://stackoverflow.com/questions/64688786/aws-s3-cors-error-only-in-chrome-when-using-mapbox-loadimage-function
      const tex = THREE.ImageUtils.loadTexture(
        materialInfo.textureFileUrl + '?cacheblock=true ',
        null,
        async (texture) => {
          material.map = texture;
          texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          const factor = 30.48 / materialInfo.realWorldSize;
          texture.repeat.set(factor, factor);
          texture.needsUpdate = true;
          material.needsUpdate = true;
          viewer.impl.invalidate(true, true);
          material.reflectivity = materialInfo.reflectivity;

          viewer.impl.matman().addMaterial(materialInfo.id, material, true);
          resolve(material);
        },
        (msg) => {
          console.log('Error loading texture: ' + msg);
        }
      );
    }
  });
  // return material;
}

function animate(time) {
  requestAnimationFrame(animate);
  TWEEN.update(time);
}

function applyTextureAndAnimate(elementId: number, viewer, texturedMaterial) {
  const tree = viewer.model.getData().instanceTree;
  const elementFragIds = [];
  tree.enumNodeFragments(elementId, function (fragId) {
    elementFragIds.push(fragId);
  });

  const droppingHeight = 30;
  const droppingTime = 1000;

  for (let i = 0; i < elementFragIds.length; i++) {
    const fragment = elementFragIds[i];
    const fragProxy = viewer.impl.getFragmentProxy(viewer.model, fragment);
    fragProxy.getAnimTransform();
    const originalElevation = fragProxy.position.z;
    const startElevation = originalElevation + droppingHeight;

    fragProxy.setMaterial(texturedMaterial);

    requestAnimationFrame(animate);
    new TWEEN.Tween({ z: startElevation })
      .to({ z: originalElevation }, droppingTime)
      .easing(TWEEN.Easing.Bounce.Out)
      .onUpdate(function (object) {
        updateFragmentPositionZ(viewer, fragment, object.z);
      })
      .start();
  }
}

function updateFragmentPositionZ(viewer, fragment, elevation) {
  const fragProxy = viewer.impl.getFragmentProxy(viewer.model, fragment);
  fragProxy.getAnimTransform();
  fragProxy.position.z = elevation;
  fragProxy.updateAnimTransform();
  viewer.impl.sceneUpdated(true);
}

function temporalColorAnimated(dbId: number, modelId, color: THREE.Vector4, viewer) {
  const animationTime = 3000;
  const startSaturation = color.w;

  const models = viewer.getAllModels();
  const model = models.filter((m) => m.id === modelId)[0];

  requestAnimationFrame(animate);
  new TWEEN.Tween({ w: startSaturation })
    .to({ w: 0 }, animationTime)
    .easing(TWEEN.Easing.Quartic.In)
    .onUpdate(function (object) {
      const currentColor = color.clone();
      currentColor.setW(object.w);
      viewer.setThemingColor(dbId, currentColor, model, true);
    })
    .start();
}
