import { CloseOutlined } from '@mui/icons-material';
import { Snackbar, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import IntlMessages from 'shared/utility/IntlMessages';
import { CustomRequestMode } from 'types/models/CustomRequest';

const AddCustomRequestSnackBar = () => {
  const { mode } = useSelector<AppState, AppState['customRequest']>(
    ({ customRequest }) => customRequest
  );
  const [isOpen, setOpen] = useState<boolean>(true);

  const action = (
    <IconButton
      onClick={() => {
        setOpen(false);
      }}
      color="inherit"
      size="small">
      <CloseOutlined fontSize="small" />
    </IconButton>
  );

  useEffect(() => {
    setOpen(mode === CustomRequestMode.EDIT);
  }, [mode]);

  return (
    <>
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        message={<IntlMessages id="tweaks.viewer.tooltip.note" />}
        action={action}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        sx={{
          '& .MuiSnackbarContent-root': {
            background: 'linear-gradient( #ff5900 9%, #FFEEE5 10%)',
            width: '340px',
            height: '63px',
            color: '#5A55F4',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            borderRadius: '8px'
          },
          '&.MuiSnackbar-root': {
            transform: 'translateX(0)'
          }
        }}
      />
    </>
  );
};

export default AddCustomRequestSnackBar;
