import Settings from './Setting';
import Common from './Common';
import Project from './Project';
import Home from './Home';
import Model from './Model';
import Supplier from './Supplier';
import Modification from './Modification';
import Viewer from './Viewer';
import Asset from './Asset';
import CustomRequest from './CustomRequest';
import User from './User';
import CatelogDialog from './CatelogDialog';

const reducers = {
  catelog: CatelogDialog,
  settings: Settings,
  common: Common,
  project: Project,
  home: Home,
  model: Model,
  supplier: Supplier,
  modification: Modification,
  viewer: Viewer,
  asset: Asset,
  customRequest: CustomRequest,
  user: User
};

export default reducers;
