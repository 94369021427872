import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import jwtAxios from 'services/auth/jwt-auth';
import {
  GET_MODIFICATIONS,
  RESET_MODIFICATION_STORE,
  ADD_MODIFICATION,
  UPDATE_MODIFICATION,
  DELETE_MODIFICATION,
  GET_TILE_MODIFICATION_BY_ELEMENT_DBID,
  RESET_TILE_MODIFICATION_BY_ELEMENT_DBID
} from 'types/actions/Modification.actions';
import { IModification, MODIFICATION_TYPE } from 'types/models/Modification';
import { roundNumber } from 'shared/utility/Utils';
import { mapPrices, getPrice } from 'shared/constants/Costs';
import { getDbNameForItem } from 'shared/constants/AppConst';
import { IElementInfo } from 'types/models/ForgeViewer';
import { IDbidWithModelId, isLight, isSwitch } from 'shared/utility/ForgeUtilities/ForgeUtils';
import { store } from 'App';

export const getModifications = (homeId: string, callback = null) => {
  return (dispatch: Dispatch<AppActions>, getState) => {
    jwtAxios.get(`/homes/${homeId}/modifications/`).then((data) => {
      dispatch({ type: GET_MODIFICATIONS, payload: data.data });
      if (callback) callback();
    });
  };
};

const showPreviouslyHiddenModification = (
  dbId: number | object,
  viewer: Autodesk.Viewing.GuiViewer3D,
  items: IModification[] = []
) => {
  const customExt = viewer.getExtension('TweaksxCustomizer') as any;

  viewer.impl.visibilityManager.setNodeOff(dbId, false);
  viewer.impl.invalidate(true, true, true);

  if (typeof dbId === 'number') {
    const element: IDbidWithModelId = {
      dbId: dbId,
      model: viewer.model
    };
    customExt.updateCacheModelDbids([element], 'Add');
  }
};

export const resetModifications = (
  modificationsToReset: IModification[],
  viewer: Autodesk.Viewing.GuiViewer3D,
  callback: () => void
) => {
  return async (dispatch: Dispatch<AppActions>, getState) => {
    const moveExt = viewer.getExtension('TweaksxMove') as any;
    const customExt = viewer.getExtension('TweaksxCustomizer') as any;

    for (let i = 0; i < modificationsToReset.length; i++) {
      const mod = modificationsToReset[i];
      const { items } = getState().modification;

      if (modificationsToReset[i].dbIdRef) {
        showPreviouslyHiddenModification(modificationsToReset[i].dbIdRef, viewer, items);
        const modificationToDelete = getOldModification(
          modificationsToReset[i].dbIdRef,
          MODIFICATION_TYPE.DELETE,
          items
        );

        if (modificationToDelete) {
          await deleteModification(
            modificationToDelete.home,
            modificationToDelete._id,
            modificationToDelete,
            items
          ).then((data) => {
            dispatch({ type: DELETE_MODIFICATION, payload: modificationToDelete._id });
          });
        }
      }

      switch (mod.type) {
        case MODIFICATION_TYPE.MOVE:
          const offset = new THREE.Vector3(
            -mod.attrs.offsetX,
            -mod.attrs.offsetY,
            -mod.attrs.offsetZ
          );
          moveExt.applyElementOffset(mod.dbId, offset);
          break;
        case MODIFICATION_TYPE.LIGHT:
          if (mod.attrs?.fixtureAttrs) {
            const offset = new THREE.Vector3(
              -mod.attrs.fixtureAttrs.offsetX,
              -mod.attrs.fixtureAttrs.offsetY,
              -mod.attrs.fixtureAttrs.offsetZ
            );
            moveExt.applyElementOffset(mod.dbId, offset);
          }
          if (mod.attrs?.switches?.length > 0) {
            for (let i = 0; i < mod.attrs.switches.length; i++) {
              if (mod.attrs?.switches[i]?.type === MODIFICATION_TYPE.MOVE) {
                const offset = new THREE.Vector3(
                  -mod.attrs.switches[i].attrs.offsetX,
                  -mod.attrs.switches[i].attrs.offsetY,
                  -mod.attrs.switches[i].attrs.offsetZ
                );

                moveExt.applyElementOffset(mod.attrs.switches[i].dbId, offset);
              }
              if (mod.attrs.switches[i].type === MODIFICATION_TYPE.ADD) {
                const models = viewer.getAllModels();
                const model = models.filter((m) => m.id === mod.attrs.switches[i].attrs.modelId)[0];
                if (model !== undefined && model !== null && typeof model !== 'number') {
                  viewer.unloadModel(model as any);
                  const element: IDbidWithModelId = {
                    dbId: mod.attrs.switches[i].dbId,
                    model: model
                  };
                  customExt.updateCacheModelDbids([element], 'Remove');
                }
              }
            }
          }
          break;
        case MODIFICATION_TYPE.ADD:
          const models = viewer.getAllModels();
          const model = models.filter((m) => m.id === mod.attrs.modelId)[0];
          if (model !== undefined && model !== null && typeof model !== 'number') {
            const element: IDbidWithModelId = {
              dbId: mod.dbId,
              model: model
            };

            customExt.updateCacheModelDbids([element], 'Remove');
            viewer.unloadModel(model as any);
          }
          break;
        case MODIFICATION_TYPE.DELETE:
          viewer.impl.visibilityManager.setNodeOff(mod.dbId, false);
          viewer.impl.invalidate(true, true, true);
          const element: IDbidWithModelId = {
            dbId: mod.dbId,
            model: viewer.model
          };
          customExt.updateCacheModelDbids([element], 'Add');
          break;
        default:
          break;
      }
      await deleteModification(mod.home, mod._id, mod, items).then((data) => {
        dispatch({ type: DELETE_MODIFICATION, payload: mod._id });
      });
    }
    callback();
  };
};

const getModificationMoveOffset = (elementInfo: IElementInfo, modification: IModification) => {
  let offset: THREE.Vector3;
  if (isSwitch(elementInfo.name)) {
    if (modification.attrs?.switches?.length > 0) {
      offset = new THREE.Vector3(
        -modification.attrs.switches[0].attrs.offsetX,
        -modification.attrs.switches[0].attrs.offsetY,
        -modification.attrs.switches[0].attrs.offsetZ
      );
    }

    return offset;
  }
  if (isLight(elementInfo.name, elementInfo.category)) {
    offset = new THREE.Vector3(
      -modification.attrs.fixtureAttrs.offsetX,
      -modification.attrs.fixtureAttrs.offsetY,
      -modification.attrs.fixtureAttrs.offsetZ
    );

    return offset;
  }
  offset = new THREE.Vector3(
    -modification.attrs.offsetX,
    -modification.attrs.offsetY,
    -modification.attrs.offsetZ
  );

  return offset;
};

const getModificationType = (selectedElement: IElementInfo): MODIFICATION_TYPE => {
  const modelId = selectedElement.modelId;

  if (isSwitch(selectedElement.name) || isLight(selectedElement.name, selectedElement.category)) {
    return MODIFICATION_TYPE.LIGHT;
  }

  if (typeof modelId !== 'number') {
    return MODIFICATION_TYPE.ADD;
  }

  return MODIFICATION_TYPE.MOVE;
};

export const getElementModification = (selectedElements: IElementInfo[]): IModification => {
  const state = store.getState();
  const { items } = state.modification;
  const selectedElement = selectedElements[0];

  const dbId =
    typeof selectedElement.modelId === 'number' ? selectedElement.dbId : selectedElement.modelId;

  const modificationType = getModificationType(selectedElement);
  const oldModification: IModification = getOldModification(dbId, modificationType, items);

  return oldModification;
};

export const deleteModification = (
  homeId,
  modificationId,
  modification: IModification,
  modifications: IModification[]
): Promise<string> => {
  // CHECKING IF ITS LIGHT MODIFICATION
  // Delete modification in light is actually updating light modification
  // So here we just make sure its light and update (delete attrs)
  if (modification.attrs?.switches?.length > 0 || modification.attrs?.fixtureAttrs !== undefined) {
    const foundModification = getOldModification(
      modification.dbId,
      MODIFICATION_TYPE.LIGHT,
      modifications
    );

    if (modification.attrs?.switches?.length > 0) {
      // update switches modifcation
      for (let i = 0; i < modification.attrs.switches.length; i++) {
        if (modification.attrs.switches[i].type === modification.type) {
          foundModification.attrs.switches.splice(i, 1);
        }
      }
    }

    // update light modifcation
    if (modification.attrs?.fixtureAttrs !== undefined) {
      if (modification.attrs?.fixtureType === modification.type) {
        delete foundModification.attrs.fixtureAttrs;
        foundModification.cost = 0;
      }
    }

    if (foundModification?.attrs?.switches?.length > 0 || foundModification?.attrs?.fixtureAttrs) {
      console.log('updating light modification');
      jwtAxios.put(`/homes/${foundModification.home}/modifications/${foundModification._id}`, {
        attrs: foundModification.attrs,
        type: foundModification.type,
        cost: foundModification.cost,
        room: foundModification?.room
      });
    } else {
      console.log('deleting light modification');
      jwtAxios.delete(`/homes/${homeId}/modification/${foundModification._id}`);
    }
  }

  return jwtAxios.delete(`/homes/${homeId}/modification/${modificationId}`);
};

export const getModifcationPrices = (projectId) => {
  jwtAxios.get(`projects/${projectId}/projectPrices`).then((data) => {
    mapPrices(data.data.modificationsPrices);
  });
};

const getLightTypeModication = (
  mod: IModification,
  foundModification: IModification
): IModification => {
  if (mod.type === MODIFICATION_TYPE.LIGHT) {
    // Updating switches
    // looking for a switch in previous modification and than updating the list or adding new one

    if (mod.attrs?.switches?.length > 0) {
      let switchIndex = -1;

      let modelId;
      if (mod?.attrs?.switches[0]?.attrs?.modelId) {
        modelId = mod.attrs.switches[0].attrs.modelId;
      }

      if (mod?.attrs?.switches[0]?.modelId) {
        modelId = mod.attrs.switches[0].modelId;
      }

      // Searching switch based on model id(added switches) or db id(already existed switches)
      if (!modelId) {
        switchIndex = foundModification?.attrs?.switches?.findIndex((switchMod) => {
          return switchMod?.dbId === mod.attrs?.switches[0]?.dbId;
        });
        console.log(switchIndex);
      } else {
        switchIndex = foundModification?.attrs?.switches?.findIndex((switchMod) => {
          return switchMod?.attrs?.modelId === modelId;
        });
        console.log(switchIndex);
      }

      // theres no found switch in previous modification so we add it to
      if (switchIndex === -1) {
        foundModification.attrs?.switches?.push(mod.attrs.switches[0]);
      }

      // theres no switch at all
      if (switchIndex === undefined && mod.attrs?.switches?.length > 0) {
        foundModification.attrs.switches = mod.attrs.switches;
      }
      // found a switch we moved before so we just update it
      if (
        switchIndex >= 0 &&
        foundModification.attrs.switches[switchIndex].type === MODIFICATION_TYPE.MOVE
      ) {
        foundModification.attrs.switches[switchIndex].attrs.offsetX =
          foundModification.attrs.switches[switchIndex].attrs.offsetX +
          mod.attrs.switches[0].attrs.offsetX;
        foundModification.attrs.switches[switchIndex].attrs.offsetY =
          foundModification.attrs.switches[switchIndex].attrs.offsetY +
          mod.attrs.switches[0].attrs.offsetY;
        foundModification.attrs.switches[switchIndex].attrs.offsetZ =
          foundModification.attrs.switches[switchIndex].attrs.offsetZ +
          mod.attrs.switches[0].attrs.offsetZ;
      }

      // found a switch we added before so we just update it
      if (
        switchIndex >= 0 &&
        foundModification.attrs.switches[switchIndex].type === MODIFICATION_TYPE.ADD
      ) {
        if (mod.attrs.switches[0].type === MODIFICATION_TYPE.DELETE) {
          foundModification.attrs.switches.splice(switchIndex, 1);
        } else if (mod.attrs.switches[0].type === MODIFICATION_TYPE.MOVE) {
          foundModification.attrs.switches[switchIndex].attrs.hitPoint.x =
            foundModification.attrs.switches[switchIndex].attrs.hitPoint.x +
            mod.attrs.switches[0].attrs.offsetX;

          foundModification.attrs.switches[switchIndex].attrs.hitPoint.y =
            foundModification.attrs.switches[switchIndex].attrs.hitPoint.y +
            mod.attrs.switches[0].attrs.offsetY;

          foundModification.attrs.switches[switchIndex].attrs.hitPoint.z =
            foundModification.attrs.switches[switchIndex].attrs.hitPoint.z +
            mod.attrs.switches[0].attrs.offsetZ;
        }
      }
    }

    // Updating light position
    if (mod.attrs?.fixtureAttrs) {
      // Theres allready a move modification in previous modification for the light and we also moved the light so we updating the new position
      if (foundModification.attrs?.fixtureAttrs) {
        foundModification.attrs.fixtureAttrs.offsetX =
          foundModification.attrs.fixtureAttrs.offsetX + mod.attrs.fixtureAttrs.offsetX;
        foundModification.attrs.fixtureAttrs.offsetY =
          foundModification.attrs.fixtureAttrs.offsetY + mod.attrs.fixtureAttrs.offsetY;
        foundModification.attrs.fixtureAttrs.offsetZ =
          foundModification.attrs.fixtureAttrs.offsetZ + mod.attrs.fixtureAttrs.offsetZ;
      } else {
        foundModification.attrs.fixtureAttrs = mod.attrs.fixtureAttrs;
      }
    }
    if (mod.attrs?.fixtureType) {
      foundModification.attrs.fixtureType = mod.attrs.fixtureType;
    }

    if (foundModification.attrs.fixtureAttrs !== undefined) {
      foundModification.cost = CalcModificationPrice('lighting_fixture', MODIFICATION_TYPE.MOVE);
    } else {
      foundModification.cost = 0;
    }
  }
  // its not a light or a switch
  else if (mod.type === MODIFICATION_TYPE.MOVE) {
    foundModification.attrs.offsetX = foundModification.attrs.offsetX + mod.attrs.offsetX;
    foundModification.attrs.offsetY = foundModification.attrs.offsetY + mod.attrs.offsetY;
    foundModification.attrs.offsetZ = foundModification.attrs.offsetZ + mod.attrs.offsetZ;
  } else if (mod.type === MODIFICATION_TYPE.TILE) {
    foundModification.attrs = mod.attrs;
  }

  return foundModification;
};

export const CalcModificationPrice = (
  itemName: string,
  modificationType: MODIFICATION_TYPE
): number => {
  const itemNameInDb = getDbNameForItem(itemName);

  if (itemNameInDb === undefined) {
    console.warn('Item name not found in getDbNameForItem');

    return 0;
  }

  return getPrice(itemNameInDb, modificationType);
};

const getOldModification = (
  modId: any,
  modType: MODIFICATION_TYPE,
  items: IModification[]
): any => {
  if (modId === undefined) {
    return null;
  }

  if (modType === MODIFICATION_TYPE.LIGHT) {
    if (typeof modId === 'number') {
      // Is the a light modification
      const foundModification = items.find((item) => {
        return item?.dbId === modId && item.type === modType;
      });

      // Is there a switch modification
      if (!foundModification) {
        for (let i = 0; i < items.length; i++) {
          const switchMod = items[i].attrs?.switches?.find((switchMod) => {
            return switchMod?.dbId === modId;
          });

          if (switchMod) return items[i];
        }
      }

      return foundModification;
    } else {
      for (let i = 0; i < items.length; i++) {
        const switchMod = items[i].attrs?.switches?.find((switchMod) => {
          return switchMod?.attrs?.modelId === modId;
        });

        console.log(switchMod);
        if (switchMod) return items[i];
      }
    }
  }

  if (typeof modId === 'number') {
    return modId
      ? items.find((item) => {
          return item?.dbId === modId && item.type === modType;
        })
      : null;
  } else {
    return items.find((item: IModification) => {
      return item?.attrs?.modelId === modId;
    });
  }
};

export const getTotalCost = (homeCost: number, mods: IModification[]) => {
  let totalCost = 0;
  mods.forEach((mod) => {
    totalCost += mod.cost;

    if (mod.attrs?.switches?.length > 0) {
      mod.attrs.switches.forEach((switchMod) => {
        totalCost += switchMod.cost;
      });
    }
  });
  let finalCost = roundNumber(totalCost + homeCost);
  if (finalCost < 0) finalCost = 0;
  return roundNumber(finalCost);
};

export const processModification = (newModification: IModification) => {
  return (dispatch: Dispatch<AppActions>, getState) => {
    const { items } = getState().modification;
    let newModificationNeeded = true;

    // *** ---- WE DELETE THE HOME PROPERTY FROM THE MODIFICATION ATTRS ---- *** //
    if (newModification.attrs?.switches) {
      for (let i = 0; i < newModification.attrs.switches.length; i++) {
        delete newModification.attrs.switches[i].home;
      }
    }

    if (newModification.type === MODIFICATION_TYPE.DELETE) {
      const previousModification = getOldModification(
        newModification.dbId,
        newModification.type,
        items
      );
      if (previousModification) {
        deleteModification(
          previousModification.home,
          previousModification._id,
          previousModification,
          items
        );
        dispatch({
          type: DELETE_MODIFICATION,
          payload: previousModification._id
        });
      }
    } else {
      const previousModification = getOldModification(
        newModification.dbId,
        newModification.type,
        items
      );

      if (previousModification) {
        // We get a new modifcation with the updated values
        const updatedModification = getLightTypeModication(newModification, previousModification);

        console.log(newModification);
        console.log(previousModification);
        console.log(updatedModification);
        console.log('---updatedModification---');

        newModificationNeeded = false;
        jwtAxios
          .put(`/homes/${newModification.home}/modifications/${previousModification._id}`, {
            attrs: updatedModification.attrs,
            type: updatedModification.type,
            cost: updatedModification.cost,
            room: updatedModification?.room,
            modificationRef: updatedModification?.dbIdRef
          })
          .then((data) => {
            dispatch({ type: UPDATE_MODIFICATION, payload: data.data });
          });
      }
    }

    if (newModificationNeeded) {
      console.log(newModificationNeeded);
      console.log('---newModificationNeeded--');

      jwtAxios
        .post(`/homes/${newModification.home}/modifications`, {
          revitId: newModification.revitId,
          type: newModification.type,
          attrs: newModification.attrs,
          cost: newModification.cost,
          dbId: newModification.dbId,
          room: newModification?.room,
          dbIdRef: newModification?.dbIdRef
        })
        .then((data) => {
          // if (data.data.type === MODIFICATION_TYPE.DELETE) {
          //   busDispatch({
          //     type: ViewerEvents.DeleteElement,
          //     deletedElementId: data.data
          //   });
          // }
          dispatch({ type: ADD_MODIFICATION, payload: data.data });
        });
    }
  };
};

export const processAddedModelModification = (newModification: IModification, modelId: string) => {
  return (dispatch: Dispatch<AppActions>, getState) => {
    const { items } = getState().modification;

    // *** ---- WE DELETE THE HOME PROPERTY FROM THE MODIFICATION ATTRS ---- *** //
    if (newModification.attrs?.switches) {
      for (let i = 0; i < newModification.attrs.switches.length; i++) {
        delete newModification.attrs.switches[i].home;
      }
    }

    let previousModification = getOldModification(modelId, newModification.type, items);
    if (newModification.type === MODIFICATION_TYPE.LIGHT) {
      previousModification = getOldModification(newModification.dbId, newModification.type, items);
    }

    if (!previousModification) {
      console.warn('ADD modification not found');
      return;
    }

    switch (newModification.type) {
      case MODIFICATION_TYPE.LIGHT:
        const updatedModifcation = getLightTypeModication(newModification, previousModification);

        jwtAxios
          .put(`/homes/${newModification.home}/modifications/${updatedModifcation._id}`, {
            attrs: updatedModifcation.attrs,
            type: updatedModifcation.type,
            cost: updatedModifcation.cost
          })
          .then((data) => {
            dispatch({ type: UPDATE_MODIFICATION, payload: data.data });
          });
        break;

      case MODIFICATION_TYPE.MOVE:
        previousModification.attrs.hitPoint.x =
          previousModification.attrs.hitPoint.x + newModification.attrs.offsetX;
        previousModification.attrs.hitPoint.y =
          previousModification.attrs.hitPoint.y + newModification.attrs.offsetY;
        previousModification.attrs.hitPoint.z =
          previousModification.attrs.hitPoint.z + newModification.attrs.offsetZ;

        jwtAxios
          .put(`/homes/${newModification.home}/modifications/${previousModification._id}`, {
            attrs: previousModification.attrs,
            type: previousModification.type,
            cost: previousModification.cost
          })
          .then((data) => {
            dispatch({ type: UPDATE_MODIFICATION, payload: data.data });
          });
        break;
      case MODIFICATION_TYPE.DELETE:
        deleteModification(
          previousModification.home,
          previousModification._id,
          previousModification,
          items
        ).then((data) => {
          dispatch({ type: DELETE_MODIFICATION, payload: previousModification._id });
        });
        break;
      default:
        break;
    }
  };
};

export const getTileModificationByElementDbId = (dbId: number) => {
  return (dispatch: Dispatch<AppActions>, getState) => {
    const { items } = getState().modification;
    const mod = items.find((item) => {
      return item.type === MODIFICATION_TYPE.TILE && item.dbId === dbId;
    });

    dispatch({
      type: GET_TILE_MODIFICATION_BY_ELEMENT_DBID,
      payload: mod || null
    });
  };
};

export const resetTileModificationByElementDbId = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: RESET_TILE_MODIFICATION_BY_ELEMENT_DBID });
  };
};

export const resetModificationStore = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: RESET_MODIFICATION_STORE });
  };
};
