export enum MODIFICATION_TYPE {
  MOVE = 'Move',
  TILE = 'Tile',
  ADD = 'Add',
  DELETE = 'Delete',
  LIGHT = 'Light',
  PACKAGE = 'Package'
}

export enum TILE_ELEMENT_TYPE {
  WALL = 'Wall',
  FLOOR = 'Floor'
}

export interface IModification {
  _id?: string;
  dbId?: number;
  revitId?: number;
  modelId?: string | number;
  type: MODIFICATION_TYPE;
  attrs?:
    | IMoveModificationAttrs
    | IAddModificationAttrs
    | ITileModificationAttrs
    | ILightModificationAttrs
    | any; // @todo fix the need for any here
  cost: number;
  home: string;
  room?: string;
  dbIdRef?: number;
}

export interface IMoveModificationAttrs {
  offsetX: number;
  offsetY: number;
  offsetZ: number;
}

export interface IDeleteModificationAttrs {
  dbId: number;
}

export interface IRevitProperty {
  name: string;
  category: string;
  value: string;
}

export interface IAddModificationAttrs {
  hitPoint: {
    x: number;
    y: number;
    z: number;
  };
  angle: number;
  modelId: string;
  modelUrn: string;
  hostId: number;
  revitProperties: IRevitProperty[];
}

export interface ITileModificationAttrs {
  name: string;
  textureFileUrl: string;
  id: string;
  reflectivity: number;
  realWorldSize: number;
  elementType: TILE_ELEMENT_TYPE;
  area: number;
}
export interface ILightModificationAttrs {
  fixtureAttrs?: IAddModificationAttrs | IMoveModificationAttrs | IDeleteModificationAttrs;
  fixtureType?: MODIFICATION_TYPE;
  switches?: IModification[];
}
