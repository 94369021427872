import { MODIFICATION_TYPE } from 'types/models/Modification';

interface IPrice {
  modificationType: MODIFICATION_TYPE;
  price: number;
  item: {
    itemName: string;
    id: string;
  };
}

const prices: IPrice[] = [];

export const getPrice = (itemName: string, modificationType: MODIFICATION_TYPE): number => {
  const price = prices.find((price: IPrice) => {
    return (
      price.item.itemName.toLowerCase() === itemName.toLowerCase() &&
      price.modificationType.toLowerCase() === modificationType.toLowerCase()
    );
  });

  return price ? price.price : 0;
};

export const mapPrices = (modificationsPrices: []) => {
  if (modificationsPrices) {
    modificationsPrices.map((modificationPrice) => {
      const { item, modificationType, price } = modificationPrice;
      prices.push({
        modificationType,
        price,
        item
      });
    });
  }

  console.log(prices);
};
