import { Button, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import emitApiServiceBuilder from 'pixel_streaming/services/emit-api.service';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCustomRequest,
  getCustomRequests,
  setCustomRequestViewMode
} from 'redux/actions/CustomRequest';
import { AppState } from 'redux/store';
import { CONTEXTS, EVENT_TYPES } from 'shared/constants/AppConst';
import BIService from 'shared/utility/BIService';
import { CustomRequestMode } from 'types/models/CustomRequest';
import * as yup from 'yup';
import { MutableRefObject } from 'react';
import { Application } from '@yovelsapir/lib-pixelstreamingfrontend-ui-ue5.2';

interface Props {
  menuPosition: THREE.Vector2;
  visible: boolean;
  setEditCustomRequestMenuVisible: (visible: boolean) => void;
  pixelStreamingRef?: MutableRefObject<Application>;
}

const AddCommentCustomRequest = ({
  menuPosition: menuPos,
  visible,
  setEditCustomRequestMenuVisible,
  pixelStreamingRef
}: Props) => {
  const { messages } = useIntl();

  const dispatch = useDispatch();
  const emitApiService = emitApiServiceBuilder(pixelStreamingRef);

  const { item: home } = useSelector<AppState, AppState['home']>(
    ({ home: homeInstance }) => homeInstance
  );

  const { selectedMarker } = useSelector<AppState, AppState['customRequest']>(
    ({ customRequest }) => customRequest
  );

  const handleSaveRequest = (requestComment: string) => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.ADD_REQUEST, CONTEXTS.REQUESTS);

    const cameraInfo = {
      viewport: {
        name: '',
        eye: [-2.1019082069396973, -4.909591913223267, 3.8957705156994633],
        target: [-2.1019082069396973, -3.9095919132232666, 3.8957705156994633],
        up: [0, 0, 1],
        worldUpVector: [0, 0, 1],
        pivotPoint: [-2.1019082069396973, 3.480008125305176, 0.8693803548812866],
        distanceToOrbit: 8.389600038528442,
        aspectRatio: 3.3670161766359294,
        projection: 'perspective',
        isOrthographic: false,
        fieldOfView: 73.73979529168804
      }
    };

    console.log(JSON.stringify(selectedMarker));

    dispatch(
      createCustomRequest(
        {
          modelTemplateId: home.modelTemplate._id,
          homeId: home._id,
          dbId: selectedMarker.data.dbId,
          locationX: selectedMarker.x,
          locationY: selectedMarker.y,
          locationZ: selectedMarker.z,
          comment: requestComment,
          cameraInfo
        },
        (data) => {
          dispatch(getCustomRequests(home._id));
          dispatch(setCustomRequestViewMode(CustomRequestMode.EDIT));
          emitApiService.emitEventToUnreal(
            'addComments',
            JSON.stringify({
              comments: [
                {
                  id: data._id,
                  x: data.locationX,
                  y: data.locationY,
                  z: data.locationZ
                }
              ]
            })
          );
        }
      )
    );
  };

  if (!visible) {
    return null;
  }

  const validationSchema = yup.object({
    comment: yup.string().required(String(messages['validation.required']))
  });

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnMount
      validateOnChange
      initialValues={{
        comment: ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSaveRequest(values.comment);
        setSubmitting(false);
        setEditCustomRequestMenuVisible(false);
      }}>
      {({ isSubmitting, isValid, values, handleChange }) => (
        <Form
          style={{
            position: 'absolute',
            top: menuPos.y,
            left: menuPos.x,
            width: '241px',
            height: '92px',
            backgroundColor: '#FFFFFF',
            zIndex: 9999,
            borderRadius: '8px',
            padding: '7px 13px 13px 13px',
            border: '1px solid #E5E5E5',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 3
          }}>
          <TextField
            variant="outlined"
            autoComplete="off"
            name="comment"
            value={values.comment}
            onChange={handleChange}
            inputRef={(input) => {
              if (input != null) {
                input.focus();
              }
            }}
            InputProps={{
              sx: {
                fontSize: '12px',
                fontWeight: 300,
                height: '28px'
              }
            }}
            sx={{
              width: '220px',
              fontSize: '12px',
              fontWeight: 300,
              borderWidth: '0px',
              '.MuiOutlinedInput-notchedOutline': { border: 'none' },
              '&:hover': {
                '.MuiOutlinedInput-notchedOutline': {
                  border: '1px solid ',
                  borderColor: 'primary.main'
                },
                '.MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid ',
                    borderColor: 'primary.main'
                  }
                }
              }
            }}
            placeholder="Add a comment"
          />
          <Button
            variant="contained"
            type="submit"
            disabled={isSubmitting || !isValid}
            sx={{
              mt: 1,
              minWidth: '38px',
              width: '46px',
              height: '38px',
              borderRadius: '20px',
              fontSize: '14px',
              fontWeight: 500,
              alignSelf: 'flex-end'
            }}>
            Add
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddCommentCustomRequest;
