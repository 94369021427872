import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Popper,
  Typography,
  useTheme
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTotalCosts } from 'pages/homes/hooks/useTotalCosts';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NumbersService } from 'services/numbers/number.service';
import { FindInPageOutlined } from '@mui/icons-material';
// import { RiUpload2Fill } from 'react-icons/ri';
import { AppState } from 'redux/store';
import { IRoomInfo } from 'types/models/ForgeViewer';
import { IModification, MODIFICATION_TYPE } from 'types/models/Modification';
import { IRoomPackage } from 'pages/projects/projectDetail/RoomsList/RoomListItem';
import { CONTEXTS, EVENT_TYPES } from 'shared/constants/AppConst';
import { useDispatch, useSelector } from 'react-redux';
import { Customizer } from 'shared/extensions/tweaksx-customizer';
import { ForgeUtils } from 'shared/utility/ForgeUtilities/ForgeUtils';
import Scrollbars from 'react-custom-scrollbars-2';
import IntlMessages from 'shared/utility/IntlMessages';
import { submitHome, getModifications } from 'redux/actions';
import BIService from 'shared/utility/BIService';
import { useResetHome } from '../hooks/useResetHome';
import AppConfirmDialog from 'shared/core/AppConfirmDialog';
import { useHomeButtonStatus } from '../hooks/useHomeButtonStatus';
import { InReviewModal } from './InReviewModal';
import { useJWTAuth } from 'services/auth/jwt-auth/JWTAuthProvider';
import { findRoomKeyByRevitRoomName } from 'services/rooms/rooms.service';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import { ReactComponent as PendEdit } from '../../../../assets/icon/pen-edit-delete-cross-circle.svg';
import SubmitDesignModal from '../SubmitDesignModal';
import SubmitSnackBar from './SnackBars/SubmitSnackBar';
import { STATUS_TYPE } from 'types/models/Home';
import UnderReviewSnackBar from './SnackBars/UnderReviewSnackBar';
import TimerSnackBar from './SnackBars/TimerSnackBar';
import { useHomeStatus } from 'pages/homes/hooks/useHomeStatus';

interface GroupedModifcationWithCost {
  name: string;
  id: string;
  type: string;
  cost: number;
}

const CurrentButtonOverlay = ({ totalCost }: { totalCost: number }) => {
  const user = useJWTAuth();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          whiteSpace: 'nowrap'
        }}>
        <Typography
          color="text.primary"
          textAlign={'left'}
          sx={{
            fontSize: 16,
            fontWeight: 500
          }}>
          {user.user.displayName}
        </Typography>
        <Box
          display={'flex'}
          sx={{
            borderRadius: '4px',
            backgroundColor: '#FAF8F7',
            padding: '2px 8px 2px 8px'
          }}>
          <Typography
            color="text.primary"
            sx={{
              textAlign: 'start',
              marginInlineEnd: 1,
              fontSize: 11,
              textTransform: 'uppercase'
            }}>
            <IntlMessages id="tweaks.totals" />
          </Typography>
          <Typography fontWeight={500} fontSize={11} color="primary">
            {NumbersService.formatNumberToCurrency(totalCost)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />

      <KeyboardArrowDownIcon
        sx={{
          color: 'black',
          backgroundColor: '#EFECEB',
          borderRadius: '50%',
          width: '16px',
          height: '16px'
        }}
      />
    </>
  );
};

export const TweaksTotal = () => {
  const { rooms: _rooms } = useSelector<AppState, AppState['viewer']>(({ viewer }) => viewer);
  const { item: _home } = useSelector<AppState, AppState['home']>(({ home }) => home);
  const [rooms, setRooms] = useState<IRoomInfo[]>([]);

  const [open, setOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<IRoomInfo>();
  const [modificationsList, setModificationsList] = useState<GroupedModifcationWithCost[]>([]);
  const [openInReviewModal, setOpenInReviewModal] = useState(false);
  const [showSubmitTimerSnackbar, setshowSubmitTimerSnackbar] = useState<boolean>(false);
  const [showUnderReview, setShowUnderReview] = useState<boolean>(false);
  const [showSubmitSnackbar, setshowSubmitSnackbar] = useState<boolean>(false);
  const [showSubmitModal, setShowSubmitModal] = useState<boolean>(false);

  const isHomeLockedForChanges = useHomeStatus();
  const anchorRef = useRef<HTMLDivElement>(null);
  const totalCost = useTotalCosts();
  const theme = useTheme();

  const { items: modifications } = useSelector<AppState, AppState['modification']>(
    ({ modification }) => modification
  );
  const reduxDispatch = useDispatch();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const resetHomeInstance = useResetHome();
  const homeButtonStatus = useHomeButtonStatus();

  useEffect(() => {
    if (_home?.status === STATUS_TYPE.SUBMITTED && _home?.submissionDueDate) {
      if (isHomeLockedForChanges) {
        setshowSubmitTimerSnackbar(true);
      } else {
        onTimeEndedForModifications();
        console.log('time end');
      }
    }
  }, [_home?.status, isHomeLockedForChanges]);

  useEffect(() => {
    if (_rooms?.length > 0) {
      const globalRoom: IRoomInfo = {
        dbId: 0,
        id: 'GlobalId',
        boundingBox: null,
        properties: undefined,
        category: 'Global'
      };
      setRooms([globalRoom, ..._rooms]);

      setSelectedRoom(_rooms[0]);
    }
  }, [_rooms]);

  useEffect(() => {
    if (_rooms?.length > 0) {
      selectRoom(rooms[0]);
    }
  }, [open]);

  const onTimeEndedForModifications = () => {
    console.log('time ended');
    setShowUnderReview(true);
    setshowSubmitTimerSnackbar(false);
    // disable user interactions
  };

  const handleOnConfirm = () => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.SUBMIT_HOME, CONTEXTS.HOME, {
      home_id: _home._id,
      btn_clicked: 'yes'
    });

    reduxDispatch(
      submitHome(_home._id, () => {
        setTimeout(() => {
          setshowSubmitSnackbar(false);
          setshowSubmitTimerSnackbar(true);
        }, 2000);
        reduxDispatch(getModifications(_home._id));
      })
    );

    setShowSubmitModal(false);
  };

  const onClick = () => {
    if (homeButtonStatus === 'review') {
      setOpenInReviewModal(true);
    } else if (homeButtonStatus === 'available') {
      const biService = BIService.getInstance();
      biService.logEvent(EVENT_TYPES.SUBMIT_SHOW_DIALOG, CONTEXTS.HOME, {
        home_id: _home._id
      });
      setShowSubmitModal(true);
    }
  };

  const onDoneSubmit = () => {
    setShowSubmitModal(false);
    setshowSubmitSnackbar(true);
    handleOnConfirm();
  };

  const selectRoom = (room: IRoomInfo) => {
    let ModificationListForRoom: GroupedModifcationWithCost[] = [];
    const foundSelectedPackageInRoom: IRoomPackage[] = [];
    let roomModifications: IModification[] = [];
    const viewer = Customizer.viewerInstance;

    // _home.selectedPackages
    if (room?.category === 'Global') {
      roomModifications = modifications.filter(
        (modification) =>
          modification.type === MODIFICATION_TYPE.TILE &&
          modification.attrs?.elementType?.toLowerCase() === 'floor'
      );
    } else {
      roomModifications = modifications
        .filter((modification) => modification.room === room?.category)
        .filter((modification) => modification.type !== MODIFICATION_TYPE.TILE);

      const roomTiles = modifications.filter(
        (modification) =>
          modification.room === room?.category &&
          modification.type === MODIFICATION_TYPE.TILE &&
          modification.attrs?.elementType?.toLowerCase() === 'wall'
      );

      roomModifications = [...roomModifications, ...roomTiles];

      const roomPackages = _home?.modelTemplate?.packages?.filter((x) => x.room === room?.category);

      roomPackages?.forEach((roomPackage: IRoomPackage) => {
        const foundPackage = _home?.selectedPackages?.some((x) => x.packageId === roomPackage._id);
        if (foundPackage) {
          foundSelectedPackageInRoom.push(roomPackage);
        }
      });
    }

    const lightModifications: GroupedModifcationWithCost[] = [];

    roomModifications.forEach((roomModification) => {
      if (roomModification.type === MODIFICATION_TYPE.LIGHT) {
        if (roomModification.attrs?.fixtureAttrs) {
          const modification: GroupedModifcationWithCost = {
            type: roomModification.attrs?.fixtureType,
            cost: roomModification.cost,
            id: roomModification._id,
            name: ForgeUtils.getName(roomModification.dbId, roomModification.modelId, viewer)
          };

          lightModifications.push(modification);
        }

        if (roomModification.attrs?.switches?.length > 0) {
          roomModification.attrs?.switches?.forEach((switchItem) => {
            const modification: GroupedModifcationWithCost = {
              type: switchItem.type,
              cost: switchItem.cost,
              id: roomModification._id,
              name: ForgeUtils.getName(switchItem.dbId, switchItem.attrs.modelId, viewer)
            };

            lightModifications.push(modification);
          });
        }
      }

      if (roomModification.type !== MODIFICATION_TYPE.LIGHT) {
        let modelId = roomModification?.attrs?.modelId;
        if (modelId === undefined) {
          modelId = 1;
        }

        let name = ForgeUtils.getName(roomModification.dbId, modelId, viewer);

        if (roomModification.type === MODIFICATION_TYPE.TILE) {
          const tileName = Customizer.getWallsGroupNameByDbid(roomModification.dbId);
          if (tileName !== '') {
            name = tileName;
            name = name.replace(/\bwalls?\b|-/gi, '');
          }
        }

        const ModificationForRoom: GroupedModifcationWithCost = {
          type: roomModification.type,
          cost: roomModification.cost,
          id: roomModification._id,
          name: name
        };

        ModificationListForRoom.push(ModificationForRoom);
      }
    });

    foundSelectedPackageInRoom.forEach((roomPackage) => {
      const ModificationForRoom: GroupedModifcationWithCost = {
        type: MODIFICATION_TYPE.PACKAGE,
        cost: roomPackage.price,
        id: roomPackage._id,
        name: roomPackage.title
      };
      ModificationListForRoom.push(ModificationForRoom);
    });

    ModificationListForRoom = [...ModificationListForRoom, ...lightModifications];

    setModificationsList(ModificationListForRoom);
    setSelectedRoom(room);
  };

  const handleToggle = () => {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.SUMMARY_CLICKED, CONTEXTS.SUMMARY, {
      is_open: !open
    });
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const uniqueModificationsList = modificationsList.reduce((acc, value) => {
    if (value.type.toLowerCase() === 'tile') {
      // Check if a room with the same name has already been included
      if (!acc.some((item) => item.name === value.name && item.type.toLowerCase() === 'tile')) {
        acc.push(value);
      }
    } else {
      acc.push(value);
    }
    return acc;
  }, []);

  return (
    <>
      {openInReviewModal ? (
        <InReviewModal
          handleOnConfirm={handleOnConfirm}
          openInReviewModal={openInReviewModal}
          setOpenInReviewModal={setOpenInReviewModal}
        />
      ) : null}

      {resetHomeInstance.isConfirmationDialogOpen
        ? resetHomeInstance.resetRoomConfirmationModal(selectedRoom)
        : null}
      <AppConfirmDialog
        type="confirm"
        open={isConfirmationDialogOpen}
        onDeny={() => {
          const biService = BIService.getInstance();
          biService.logEvent(EVENT_TYPES.SUBMIT_HOME, CONTEXTS.HOME, {
            home_id: _home._id,
            btn_clicked: 'no'
          });

          setIsConfirmationDialogOpen(false);
        }}
        onConfirm={handleOnConfirm}
        confirmCopy={<IntlMessages id="actions.areYouSure.actions.submit" />}
        denyCopy={<IntlMessages id="actions.areYouSure.actions.cancel" />}
        title={
          <Typography
            sx={{
              color: 'text.primary',
              fontSize: 14,
              textAlign: 'center',
              maxWidth: 390,
              mx: 'auto',
              mt: 6
            }}>
            <IntlMessages id="actions.areYouSure.submit" values={{ br: <br /> }} />
          </Typography>
        }
        dialogTitle={
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: 18,
              marginInline: '52px'
            }}>
            <IntlMessages id="actions.submitTweaks" />
          </Typography>
        }
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }} ref={anchorRef}>
        <ButtonGroup
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: 196,
            maxHeight: 52,
            alignItems: 'center',
            color: '#FF5913',
            padding: '0px 8px 0px 8px',
            ':hover': {
              backgroundColor: '#FAF8F7'
            }
          }}
          onClick={handleToggle}>
          <CurrentButtonOverlay totalCost={totalCost.total} />
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
            width: anchorRef.current ? anchorRef.current.clientWidth : undefined
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}>
              <Paper
                sx={{
                  position: 'relative',
                  top: -2,
                  minWidth: 537,
                  height: 514,
                  maxWidth: '100%',
                  maxHeight: '100%',
                  backgroundColor: '#ffffff',
                  borderRadius: '12px',
                  border: '1px solid rgba(0, 0, 0, 0.10)',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  boxShadow:
                    '0px 1px 7px 0px rgba(0, 0, 0, 0.06), 0px 8px 24px 0px rgba(0, 0, 0, 0.06);'
                }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <Grid
                    container
                    sx={{
                      color: 'text.primary',
                      display: 'flex',
                      flexDirection: 'row',
                      width: 593,
                      height: 514
                    }}>
                    <Grid
                      item
                      xs={5}
                      style={{
                        padding: '24px 24px 0px 24px'
                      }}
                      sx={{
                        position: 'relative',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        height: '439px',
                        borderRight: '1px solid #EFECEB'
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          height: '40px',
                          gap: '21px',
                          alignItems: 'center'
                        }}>
                        <Typography fontSize={24} fontWeight={500} lineHeight={19}>
                          <IntlMessages id="viewer.controls.room" />
                        </Typography>
                      </Box>
                      <Scrollbars autoHide dir={'ltr'}>
                        <List
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            maxHeight: '400px'
                          }}>
                          {rooms.map((room) => {
                            const roomKeyId = findRoomKeyByRevitRoomName(
                              room.category.toLowerCase()
                            );
                            return (
                              <ListItem key={room.id} disablePadding>
                                <ListItemButton
                                  sx={{
                                    backgroundColor: selectedRoom === room ? '#f6f6f6' : '#ffffff',
                                    borderLeft:
                                      selectedRoom === room ? '2px solid #FF5A00' : 'none',
                                    borderRadius: '4px',
                                    paddingInline: '10px',
                                    ':hover': {
                                      backgroundColor: '#f6f6f6'
                                    }
                                  }}
                                  onClick={() => {
                                    const biService = BIService.getInstance();
                                    biService.logEvent(EVENT_TYPES.ROOM_CLICKED, CONTEXTS.SUMMARY, {
                                      room_name: room.category,
                                      room_key: findRoomKeyByRevitRoomName(room.category)
                                    });
                                    selectRoom(room);
                                  }}>
                                  <Typography fontSize={14}>
                                    <IntlMessages id={roomKeyId} />
                                  </Typography>

                                  <ArrowRightIcon
                                    sx={{
                                      position: 'absolute',
                                      right: 0,
                                      color: '#252525'
                                    }}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Scrollbars>
                    </Grid>

                    <Grid
                      item
                      xs={7}
                      style={{
                        padding: '24px 24px 0px 24px'
                      }}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '439px',
                        alignItems: 'center'
                      }}>
                      <Button
                        sx={{
                          borderRadius: '88px',
                          height: '28px',
                          width: '161px',
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: '#EFECEB',
                          transition: 'all 0.3s ease',
                          ':hover': {
                            backgroundColor: '#EFECEB',
                            transform: 'scale(1.02)'
                          },
                          alignSelf: 'flex-end',
                          gap: '8px',
                          mb: '16px'
                        }}
                        onClick={() => {
                          resetHomeInstance.setIsConfirmationDialogOpen(true);
                        }}>
                        <PendEdit />
                        <Typography
                          fontSize={12}
                          fontWeight={500}
                          sx={{
                            color: '#0A0A0A'
                          }}>
                          <IntlMessages id="tweaks.total.costs.resetChanges" />
                        </Typography>
                      </Button>

                      <Scrollbars autoHide dir="ltr">
                        <List
                          sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',

                            position: 'relative'
                          }}>
                          {modificationsList.length === 0 ? (
                            <ListItem key={0} disablePadding>
                              <Box
                                width={'100%'}
                                flex={1}
                                alignItems={'center'}
                                justifyContent={'center'}
                                textAlign={'center'}>
                                <FindInPageOutlined sx={{ fontSize: 48 }} />
                                <Typography fontSize={14} fontWeight={400} lineHeight={1}>
                                  <IntlMessages id="tweaks.totalCost.noChanges" />
                                </Typography>
                              </Box>
                            </ListItem>
                          ) : null}
                          {uniqueModificationsList.map((value, index) => {
                            return (
                              <ListItem
                                key={index}
                                disablePadding
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '8px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    mb: '12px',
                                    mt: '12px'
                                  }}>
                                  <Typography fontWeight={'bold'} fontSize={12}>
                                    {value.name}{' '}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      gap: '10px',
                                      justifyContent: 'flex-end',
                                      alignItems: 'center'
                                    }}>
                                    <Typography
                                      fontSize={12}
                                      sx={{
                                        color: '#949290',
                                        fontWeight: 'bold'
                                      }}>
                                      {value.type === MODIFICATION_TYPE.MOVE ? (
                                        <IntlMessages id="tweaks.mod.move" />
                                      ) : value.type === MODIFICATION_TYPE.ADD ? (
                                        <IntlMessages id="tweaks.mod.add" />
                                      ) : value.type === MODIFICATION_TYPE.DELETE ? (
                                        <IntlMessages id="tweaks.mod.delete" />
                                      ) : value.type === MODIFICATION_TYPE.TILE ? (
                                        <IntlMessages id="tweaks.mod.tile" />
                                      ) : value.type === MODIFICATION_TYPE.PACKAGE ? (
                                        <IntlMessages id="tweaks.mod.package" />
                                      ) : (
                                        <IntlMessages id="tweaks.mod.move" />
                                      )}
                                    </Typography>
                                    <Typography fontSize={14} fontWeight={500} color="#252525">
                                      {NumbersService.formatNumberToCurrency(value.cost)}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Divider flexItem sx={{ width: '100%', borderStyle: 'dashed' }} />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Scrollbars>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ height: '75px' }}
                      sx={{
                        borderTop: '1px solid #EFECEB',
                        zIndex: 10
                      }}>
                      <Box
                        width={'100%'}
                        display={'flex'}
                        sx={{ alignItems: 'center', height: '100%' }}
                        padding={'16px 24px 16px 24px'}
                        justifyContent="space-between">
                        <Box
                          sx={{
                            height: '75px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            pointerEvents: 'none'
                          }}>
                          <Typography fontSize={18} fontWeight={700}>
                            <IntlMessages id="tweaks.total.costs.summary" />: &nbsp;
                          </Typography>

                          <Typography fontWeight={600} fontSize={16} color="primary">
                            {NumbersService.formatNumberToCurrency(totalCost.total)}
                          </Typography>
                        </Box>
                        <Button
                          disabled={homeButtonStatus === 'disabled'}
                          onClick={onClick}
                          variant={homeButtonStatus === 'review' ? 'review' : 'contained'}
                          sx={{
                            width: 175,
                            height: 46,
                            borderRadius: 40,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}>
                          <Typography
                            sx={{
                              fontSize: 14,
                              ...(homeButtonStatus === 'review' ? { fontWeight: 'bold' } : {})
                            }}>
                            {homeButtonStatus === 'review' ? (
                              <IntlMessages id="tweaks.totalCost.inReview" />
                            ) : (
                              <IntlMessages id="tweaks.totalCost.submit" />
                            )}
                          </Typography>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {showSubmitModal && (
          <SubmitDesignModal
            onDoneSubmit={onDoneSubmit}
            onCancelSubmit={() => {
              setShowSubmitModal(false);
            }}
          />
        )}

        <TimerSnackBar isOpen={showSubmitTimerSnackbar} timeEnded={onTimeEndedForModifications} />
        <SubmitSnackBar isOpen={showSubmitSnackbar} />
        <UnderReviewSnackBar isOpen={showUnderReview} />
      </Box>
    </>
  );
};
