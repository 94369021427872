import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { STATUS_TYPE } from 'types/models/Home';

const counterObservale = () => {
  let listeners = [];
  let interval: any;
  let timeLeftToEndTime = 0;
  let endTime = 1;

  const get = () => {
    return timeLeftToEndTime;
  };

  const setEndTime = (targetEndTime: number) => {
    endTime = targetEndTime;
  };

  const set = (newValue: number) => {
    if (timeLeftToEndTime === newValue) return;
    timeLeftToEndTime = newValue;
    listeners.forEach((l) => l(timeLeftToEndTime));
  };

  const subscribe = (listenerFunc) => {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc);
  };

  const startCouting = () => {
    if (!interval) {
      interval = setInterval(() => {
        const differenceInMilliseconds = endTime - Date.now();
        const totalMinutesTillTimeEnds = differenceInMilliseconds / (60 * 1000);

        set(totalMinutesTillTimeEnds);
      }, 60 * 100);
      return () => {
        clearInterval(interval);
      };
    }
  };

  const stopCounting = () => {
    clearInterval(interval);
  };

  const unsubscribe = (listenerFunc) => {
    listeners = listeners.filter((l) => l !== listenerFunc);
  };

  return {
    get,
    set,
    subscribe,
    startCouting,
    stopCounting,
    setEndTime
  };
};

const counterStore = counterObservale();

export const useHomeStatus = () => {
  const { item: home } = useSelector<AppState, AppState['home']>(({ home }) => home);
  const [isHomeLockedForChanges, setIsHomeLockedForChanges] = useState<boolean>(false);
  const [minutesLeft, setMinutesLeft] = useState(100);

  const startTime = new Date(home?.submissionDueDate || 0).getTime() - 48 * 60 * 60 * 1000;
  const endTime = new Date(home?.submissionDueDate || 0).getTime();
  const totalDuration = endTime - startTime;
  const elapsedTime = Date.now() - startTime;
  const percentageElapsed = (elapsedTime / totalDuration) * 100;

  useEffect(() => {
    if (home?.submissionDueDate === undefined || home.status !== STATUS_TYPE.SUBMITTED) return;

    const endTime = new Date(home?.submissionDueDate || 0).getTime();

    counterStore.setEndTime(endTime);
    const differenceInMilliseconds = endTime - Date.now();
    const minLeft = differenceInMilliseconds / (60 * 1000);
    setMinutesLeft(minLeft);

    counterStore.subscribe((minLeft: number) => {
      setMinutesLeft(minLeft);

      if (minLeft < 1) {
        setIsHomeLockedForChanges(true);
        counterStore.stopCounting();
      }
    });

    counterStore.startCouting();
  }, [home?.submissionDueDate, home?.status]);

  return { isHomeLockedForChanges, minutesLeft, percentageElapsed };
};
