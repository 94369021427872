import {
  bathRoomNames,
  doubleSocketOutLetNames,
  singleSocketOutLetNames,
  switchesNames
} from 'shared/constants/AppConst';
import { Customizer } from 'shared/extensions/tweaksx-customizer';
import { Vector3 } from 'three';
import { IElementInfo } from 'types/models/ForgeViewer';

export interface IRevitDistanceLimit {
  WINDOWS: number;
  DOORS: number;
  WALLEDGE: number;
  CEILINGS: number;
  FLOORS: number;
  FLOORHEIGHT: number;
  OULETS: number;
}

const distanceToCm = 3.28084;

export const revitDistanceLimitOutLet: IRevitDistanceLimit = {
  WINDOWS: 0.15 * distanceToCm,
  DOORS: 0.15 * distanceToCm,
  WALLEDGE: 0.15 * distanceToCm,
  CEILINGS: 0.2 * distanceToCm,
  FLOORS: 0.3 * distanceToCm,
  FLOORHEIGHT: 0.5 * distanceToCm,
  OULETS: 0.15 * distanceToCm
};

export const revitDistanceLimitSwitch: IRevitDistanceLimit = {
  WINDOWS: 0.15 * distanceToCm,
  DOORS: 0.15 * distanceToCm,
  WALLEDGE: 0.15 * distanceToCm,
  CEILINGS: 0.2 * distanceToCm,
  FLOORS: 0.3 * distanceToCm,
  FLOORHEIGHT: 0.5 * distanceToCm,
  OULETS: 0.15 * distanceToCm
};

export const getDistaceLimit = (elementName: string): IRevitDistanceLimit => {
  if (isOutlet(elementName)) {
    return revitDistanceLimitOutLet;
  }
  if (isSwitch(elementName)) {
    return revitDistanceLimitSwitch;
  }

  return revitDistanceLimitOutLet;
};

export enum EDGE_AXIS {
  X,
  Y,
  Z
}

export const isSwitch = (name: string): boolean => {
  let _isSwitch = false;
  switchesNames.forEach((x) => {
    if (name.toLowerCase().includes(x.toLowerCase())) {
      _isSwitch = true;
    }
  });

  return _isSwitch;
};

export const isOutlet = (name: string): boolean => {
  let _isOutlet = false;
  doubleSocketOutLetNames.forEach((x) => {
    if (name.toLowerCase().includes(x.toLowerCase())) {
      _isOutlet = true;
    }
  });

  singleSocketOutLetNames.forEach((x) => {
    if (name.toLowerCase().includes(x.toLowerCase())) {
      _isOutlet = true;
    }
  });

  return _isOutlet;
};

export const isSingleOutlet = (name: string): boolean => {
  let _isSingleOutlet = false;

  singleSocketOutLetNames.forEach((x) => {
    if (name.toLowerCase().includes(x.toLowerCase())) {
      _isSingleOutlet = true;
    }
  });

  return _isSingleOutlet;
};

export const isBathRoom = (name: string): boolean => {
  let _isBathRoom = false;

  bathRoomNames.forEach((x) => {
    if (name.toLowerCase().includes(x.toLowerCase())) {
      _isBathRoom = true;
    }
  });

  return _isBathRoom;
};

const calcElementSizeInCm = (
  viewer: Autodesk.Viewing.GuiViewer3D,
  element: IElementInfo
): { width: number; height: number; depth: number } => {
  let size: { width: number; height: number; depth: number } = {
    width: 0,
    height: 0,
    depth: 0
  };

  const bbox = getBoundingBox(element.dbId, element.modelId, viewer);
  const bBoxSize = new THREE.Vector3();
  bbox.getSize(bBoxSize);

  if (bbox) {
    size = {
      width: bBoxSize.x * 10 * 3.28084,
      height: bBoxSize.y * 10 * 3.28084,
      depth: bBoxSize.z * 10 * 3.28084
    };
  }

  console.log(bBoxSize);

  return size;
};

export const isDoubleOutlet = (name: string): boolean => {
  let _isDoubleOutlet = false;

  doubleSocketOutLetNames.forEach((x) => {
    if (name.toLowerCase().includes(x.toLowerCase())) {
      _isDoubleOutlet = true;
    }
  });
  return _isDoubleOutlet;
};

export const isLight = (name: string, category: string): boolean => {
  let _isLight = false;
  if (category === 'Revit Lighting Fixtures' && !isSwitch(name)) {
    _isLight = true;
  }

  return _isLight;
};

export interface IDbidWithModelId {
  dbId: number;
  model: Autodesk.Viewing.Model;
}

const getFragmentsByDbid = (
  elementId: number,
  modelId: number,
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  let model;
  if (modelId === undefined) {
    model = viewer.model;
  } else {
    const models = viewer.getAllModels();
    model = models.filter((m) => m.id === modelId)[0];
  }

  const tree = model.getData().instanceTree;
  const elementFragIds = [];
  tree.enumNodeFragments(
    elementId,
    function (fragId) {
      elementFragIds.push(fragId);
    },
    true
  );

  return elementFragIds;
};

const getHitTestWithFilters = (
  mouseEvent,
  viewer: Autodesk.Viewing.GuiViewer3D,
  filterDbids: number[]
) => {
  const _pointerVector = new THREE.Vector3();
  const _rayCaster = new THREE.Raycaster();
  const camera = viewer?.navigation?.getCamera();

  if (!camera) {
    console.log('No Camera Found!');
    return;
  }

  const x = (mouseEvent.canvasX / camera.clientWidth) * 2 - 1;
  const y = -(mouseEvent.canvasY / camera.clientHeight) * 2 + 1; // y-direction flips between canvas and viewport coords

  _pointerVector.set(x, y, 0.5);
  _pointerVector.unproject(camera);
  _rayCaster.set(camera.position, _pointerVector.sub(camera.position).normalize());
  const intersection = viewer.impl.rayIntersect(_rayCaster.ray, false, filterDbids);

  return intersection;
};

const highlightObject = (dbId: number, modelId: number, viewer: Autodesk.Viewing.GuiViewer3D) => {
  const fragIds = getFragmentsByDbid(dbId, modelId, viewer);
};

const getHitTest = (mouseEvent, viewer: Autodesk.Viewing.GuiViewer3D) => {
  const screenPoint = {
    x: mouseEvent.canvasX,
    y: mouseEvent.canvasY
  };

  const hitPoint = viewer.impl.hitTest(screenPoint.x, screenPoint.y, false);

  if (!hitPoint) return;

  return hitPoint;
};

const getGlobalOffset = (viewer: Autodesk.Viewing.GuiViewer3D): Vector3 => {
  const globalOffset = viewer.model.getData().globalOffset;
  const offsetVec = new THREE.Vector3(globalOffset.x, globalOffset.y, globalOffset.z);

  return offsetVec;
};

const getDbIdsByCategory = (category: string, viewer: any): [] => {
  viewer.search(
    category,
    (dbIds: []) => {
      console.log('dbids', dbIds);
      return dbIds;
    },
    () => {
      console.log('error getting dbids , did viewer loaded ?');
      return [];
    },
    ['Category'],
    { searchHidden: true }
  );
  return [];
};

const getDbIdsByCategoryAsync = async (category: string, viewer) => {
  const elementsDbIds = await new Promise((resolve, reject) => {
    viewer.search(
      category,
      (dbIds) => {
        resolve(dbIds);
      },
      (error) => reject(error),
      ['Category'],
      { searchHidden: true }
    );
  });

  return elementsDbIds;
};

const getDistanceBetweenPoints = (point1: Vector3, point2: Vector3): number => {
  const diff = {
    x: point1.x - point2.x,
    y: point1.y - point2.y,
    z: point1.z - point2.z
  };

  const dist = Math.sqrt(diff.x * diff.x + diff.y * diff.y + diff.z * diff.z);
  return dist;
};

const getBoundingBox = (dbId: number, modelId: number, viewer: Autodesk.Viewing.GuiViewer3D) => {
  let model;
  if (modelId === undefined) {
    model = viewer.model;
  } else {
    const models = viewer.getAllModels();
    model = models.filter((m) => m.id === modelId)[0];
  }

  if (model === null || model === undefined) return null;

  const it = model.getInstanceTree();
  const fragList = model.getFragmentList();
  const bounds = new THREE.Box3();

  it.enumNodeFragments(
    dbId,
    (fragId) => {
      const box = new THREE.Box3();
      fragList.getWorldBounds(fragId, box);
      bounds.union(box);
    },
    true
  );

  return bounds;
};

function getClosestVertex(point, mesh) {
  // convert point to local space
  mesh.worldToLocal(point);

  // initialize variables for closest vertex and distance
  let closestVertex, closestDistance;

  // iterate through all vertices
  for (let i = 0; i < mesh.geometry.attributes.position.count; i++) {
    const vertex = mesh.geometry.vertices[i];

    // calculate distance between vertex and point
    const distance = Math.abs(vertex.z - point.z);

    // update closest vertex and distance if necessary
    if (closestDistance === undefined || distance < closestDistance) {
      closestDistance = distance;
      closestVertex = vertex;
    }
  }
}

const getWorldPosition = (
  position: THREE.Vector3,
  fragRef,
  viewer: Autodesk.Viewing.GuiViewer3D
): THREE.Vector3 => {
  const renderProxy = viewer.impl.getRenderProxy(viewer.model, fragRef);
  const worldPos = position.clone();
  worldPos.applyMatrix4(renderProxy.matrixWorld);
  return worldPos;
};

const isToCloseToDbids = (
  location: THREE.Vector3,
  dbidsWithModelId: IDbidWithModelId[],
  distanceLimitByCategory: number,
  viewer: Autodesk.Viewing.GuiViewer3D,
  useCenter = false
): boolean => {
  let result = false;

  for (let i = 0; i < dbidsWithModelId.length; i++) {
    if (!dbidsWithModelId[i].model) continue;
    const bBox = getBoundingBox(dbidsWithModelId[i].dbId, dbidsWithModelId[i].model.id, viewer);
    if (!bBox) continue;
    let distance = 0;
    if (useCenter) {
      distance = bBox.getCenter().distanceTo(location);
    } else {
      distance = bBox.distanceToPoint(location);
    }

    if (distance < distanceLimitByCategory) {
      result = true;
      console.log(dbidsWithModelId[i].dbId, ' dist : ' + distance / distanceToCm);
    }
  }

  return result;
};

const isPointTooCloseToEdge = (
  point: THREE.Vector3,
  edgePoint: THREE.Vector3,
  axis: EDGE_AXIS,
  tolerance: number
): boolean => {
  let isTooClose = false;
  switch (axis) {
    case EDGE_AXIS.X:
      isTooClose = Math.abs(point.x - edgePoint.x) < tolerance;
      break;
    case EDGE_AXIS.Y:
      isTooClose = Math.abs(point.y - edgePoint.y) < tolerance;
      break;
    case EDGE_AXIS.Z:
      isTooClose = Math.abs(point.z - edgePoint.z) < tolerance;
      break;
    default:
      console.error('Invalid axis specified. Choose "x", "y", or "z".');
  }
  return isTooClose;
};

const isPointTooHighFromCenter = (
  hitPoint: THREE.Vector3,
  center: THREE.Vector3,
  distanceLimitByCategory: number
): boolean => {
  const distance = Math.abs(hitPoint.z - center.z);

  const distanceInMeters = distance / 3.2808399;

  if (distanceInMeters < distanceLimitByCategory) {
    return false;
  }

  return true;
};

const isPointTooCloseToCenter = (
  hitPoint: THREE.Vector3,
  center: THREE.Vector3,
  distanceLimitByCategory: number
): boolean => {
  const distance = Math.abs(hitPoint.z - center.z);

  const distanceInMeters = distance / 3.2808399;

  if (distanceInMeters > distanceLimitByCategory) {
    return false;
  }

  return true;
};

const getPosition = (
  dbId: number,
  modelId: number,
  viewer: Autodesk.Viewing.GuiViewer3D
): Vector3 => {
  let model;
  if (modelId === undefined) {
    model = viewer.model;
  } else {
    const models = viewer.getAllModels();
    model = models.filter((m) => m.id === modelId)[0];
  }

  const instanceTree = model.getData().instanceTree;
  const fragList = model.getFragmentList();

  const bounds = new THREE.Box3();

  instanceTree.enumNodeFragments(
    dbId,
    (fragId) => {
      const box = new THREE.Box3();
      fragList.getWorldBounds(fragId, box);
      bounds.union(box);
    },
    true
  );

  const position = bounds.getCenter();

  return position;
};

const getModel = (modelId: number, viewer: Autodesk.Viewing.GuiViewer3D) => {
  let model;
  if (modelId === undefined) {
    model = viewer.model;
  } else {
    const models = viewer
      .getAllModels()
      .filter((model: any) => !model._isSwatch && !model._isMaster);
    model = models.filter((m) => m.id === modelId)[0];
  }

  return model;
};

const getNearByWalls = async (
  dbid: number,
  modelId: number,
  wallsDbids: [],
  faceNormal: THREE.Vector3,
  viewer: Autodesk.Viewing.GuiViewer3D,
  unconnectedHeightPropFilter: number // some walls as small height number , we can filter them out
) => {
  const bBox = getBoundingBox(dbid, modelId, viewer);

  const dbids = wallsDbids;
  const candidates: number[] = [];

  const wallsProp = (await Customizer.getBulkProperties(
    wallsDbids,
    'Unconnected Height',
    getModel(modelId, viewer)
  )) as any;

  const onlyWallsProps = wallsProp.filter(
    (x) => x.properties[0].displayValue > unconnectedHeightPropFilter
  );

  for (let i = 0; i < onlyWallsProps.length; i++) {
    const hostBBox = getBoundingBox(onlyWallsProps[i].dbId, modelId, viewer);

    if (bBox.intersectsBox(hostBBox)) {
      if (faceNormal.x !== 0) {
        if (faceNormal.x === -1) {
          if (hostBBox.min.x === bBox.min.x) {
            candidates.push(onlyWallsProps[i]);
          }
        }

        if (faceNormal.x === 1) {
          if (hostBBox.max.x === bBox.max.x) {
            candidates.push(onlyWallsProps[i]);
          }
        }
      }

      if (faceNormal.y !== 0) {
        if (faceNormal.y === -1) {
          if (hostBBox.min.y === bBox.min.y) {
            candidates.push(onlyWallsProps[i]);
          }
        }

        if (faceNormal.y === 1) {
          if (hostBBox.max.y === bBox.max.y) {
            candidates.push(onlyWallsProps[i]);
          }
        }
      }
    }
  }

  console.log('near by walls', candidates);
  return candidates;
};

const calcOffsetCenterBased = (
  hitPoint: THREE.Vector3,
  center: THREE.Vector3,
  angle: number
): THREE.Vector3 => {
  let y = hitPoint.y - center.y;
  y = y * Math.abs(Math.sin(angle));
  let x = hitPoint.x - center.x;
  x = x * Math.abs(Math.cos(angle));

  const offset = new THREE.Vector3(x, y, hitPoint.z - center.z);

  return offset;
};

const moveFragmentToHitPoint = (
  fragId: number,
  modelId: number,
  angle: number,
  hitPoint: THREE.Vector3,
  viewer: any,
  center: THREE.Vector3
) => {
  let model;
  if (modelId === undefined) {
    model = viewer.model;
  } else {
    const models = viewer.getAllModels();
    model = models.filter((m) => m.id === modelId)[0];
  }

  const fragProxy = viewer.impl.getFragmentProxy(model, fragId);
  fragProxy.getAnimTransform();
  const worldPositionDiff = calcOffsetCenterBased(hitPoint, center, angle);
  fragProxy.position.add(worldPositionDiff);
  fragProxy.updateAnimTransform();
  viewer.impl.invalidate(true);
};

const getMeshFromFargment = (fragId: number, modelId: string, viewer): THREE.Mesh => {
  let model;
  if (modelId === undefined) {
    model = viewer.model;
  } else {
    const models = viewer.getAllModels();
    model = models.filter((m) => m.id === modelId)[0];
  }

  const fragProxy = viewer.impl.getRenderProxy(model, fragId);

  return fragProxy;
};

const calcOffset = (hitPoint: THREE.Vector3, fragProxy: any): THREE.Vector3 => {
  const fragCenter = fragProxy.position;
  const fragWorldMatrix = new THREE.Matrix4();
  fragProxy.getWorldMatrix(fragWorldMatrix);
  const fragWorldPosition = new THREE.Vector3();
  fragWorldPosition.setFromMatrixPosition(fragWorldMatrix);
  const _calcOffset = hitPoint.clone().sub(fragWorldPosition);

  return _calcOffset;
};

const moveFragmentsToHitPoint = (
  fragIds: number[],
  modelid: number,
  dbId: number,
  angle: number,
  hitPoint: THREE.Vector3,
  viewer: any
) => {
  const bBox = getBoundingBox(dbId, modelid, viewer);

  const center = bBox.getCenter();
  // const diff = center.sub(hitPoint);

  fragIds.forEach((fragId) => {
    moveFragmentToHitPoint(fragId, modelid, angle, hitPoint, viewer, center);
  });
};

const getBoundingBoxDbids = (
  dbId: number[],
  modelId: number,
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  let model;
  if (modelId === undefined) {
    model = viewer.model;
  } else {
    const models = viewer.getAllModels();
    model = models.filter((m) => m.id === modelId)[0];
  }
  const it = model.getInstanceTree();
  const fragList = model.getFragmentList();
  const bounds = new THREE.Box3();

  dbId.forEach((dbId) => {
    it.enumNodeFragments(
      dbId,
      (fragId) => {
        const box = new THREE.Box3();
        fragList.getWorldBounds(fragId, box);
        bounds.union(box);
      },
      true
    );
  });

  return bounds;
};

const getAngleFromFace = (face: THREE.Face3): number => {
  let angle = 1;
  const x = Math.round(face.normal.x);
  const y = Math.round(face.normal.y);

  if (x === 0 && y === -1) {
    angle = 0;
  } else if (x === 0 && y === 1) {
    angle = 2 * Math.PI * 0.5;
  } else if (x === 1 && y === 0) {
    angle = 2 * Math.PI * 0.25;
  } else if (x === -1 && y === 0) {
    angle = 2 * Math.PI * 0.75;
  }

  return angle;
};

const faceCameraToWall = async (
  selectedElement: IElementInfo,
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  if (selectedElement.category !== 'Revit Walls') {
    console.log('Not Wall!');
    return;
  }

  const distance = 4;
  const bBox = getBoundingBox(selectedElement.dbId, selectedElement.modelId, viewer);

  const cam = viewer.getCamera();
  const camPos = cam.getWorldPosition();

  const newX = camPos.x - distance * Math.sin(selectedElement.angle);
  const newY = camPos.y + distance * Math.cos(selectedElement.angle);

  const lookAtPosition = new THREE.Vector3(newX, newY, camPos.z);

  viewer.navigation.setView(cam.position, lookAtPosition);
  viewer.navigation.fitBounds(false, bBox, true);
};

// First point is left side of bbox but y position of hitpoint
const getBBoxEdgePositions = (
  bBox: THREE.Box3,
  hitTest: Autodesk.Viewing.Private.HitTestResult,
  zOffset = 0
): THREE.Vector3[] => {
  let pointEdges: THREE.Vector3[];
  const face = hitTest.face;
  const x = Math.round(face.normal.x);
  const y = Math.round(face.normal.y);

  if (x === -1 && y === 0)
    pointEdges = [
      new THREE.Vector3(hitTest.point.x - zOffset, bBox.max.y, bBox.max.z),
      new THREE.Vector3(hitTest.point.x - zOffset, bBox.min.y, bBox.min.z)
    ];
  else if (x === 1 && y === 0)
    pointEdges = [
      new THREE.Vector3(hitTest.point.x + zOffset, bBox.min.y, bBox.max.z),
      new THREE.Vector3(hitTest.point.x + zOffset, bBox.max.y, bBox.min.z)
    ];
  else if (x === 0 && y === -1)
    pointEdges = [
      new THREE.Vector3(bBox.min.x, hitTest.point.y - zOffset, bBox.max.z),
      new THREE.Vector3(bBox.max.x, hitTest.point.y - zOffset, bBox.min.z)
    ];
  else if (x === 0 && y === 1)
    pointEdges = [
      new THREE.Vector3(bBox.max.x, hitTest.point.y + zOffset, bBox.max.z),
      new THREE.Vector3(bBox.min.x, hitTest.point.y + zOffset, bBox.min.z)
    ];

  return pointEdges;
};

// First point is left upper side of bbox
const getLeftTopRightBottomBBoxPositions = (
  bBox: THREE.Box3,
  hitTest: Autodesk.Viewing.Private.HitTestResult
): THREE.Vector3[] => {
  const face = hitTest.face;
  const x = Math.round(face.normal.x);
  const y = Math.round(face.normal.y);
  const z = Math.round(face.normal.z);

  let pointEdges: THREE.Vector3[] = [];

  if (x === -1 && y === 0) {
    pointEdges = [
      new THREE.Vector3(bBox.min.x, bBox.max.y, bBox.max.z),
      new THREE.Vector3(bBox.min.x, bBox.min.y, bBox.min.z)
    ];
  } else if (x === 1 && y === 0) {
    pointEdges = [
      new THREE.Vector3(bBox.max.x, bBox.min.y, bBox.max.z),
      new THREE.Vector3(bBox.max.x, bBox.max.y, bBox.min.z)
    ];
  } else if (x === 0 && y === -1) {
    pointEdges = [
      new THREE.Vector3(bBox.min.x, bBox.min.y, bBox.max.z),
      new THREE.Vector3(bBox.max.x, bBox.min.y, bBox.min.z)
    ];
  } else if (x === 0 && y === 1) {
    pointEdges = [
      new THREE.Vector3(bBox.max.x, bBox.max.y, bBox.max.z),
      new THREE.Vector3(bBox.min.x, bBox.max.y, bBox.min.z)
    ];
  } else if (z === -1) {
    pointEdges = [
      new THREE.Vector3(bBox.min.x, bBox.max.y, bBox.min.z),
      new THREE.Vector3(bBox.max.x, bBox.min.y, bBox.min.z)
    ];
  } else if (z === 1) {
    pointEdges = [
      new THREE.Vector3(bBox.max.x, bBox.max.y, bBox.max.z),
      new THREE.Vector3(bBox.min.x, bBox.min.y, bBox.max.z)
    ];
  }

  return pointEdges;
};

const getDbisPositions = (
  Dbids: number[],
  viewer: Autodesk.Viewing.GuiViewer3D,
  modelId: number
): THREE.Vector3[] => {
  const dbIdCenter: THREE.Vector3[] = [];

  for (const dbId of Dbids) {
    const elBBox = getBoundingBox(dbId, modelId, viewer);
    dbIdCenter.push(elBBox.getCenter());
  }
  return dbIdCenter;
};

const getdDidPosition = (
  dbId: number,
  viewer: Autodesk.Viewing.GuiViewer3D,
  modelId: number
): THREE.Vector3 => {
  return getBoundingBox(dbId, modelId, viewer).getCenter();
};

const moveModelToHitPoint = (
  dbId: number,
  modelid: number,
  angle: number,
  viewer: any,
  hitPoint: THREE.Vector3
) => {
  const fragIds = getFragmentsByDbid(dbId, modelid, viewer);
  moveFragmentsToHitPoint(fragIds, modelid, dbId, angle, hitPoint, viewer);
};

const getBaseModel = (viewer: Autodesk.Viewing.GuiViewer3D): Autodesk.Viewing.Model => {
  const models = viewer.getAllModels();
  const baseModel = models.filter((m: any) => m._isBase)[0];
  return baseModel;
};

async function getExternalIdMappingAsync(
  model: Autodesk.Viewing.Model
): Promise<{ [key: string]: number }> {
  return new Promise((resolve, reject) => {
    model.getExternalIdMapping(
      (map) => resolve(map),
      () => reject()
    );
  });
}

async function getBulkProperties(
  model: Autodesk.Viewing.Model,
  dbIds,
  propFilter
): Promise<Autodesk.Viewing.PropertyResult[]> {
  return new Promise((resolve, reject) => {
    model.getBulkProperties(
      dbIds,
      { propFilter: propFilter },
      (props) => resolve(props),
      (error) => reject(error)
    );
  });
}

function getAllTreeComponents(viewer: Autodesk.Viewing.GuiViewer3D): Promise<number[]> {
  return new Promise((resolve) => {
    let cbCount = 0; // count pending callbacks
    const components: number[] = []; // store the results
    let tree; // the instance tree

    function getLeafComponentsRec(parent) {
      cbCount++;
      if (tree.getChildCount(parent) != 0) {
        components.push(parent);
        tree.enumNodeChildren(
          parent,
          function (children) {
            getLeafComponentsRec(children);
          },
          false
        );
      } else {
        components.push(parent);
      }
      if (--cbCount == 0) resolve(components);
    }
    viewer.getObjectTree(function (objectTree) {
      tree = objectTree;
      getLeafComponentsRec(tree.getRootId());
    });
  });
}

async function getDbIdsAndModelMappedByCategory(
  viewer: Autodesk.Viewing.GuiViewer3D
): Promise<{ [key: string]: IDbidWithModelId[] }> {
  const result: { [key: string]: IDbidWithModelId[] } = {};

  const dbids = Object.keys(viewer.model.getInstanceTree().nodeAccess.dbIdToIndex).map(Number);
  const props = await getBulkProperties(viewer.model, dbids, ['Category']);
  props.forEach((prop) => {
    const category = prop.properties[0].displayValue;
    if (result[category] === undefined) {
      result[category] = [];
    }
    result[category].push({ model: viewer.model, dbId: prop.dbId });
  });

  return result;
}

async function getDbIdsAndModelByProperty(
  viewer: Autodesk.Viewing.GuiViewer3D,
  propType: string,
  propValue: string | number,
  model?: Autodesk.Viewing.Model
): Promise<IDbidWithModelId[]> {
  const models = viewer.getAllModels() as any;
  let filteredModels = models.filter((_model) => !_model._isSwatch && !_model._isMaster);
  if (model !== undefined) {
    filteredModels = [model];
  }
  const result: IDbidWithModelId[] = [];

  for (let i = 0; i < filteredModels.length; i++) {
    const instanceTree = filteredModels[i].getInstanceTree();
    const dbids = Object.keys(instanceTree ? instanceTree.nodeAccess.dbIdToIndex : []).map(Number);
    const props = await getBulkProperties(filteredModels[i], dbids, [propType]);
    props.forEach((prop) => {
      if (prop.properties[0].displayValue === propValue) {
        result.push({ model: filteredModels[i], dbId: prop.dbId });
      }
    });
  }

  return result;
}

const doesDbidExistsInViewer = async (
  dbId: number,
  revitId: number,
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  const dbids: number[] = [];
  dbids.push(dbId);
  let foundNode = false;

  const viewerModels = viewer.getAllModels();

  for (let i = 0; i < viewerModels.length; i++) {
    const node = viewerModels[i].getInstanceTree().getNodeName(dbId);
    const props = await Customizer.getBulkProperties(dbids, 'ElementId', viewerModels[i]);

    if (node !== undefined) {
      if (Number(props[0]?.properties[0]?.displayValue) === revitId) {
        console.log(node);
        foundNode = true;
      }
    }
  }

  return foundNode;
};

async function getDbIdsByProperty(
  viewer: Autodesk.Viewing.GuiViewer3D,
  propType: string,
  propValue: string | number,
  model?: Autodesk.Viewing.Model
): Promise<number[]> {
  const models = viewer.getAllModels() as any;
  let filteredModels = models.filter((model) => !model._isSwatch && !model._isMaster);
  if (model !== undefined) {
    filteredModels = [model];
  }
  const result: number[] = [];

  for (let i = 0; i < filteredModels.length; i++) {
    const instanceTree = filteredModels[i].getInstanceTree();
    const dbids = Object.keys(instanceTree ? instanceTree.nodeAccess.dbIdToIndex : []).map(Number);
    const props = await getBulkProperties(filteredModels[i], dbids, [propType]);
    props.forEach((prop) => {
      if (prop.properties[0].displayValue === propValue) {
        result.push(prop.dbId);
      }
    });
  }

  return result;
}

export const getName = (dbId: number, modelId, viewer: Autodesk.Viewing.GuiViewer3D): string => {
  let name = '';

  if (typeof modelId === 'number' || modelId === undefined) {
    const instanceTree = viewer.model.getData().instanceTree;
    if (instanceTree) {
      name = instanceTree.getNodeName(dbId);
    }
  } else {
    const model = viewer.getAllModels().filter((m) => m.id === modelId)[0];
    const instanceTree = model?.getData().instanceTree;

    if (instanceTree !== undefined) {
      name = instanceTree.getNodeName(dbId);

      if (dbId == undefined) {
        instanceTree.enumNodeChildren(
          instanceTree.getRootId(),
          (childId) => {
            name = instanceTree.getNodeName(childId);
          },
          true
        );
      }
    } else {
      name = 'Not found in model';
    }
  }

  if (name === undefined) {
    return 'Not found in model';
  }

  name = name.replace(/\[.*?\]/g, '');
  return name;
};

export const ForgeUtils = {
  getFragmentsByDbid,
  getDbIdsByCategory,
  moveFragmentsToHitPoint,
  moveModelToHitPoint,
  getHitTestWithFilters,
  getGlobalOffset,
  getBoundingBox,
  calcOffsetCenterBased,
  getAngleFromFace,
  getBBoxEdgePositions,
  isPointTooHighFromCenter,
  isPointTooCloseToEdge,
  getLeftTopRightBottomBBoxPositions,
  getMeshFromFargment,
  getDbIdsByCategoryAsync,
  getDbisPositions,
  getdDidPosition,
  getHitTest,
  faceCameraToWall,
  getDistanceBetweenPoints,
  getNearByWalls,
  getBoundingBoxDbids,
  isPointTooCloseToCenter,
  isToCloseToDbids,
  getBaseModel,
  getPosition,
  isSwitch,
  isOutlet,
  isSingleOutlet,
  isDoubleOutlet,
  isBathRoom,
  isLight,
  getModel,
  getExternalIdMappingAsync,
  getBulkProperties,
  getAllTreeComponents,
  getDbIdsAndModelMappedByCategory,
  getDbIdsAndModelByProperty,
  getDbIdsByProperty,
  getName,
  calcElementSizeInCm,
  doesDbidExistsInViewer
};
