import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import { GET_HOME, UPDATE_HOME, RESET_HOME_STORE, LOADING_HOME } from 'types/actions/Home.actions';
import { fetchError, fetchSuccess, showMessage } from './Common';
import jwtAxios from 'services/auth/jwt-auth';
import { appIntl } from 'shared/utility/helper/Utils';
import { IHome } from 'types/models/Home';
import { downloadFile } from 'shared/utility/Utils';
import { AppState } from 'redux/store';

export const updateHome = (homeId: string, data, callback?: () => void, silent = false) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_HOME });
    jwtAxios
      .put(`/homes/${homeId}`, data)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_HOME, payload: data.data });
        if (!silent) {
          dispatch(showMessage(messages['unit.message.updated'] as string));
        }

        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const updateSelectedPackages = (
  homeId: string,
  selectedPackages: IHome['selectedPackages']
) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const { home } = getState();

    dispatch({
      type: UPDATE_HOME,
      payload: {
        ...home.item,
        selectedPackages
      }
    });
    return jwtAxios.post(`homes/${homeId}/packages`, { selectedPackages }).catch(() => {
      dispatch({ type: UPDATE_HOME, payload: { ...home.item } });
    });
  };
};

export const submitHome = (homeId: string, callback?: () => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_HOME });
    jwtAxios
      .put(`/homes/${homeId}/submit`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_HOME, payload: data.data });
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const approveHome = (homeId: string, callback = null) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_HOME });
    jwtAxios
      .put(`/homes/${homeId}/approve`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_HOME, payload: data.data });
        dispatch(showMessage(messages['unit.message.updated'] as string));
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const rejectHome = (homeId: string, callback = null) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_HOME });
    jwtAxios
      .put(`/homes/${homeId}/reject`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_HOME, payload: data.data });
        dispatch(showMessage(messages['unit.message.updated'] as string));
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const submitToBuilder = (homeId: string, callback = null) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_HOME });
    jwtAxios
      .put(`/homes/${homeId}/submit-to-builder`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_HOME, payload: data.data });
        dispatch(showMessage(messages['unit.message.updated'] as string));
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const backToOwner = (homeId: string, callback = null) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_HOME });
    jwtAxios
      .put(`/homes/${homeId}/back-to-owner`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: UPDATE_HOME, payload: data.data });
        dispatch(showMessage(messages['unit.message.updated'] as string));
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const resetHome = (homeId: string, callback?: () => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_HOME });
    jwtAxios
      .delete(`/homes/${homeId}/modifications`)
      .then(() => {
        dispatch(fetchSuccess());
        dispatch(showMessage(messages['unit.message.updated'] as string));
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};
export const deleteHome = (homeId: string, callback?: () => void) => {
  const { messages } = appIntl();
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_HOME });
    jwtAxios
      .delete(`/homes/${homeId}`)
      .then(() => {
        dispatch(fetchSuccess());
        dispatch(showMessage(messages['unit.message.updated'] as string));
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getHome = (id: string, callback = null) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_HOME });
    jwtAxios
      .get(`/homes/${id}`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_HOME, payload: data.data });
        if (callback) callback();
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const resetHomeStore = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: RESET_HOME_STORE });
  };
};

// Modifications sent from the client is a temp solution until we start saving them in the db
export const processModelTweaks = (homeId): Promise<string> => {
  return jwtAxios.post(`/homes/${homeId}/process-model-tweaks`).then((result) => {
    // returns workItemId
    return result.data;
  });
};

export const checkWorkItemStatus = (workItemId): Promise<{ status: string; reportUrl: string }> => {
  return jwtAxios.get(`/homes/model-tweaks-work-item-status/${workItemId}`).then((result) => {
    return result.data;
  });
};

export const downloadModel = (homeId, fileName) => {
  return jwtAxios.get(`/homes/${homeId}/download-model-link`).then((response) => {
    const downloadUrl = response.data;
    downloadFile(downloadUrl, fileName);
  });
};

export const getOwnerHomes = (): Promise<IHome[]> => {
  return jwtAxios.get(`/homes`).then((result) => {
    return result.data;
  });
};

export const downloadModifications = (homeId, fileName) => {
  return jwtAxios.get(`/data/modifications-export/tiles/${homeId}`).then((response) => {
    const downloadUrl = response.data.FileUrl;
    downloadFile(downloadUrl, fileName, false);
  });
};
