import { Box, Grid } from '@mui/material';
import { Fonts } from 'shared/constants/AppEnums';
import AppGridContainer from 'shared/core/AppGridContainer';
import ProjectFiles from './ProjectFiles';
import ProjectDetailInfo from '../projectDetail/ProjectDetailInfo';
import AppLoader from 'shared/core/AppLoader';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { useEffect } from 'react';
import { Params, useParams } from 'react-router-dom';
import { getProject, resetProjectStore } from 'redux/actions/Project';

const ProjectHome = () => {
  const { id } = useParams<Readonly<Params>>();
  const dispatch = useDispatch();

  const { item: project } = useSelector<AppState, AppState['project']>(({ project }) => project);

  useEffect(() => {
    if (id) {
      dispatch(getProject(id));
    }

    return () => {
      dispatch(resetProjectStore());
    };
  }, []);

  if (!project?._id) {
    return <AppLoader />;
  }

  return (
    <>
      <Box
        component="h3"
        sx={{
          color: 'text.primary',
          mb: { xs: 4, sm: 4, xl: 6 },
          fontSize: 16,
          fontWeight: Fonts.BOLD
        }}>
        {project?.projectName}
      </Box>
      <AppGridContainer>
        <Grid item xs={12} md={6}>
          <ProjectDetailInfo project={project} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ProjectFiles project={project} />
        </Grid>
      </AppGridContainer>
    </>
  );
};

export default ProjectHome;
