import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { Fonts } from '../../../shared/constants/AppEnums';
import AppTextField from 'shared/core/AppFormComponents/AppTextField';
import IntlMessages from 'shared/utility/IntlMessages';
import { updateTempPassword } from 'services/invite/invite';
import { Checkbox, FormControlLabel } from '@mui/material';

const SetFirstPasswordForm = ({ inviteId, onSuccess }) => {
  const { messages } = useIntl();
  const validationSchema = yup.object({
    password: yup.string().required(String(messages['validation.passwordRequired'])),
    confirmPassword: yup
      .string()
      .required(String(messages['validation.passwordRequired']))
      .oneOf([yup.ref('password'), null], String(messages['validation.passwordMustMatch'])),
    terms: yup
      .boolean()
      .required()
      .oneOf([true], String(messages['validation.acceptTermsAndConditions']))
  });

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        password: '',
        confirmPassword: '',
        terms: false
      }}
      validationSchema={validationSchema}
      onSubmit={(data, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        updateTempPassword(inviteId, data.password, data.confirmPassword).then(() => {
          setSubmitting(false);
          resetForm();
          onSuccess();
        });
      }}>
      {({ isSubmitting }) => (
        <Form style={{ textAlign: 'left' }}>
          <Box sx={{ mb: { xs: 5, lg: 8 } }}>
            <AppTextField
              label={<IntlMessages id="common.password" />}
              name="password"
              type="password"
              variant="outlined"
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  fontSize: 14
                }
              }}
            />
            <AppTextField
              label={<IntlMessages id="common.password.confirm" />}
              name="confirmPassword"
              type="password"
              variant="outlined"
              sx={{
                width: '100%',
                '& .MuiInputBase-input': {
                  fontSize: 14
                },
                marginTop: '1em'
              }}
            />
          </Box>
          <Box
            sx={{
              mb: { xs: 3, xl: 4 },
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { sm: 'center' }
            }}
            className="acceptTerms">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& label': {
                  marginRight: '5px !important'
                }
              }}>
              <Field
                as={FormControlLabel}
                type="checkbox"
                name="terms"
                control={<Checkbox />}
                style={{ marginRight: '5x' }}
                label={<IntlMessages id="common.iAgreeTo" />}
              />

              <Box
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  cursor: 'pointer'
                }}>
                <Button
                  href="https://tweaksx.notion.site/Terms-Conditions-4072bbf1743245c7b8b4dc7462a506c2"
                  target="_blank">
                  <IntlMessages id="common.termConditions" />
                </Button>
              </Box>
            </Box>
          </Box>
          <Box style={{ color: '#d32f2f', marginBottom: '1em', fontSize: '12px' }}>
            <ErrorMessage name="terms" component="div" />
          </Box>
          <div>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              sx={{
                fontWeight: Fonts.REGULAR,
                textTransform: 'capitalize',
                fontSize: 16,
                minWidth: 160
              }}
              type="submit">
              <IntlMessages id="common.setPassword" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SetFirstPasswordForm;
