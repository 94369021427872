import {
  SET_SELECTED_ELEMENTS,
  SET_MOVED_ELEMENTS_DATA,
  GET_ROOM_CAMERAS,
  SET_ADDED_ELEMENTS_DATA,
  SET_DELETED_ELEMENT_DATA,
  RESET_VIEWER_STORE,
  SET_BASE_MODEL_LOADED,
  SET_ROOMS,
  SET_TEMPLATE_ID,
  SET_TEMPLATE,
  SET_BATCH_TILE_MODIFICATIONS_DATA,
  SET_VIEWER_SCREENSHOT,
  SET_IS_TOP_VIEW,
  SET_SELECTED_ROOM,
  SET_LINKED_FILE_MAP,
  SET_CONNECTION_QUALITY_STATUS
} from 'types/actions/Viewer.actions';

import { AppActions } from '../../types';
import {
  IElementInfo,
  IMovedElementsData,
  IAddedElementsData,
  IRoomInfo,
  IRoomCameraInfo,
  IBatchTileUpdateData
} from 'types/models/ForgeViewer';
import { IModelTemplate } from 'types/models/ModelTemplate';
import { findRoomKeyByRevitRoomName } from 'services/rooms/rooms.service';
import { SelectedRoom } from 'types/common.types';

const initialState: {
  selectedElements: IElementInfo[] | null;
  templateId: string | null;
  template: IModelTemplate | null;
  rooms: IRoomInfo[];
  roomsFetched: boolean;
  movedElementsData: IMovedElementsData | null;
  roomCameras: IRoomCameraInfo[] | null;
  addedElementsData: IAddedElementsData | null;
  deletedElementData: IElementInfo | null;
  batchTileUpdateData: IBatchTileUpdateData | null;
  baseModelLoaded: boolean;
  viewerScreenshotBlobUrl: string | null;
  isTopView: boolean;
  selectedRoom: SelectedRoom & IRoomInfo;
  linkedFileNamesMap: {};
  connectionQualityStatus: 1 | 2 | 3;
} = {
  selectedElements: null,
  templateId: null,
  rooms: [],
  roomsFetched: false,
  movedElementsData: null,
  roomCameras: null,
  addedElementsData: null,
  deletedElementData: null,
  baseModelLoaded: false,
  batchTileUpdateData: null,
  template: null,
  viewerScreenshotBlobUrl: null,
  isTopView: true,
  selectedRoom: {
    key: undefined,
    index: undefined,
    boundingBox: undefined,
    category: undefined,
    id: undefined,
    name: undefined,
    dbId: undefined,
    properties: undefined,
    camera: undefined,
    packages: []
  },
  linkedFileNamesMap: {},
  connectionQualityStatus: 3
};

const Viewer = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case SET_SELECTED_ELEMENTS:
      return {
        ...state,
        selectedElements: action.payload
      };

    case SET_TEMPLATE_ID:
      return {
        ...state,
        templateId: action.payload
      };

    case SET_TEMPLATE:
      return {
        ...state,
        template: action.payload
      };

    case SET_ROOMS:
      const orderRooms = (rooms: IRoomInfo[]) => {
        const priority = [
          'tweaks.rooms.exterior',
          'tweaks.rooms.entrance',
          'tweaks.rooms.foyer',
          'tweaks.rooms.livingroom',
          'tweaks.rooms.kitchen',
          'tweaks.rooms.pantry',
          'tweaks.rooms.master_bedroom',
          'tweaks.rooms.great_room',
          'tweaks.rooms.dining',
          'tweaks.rooms.casual_dining',
          'tweaks.rooms.flex',
          'tweaks.rooms.loft',
          'tweaks.rooms.primary_bedroom',
          'tweaks.rooms.primary_bathroom',
          'tweaks.rooms.bedroom',
          'tweaks.rooms.bedroom_1',
          'tweaks.rooms.bedroom_2',
          'tweaks.rooms.bedroom_3',
          'tweaks.rooms.bedroom_4',
          'tweaks.rooms.bedroom_5',
          'tweaks.rooms.children_room',
          'tweaks.rooms.master_bathroom',
          'tweaks.rooms.general_bathroom',
          'tweaks.rooms.bathroom_1',
          'tweaks.rooms.bathroom_2',
          'tweaks.rooms.bathroom_3',
          'tweaks.rooms.bathroom_4',
          'tweaks.rooms.shower'
        ];

        return rooms.sort((a: IRoomInfo, b: IRoomInfo) => {
          const keyA = findRoomKeyByRevitRoomName(a.category?.toLowerCase());
          const keyB = findRoomKeyByRevitRoomName(b.category?.toLowerCase());

          // If both rooms have priority, then sort based on their priority.
          // If only one room has priority, then that room comes first.
          // If neither room has priority, then don't change their order.
          if (priority.includes(keyA) && priority.includes(keyB)) {
            return priority.indexOf(keyA) - priority.indexOf(keyB);
          } else if (priority.includes(keyA)) {
            return -1;
          } else if (priority.includes(keyB)) {
            return 1;
          } else {
            return 0;
          }
        });
      };

      return {
        ...state,
        rooms: !action.payload ? [] : orderRooms(action.payload),
        roomsFetched: true
      };

    case GET_ROOM_CAMERAS:
      return {
        ...state,
        roomCameras: action.payload,
        roomCamerasFetched: true
      };

    case SET_MOVED_ELEMENTS_DATA:
      return {
        ...state,
        movedElementsData: action.payload
      };

    case SET_ADDED_ELEMENTS_DATA:
      return {
        ...state,
        addedElementsData: action.payload
      };

    case SET_DELETED_ELEMENT_DATA:
      return {
        ...state,
        deletedElementData: action.payload
      };

    case SET_BASE_MODEL_LOADED:
      return {
        ...state,
        baseModelLoaded: action.payload
      };

    case RESET_VIEWER_STORE:
      return {
        ...initialState
      };

    case SET_BATCH_TILE_MODIFICATIONS_DATA:
      return {
        ...state,
        batchTileUpdateData: action.payload
      };

    case SET_VIEWER_SCREENSHOT:
      return {
        ...state,
        viewerScreenshotBlobUrl: action.payload
      };

    case SET_IS_TOP_VIEW:
      return {
        ...state,
        isTopView: action.payload
      };

    case SET_SELECTED_ROOM:
      return {
        ...state,
        selectedRoom: action.payload
      };

    case SET_LINKED_FILE_MAP:
      return {
        ...state,
        linkedFileNamesMap: action.payload
      };

    case SET_CONNECTION_QUALITY_STATUS:
      return {
        ...state,
        connectionQualityStatus: action.payload
      };

    default:
      return state;
  }
};

export default Viewer;
