import { IUser } from 'types/models/User';

import * as chmln from '@chamaeleonidae/chmln';

class Chameleon {
  static _instance: Chameleon;

  chmln: any;

  private env: string;

  constructor(user?: IUser) {
    this.env = process.env.REACT_APP_ENVIRONMENT;
    this.init(user);
  }

  static getInstance(user?: IUser): Chameleon {
    if (!this._instance) {
      this._instance = new Chameleon(user);
    }
    return this._instance;
  }

  init(user?: IUser) {
    // init only if it's not development because we don't want to send events to biService
    if (!this.isDevelopment()) {
      chmln.init('SaGEFY6KPNDYsy1jwlocDXamBTzNehulvGaULFIEpuYwPS-1P0GDq-E3Qb81yHsK3S8pPO', {
        fastUrl: 'https://fast.chameleon.io/'
        // forceOverride: true
      });

      if (user) {
        chmln.identify(user.id, {
          email: user.email,
          name: user.displayName,
          role: user.role
        });
      }
    }
  }

  track(event) {
    if (!this.isDevelopment()) {
      chmln.track(event);
    }
  }

  isDevelopment() {
    return this.env === 'development' || this.env == null;
  }
}

export default Chameleon;
