import jwtAxios from 'services/auth/jwt-auth';

export interface IInviteData {
  builderName: string;
  projectName: string;
  address: string;
  homeNumber: string;
  userDisplayName: string;
  isTempPassword: boolean;
}

export const getInviteData = (inviteId: string): Promise<IInviteData> => {
  return jwtAxios.get(`/invites/${inviteId}`).then((response) => {
    return response.data;
  });
};

export const updateTempPassword = (inviteId, password, confirmPassword) => {
  return jwtAxios.post(`/invites/${inviteId}/set-password`, {
    password,
    confirmPassword
  });
};
