import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Document, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import { useDispatch, useSelector } from 'react-redux';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { getProjectFile } from 'redux/actions';
import { AppState } from 'redux/store';
import { usePdf } from 'shared/hooks/usePdf';
import IntlMessages from 'shared/utility/IntlMessages';
import { DraggableElement, DraggableElementType } from 'types/common.types';
import ScrollbarsMenu from '../scrollbars/ScrollbarsMenu';
import SignatureDialog from './SignatureDialog/SignatureDialog';
import { PanToolAlt, SignLanguage, TextFields } from '@mui/icons-material';
import { FileViewerPage } from './FileViewerPage';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

const FileViewer = () => {
  const containerRef = useRef(null);
  const signaturePadRef = useRef(null);
  const { id, file_id: fileId } = useParams<Readonly<Params>>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectFile } = useSelector<AppState, AppState['project']>(({ project }) => project);
  const [pdfSignatureModalIsOpen, setPdfSignatureModalIsOpen] = useState<boolean>(false);
  const [signatureDataUrl, setSignatureDataUrl] = useState<string>();
  const [draggableElements, setDraggableElements] = useState<
    DraggableElement<DraggableElementType>[]
  >([]);
  const [numPages, setNumPages] = useState(null);
  const pdfRef = useRef(null);
  const pdfService = usePdf(projectFile?.url, signatureDataUrl);
  const [editorTab, setEditorTab] = useState<'SELECT' | 'SIGNATURE' | 'TEXT'>('SELECT');

  const pdfFileUrl = useMemo(() => {
    return pdfService.getLastModifiedPdfFile
      ? { data: pdfService.getLastModifiedPdfFile }
      : { url: projectFile?.url };
  }, [projectFile?.url, pdfService.getLastModifiedPdfFile]);

  function onDocumentLoadSuccess({ numPages: _numPages }) {
    setNumPages(_numPages);
  }

  const saveElementsInDocument = () => {
    if (containerRef.current && pdfRef.current) {
      pdfRef.current.linkService.pdfDocument.getPage(1).then(function (pageDocumentProxy) {
        const relativeWidth = containerRef.current.clientWidth;
        const relativeHeight = containerRef.current.scrollHeight / pdfRef.current.pages.length;
        const getDraggableElementsListWithPdfSaveCoords = draggableElements.map((e) => {
          const { x, y } = pdfService.getDraggableElementCoordsRelatedToThePdfDocument(
            pageDocumentProxy,
            e.elementRef,
            e.coords,
            relativeWidth,
            relativeHeight
          );

          return {
            ...e,
            x,
            y
          };
        });

        pdfService.saveElementsInPdf(getDraggableElementsListWithPdfSaveCoords);
        setDraggableElements([]);
      });
    }
  };

  const pdfFileLoader = useMemo(() => {
    function Loader() {
      return (
        <Box
          sx={{
            height: `${containerRef.current.scrollHeight}px`,
            width: `${containerRef.current.width}px`,
            backgroundColor: 'white'
          }}>
          <IntlMessages id="actions.loading.file" />
        </Box>
      );
    }
    if (containerRef.current) {
      return Loader;
    }
    return <></>;
  }, []);

  useEffect(() => {
    dispatch(getProjectFile(id, fileId));
  }, []);

  return (
    <>
      <Box>
        <SignatureDialog
          signatureDataUrl={signatureDataUrl}
          pdfSignatureModalIsOpen={pdfSignatureModalIsOpen}
          setPdfSignatureModalIsOpen={setPdfSignatureModalIsOpen}
          setSignatureDataUrl={setSignatureDataUrl}
          signaturePadRef={signaturePadRef}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            padding: 4,
            maxWidth: 800,
            margin: '0 auto',
            marginBottom: 2,
            borderBottom: '1px solid #99999944'
          }}>
          <Box>
            <Box sx={{ borderBottom: '1px solid #999999a9', paddingBottom: 3 }}>
              <Typography variant="h2">{projectFile?.label}.pdf</Typography>
            </Box>

            <Box sx={{ marginTop: 2 }}>
              <Tabs
                value={editorTab}
                onChange={(event, newValue) => setEditorTab(newValue)}
                textColor="primary"
                indicatorColor="primary"
                aria-label="tabs">
                <Tab
                  value="SELECT"
                  label="SELECT"
                  icon={<PanToolAlt />}
                  sx={{ backgroundColor: '#F6F6F6' }}
                />
                <Tab
                  value="TEXT"
                  label="TEXT"
                  icon={<TextFields />}
                  sx={{ backgroundColor: '#F6F6F6' }}
                />
                <Tab
                  value="SIGNATURE"
                  label="SIGNATURE"
                  icon={<SignLanguage />}
                  sx={{ backgroundColor: '#F6F6F6' }}
                  disabled={!signatureDataUrl}
                />
              </Tabs>
            </Box>
          </Box>

          <Box>
            <Button
              variant="contained"
              onClick={() => setPdfSignatureModalIsOpen(true)}
              sx={{ marginLeft: '1em' }}>
              <IntlMessages id="common.signature" />
            </Button>

            <Button
              variant="contained"
              onClick={() => {
                pdfService.resetPdfDocument();
                setSignatureDataUrl('');
                setDraggableElements([]);
              }}
              sx={{ marginLeft: '1em' }}
              disabled={draggableElements.length === 0 && !pdfService.getLastModifiedPdfFile}>
              <IntlMessages id="common.reset" />
            </Button>

            <Button
              variant="contained"
              onClick={() => pdfService.downloadLastFile(projectFile.label)}
              disabled={pdfService.modifiedPdfFiles.length === 0}
              sx={{ marginLeft: '1em' }}>
              <IntlMessages id="common.download" />
            </Button>

            <Button
              variant="contained"
              onClick={saveElementsInDocument}
              sx={{ marginLeft: '1em' }}
              disabled={draggableElements.length === 0}>
              <IntlMessages id="common.save" />
            </Button>

            <Button
              variant="outlined"
              onClick={() => navigate(`/projects/${id}`)}
              sx={{ marginLeft: '1em' }}>
              <IntlMessages id="common.back" />
            </Button>
          </Box>
        </Box>
      </Box>
      {/* Container box with the application width*/}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Box that contains scrollbars and defines max height outside canvas*/}
        <Box
          width={800}
          sx={{
            height: {
              xs: 'calc(100vh - 170px)',
              sm: 'calc(100vh - 170px)',
              md: 'calc(100vh - 170px)',
              lg: 'calc(100vh - 170px)',
              xl: 'calc(100vh - 210px)'
            },
            top: {
              xs: 90,
              sm: 90,
              md: 90,
              lg: 90,
              xl: 90
            },
            overflow: 'hidden'
          }}>
          <ScrollbarsMenu autoHideDuration={700} autoHide={true}>
            <Box
              ref={containerRef}
              style={{ position: 'relative' }}
              sx={{
                '& canvas': {
                  padding: 0,
                  margin: 'auto',
                  display: 'block'
                },
                height: '100%',
                width: '100%',
                padding: 0,
                margin: 0
              }}>
              <Document
                ref={pdfRef}
                file={pdfFileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(error) => console.log(error)}
                loading={pdfFileLoader}
                noData={<IntlMessages id="actions.noFile" />}
                error={<IntlMessages id="actions.failed.file" />}>
                {Array.from(new Array(numPages), (el, index) => (
                  <FileViewerPage
                    key={`page__${index + 1}`}
                    pageNumber={index + 1}
                    draggableElements={draggableElements.filter(
                      (d_el) => d_el.pageNumber === index + 1
                    )}
                    elementIdx={index + 1}
                    editorTab={editorTab}
                    pdfRef={pdfRef}
                    pdfService={pdfService}
                    setDraggableElements={setDraggableElements}
                    signatureDataUrl={signatureDataUrl}
                    containerRef={containerRef}
                  />
                ))}
              </Document>
            </Box>
          </ScrollbarsMenu>
        </Box>
      </Box>
    </>
  );
};

export default FileViewer;
