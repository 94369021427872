import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Fonts } from 'shared/constants/AppEnums';
import { IProject } from 'types/models/Project';
import { AiOutlineEdit, AiOutlineDownload } from 'react-icons/ai';
import { RoutePermittedRole } from 'shared/constants/AppConst';
import { useAuthUser } from 'shared/utility/AuthHooks';
import { downloadFile } from 'shared/utility/Utils';

interface FileListItemProps {
  row: any;
  project: IProject;
}

const FileListItem: React.FC<FileListItemProps> = ({ row, project }) => {
  const navigate = useNavigate();
  const { user } = useAuthUser();
  return (
    <TableRow
      sx={{
        borderBottom: '0 none',
        '& .tableCell': {
          borderBottom: '0 none',
          fontSize: 13,
          padding: '6px 8px',
          '&:first-of-type': {
            pl: 5
          },
          '&:last-of-type': {
            pr: 5
          }
        }
      }}
      className="item-hover">
      <TableCell align="left" className="tableCell">
        <Box
          component="span"
          sx={{
            fontWeight: Fonts.MEDIUM,
            '& a': {
              color: (theme) => theme.palette.primary.main,
              textDecoration: 'none'
            }
          }}>
          {/* Link to viewer on click */}
          <Link to={`/projects/${project._id}/file/${row._id}/view`}>{row.label}</Link>
        </Box>
      </TableCell>
      <TableCell align="left" className="tableCell">
        {row?.createdAt ? moment(row.createdAt).format('DD-MM-YYYY HH:mm') : '-'}
      </TableCell>
      <TableCell align="left" className="tableCell">
        <Box
          component="span"
          sx={{
            fontWeight: Fonts.MEDIUM,
            '& svg ': {
              fontSize: '18px'
            },
            color: (theme) => theme.palette.primary.main,
            textDecoration: 'none',
            cursor: 'pointer'
          }}
          onClick={() => downloadFile(row.url, row.label, false)}>
          <AiOutlineDownload />
        </Box>
      </TableCell>
      {(user?.role === RoutePermittedRole.Admin || user?.role === RoutePermittedRole.Builder) && (
        <TableCell align="left" className="tableCell">
          <Box
            component="span"
            sx={{
              fontWeight: Fonts.MEDIUM,
              '& svg ': {
                fontSize: '18px'
              },
              color: (theme) => theme.palette.primary.main,
              cursor: 'pointer'
            }}
            onClick={() => navigate(`/projects/${project._id}/file/${row._id}`)}>
            <AiOutlineEdit />
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

export default FileListItem;
