import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ValidationMessage from './pages/dashboards/Model/ValidationMessage';
import { BrowserRouter } from 'react-router-dom';
import defaultConfig from 'shared/utility/AppContextProvider/defaultConfig';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const baseName = defaultConfig.locale.locale;

const location = window.location;
const currentUrlParts = location.pathname.split('/');
if (currentUrlParts[1] !== 'en' && currentUrlParts[1] !== 'he') {
  const redirectUrl = location.protocol + '//' + location.host + '/' + baseName + location.pathname;
  window.location.href = redirectUrl;
}

root.render(
  <SnackbarProvider
    maxSnack={3}
    autoHideDuration={3000}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}>
    <ValidationMessage />
    <BrowserRouter basename={`/${baseName}`}>
      <App />
    </BrowserRouter>
  </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
