import moment from 'moment';
import { Breakpoint } from '@mui/system';
import jwtAxios from 'services/auth/jwt-auth';
import axios from 'axios';

export const isLocalhost = window?.location?.hostname === 'localhost';

export const getBreakPointsValue = (valueSet: any, breakpoint: Breakpoint) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
  }
};

export const getFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const timeFromNow = (date: any) => {
  const timestamp = moment(date).format('X');
  const newDate = moment.unix(Number(timestamp));
  return moment(newDate).fromNow();
};

export const checkPermission = (routeAuth: any, userRole: any) => {
  if (routeAuth === null || routeAuth === undefined) {
    return true;
  }

  if (userRole && Array.isArray(userRole)) {
    return routeAuth.some((r: any) => userRole.indexOf(r) >= 0);
  }

  if (routeAuth.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeAuth)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeAuth.indexOf(userRole) >= 0;
};

export const generateUniqueID = () => {
  return `v1-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const roundNumber = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const downloadFile = (url, fileName, useJwtAxios = true) => {
  const axiosInstance = useJwtAxios ? jwtAxios : axios;

  return axiosInstance({
    url: url,
    method: 'GET',
    responseType: 'blob'
  }).then((response) => {
    // Create blob link to download
    const linkUrl = window.URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = linkUrl;
    link.setAttribute('download', fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
    return true;
  });
};

export const getEntityId = (val: string | { _id: string; [key: string]: any }) => {
  return typeof val === 'object' ? val._id : val;
};

export const getUniqueListBy = (data, key) => {
  const uniqKeys = data
    .map((item) => item[key])
    .filter((value, index, self) => self.indexOf(value) === index);

  return uniqKeys.map((keyVal) => data.find((item) => item[key] === keyVal));
};

export const momentHeConfig = {
  months: 'ינואר_פברואר_מרץ_אפריל_מאי_יוני_יולי_אוגוסט_ספטמבר_אוקטובר_נובמבר_דצמבר'.split('_'),
  monthsShort: 'ינו׳_פבר׳_מרץ_אפר׳_מאי_יוני_יולי_אוג׳_ספט׳_אוק׳_נוב׳_דצמ׳'.split('_'),
  weekdays: 'ראשון_שני_שלישי_רביעי_חמישי_שישי_שבת'.split('_'),
  weekdaysShort: 'א׳_ב׳_ג׳_ד׳_ה׳_ו׳_ש׳'.split('_'),
  weekdaysMin: 'א_ב_ג_ד_ה_ו_ש'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D [ב]MMMM YYYY',
    LLL: 'D [ב]MMMM YYYY HH:mm',
    LLLL: 'dddd, D [ב]MMMM YYYY HH:mm',
    l: 'D/M/YYYY',
    ll: 'D MMM YYYY',
    lll: 'D MMM YYYY HH:mm',
    llll: 'ddd, D MMM YYYY HH:mm'
  },
  calendar: {
    sameDay: '[היום ב־]LT',
    nextDay: '[מחר ב־]LT',
    nextWeek: 'dddd [בשעה] LT',
    lastDay: '[אתמול ב־]LT',
    lastWeek: '[ביום] dddd [האחרון בשעה] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: 'בעוד %s',
    past: 'לפני %s',
    s: 'מספר שניות',
    ss: '%d שניות',
    m: 'דקה',
    mm: '%d דקות',
    h: 'שעה',
    hh: function (number) {
      if (number === 2) {
        return 'שעתיים';
      }
      return number + ' שעות';
    },
    d: 'יום',
    dd: function (number) {
      if (number === 2) {
        return 'יומיים';
      }
      return number + ' ימים';
    },
    M: 'חודש',
    MM: function (number) {
      if (number === 2) {
        return 'חודשיים';
      }
      return number + ' חודשים';
    },
    y: 'שנה',
    yy: function (number) {
      if (number === 2) {
        return 'שנתיים';
      } else if (number % 10 === 0 && number !== 10) {
        return number + ' שנה';
      }
      return number + ' שנים';
    }
  },
  meridiemParse: /אחה"צ|לפנה"צ|אחרי הצהריים|לפני הצהריים|לפנות בוקר|בבוקר|בערב/i,
  isPM: function (input) {
    return /^(אחה"צ|אחרי הצהריים|בערב)$/.test(input);
  },
  meridiem: function (hour, minute, isLower) {
    if (hour < 5) {
      return 'לפנות בוקר';
    } else if (hour < 10) {
      return 'בבוקר';
    } else if (hour < 12) {
      return isLower ? 'לפנה"צ' : 'לפני הצהריים';
    } else if (hour < 18) {
      return isLower ? 'אחה"צ' : 'אחרי הצהריים';
    } else {
      return 'בערב';
    }
  }
};

export const groupBy = (list, fn) => {
  const groups = {};
  list.forEach((o) => {
    const group = JSON.stringify(fn(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map((group) => {
    return groups[group];
  });
};
