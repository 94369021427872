import { GET_ASSETS, LOADING_ASSET, RESET_ASSET_STORE } from 'types/actions/Asset.actions';
import { IAsset } from 'types/models/Asset';

import { AppActions } from '../../types';

const initialState: {
  items: IAsset[] | null;
  loading: boolean;
} = {
  items: null,
  loading: false
};

const Asset = (state = initialState, action: AppActions) => {
  switch (action.type) {
    case GET_ASSETS:
      return {
        ...state,
        items: action.payload,
        loading: false
      };

    case LOADING_ASSET:
      return {
        ...state,
        loading: true
      };

    case RESET_ASSET_STORE:
      return {
        ...initialState
      };

    default:
      return state;
  }
};
export default Asset;
