import { store } from 'App';

export class NumbersService {
  static getCurrentCurrency() {
    const state = store.getState();
    const fail = [false, false];
    try {
      const currency = state.home.item.currency;
      if (!currency) throw new Error('error getting currency from home item state');
      return currency;
    } catch (e) {
      fail[0] = true;
    }

    // Find admin currency (project currency)
    try {
      const projectCurrency = state.project.item.currency;
      if (!projectCurrency) throw new Error('error getting currency from project item state');
      return projectCurrency;
    } catch (e) {
      fail[1] = true;
    }

    if (fail[0] && fail[1]) {
      console.error('error getting currency from home and project item state');
    }

    return 'USD';
  }

  public static getCurrencySymbol(): string {
    const currency = NumbersService.getCurrentCurrency();

    switch (currency) {
      case 'ILS':
        return '₪';
      case 'USD':
        return '$';
      case 'CAD':
        return 'C$';
      case 'EUR':
        return '€';
      default:
        return '₪';
    }
  }

  public static formatNumberToCurrency(number: number): string {
    if (typeof number !== 'number') throw new Error('The number must be a number');

    const currency = NumbersService.getCurrentCurrency();

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      useGrouping: true,
      maximumFractionDigits: 0
    }).format(number);
  }
}
