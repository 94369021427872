import {
  CLOSE_CATALOG__DIALOG,
  CatelogCloseActionType,
  CatelogName,
  CatelogOpenActionType,
  OPEN_CATALOG_DIALOG
} from 'types/actions/Catelog.actions';

export const openCatelogDialog = (catelogName: CatelogName): CatelogOpenActionType => ({
  type: OPEN_CATALOG_DIALOG,
  payload: { catelogName }
});

export const closeDialogDialog = (): CatelogCloseActionType => ({ type: CLOSE_CATALOG__DIALOG });
