/* eslint-disable @typescript-eslint/no-unused-expressions */
// @ts-nocheck
import { ForgeUtils, IDbidWithModelId } from 'shared/utility/ForgeUtilities/ForgeUtils';
import { v4 as uuidv4 } from 'uuid';
import { Customizer } from 'shared/extensions/tweaksx-customizer';
export interface IMarker {
  _id: string;
  x: number;
  y: number;
  z: number;
  data: any;
  status: MarkerStatus;
}

export enum MarkerStatus {
  OPEN = 'open',
  CLOSED = 'closed'
}

export class Markers extends Autodesk.Viewing.Extension {
  selectableDbidsWithModel: IDbidWithModel[] = [];

  customizerExtension = null;

  constructor(viewer, options) {
    super(viewer, options);

    /* Preferences */
    this.prefs = {
      POINT_CLOUD_OPACITY: 1 // Opacity of pointcloud objects
    };

    this.overlayManager = new Autodesk.Viewing.OverlayManager(viewer.impl);
    this.sceneName = 'markers';

    this.viewer = viewer; // Viewer

    this.geometry;
    this.selectableGeometry;
    this.markers = [];
    this.selectableMarkers = [];
    this.material;
    this.selectableMaterial;
    this.points; // THREE.Points
    this.selectablePoints;
    this.enabled = false;

    this.statusColorMap = {
      [MarkerStatus.OPEN]: new THREE.Color(1, 1, 1),
      [MarkerStatus.CLOSED]: new THREE.Color(1, 0, 1)
    };

    this.scene; // Save a reference to the current overlay scene once it is created

    /* THREE.Points Display Params */
    this.size = 15; // markup size.  Change this if markup size is too big or small
    this.threshold = 0.4; // Higher threshold means the raycaster is more sensitive

    this._onMarkerClick = options.onMarkerClick || this.onMarkerClick;
    this._onMarkerPlaced = options.onMarkerPlaced || this.onMarkerPlaced;

    // Fields necessary for Three.Points
    this.pointCloudTextureURL = '/assets/images/extensions/markers_icons.png';

    this.vertexShader = `
        uniform float size;
        varying vec3 vColor;
        void main() {
          vColor = color;
          vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
          vec3 toCamera = normalize(-mvPosition.xyz); 

          mvPosition.xyz += 0.2 * toCamera;

          // Here, adjust position based on the normal
          vec3 adjustedPosition = mvPosition.xyz ; // Adjust as required

          gl_PointSize = size * ( size / (length(adjustedPosition) + 1.0) );
          gl_Position = projectionMatrix * mvPosition;
        }
    `;

    this.fragmentShader = `
        uniform sampler2D tex;
        uniform int textureIndex; 
        varying vec3 vColor;
        uniform float opacity; // Added opacity uniform

        void main() {
          // Calculate the x offset based on the texture index
          float xOffset = float(textureIndex) / 4.0;
          vec2 texCoords = vec2(gl_PointCoord.x / 4.0 + xOffset, 1.0 - gl_PointCoord.y);
      
          gl_FragColor = vec4( vColor.x, vColor.x, vColor.x, 1.0 );
          gl_FragColor = gl_FragColor * texture2D(tex, texCoords);
      
          gl_FragColor.a *= opacity; // Multiply alpha value with the opacity
      
            if (gl_FragColor.w < 0.5) discard;
        }
    `;
  }

  load = () => {
    console.info('TweaksxMarkers extension has been loaded');
    const customizerExtName = Customizer.register();
    this.customizerExtension = this.viewer.getExtension(customizerExtName);

    this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.init);

    return true; // This returning flag represents the load success
  };

  onMarkerClick = (marker) => {
    console.info('marker clicked', marker);
  };

  onMarkerPlaced = (marker) => {
    console.info('marker placed', marker);
  };

  onMarkerRemove = (marker) => {
    console.info('marker removed', marker);
  };

  showSelectableMarkers = async (visible: boolean, dbidsWithModel: IDbidWithModelId[]) => {
    if (!visible) {
      this.selectableMarkers = [];
      this.updateScene();
      return;
    }
    const editModeMarkers = [];
    const visibleMarkers: IDbidWithModelId = [];

    dbidsWithModel.forEach((dbidWithModel) => {
      if (!dbidWithModel.model.getInstanceTree().isNodeOff(dbidWithModel.dbId)) {
        visibleMarkers.push(dbidWithModel);
      }
    });

    for (let i = 0; i < visibleMarkers.length; i++) {
      const position = ForgeUtils.getdDidPosition(
        visibleMarkers[i].dbId,
        this.viewer,
        visibleMarkers[i].model.id
      );

      const globalPosition = position.add(ForgeUtils.getGlobalOffset(this.viewer));
      const marker: IMarker = {
        x: globalPosition.x,
        y: globalPosition.y,
        z: globalPosition.z + 0.1,
        _id: visibleMarkers[i].dbId,
        status: MarkerStatus.OPEN,
        data: {
          dbId: visibleMarkers[i].dbId,
          customRequestId: null
        }
      };
      const point = this.markerToPoint({
        _id: visibleMarkers[i].dbId,
        ...marker,
        status: MarkerStatus.OPEN
      });
      editModeMarkers.push(point);
    }

    this.selectableMarkers = [];
    for (let i = 0; i < editModeMarkers.length; i++) {
      this.selectableMarkers.push(editModeMarkers[i]);
    }

    this.updateScene();
  };

  init = () => {
    this.viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.init);

    this.overlayManager.addScene(this.sceneName); // Add scene to overlay manager
    this.scene = this.viewer.impl.overlayScenes[this.sceneName].scene; // Save reference to the scene
    const globalOffset = this.viewer.model.getData().globalOffset;
    const offsetVec = new THREE.Vector3(globalOffset.x, globalOffset.y, globalOffset.z);
    this.scene.position.sub(offsetVec);

    const texture = THREE.ImageUtils.loadTexture(this.pointCloudTextureURL);
    this.material = new THREE.ShaderMaterial({
      vertexColors: THREE.VertexColors,
      opacity: this.prefs.POINT_CLOUD_OPACITY,
      fragmentShader: this.fragmentShader,
      vertexShader: this.vertexShader,
      depthWrite: true,
      depthTest: true,
      uniforms: {
        size: { type: 'f', value: 25 },
        tex: { type: 't', value: texture },
        textureIndex: { type: 'i', value: 1 },
        opacity: { type: 'f', value: this.prefs.POINT_CLOUD_OPACITY }
      }
    });

    this.selectableMaterial = new THREE.ShaderMaterial({
      vertexColors: THREE.VertexColors,
      opacity: this.prefs.POINT_CLOUD_OPACITY,
      fragmentShader: this.fragmentShader,
      vertexShader: this.vertexShader,
      depthWrite: true,
      depthTest: true,
      uniforms: {
        size: { type: 'f', value: 20 },
        tex: { type: 't', value: texture },
        textureIndex: { type: 'i', value: 0 },
        opacity: { type: 'f', value: 0.7 }
      }
    });

    /* Create pointCloud */
    this.geometry = new THREE.BufferGeometry();
    this.geometry.isPoints = true;

    this.selectableGeometry = new THREE.BufferGeometry();
    this.selectableGeometry.isPoints = true;

    this.points = new THREE.PointCloud(this.geometry, this.material);
    this.selectablePoints = new THREE.PointCloud(this.selectableGeometry, this.selectableMaterial);

    /* Add the pointcloud to the scene */
    this.updateScene(true);

    this.viewer.canvasWrap.addEventListener('click', this.runHitTest, true);

    // this.viewer.canvasWrap.addEventListener(
    //   "mouseup",
    //   (event) => {
    //     const hit = this.hitTest({ x: event.clientX, y: event.clientY });
    //     if (hit) {
    //       const markerIndex = this.markers.findIndex((marker) => {
    //         return hit._id === marker._id;
    //       });
    //     }
    //   },
    //   false
    // );
  };

  async drawMarkersOnSelectableDbids() {
    this.selectableDbidsWithModel = await this.customizerExtension.getSelectableDbidsWithModel();
    this.showSelectableMarkers(true, this.selectableDbidsWithModel);
  }

  hideMarkersOnSelectableDbids() {
    this.showSelectableMarkers(false, []);
  }

  runHitTest = (event) => {
    event.preventDefault();
    this.hitTest({ x: event.clientX, y: event.clientY });
  };

  hitTest = (coords) => {
    const pos = this.screenToWorld({
      x: coords.x,
      y: coords.y
    });

    if (pos === undefined) return null;

    const hits = this.getMarkersFromPos(pos);
    if (hits.length > 0) {
      this._onMarkerClick(hits[0]);
    }

    return hits.length > 0 ? hits[0] : null;
  };

  markerToPoint = (marker: IMarker) => {
    const point = new THREE.Vector3(marker.x, marker.y, marker.z);
    point._id = marker?._id || uuidv4();
    point.data = marker.data;
    point.status = marker.status;
    return point;
  };

  addMarkerOnClick = (marker: IMarker) => {
    // if (!this.enabled) return;

    if (
      this.getMarkersFromPos({
        x: marker.x,
        y: marker.y,
        z: marker.z
      }).length > 0
    )
      return;

    const point = this.markerToPoint({
      _id: uuidv4(),
      ...marker,
      status: MarkerStatus.OPEN
    });
    this.markers.push(point);
    this.updateScene();
    this._onMarkerPlaced(point);
  };

  updateMarkerId = (oldId, newId) => {
    this.markers = this.markers.map((marker) => {
      return oldId === marker._id ? { ...marker, _id: newId } : marker;
    });
    this.updateScene();
  };

  removeMarker = (markerToRemove: IMarker) => {
    // const markerIndex = this.markers.findIndex((marker) => {
    //   return marker._id === markerToRemove._id;
    // });

    this.markers = this.markers.filter((marker) => {
      return marker._id !== markerToRemove._id;
    });

    // this.markers.splice(markerIndex, 1);
    this.updateScene();
  };

  loadMarkers = (markers: IMarker[]) => {
    // remove previous points
    this.markers = [];
    this.updateScene();

    markers.forEach((marker) => {
      const point = this.markerToPoint(marker);
      this.markers.push(point);
    });
    this.updateScene();
  };

  updateScene = (first = false) => {
    if (first && this.markers.length === 0) return;
    if (this.geometry) this.geometry.dispose();

    this.geometry = new THREE.BufferGeometry();
    this.geometry.setFromPoints(this.markers);
    this.geometry.computeBoundingBox();
    this.geometry.isPoints = true;

    this.selectableGeometry = new THREE.BufferGeometry();
    this.selectableGeometry.setFromPoints(this.selectableMarkers);
    this.selectableGeometry.computeBoundingBox();
    this.selectableGeometry.isPoints = true;

    const colors = [];
    const selectableColors = [];

    this.markers.forEach((marker) => {
      const color = this.statusColorMap[marker.status];
      colors.push(color.r, color.g, color.b);
    });

    this.selectableMarkers.forEach((marker) => {
      const color = new THREE.Color(1, 1, 1);
      selectableColors.push(color.r, color.g, color.b);
    });

    this.geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
    this.selectableGeometry.setAttribute(
      'color',
      new THREE.Float32BufferAttribute(selectableColors, 3)
    );

    this.geometry.attributes.color.needsUpdate = true;
    this.selectableGeometry.attributes.color.needsUpdate = true;

    this.overlayManager.removeMesh(this.points, this.sceneName);
    this.overlayManager.removeMesh(this.selectablePoints, this.sceneName);

    this.points = new THREE.PointCloud(this.geometry, this.material);
    this.selectablePoints = new THREE.PointCloud(this.selectableGeometry, this.selectableMaterial);

    this.overlayManager.addMesh(this.points, this.sceneName);
    this.overlayManager.addMesh(this.selectablePoints, this.sceneName);

    this.viewer.impl.invalidate(true); // Render the scene again
  };

  setEnabled = (val: boolean) => {
    this.enabled = val;
  };

  isEnabled = () => {
    return this.enabled;
  };

  screenToWorld = (coords) => {
    const mouse = new THREE.Vector2();
    mouse.x = coords.x;
    mouse.y = coords.y;

    const viewport = this.viewer.navigation.getScreenViewport();

    const n = {
      x: (mouse.x - viewport.left) / viewport.width,
      y: (mouse.y - viewport.top) / viewport.height
    };

    const hitPoint = this.viewer.utilities.getHitPoint(n.x, n.y);

    if (!hitPoint) return;

    const globalOffset = this.viewer.model.getData().globalOffset;
    const offsetVec = new THREE.Vector3(globalOffset.x, globalOffset.y, globalOffset.z);

    return hitPoint.add(offsetVec);
  };

  getMarkersFromPos = (pos: { x: number; y: number; z: number }, threshold = this.threshold) => {
    if (pos === undefined) return [];

    const hits = this.markers?.filter((marker) => {
      const diff = {
        x: pos.x - marker.x,
        y: pos.y - marker.y,
        z: pos.z - marker.z
      };

      const dist = Math.sqrt(diff.x * diff.x + diff.y * diff.y + diff.z * diff.z);

      return dist < threshold;
    });

    return hits;
  };

  unload = () => {
    console.info('TweaksxMarkers extension has been unloaded');
    this.overlayManager.removeScene(this.sceneName);
    this.viewer.canvasWrap.removeEventListener('click', this.runHitTest);
    return true; // This returning flag represents the unload success
  };

  reload = () => {
    this.unload();
    this.load();
  };

  static register() {
    const extensionName = 'TweaksxMarkers';
    Autodesk.Viewing.theExtensionManager.registerExtension(extensionName, Markers);
    return extensionName;
  }
}
