import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import { toggleNavCollapsed } from 'redux/actions';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from 'react-redux';
import ViewerToolsbar from 'pages/dashboards/Model/ViewerControls/ViewerToolsbar';
import UserInfo from '../../components/UserInfo';
import { useSidebarContext } from 'shared/utility/AppContextProvider/SidebarContextProvider';

interface AppHeaderProps {
  isCollapsed?: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const dispatch = useDispatch();
  const { sidebarTextColor } = useSidebarContext();

  return (
    <AppBar
      position="relative"
      color="inherit"
      sx={{
        boxShadow: 'none',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: 'white',
        transition: 'width 0.5s ease',
        width: '100%',
        borderRadius: 0
      }}
      className="app-bar">
      <Toolbar
        sx={{
          boxSizing: 'border-box',
          minHeight: { xs: 56, sm: 70 }
        }}>
        {/* <Hidden lgDown>
          <IconButton
            sx={{
              color: "text.secondary",
            }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setCollapsed(!isCollapsed)}
            size="large"
          >
            <MenuIcon
              sx={{
                width: 35,
                height: 35,
              }}
            />
          </IconButton>
        </Hidden> */}
        <Hidden lgUp>
          <IconButton
            sx={{
              color: 'text.secondary'
            }}
            edge="start"
            className="menu-btn"
            color="inherit"
            aria-label="open drawer"
            onClick={() => dispatch(toggleNavCollapsed())}
            size="large">
            <MenuIcon
              sx={{
                width: 35,
                height: 35
              }}
            />
          </IconButton>
        </Hidden>
        <Box
          sx={{
            '& .logo-text': {
              display: { xs: 'none', sm: 'block' }
            },
            '& .user-info': {
              display: 'none'
            }
          }}>
          <UserInfo color={sidebarTextColor} />
        </Box>
        <ViewerToolsbar />
      </Toolbar>
    </AppBar>
  );
};
export default AppHeader;
