import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Alert, Box, IconButton, LinearProgress, Snackbar } from '@mui/material';
import { Fonts } from 'shared/constants/AppEnums';
import { IProject } from 'types/models/Project';
import { Link } from 'react-router-dom';
import { IModel } from 'types/models/Model';
import IntlMessages from 'shared/utility/IntlMessages';
import DeleteIcon from '@mui/icons-material/Delete';
import AppConfirmDialog from 'shared/core/AppConfirmDialog';
import { RoutePermittedRole } from 'shared/constants/AppConst';
import { useAuthUser } from 'shared/utility/AuthHooks';
import { deleteModelTemplate, getModelTemplates } from 'redux/actions';
import { useDispatch } from 'react-redux';
import useTranslationProgress from '../hooks/useTranslationProgress';
interface IModelTemplatesTableRow {
  row: any;
  project: IProject;
  model: IModel;
}

const ModelTemplatesTableRow: React.FC<IModelTemplatesTableRow> = ({ row, project, model }) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(null);
  const { user } = useAuthUser();
  const dispatch = useDispatch();

  const translationProgress = useTranslationProgress(row._id);

  const handleOnConfirm = (modelTemplate) => {
    deleteModelTemplate(project._id, modelTemplate._id)
      .then(() => {
        dispatch(getModelTemplates(modelTemplate.model));
      })
      .catch(() => {
        setOpenSnackbar(true);
        setIsConfirmationDialogOpen(false);
      });
  };

  return (
    <TableRow
      sx={{
        borderBottom: '0 none',
        '& .tableCell': {
          borderBottom: '0 none',
          fontSize: 13,
          padding: '6px 8px',
          '&:first-of-type': {
            pl: 2
          },
          '&:last-of-type': {
            pr: 2
          }
        }
      }}
      className="item-hover">
      <TableCell align="left" className="tableCell">
        <Box
          component="span"
          sx={{
            fontWeight: Fonts.MEDIUM,
            '& a': {
              color: (theme) => theme.palette.primary.main,
              textDecoration: 'none'
            }
          }}>
          <Link to={`/projects/${project._id}/models/${model._id}/model-templates/${row._id}`}>
            {row.name}
          </Link>
        </Box>
      </TableCell>
      <TableCell align="center" className="tableCell">
        <div>
          <LinearProgress variant="determinate" value={translationProgress} />
          <span>{translationProgress}%</span> {/* Display the progress number */}
        </div>
      </TableCell>
      <TableCell align="right" className="tableCell">
        {row.default ? 'Yes' : 'No'}
      </TableCell>
      {user?.role === RoutePermittedRole.Builder && (
        <TableCell align="right" className="tableCell" sx={{ maxWidth: '1em' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}>
            <IconButton
              sx={{ '& .MuiSvgIcon-root': { width: '0.7em', height: '0.7em' } }}
              onClick={() => setIsConfirmationDialogOpen(true)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      )}
      <AppConfirmDialog
        open={isConfirmationDialogOpen}
        onDeny={() => setIsConfirmationDialogOpen(false)}
        onConfirm={() => handleOnConfirm(row)}
        title={<IntlMessages id="tweaks.delete.modelTemplate.areYouSure" />}
        dialogTitle={<IntlMessages id="tweaks.delete.modelTemplate" />}
      />
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          <IntlMessages id="tweaks.error.youCantDelete" />
        </Alert>
      </Snackbar>
    </TableRow>
  );
};

export default ModelTemplatesTableRow;
