import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Params } from 'react-router-dom';
import { getProject, resetProjectStore } from 'redux/actions';
import { AppState } from 'redux/store';
import AppCard from 'shared/core/AppCard';
import { Fonts } from 'shared/constants/AppEnums';
import AppLoader from 'shared/core/AppLoader';
import Grid from '@mui/material/Grid';
import AppGridContainer from 'shared/core/AppGridContainer';
import { useIntl } from 'react-intl';
import NotificationsForm from './NotificationsForm';
import { IUser } from 'types/models/User';

const NotificationsWrapper = () => {
  const { messages } = useIntl();
  const { id } = useParams<Readonly<Params>>();
  const dispatch = useDispatch();

  const [users, setUsers] = useState<IUser[]>([]);

  const { item: project, loading } = useSelector<AppState, AppState['project']>(
    ({ project }) => project
  );

  useEffect(() => {
    if (!project) return;
    const homeOwners: IUser[] = project.homes.map((home) => home.homeOwner);

    // Remove duplicated users
    const uniqueUsers = [
      // eslint-disable-next-line no-undef
      // @ts-ignore
      ...new Map(homeOwners.map((o) => [o._id, o])).values()
    ];

    setUsers(uniqueUsers);
  }, [project]);

  useEffect(() => {
    if (id) dispatch(getProject(id));

    return () => {
      dispatch(resetProjectStore());
    };
  }, []);

  return (
    <>
      {!loading ? (
        <AppGridContainer>
          <Grid item xs={12}>
            <Box
              component="h2"
              sx={{
                fontSize: 16,
                color: 'text.primary',
                fontWeight: Fonts.SEMI_BOLD,
                mb: {
                  xs: 2,
                  lg: 4
                }
              }}>
              {project?.projectName}
            </Box>
            <AppCard>
              <Box>
                <NotificationsForm project={project} users={users} />
              </Box>
            </AppCard>
          </Grid>
        </AppGridContainer>
      ) : (
        <AppLoader />
      )}
    </>
  );
};

export default NotificationsWrapper;
