import { CloseOutlined } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, IconButton, Typography } from '@mui/material';
import { useMemo } from 'react';
import { BiMove } from 'react-icons/bi';
import { MdFindReplace } from 'react-icons/md';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { ForgeUtils } from 'shared/utility/ForgeUtilities/ForgeUtils';
import { IElementInfo } from 'types/models/ForgeViewer';
import './ElementEditMenu.css';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import IntlMessages from 'shared/utility/IntlMessages';

export interface ElementEditProps {
  selectedOutlet: IElementInfo;
  onMoveStart: () => void;
  onDelete: () => void;

  onShowSwitch: () => void;
  onShowLight: () => void;
  onAddSwitch: () => void;
  onReplaceOutLet: () => void;
  onCloseMenu: () => void;
  onResetElement: () => void;
  isDrawerOpen: boolean;
}

const ElementEditMenu = (props: ElementEditProps) => {
  const selectedElementSize = useMemo(() => {
    let size: { width: number; height: number; depth: number } = {
      width: 0,
      height: 0,
      depth: 0
    };

    if (props.selectedOutlet) {
      // TODO: need to caluclate size based on BBOX
      // const calc = ForgeUtils.calcElementSizeInCm(Customizer.viewerInstance, props.selectedOutlet);
      // console.log(calc);

      if (ForgeUtils.isSingleOutlet(props.selectedOutlet.name)) {
        size = {
          width: 12,
          height: 8,
          depth: 0
        };
      }

      if (ForgeUtils.isDoubleOutlet(props.selectedOutlet.name)) {
        size = {
          width: 15,
          height: 8,
          depth: 0
        };
      }

      if (ForgeUtils.isSwitch(props.selectedOutlet.name)) {
        size = {
          width: 12,
          height: 8,
          depth: 0
        };
      }

      if (ForgeUtils.isLight(props.selectedOutlet.name, props.selectedOutlet.category)) {
        size = {
          width: 10,
          height: 30,
          depth: 0
        };
      }
    }

    return size;
  }, [props.selectedOutlet?.name]);

  let name = props.selectedOutlet ? props.selectedOutlet.name : '';
  name = name.replace(/\[.*?\]/g, '');

  return (
    <Drawer
      disableEnforceFocus
      open={props.isDrawerOpen}
      // variant="permanent"
      className="element-edit-menu-container"
      id="element-edit-menu"
      PaperProps={{
        style: {
          width: '17%'
        }
      }}
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        backgroundColor: 'white'
      }}
      hideBackdrop={true}>
      <Box>
        <Box
          sx={{
            height: '70px',
            width: '100%'
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            alignItems: 'center',
            padding: '12px 5px 10px 5px'
          }}>
          <IconButton
            color="primary"
            aria-label="close"
            component="label"
            sx={{
              height: '13px',
              width: '30px',
              borderColor: '#6d6d6d',
              color: 'black',
              '&:hover': {
                backgroundColor: 'white',
                opacity: [1, 1, 1]
              }
            }}
            onClick={() => props.onCloseMenu()}>
            <CloseOutlined />
          </IconButton>
        </Box>

        <Box
          sx={{
            height: '89px',
            padding: '6px 0px 6px 13px'
          }}>
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              mb: 4
            }}>
            {name}
          </Typography>
        </Box>
        <Divider flexItem />
        <Box
          sx={{
            height: '57px',
            display: 'flex',
            justifyContent: ' flex-start',
            flexDirection: 'column',
            padding: '6px 0px 6px 13px'
          }}>
          <Typography variant="body1" sx={{ fontWeight: '400', fontSize: '12px' }}>
            <IntlMessages id="tweaks.editor.size" />
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: '300', fontSize: '12px' }}>
            {Math.round(selectedElementSize?.width / 2.54).toString()}″ X H{' '}
            {Math.round(selectedElementSize?.height / 2.54).toString()}″ {'  '}
            {Math.round(selectedElementSize?.width).toString()}cm X H{' '}
            {Math.round(selectedElementSize?.height).toString()}cm
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          padding: '0px 0px 0px 6px'
        }}>
        <Button
          sx={{
            color: 'black',
            ':hover': {
              color: 'primary.main'
            },
            fontWeight: '500',
            fontSize: '14px',
            height: '60px',
            width: '100%',
            alignSelf: 'flex-start',
            justifyContent: 'flex-start'
          }}
          startIcon={<BiMove />}
          onClick={props.onMoveStart}>
          <IntlMessages id="tweaks.mod.move" />
        </Button>

        <Button
          sx={{
            color: 'black',
            ':hover': {
              color: 'primary.main'
            },
            fontWeight: '500',
            fontSize: '14px',
            height: '60px',
            width: '100%',
            alignSelf: 'flex-start',
            justifyContent: 'flex-start'
          }}
          startIcon={<BiMove />}
          onClick={(e) => {
            e.stopPropagation();
            props.onResetElement();
          }}>
          Reset
        </Button>

        {props.selectedOutlet &&
          ForgeUtils.isLight(props.selectedOutlet?.name, props.selectedOutlet?.category) && (
            <Button
              sx={{
                color: 'black',
                ':hover': {
                  color: 'primary.main'
                },
                fontWeight: '500',
                fontSize: '14px',
                height: '60px',
                width: '100%',
                alignSelf: 'flex-start',
                justifyContent: 'flex-start'
              }}
              startIcon={<AiOutlinePlusCircle />}
              onClick={(e) => {
                e.stopPropagation();
                props.onAddSwitch();
              }}>
              <IntlMessages id="tweaks.mod.addSwitch" />
            </Button>
          )}

        {props.selectedOutlet &&
          ForgeUtils.isLight(props.selectedOutlet?.name, props.selectedOutlet?.category) && (
            <Button
              sx={{
                color: 'black',
                ':hover': {
                  color: 'primary.main'
                },
                fontWeight: '500',
                fontSize: '14px',
                height: '60px',
                width: '100%',
                alignSelf: 'flex-start',
                justifyContent: 'flex-start'
              }}
              startIcon={<MdFindReplace />}
              onClick={(e) => {
                e.stopPropagation();
                props.onShowSwitch();
              }}>
              <IntlMessages id="viewer.controls.showConnectedSwitch" />
            </Button>
          )}

        {props.selectedOutlet && ForgeUtils.isSwitch(props.selectedOutlet?.name) && (
          <Button
            sx={{
              color: 'black',
              ':hover': {
                color: 'primary.main'
              },
              fontWeight: '500',
              fontSize: '14px',
              height: '60px',
              width: '100%',
              alignSelf: 'flex-start',
              justifyContent: 'flex-start'
            }}
            startIcon={<MdFindReplace />}
            onClick={(e) => {
              e.stopPropagation();
              props.onShowLight();
            }}>
            <IntlMessages id="viewer.controls.showConnectedLight" />
          </Button>
        )}
        {props.selectedOutlet && ForgeUtils.isOutlet(props.selectedOutlet?.name) && (
          <Button
            sx={{
              color: 'black',
              ':hover': {
                color: 'primary.main'
              },
              fontWeight: '500',
              fontSize: '14px',
              height: '60px',
              width: '100%',
              alignSelf: 'flex-start',
              justifyContent: 'flex-start'
            }}
            startIcon={<MdFindReplace />}
            onClick={(e) => {
              e.stopPropagation();
              props.onReplaceOutLet();
            }}>
            <IntlMessages id="tweaks.mod.replace" />
          </Button>
        )}
        <Button
          sx={{
            color: 'black',
            ':hover': {
              color: 'primary.main'
            },
            fontWeight: '500',
            fontSize: '14px',
            height: '60px',
            width: '100%',
            alignSelf: 'flex-start',
            justifyContent: 'flex-start'
          }}
          startIcon={<RiDeleteBin5Line />}
          onClick={props.onDelete}>
          <IntlMessages id="tweaks.mod.delete" />
        </Button>
      </Box>
    </Drawer>
  );
};

export default ElementEditMenu;
