import { ICameraInfo } from './ForgeViewer';

export interface ICustomRequest {
  _id: string;
  status: CustomRequestStatus;
  modelTemplate: string;
  home: string;
  user?: string;
  dbId: number;
  locationX: number;
  locationY: number;
  locationZ: number;
  camera?: ICameraInfo;
  comments?: ICustomRequestComment[];
}

export interface IAddCustomRequestComment {
  comment: string;
}

export interface ICustomRequestRequest {
  modelTemplateId: string;
  homeId: string;
  dbId: number;
  locationX: number;
  locationY: number;
  locationZ: number;
  comment: string;
  cameraInfo: any;
}

export interface ICustomRequestComment {
  _id: string;
  customRequest: string;
  user: string;
  comment: string;
  updatedAt?: string;
  createdAt?: string;
}

export enum CustomRequestStatus {
  ACTIVE = 'Active',
  CLOSED = 'Closed',
  DELETED = 'Deleted'
}

export enum CustomRequestMode {
  EDIT = 'Edit',
  CLOSE = 'Close',
  VIEW = 'View',
  LIST = 'List'
}
