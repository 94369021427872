import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Params, useNavigate } from 'react-router-dom';
import { AppState } from 'redux/store';
import AppCard from 'shared/core/AppCard';
import AppLoader from 'shared/core/AppLoader';
import { getProject, resetProjectStore } from 'redux/actions/Project';
import Grid from '@mui/material/Grid';
import {
  getAllModelTemplateSupplierInfo,
  getModelTemplate,
  resetModelStore,
  setDefaultModelTemplate,
  updateModelTemplate
} from 'redux/actions/Model';
import Button from '@mui/material/Button';
import IntlMessages from 'shared/utility/IntlMessages';
import ReadOnlyViewer from 'pages/dashboards/Model/ReadOnlyViewer';
import {
  Card,
  CardContent,
  CardHeader,
  Modal,
  Typography,
  Divider,
  IconButton
} from '@mui/material';
import RoomsList from './RoomsList/RoomsList';
import { setTemplate, setTemplateId } from 'redux/actions/Viewer';
import { Formik } from 'formik';
import * as yup from 'yup';
import ModelTemplateForm from './ModelTemplateForm';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { messageSeverity } from 'shared/constants/AppConst';
import ModelTemplateImage from './ModelTemplateImage';
import ScrollbarsMenu from 'shared/core/scrollbars/ScrollbarsMenu';
import ModelTemplateSupplierSummary from './ModelTemplateSupplierAdmin/ModelTemplateSupplierSummary';
import { IModelTemplateSupplierInfo } from 'types/models/ModelTemplate';
import { getSuppliers } from 'redux/actions/Supplier';
import ModelTemplateSupplierTabs from './ModelTemplateSupplierAdmin/ModelTemplateSupplierTabs';
import CloseIcon from '@mui/icons-material/Close';

const ModelTemplateDetail = () => {
  const [modelTemplateSupplierInfoEdit, setModelTemplateSupplierInfoEdit] = useState(false);
  const [modelTemplateSupplierInfo, setModelTemplateSupplierInfo] =
    useState<IModelTemplateSupplierInfo[]>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { messages } = useIntl();
  const {
    id,
    model_template_id: modelTemplateId,
    model_id: modelId
  } = useParams<Readonly<Params>>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { item: project } = useSelector<AppState, AppState['project']>(({ project }) => project);

  const { modelTemplate } = useSelector<AppState, AppState['model']>(({ model }) => model);

  const { items: suppliers } = useSelector<AppState, AppState['supplier']>(
    ({ supplier }) => supplier
  );

  useEffect(() => {
    if (modelTemplateId) {
      dispatch(setTemplateId(modelTemplateId));
    }
  }, [modelTemplateId]);

  useEffect(() => {
    if (modelTemplate) {
      dispatch(setTemplate(modelTemplate));
    }
  }, [modelTemplate]);

  const fetchModelTemplate = () => {
    dispatch(getModelTemplate(modelTemplateId));
  };

  const fetchModelTemplateSupplierInfo = () => {
    getAllModelTemplateSupplierInfo(modelTemplateId).then((response) => {
      setModelTemplateSupplierInfo(response);
    });
  };

  const setDefault = () => {
    setDefaultModelTemplate(modelTemplateId).then(() => {
      fetchModelTemplate();
    });
  };

  useEffect(() => {
    dispatch(getProject(id));
    dispatch(getSuppliers());
    fetchModelTemplate();
    fetchModelTemplateSupplierInfo();

    return () => {
      dispatch(resetProjectStore());
      dispatch(resetModelStore());
    };
  }, []);

  const validationSchema = yup.object({
    cost: yup.number().required('This field is required')
  });

  const onUpdate = (data) => {
    updateModelTemplate(modelTemplate._id, data).then(() => {
      enqueueSnackbar(String(messages['common.successfullySaved']), {
        variant: messageSeverity.SUCCESS,
        autoHideDuration: 3000
      });
      fetchModelTemplate();
    });
  };

  const onModelSupplierInfoUpdate = () => {
    enqueueSnackbar(String(messages['common.successfullySaved']), {
      variant: messageSeverity.SUCCESS,
      autoHideDuration: 3000
    });
    fetchModelTemplateSupplierInfo();
  };

  return (
    <>
      {project?._id && modelTemplate?._id ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={9} style={{ paddingTop: 0 }}>
              <ReadOnlyViewer modelUrn={modelTemplate?.forgeDetails?.urn} />
            </Grid>

            <Grid
              item
              xs={3}
              sx={{
                paddingTop: 0,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                width: '100%'
              }}
              style={{
                paddingTop: 0
              }}>
              <ScrollbarsMenu autoHide={true}>
                <AppCard
                  action={
                    <Button
                      size="small"
                      sx={{
                        position: 'relative',
                        minWidth: 60,
                        padding: 0.5
                      }}
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        navigate(`/projects/${project._id}/models/${modelId}`);
                      }}>
                      <IntlMessages id="common.back" />
                    </Button>
                  }
                  title={project?.projectName}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box sx={{ color: 'text.secondary' }}>
                        <IntlMessages id="tweaks.modelTemplate" />
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Box style={{ wordBreak: 'break-all' }}>{modelTemplate.name}</Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box sx={{ color: 'text.secondary' }}>
                        <IntlMessages id="common.modelDefault" />
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Box>{modelTemplate?.default ? 'Yes' : 'No'}</Box>
                    </Grid>
                    <Formik
                      validateOnChange={false}
                      validateOnBlur={true}
                      initialValues={{
                        title: modelTemplate?.title || '',
                        cost: modelTemplate?.cost || 0,
                        description: modelTemplate?.description || '',
                        fullDescription: modelTemplate?.fullDescription || '',
                        upgrades: {
                          electricity: modelTemplate?.upgrades?.electricity || false,
                          kitchen: modelTemplate?.upgrades?.kitchen || false,
                          layout: modelTemplate?.upgrades?.layout || false,
                          lighting: modelTemplate?.upgrades?.lighting || false,
                          sanitary: modelTemplate?.upgrades?.sanitary || false
                        }
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(data) => {
                        onUpdate(data);
                      }}>
                      <ModelTemplateForm />
                    </Formik>
                    <Grid item xs={12} style={{ marginTop: 8 }}>
                      {!modelTemplate?.default && (
                        <Button
                          sx={{ mr: 2.5 }}
                          color="primary"
                          variant="contained"
                          type="submit"
                          onClick={setDefault}>
                          <IntlMessages id="actions.makeDefault" />
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </AppCard>

                <Box style={{ marginTop: '1em' }}>
                  <ModelTemplateImage modelTemplate={modelTemplate} onUpdate={onUpdate} />
                </Box>

                {suppliers?.length > 0 && (
                  <Box style={{ marginTop: '1em' }}>
                    <ModelTemplateSupplierSummary
                      suppliers={suppliers}
                      onEditClick={() => setModelTemplateSupplierInfoEdit(true)}
                      supplierInfo={modelTemplateSupplierInfo}
                    />
                  </Box>
                )}

                <Card sx={{ marginTop: '1em' }}>
                  <CardHeader
                    title={<IntlMessages id="viewer.controls.room" />}
                    style={{ paddingBottom: 0 }}
                  />
                  <CardContent>
                    <RoomsList />
                  </CardContent>
                </Card>
              </ScrollbarsMenu>
            </Grid>
          </Grid>
        </>
      ) : (
        <AppLoader />
      )}
      {suppliers?.length > 0 && (
        <Modal
          open={modelTemplateSupplierInfoEdit}
          onClose={() => setModelTemplateSupplierInfoEdit(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box
            sx={{
              position: 'absolute' as const,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              p: 6,
              borderRadius: '15px'
            }}>
            <Typography
              style={{ fontWeight: 'bold', fontSize: 15, paddingBottom: 8 }}
              component="h2">
              <IntlMessages id="tweaks.modelTemplate.supplier.config" />
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setModelTemplateSupplierInfoEdit(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}>
              <CloseIcon />
            </IconButton>
            <Divider />
            <ModelTemplateSupplierTabs
              onUpdate={onModelSupplierInfoUpdate}
              modelTemplate={modelTemplate}
              suppliers={suppliers}
              modelTemplateSupplierInfo={modelTemplateSupplierInfo}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ModelTemplateDetail;
