import { IModel } from 'types/models/Model';
import { IModelTemplate } from '../models/ModelTemplate';

export const GET_MODEL = 'GET_MODEL';
export const GET_MODEL_MODEL_TEMPLATES = 'GET_MODEL_MODEL_TEMPLATES';
export const RESET_MODEL_STORE = 'RESET_MODEL_STORE';
export const LOADING_MODEL = 'LOADING_MODEL';
export const GET_MODEL_TEMPLATE = 'GET_MODEL_TEMPLATE';

export interface GetModelAction {
  type: typeof GET_MODEL;
  payload: IModel;
}

export interface GetModelTemplatesAction {
  type: typeof GET_MODEL_MODEL_TEMPLATES;
  payload: IModelTemplate[];
}

export interface GetModelTemplateAction {
  type: typeof GET_MODEL_TEMPLATE;
  payload: IModelTemplate;
}

export interface ResetModelStoreAction {
  type: typeof RESET_MODEL_STORE;
}

export interface LoadingModelAction {
  type: typeof LOADING_MODEL;
}

export type ModelActionTypes =
  | GetModelAction
  | GetModelTemplatesAction
  | ResetModelStoreAction
  | LoadingModelAction
  | GetModelTemplateAction;
