import axios from 'axios';

export const s3Upload = (signedURL, file, contentType = 'image/png') => {
  const a = axios.create();
  return a
    .put(signedURL, file, {
      headers: {
        'Content-Type': contentType
      },
      timeout: 1000 * 60 * 30
    })
    .then((response) => {
      return response.data;
    });
};
