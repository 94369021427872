import { sumBy } from 'lodash';
import { IRoomPackage } from 'pages/projects/projectDetail/RoomsList/RoomListItem';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTotalCost } from 'redux/actions';
import { AppState } from 'redux/store';
import { ViewerEvents } from 'shared/constants/AppConst';
import useBus from 'use-bus';

interface ISelectedPackage {
  type: string;
  price: number;
}

interface IRoomAndCost {
  roomName: string;
  cost: number;
}

export const useTotalCosts = () => {
  const [total, setTotal] = useState(0);
  const { rooms: _rooms } = useSelector<AppState, AppState['viewer']>(({ viewer }) => viewer);
  const [roomsAndCosts, setRoomsAndCosts] = useState<IRoomAndCost[]>([]); // [{roomName: 'room1', cost: 100}, {roomName: 'room2', cost: 200}
  const [selectedPackages, setSelectedPackages] = useState<Map<string, ISelectedPackage[]>>(
    new Map()
  );

  const { items: modifications } = useSelector<AppState, AppState['modification']>(
    ({ modification }) => modification
  );
  const { item: home } = useSelector<AppState, AppState['home']>(({ home }) => home);

  useBus(
    ViewerEvents.ShowPackage,
    (e) => {
      const selectedPackage: ISelectedPackage = {
        type: e.packageType,
        price: e.price
      };
      const roomPackage = selectedPackages.get(e.room);

      setSelectedPackages((prev) => {
        const newMap = new Map(prev);

        // clearing room's packages if STANDARD is selected
        if (roomPackage && selectedPackage.type === 'STANDARD') {
          newMap.delete(e.room);
          return newMap;
        }

        // Checking if allready theres a package for the room if not we add it
        if (roomPackage && !roomPackage.some((p) => p.type === selectedPackage.type)) {
          newMap.set(e.room, [...(newMap.get(e.room) || []), selectedPackage]);
        }

        // No Packages at all for current room so we add
        if (!roomPackage && selectedPackage.type !== 'STANDARD') {
          newMap.set(e.room, [selectedPackage]);
        }
        return newMap;
      });
    },
    [selectedPackages]
  );

  const getPackagesCost = () => {
    let total = 0;
    const foundSelectedPackages: IRoomPackage[] = [];
    const totalPackages = home?.modelTemplate?.packages;

    totalPackages?.forEach((roomPackage: IRoomPackage) => {
      const foundPackage = home?.selectedPackages?.some((x) => x.packageId === roomPackage._id);
      if (foundPackage) {
        foundSelectedPackages.push(roomPackage);
      }
    });

    foundSelectedPackages?.forEach((packages) => {
      const price = packages.price || 0;
      total += price;
    });

    return total;
  };

  const getRoomPackagesCost = (roomname) => {
    let total = 0;
    const foundSelectedPackages: IRoomPackage[] = [];
    const totalPackages = home?.modelTemplate?.packages;

    totalPackages?.forEach((roomPackage: IRoomPackage) => {
      const foundPackage = home?.selectedPackages?.some((x) => x.packageId === roomPackage._id);
      if (foundPackage) {
        foundSelectedPackages.push(roomPackage);
      }
    });

    foundSelectedPackages?.forEach((packages) => {
      if (packages.room === roomname) {
        const price = packages.price || 0;
        total += price;
      }
    });

    return total;
  };

  const getHomeUpgradesCost = (): number => {
    if (home?.upgrades?.length > 0) {
      return sumBy(home?.upgrades, 'price');
    }
    return 0;
  };

  const getRoomsCost = () => {
    const rooms = _rooms;
    const roomsCosts: IRoomAndCost[] = [];

    rooms?.forEach((room) => {
      const roomModifications = modifications?.filter((x) => x.room === room.category);

      const roomCost = getTotalCost(home?.cost || 0, roomModifications || []);
      const roomPackagesCost = getRoomPackagesCost(room.category);
      roomsCosts.push({ roomName: room.category, cost: roomCost + roomPackagesCost });
    });

    setRoomsAndCosts(roomsCosts);
  };

  useEffect(() => {
    setTotal(
      getTotalCost(home?.cost || 0, modifications || []) + getPackagesCost() + getHomeUpgradesCost()
    );

    getRoomsCost();
  }, [modifications, home, selectedPackages]);

  return { total, roomsAndCosts };
};
