import React from 'react';
import SetFirstPassword from './SetFirstPassword';

const Signin = React.lazy(() => import('./Signin'));
const ForgotPassword = React.lazy(() => import('./ForgetPassword'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));

export const authRouteConfig = [
  {
    path: '/signin',
    element: <Signin />
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />
  },
  {
    path: '/forget-password/:code',
    element: <ResetPassword />
  },
  {
    path: ['/welcome/:id'],
    element: <SetFirstPassword />
  }
];
