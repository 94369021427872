import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Params } from 'react-router-dom';
import { getProject, resetProjectStore } from 'redux/actions';
import { AppState } from 'redux/store';
import AppCard from 'shared/core/AppCard';
import { Fonts } from 'shared/constants/AppEnums';
import AppLoader from 'shared/core/AppLoader';
import ModelsDropzone from './ModelsDropzone';
import { getModel, getModelTemplates, resetModelStore } from '../../../redux/actions/Model';
import ModelTemplatesTable from './ModelTemplatesTable';
import Grid from '@mui/material/Grid';
import AppGridContainer from 'shared/core/AppGridContainer';
import IntlMessages from 'shared/utility/IntlMessages';

const ModelsUploadWrapper = () => {
  const { id, model_id: modelId } = useParams<Readonly<Params>>();
  const dispatch = useDispatch();

  const { item: project, loading } = useSelector<AppState, AppState['project']>(
    ({ project }) => project
  );

  const { item: model, modelTemplates } = useSelector<AppState, AppState['model']>(
    ({ model }) => model
  );

  const fetchModelData = () => {
    dispatch(getModel(modelId));
    dispatch(getModelTemplates(modelId));
  };

  useEffect(() => {
    if (id) dispatch(getProject(id));
    if (modelId) {
      fetchModelData();
    }

    return () => {
      dispatch(resetProjectStore());
      if (modelId) dispatch(resetModelStore());
    };
  }, []);

  return (
    <>
      {!loading && (!modelId || model?._id) ? (
        <AppGridContainer>
          <Grid item xs={12}>
            <Box
              component="h2"
              sx={{
                fontSize: 16,
                color: 'text.primary',
                fontWeight: Fonts.SEMI_BOLD,
                mb: {
                  xs: 2,
                  lg: 4
                }
              }}>
              {project?.projectName}
            </Box>
            <AppCard>
              <Box>
                <ModelsDropzone project={project} model={model} onEdit={fetchModelData} />
              </Box>
            </AppCard>
          </Grid>

          {modelTemplates?.length > 0 && (
            <Grid item xs={12}>
              <AppCard title={<IntlMessages id="tweaks.modelTemplates" />}>
                <Box
                  style={{ marginTop: '1rem' }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <ModelTemplatesTable data={modelTemplates} project={project} model={model} />
                </Box>
              </AppCard>
            </Grid>
          )}
        </AppGridContainer>
      ) : (
        <AppLoader />
      )}
    </>
  );
};

export default ModelsUploadWrapper;
