import { Box } from '@mui/material';
import { Dispatch, MutableRefObject, SetStateAction, SyntheticEvent, useRef } from 'react';
import { Page, PDFPageProxy } from 'react-pdf';
import { usePdf } from 'shared/hooks/usePdf';
import { generateUniqueID } from 'shared/utility/Utils';
import { DraggableElement, DraggableElementType } from 'types/common.types';
import { DraggableComponentList } from '../DraggableComponent/DraggableComponentList';

type Props = {
  pageNumber: number;
  elementIdx: number;
  editorTab: string;
  draggableElements: DraggableElement<DraggableElementType>[];
  setDraggableElements: Dispatch<SetStateAction<DraggableElement<DraggableElementType>[]>>;
  pdfService: ReturnType<typeof usePdf>;
  signatureDataUrl: string;
  pdfRef: MutableRefObject<any>;
  containerRef: MutableRefObject<any>;
};

export const FileViewerPage = ({
  pageNumber,
  elementIdx,
  draggableElements,
  signatureDataUrl,
  editorTab,
  setDraggableElements
}: Props) => {
  const boxRef = useRef(null);

  const onAddDragElement = (...args: [SyntheticEvent, PDFPageProxy]) => {
    const [syntheticEvent] = args;
    const nativeEvent = syntheticEvent.nativeEvent as PointerEvent & {
      layerX: number;
      layerY: number;
    };
    const { layerX, layerY } = nativeEvent;

    if (editorTab === 'SIGNATURE') {
      setDraggableElements((prev) =>
        prev.concat({
          pageNumber,
          elementId: generateUniqueID(),
          previewUrl: signatureDataUrl,
          type: 'IMAGE',
          coords: {
            startX: layerX,
            startY: layerY,
            lastX: layerX,
            lastY: layerY
          }
        })
      );
    } else if (editorTab === 'TEXT') {
      setDraggableElements((prev) =>
        prev.concat({
          pageNumber,
          elementId: generateUniqueID(),
          type: 'TEXT',
          coords: {
            startX: layerX,
            startY: layerY,
            lastX: layerX,
            lastY: layerY
          }
        })
      );
    }
  };

  return (
    <Box
      ref={boxRef}
      sx={{
        marginBottom: 4,
        position: 'relative',
        ':hover': {
          cursor: editorTab === 'TEXT' || editorTab === 'SIGNATURE' ? 'text' : 'default'
        }
      }}>
      <DraggableComponentList
        boxRef={boxRef}
        draggableElements={draggableElements}
        setDraggableElements={setDraggableElements}
      />

      <Page
        pageNumber={elementIdx}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        width={800}
        onClick={onAddDragElement}
      />
      <Box />
    </Box>
  );
};
