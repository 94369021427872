import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import {
  SET_SELECTED_ELEMENTS,
  SET_ROOMS,
  GET_ROOM_CAMERAS,
  SET_MOVED_ELEMENTS_DATA,
  SET_DELETED_ELEMENT_DATA,
  SET_BASE_MODEL_LOADED,
  SAVE_ROOM_CAMERAS,
  UPDATE_ROOM_CAMERAS,
  SET_TEMPLATE_ID,
  SET_TEMPLATE,
  SET_BATCH_TILE_MODIFICATIONS_DATA,
  SET_VIEWER_SCREENSHOT,
  SET_IS_TOP_VIEW,
  SET_SELECTED_ROOM,
  SET_LINKED_FILE_MAP,
  SET_CONNECTION_QUALITY_STATUS
} from 'types/actions/Viewer.actions';
import {
  IElementInfo,
  IMovedElementsData,
  IRoomInfo,
  ICreateRoomCameraInfo,
  IUpdateRoomCameraInfo,
  IBatchTileUpdateData
} from 'types/models/ForgeViewer';
import jwtAxios from 'services/auth/jwt-auth';
import { dispatch as busDispatch } from 'use-bus';
import { MessageEvents } from 'shared/constants/AppConst';
import { IModelTemplate } from 'types/models/ModelTemplate';

export const setSelectedElements = (elements: IElementInfo[]) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_SELECTED_ELEMENTS, payload: elements });
  };
};
export const setTemplateId = (templateId: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_TEMPLATE_ID, payload: templateId });
  };
};

export const setTemplate = (template: IModelTemplate) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_TEMPLATE, payload: template });
  };
};

export const SetLinkedFileMap = (linkedFileNamesMap: {}) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_LINKED_FILE_MAP, payload: linkedFileNamesMap });
  };
};

export const setRooms = (elements: IRoomInfo[] | null) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_ROOMS, payload: elements });
  };
};

export const setMovedElementsData = (data: IMovedElementsData) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_MOVED_ELEMENTS_DATA, payload: data });
  };
};

export const setDeletedElementData = (data: IElementInfo) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_DELETED_ELEMENT_DATA, payload: data });
  };
};

export const setBaseModelLoaded = (val) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_BASE_MODEL_LOADED, payload: val });
  };
};

export const setConnectionQualityStatus = (val: 1 | 2 | 3) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_CONNECTION_QUALITY_STATUS, payload: val });
  };
};

export const setViewerScreenshot = (image: Blob | null) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_VIEWER_SCREENSHOT, payload: image });
  };
};

export const setIsTopView = (isTopView: boolean) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_IS_TOP_VIEW, payload: isTopView });
  };
};

export const saveRoomCamera = (roomCamera: ICreateRoomCameraInfo, callback?: () => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post(`/model-templates/room-camera`, roomCamera)
      .then((data) => {
        dispatch({ type: SAVE_ROOM_CAMERAS, payload: data.data });
        busDispatch(MessageEvents.SuccessfullySaved);
        if (callback) callback();
      })
      .catch(() => {
        busDispatch(MessageEvents.Error);
      });
  };
};

export const updateRoomCamera = (roomCamera: IUpdateRoomCameraInfo, callback?: () => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .put(`/model-templates/room-camera/${roomCamera.cameraId}`, roomCamera)
      .then((data) => {
        dispatch({ type: UPDATE_ROOM_CAMERAS, payload: data.data });
        busDispatch(MessageEvents.SuccessfullyUpdated);
        if (callback) callback();
      })
      .catch(() => {
        busDispatch(MessageEvents.Error);
      });
  };
};

export const getRoomCameras = (modelTemplateId: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get(`/model-templates/room-camera/${modelTemplateId}`)
      .then((data) => {
        dispatch({ type: GET_ROOM_CAMERAS, payload: data.data });
      })
      .catch(() => {
        busDispatch(MessageEvents.Error);
      });
  };
};

export const setBatchTileModificationsData = (data: IBatchTileUpdateData | null) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: SET_BATCH_TILE_MODIFICATIONS_DATA, payload: data });
  };
};

export const getForgeAccessToken = () => {
  return jwtAxios.get(`/auth/getForgeToken`).then((response) => {
    return response.data;
  });
};

export const setSelectedRoom = (
  selectedRoomKey: string,
  selectedRoomIndex: number,
  room: IRoomInfo
) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SET_SELECTED_ROOM,
      payload: {
        key: selectedRoomKey,
        index: selectedRoomIndex,
        ...room
      }
    });
  };
};
