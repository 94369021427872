import { IMarker } from 'shared/extensions/tweaks-markers';
import {
  CustomRequestMode,
  IAddCustomRequestComment,
  ICustomRequest,
  ICustomRequestComment,
  ICustomRequestRequest
} from 'types//models/CustomRequest';

export const GET_CUSTOM_REQUESTS = 'GET_CUSTOM_REQUESTS';
export const CREATE_CUSTOM_REQUEST = 'CREATE_CUSTOM_REQUEST';
export const ADD_CUSTOM_REQUEST_COMMENT = 'ADD_CUSTOM_REQUEST_COMMENT';
export const GET_CUSTOM_REQUEST = 'GET_CUSTOM_REQUEST';
export const CLOSE_CUSTOM_REQUEST = 'CLOSE_CUSTOM_REQUEST';
export const DELETE_CUSTOM_REQUEST = 'DELETE_CUSTOM_REQUEST';
export const UPDATE_CUSTOM_REQUEST = 'UPDATE_CUSTOM_REQUEST';
export const CUSTOM_REQUEST_SET_MODE = 'CUSTOM_REQUEST_SET_MODE';
export const CUSTOM_REQUEST_ADD_COMMENT = 'CUSTOM_REQUEST_ADD_COMMENT';
export const CUSTOM_REQUEST_GET_COMMENTS = 'CUSTOM_REQUEST_GET_COMMENTS';
export const CUSTOM_REQUEST_SELECTED_MARKER = 'CUSTOM_REQUEST_SELECTED_MARKER';
export const CUSTOM_REQUEST_REMOVE_MARKER = 'CUSTOM_REQUEST_REMOVE_MARKER';
export const CUSTOM_REQUEST_CAMERA_INFO = 'CUSTOM_REQUEST_CAMERA_INFO';
export const SET_LAST_DELETED_COMMENT_ID = 'SET_LAST_DELETED_COMMENT_ID';
export const RESET_CUSTOM_REQUEST_STORE = 'RESET_CUSTOM_REQUEST_STORE';

export interface GetCustomRequestsAction {
  type: typeof GET_CUSTOM_REQUESTS;
  payload: ICustomRequestRequest[];
}

export interface SetLastCommentIdAction {
  type: typeof SET_LAST_DELETED_COMMENT_ID;
  payload: ICustomRequestRequest[];
}

export interface CreateCustomRequestAction {
  type: typeof CREATE_CUSTOM_REQUEST;
  payload: ICustomRequest;
}

export interface GetCustomRequestAction {
  type: typeof GET_CUSTOM_REQUEST;
  payload: ICustomRequest;
}
export interface AddCustomRequestComment {
  type: typeof ADD_CUSTOM_REQUEST_COMMENT;
  payload: IAddCustomRequestComment;
}

export interface CustomRequestSetMode {
  type: typeof CUSTOM_REQUEST_SET_MODE;
  payload: CustomRequestMode;
}

export interface CloseCustomRequestAction {
  type: typeof CLOSE_CUSTOM_REQUEST;
  payload: ICustomRequest;
}

export interface DeleteCustomRequestAction {
  type: typeof DELETE_CUSTOM_REQUEST;
  payload: ICustomRequest;
}

export interface CustomRequestAddCommentAction {
  type: typeof CUSTOM_REQUEST_ADD_COMMENT;
  payload: ICustomRequestComment;
}

export interface CustomRequestUpdateCommentAction {
  type: typeof UPDATE_CUSTOM_REQUEST;
  payload: ICustomRequestComment;
}

export interface CustomRequestGetCommentsAction {
  type: typeof CUSTOM_REQUEST_GET_COMMENTS;
  payload: ICustomRequestComment[];
}

export interface CustomRequestSelectedMarkerAction {
  type: typeof CUSTOM_REQUEST_SELECTED_MARKER;
  payload: IMarker;
}

export interface CustomRequestCameraInfoAction {
  type: typeof CUSTOM_REQUEST_CAMERA_INFO;
  payload: any;
}

export interface CustomRequestRemoveMarker {
  type: typeof CUSTOM_REQUEST_REMOVE_MARKER;
  payload: IMarker;
}

export interface CustomRequestUpdateAction {
  type: typeof UPDATE_CUSTOM_REQUEST;
  payload: ICustomRequestComment;
}

export interface ResetCustomRequestStoreAction {
  type: typeof RESET_CUSTOM_REQUEST_STORE;
}

export type CustomRequestActionTypes =
  | GetCustomRequestsAction
  | CreateCustomRequestAction
  | AddCustomRequestComment
  | CustomRequestSetMode
  | CloseCustomRequestAction
  | DeleteCustomRequestAction
  | CustomRequestAddCommentAction
  | CustomRequestGetCommentsAction
  | CustomRequestSelectedMarkerAction
  | CustomRequestRemoveMarker
  | ResetCustomRequestStoreAction
  | CustomRequestUpdateCommentAction
  | GetCustomRequestAction
  | CustomRequestUpdateAction
  | CustomRequestCameraInfoAction
  | SetLastCommentIdAction;
