export enum AssetCategory {
  OUTLETS = 'Outlets',
  LIGHTING = 'Lighting',
  SWITCHES = 'Switches'
}

export const AssetCategoryProperties = {
  [AssetCategory.OUTLETS]: {
    title: 'tweaks.editor.outlets',
    selectAsset: 'tweaks.editor.selectAnOutlet',
    selectTarget: 'tweaks.editor.selectASpot',
    wrongTarget: 'tweaks.viewer.warning.addOutlet'
  },
  [AssetCategory.LIGHTING]: {
    title: 'tweaks.editor.lighting',
    selectAsset: 'tweaks.editor.selectALighting',
    selectTarget: 'tweaks.editor.selectASpotCeiling',
    wrongTarget: 'tweaks.viewer.warning.addLighting'
  },
  [AssetCategory.SWITCHES]: {
    title: 'tweaks.editor.switches',
    selectAsset: 'tweaks.editor.selectASwitch',
    selectTarget: 'tweaks.editor.selectASpotSwitch',
    wrongTarget: 'tweaks.viewer.warning.addSwitch'
  }
};

export interface IAsset {
  _id?: string;
  name: string;
  originalName: string;
  bucketKey: string;
  objectKey: string;
  urn: string;
  cost: number;
  thumbnailUrl: string;
  revitFamilyName: string;
  category: AssetCategory;
  socketAmount: number;
}
