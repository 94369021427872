import { IPaginatedResults } from 'types/models/Common';
import { IFile } from 'types/models/File';
import { IProject } from 'types/models/Project';

export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT_FILE = 'GET_PROJECT_FILE';
export const GET_PROJECT_FILES = 'GET_PROJECT_FILES';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const RESET_PROJECT_STORE = 'RESET_PROJECT_STORE';
export const LOADING_PROJECT = 'LOADING_PROJECT';
export const ADD_HOMES_TO_PROJECT = 'ADD_HOMES_TO_PROJECT';

export interface LoadingProjectAction {
  type: typeof LOADING_PROJECT;
}

export interface GetProjectAction {
  type: typeof GET_PROJECT;
  payload: IProject;
}

export interface GetProjectsAction {
  type: typeof GET_PROJECTS;
  payload: IPaginatedResults<IProject>;
}
export interface GetProjectFileAction {
  type: typeof GET_PROJECT_FILE;
  payload: IFile;
}

export interface GetProjectFilesAction {
  type: typeof GET_PROJECT_FILES;
  payload: IFile[];
}
export interface CreateProjectAction {
  type: typeof CREATE_PROJECT;
  payload: IProject;
}

export interface UpdateProjectAction {
  type: typeof UPDATE_PROJECT;
  payload: IProject;
}

export interface AddHomesToProjectAction {
  type: typeof ADD_HOMES_TO_PROJECT;
  payload: IProject;
}

export interface ResetProjectStoreAction {
  type: typeof RESET_PROJECT_STORE;
}

export type ProjectActionTypes =
  | GetProjectAction
  | GetProjectsAction
  | GetProjectFileAction
  | GetProjectFilesAction
  | CreateProjectAction
  | UpdateProjectAction
  | ResetProjectStoreAction
  | LoadingProjectAction
  | AddHomesToProjectAction;
