import { Alert, Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import AppCard from 'shared/core/AppCard';
import AppLoader from 'shared/core/AppLoader';
import IntlMessages from 'shared/utility/IntlMessages';

const ModelTemplateSupplierSummerySection = ({ data }) => {
  if (!data) {
    return (
      <Alert severity="error">
        <IntlMessages id="tweaks.defaultMaterials.missingConfig" />
      </Alert>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <IntlMessages id="common.cost" />
      </Grid>
      <Grid item xs={9}>
        <IntlMessages id="tweaks.currencySymbol" /> {data?.cost || '-'}
      </Grid>

      <Grid item xs={3}>
        <IntlMessages id="tweaks.defaultMaterials.bathroomWalls" />
      </Grid>
      <Grid item xs={9}>
        {data?.defaultMaterials?.bathroomWalls ? (
          <Chip
            variant="outlined"
            avatar={
              <Avatar
                alt={data.defaultMaterials.bathroomWalls.name}
                src={data.defaultMaterials.bathroomWalls.imgUrl}
              />
            }
            label={data.defaultMaterials.bathroomWalls.fullName}
          />
        ) : (
          '-'
        )}
      </Grid>

      <Grid item xs={3}>
        <IntlMessages id="tweaks.defaultMaterials.bathroomFloors" />
      </Grid>
      <Grid item xs={9}>
        {data?.defaultMaterials?.bathroomFloors ? (
          <Chip
            variant="outlined"
            avatar={
              <Avatar
                alt={data.defaultMaterials.bathroomFloors.name}
                src={data.defaultMaterials.bathroomFloors.imgUrl}
              />
            }
            label={data.defaultMaterials.bathroomFloors.fullName}
          />
        ) : (
          '-'
        )}
      </Grid>

      <Grid item xs={3}>
        <IntlMessages id="tweaks.defaultMaterials.floors" />
      </Grid>
      <Grid item xs={9}>
        {data?.defaultMaterials?.floors ? (
          <Chip
            variant="outlined"
            avatar={
              <Avatar
                alt={data.defaultMaterials.floors.name}
                src={data.defaultMaterials.floors.imgUrl}
              />
            }
            label={data.defaultMaterials.floors.fullName}
          />
        ) : (
          '-'
        )}
      </Grid>
    </Grid>
  );
};

const ModelTemplateSupplierSummary = ({ onEditClick, supplierInfo, suppliers }) => {
  if (supplierInfo === null) {
    return <AppLoader />;
  }

  const getSupplierInfo = (supplier) => {
    return supplierInfo.find((item) => item.supplier._id === supplier._id);
  };

  return (
    <AppCard
      action={
        <Button
          size="small"
          sx={{
            position: 'relative',
            minWidth: 60,
            padding: 0.5
          }}
          color="primary"
          variant="contained"
          onClick={onEditClick}>
          <IntlMessages id="actions.edit" />
        </Button>
      }
      title={<IntlMessages id="tweaks.modelTemplate.supplier.config" />}>
      {suppliers?.map((item, index) => (
        <Box key={index}>
          <Divider
            style={{
              paddingTop: index === 0 ? 0 : '8px'
            }}
          />
          <Box style={{ fontWeight: 'bold', padding: '8px 0' }}>{item.name}</Box>
          <ModelTemplateSupplierSummerySection data={getSupplierInfo(item)} />
        </Box>
      ))}
    </AppCard>
  );
};

export default ModelTemplateSupplierSummary;
