import { ISupplier, ITile } from 'types/models/Supplier';

export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SUPPLIER_PRODUCTS_BY_TYPE = 'GET_SUPPLIER_PRODUCTS_BY_TYPE';
export const RESET_SUPPLIER_STORE = 'RESET_SUPPLIER_STORE';
export const LOADING_SUPPLIER = 'LOADING_SUPPLIER';

export interface GetSuppliersAction {
  type: typeof GET_SUPPLIERS;
  payload: ISupplier[];
}

export interface GetSupplierProductsByTypeAction {
  type: typeof GET_SUPPLIER_PRODUCTS_BY_TYPE;
  payload: ITile[];
}

export interface ResetSupplierStoreAction {
  type: typeof RESET_SUPPLIER_STORE;
}

export interface LoadingSupplierAction {
  type: typeof LOADING_SUPPLIER;
}

export type SupplierActionTypes =
  | GetSuppliersAction
  | GetSupplierProductsByTypeAction
  | ResetSupplierStoreAction
  | LoadingSupplierAction;
