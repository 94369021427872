import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialogDialog } from 'redux/actions/Catelog';
import { AppState } from 'redux/store';
import { CatelogService } from 'services/catelog/catelog';
import { NumbersService } from 'services/numbers/number.service';
import { ViewerEvents } from 'shared/constants/AppConst';
import { LayoutDirection } from 'shared/constants/AppEnums';
import AppAnimate from 'shared/core/AppAnimate';
import IntlMessages from 'shared/utility/IntlMessages';
import { dispatch } from 'use-bus';

export const Catelog = () => {
  const { isOpen, catelogName } = useSelector<AppState, AppState['catelog']>(
    (state) => state.catelog
  );
  const theme = useTheme();
  const reduxDispatch = useDispatch();
  const catelogService = useRef(new CatelogService());

  if (!isOpen) return <></>;

  const catelogItems = catelogService.current.getCatelogItems();

  return (
    <AppAnimate key={catelogName}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: 64,
          left: 32,
          backgroundColor: 'white',
          width: 364,
          maxWidth: '100%',
          height: 500,
          maxHeight: '100%',
          borderRadius: 3,
          ...(theme.direction === LayoutDirection.LTR
            ? { paddingLeft: '16px' }
            : { paddingRight: '16px' })
        }}>
        <ClickAwayListener
          onClickAway={() => {
            reduxDispatch(closeDialogDialog());
          }}>
          <Box
            sx={{
              position: 'relative',
              width: 'inherit',
              height: 'inherit',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '100%'
            }}>
            <IconButton
              sx={{
                position: 'absolute',
                right: 2,
                top: 6,
                '& > .MuiSvgIcon-root': {
                  color: 'text.primary'
                }
              }}
              onClick={() => {
                reduxDispatch(closeDialogDialog());
              }}>
              <Close />
            </IconButton>

            <Box display={'flex'} flexDirection={'column'} flex={1}>
              <Typography
                fontSize={16}
                lineHeight={'17px'}
                mt={5}
                mb={7}
                marginLeft={theme.direction === LayoutDirection.RTL ? 4 : 0}>
                <IntlMessages id={`tweaks.upgrades.${catelogName}`} />
              </Typography>

              {catelogItems.length === 0 ? (
                <Box marginLeft={theme.direction === LayoutDirection.RTL ? 4 : 0}>
                  <IntlMessages id="common.notFound" />
                </Box>
              ) : (
                <Scrollbars style={{ flex: 1 }} dir="ltr">
                  <Box
                    flex={1}
                    sx={{
                      marginInlineEnd: 4,
                      marginBlockEnd: 2
                    }}>
                    <Grid
                      height={'inherit'}
                      container
                      spacing={2}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        boxSizing: 'border-box'
                      }}>
                      {catelogService.current.getCatelogItems().map((item, index) => (
                        <Grid key={index} item xs={6}>
                          <Button
                            sx={{
                              color: 'text.primary',
                              padding: 0,
                              width: '100%',
                              height: '100%',
                              transition: 'all 0.3s ease',
                              textAlign: 'left',
                              ':hover': {
                                filter: 'brightness(0.9)'
                              }
                            }}
                            onClick={() => {
                              dispatch({
                                type: ViewerEvents.AddElement,
                                payload: item
                              });
                              reduxDispatch(closeDialogDialog());
                            }}>
                            <Box
                              sx={{
                                width: '100%',
                                '& > .MuiBox-root:first-child': {
                                  height: 140,
                                  backgroundColor: '#F5F5F5',
                                  width: '100%',
                                  border: '1px solid #D9D9D9',
                                  borderBottom: 0,
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4
                                },
                                '& > .MuiBox-root:last-child': {
                                  backgroundColor: 'white',
                                  width: '100%',
                                  height: 44,
                                  border: '1px solid #D9D9D9',
                                  borderTop: 0,
                                  borderBottomLeftRadius: 4,
                                  borderBottomRightRadius: 4,
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  paddingInlineStart: 3,
                                  paddingInlineEnd: 4
                                }
                              }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  '& > img': {
                                    objectFit: 'contain'
                                  }
                                }}>
                                <img src={item.image} alt="" width={'100%'} />
                              </Box>
                              <Box>
                                <Typography fontSize={14} lineHeight={'15px'} fontWeight="bold">
                                  {item.name}
                                </Typography>
                                <Typography fontSize={14} lineHeight={'15px'} fontWeight="bold">
                                  {NumbersService.formatNumberToCurrency(item.price)}
                                </Typography>
                              </Box>
                            </Box>
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Scrollbars>
              )}
            </Box>
          </Box>
        </ClickAwayListener>
      </Box>
    </AppAnimate>
  );
};
