import useBus from 'use-bus';
import { CONTEXTS, EVENT_TYPES, ViewerEvents } from 'shared/constants/AppConst';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import BIService from 'shared/utility/BIService';
import { CloseOutlined } from '@mui/icons-material';

const ValidationMessage = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = (snackbarId) => (
    <>
      <IconButton
        key="close"
        aria-label="close"
        color="inherit"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
        size="small">
        <CloseOutlined fontSize="small" />
      </IconButton>
    </>
  );

  useBus(
    ViewerEvents.ValidationMessage,
    (e) => {
      enqueueSnackbar(e.payload.message, {
        variant: e.payload.type,
        autoHideDuration: e.payload.duration || 6000,
        action,
        // preventDuplicate: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
        // style: {
        //   whiteSpace: 'pre-line',
        //   background: 'linear-gradient( #ff5900 9%, #FFEEE5 10%)',
        //   width: '340px',
        //   height: '63px',
        //   color: '#5A55F4',
        //   fontWeight: 500,
        //   fontSize: '14px',
        //   lineHeight: '20px',
        //   borderRadius: '8px'
        // }
      });

      const biService = BIService.getInstance();
      biService.logEvent(EVENT_TYPES.SHOW_MESSAGE, CONTEXTS.MODEL, {
        message_type: e.payload.type,
        message: e.payload.message
      });
    },
    []
  );
  return <div></div>;
};

export default ValidationMessage;
