import Box from '@mui/material/Box';
import AppLogo from 'shared/core/AppLayout/components/AppLogo';
import Card from '@mui/material/Card';
import AppAnimate from 'shared/core/AppAnimate';
import IntlMessages from 'shared/utility/IntlMessages';
import { Params, useParams, useNavigate } from 'react-router-dom';
import SetFirstPasswordForm from './SetFirstPasswordForm';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { IInviteData, getInviteData } from 'services/invite/invite';
import './styles.css';
import AppLngSwitcher from 'shared/core/AppLngSwitcher';

const SetFirstPassword = () => {
  const { id } = useParams<Readonly<Params>>();
  const [inviteData, setInviteData] = useState<IInviteData>(null);
  const navigate = useNavigate();

  useEffect(() => {
    getInviteData(id).then((data) => {
      setInviteData(data);
    });
  }, []);

  useEffect(() => {
    if (inviteData && !inviteData?.isTempPassword) navigate('/signin');
  }, [inviteData]);

  if (inviteData?.isTempPassword) {
    return (
      <>
        <Box
          sx={{
            ml: 4,
            '& button': { backgroundColor: 'white' }
          }}>
          <AppLngSwitcher iconOnly={true} tooltipPosition="bottom" />
        </Box>
        <AppAnimate animation="transition.slideUpIn" delay={200}>
          <Box
            sx={{
              pb: 6,
              py: { xl: 4 },
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Card
              sx={{
                maxWidth: 450,
                width: '100%',
                textAlign: 'center',
                padding: { xs: 8, lg: 12, xl: '48px 64px' },
                overflow: 'hidden',
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
              }}>
              <Typography
                sx={{
                  pt: 3,
                  fontSize: 15,
                  color: 'grey.500'
                }}>
                <span>
                  <IntlMessages id="first.password.goodToSeeYou" />
                </span>
                <Box component="span">{inviteData.userDisplayName}</Box>
              </Typography>

              <Box
                sx={{
                  fontSize: 20,
                  marginTop: '1em'
                }}>
                <IntlMessages id="dashboard.analytics.welcome.to" />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <AppLogo />
                </Box>
              </Box>

              <Typography
                sx={{
                  pt: 3,
                  fontSize: 15,
                  color: 'grey.500'
                }}>
                <Box component="span">{inviteData.builderName}</Box>
                <span>
                  <IntlMessages id="first.password.invitedYou" />
                </span>
              </Typography>

              <Typography
                sx={{
                  pt: 3,
                  fontSize: 15,
                  color: 'grey.500',
                  marginBottom: '1em'
                }}>
                <span>
                  <IntlMessages id="first.password.number" />
                </span>
                <Box
                  component="span"
                  color="primary.main"
                  sx={{
                    width: '100%',
                    height: 44
                  }}>
                  {inviteData.homeNumber}
                </Box>
                <span>
                  <IntlMessages id="first.password.locatedAt" />
                </span>
                <Box
                  component="span"
                  color="primary.main"
                  sx={{
                    width: '100%',
                    height: 44
                  }}>
                  {inviteData.address}
                </Box>
              </Typography>

              <SetFirstPasswordForm
                inviteId={id}
                onSuccess={() => {
                  navigate('/signIn');
                }}
              />
            </Card>
          </Box>
        </AppAnimate>
      </>
    );
  }
};

export default SetFirstPassword;
