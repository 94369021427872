import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import {
  ModelTemplateSupplierInfoRequest,
  saveModelTemplateSupplierInfo
} from 'redux/actions/Model';
import { getAdminSupplierProductsByType } from 'redux/actions/Supplier';
import ModelTemplateSupplierForm from './ModelTemplateSupplierForm';

const ModelTemplateSupplierFormWrapper = ({
  supplier,
  modelTemplateSupplierInfo,
  modelTemplate,
  onSave
}) => {
  const [bathroomWallOptions, setBathroomWallOptions] = useState([]);
  const [bathroomFloorOptions, setBathroomFloorOptions] = useState([]);
  const [floorOptions, setFloorOptions] = useState([]);

  useEffect(() => {
    const wallTiles = [
      getAdminSupplierProductsByType(supplier._id, 'wallTiles', 'wet'),
      getAdminSupplierProductsByType(supplier._id, 'wallTiles', 'dry')
    ];
    Promise.all(wallTiles).then((data) => {
      setBathroomWallOptions(data.flat());
    });

    getAdminSupplierProductsByType(supplier._id, 'floorTiles', 'wet').then((data) => {
      setBathroomFloorOptions(data);
    });
    getAdminSupplierProductsByType(supplier._id, 'floorTiles', 'dry').then((data) => {
      setFloorOptions(data);
    });
  }, [supplier]);

  return (
    <Formik
      enableReinitialize
      validateOnBlur={true}
      initialValues={{
        cost: modelTemplateSupplierInfo?.cost || 0,
        bathroomWalls: modelTemplateSupplierInfo?.defaultMaterials?.bathroomWalls || '',
        bathroomFloors: modelTemplateSupplierInfo?.defaultMaterials?.bathroomFloors || '',
        floors: modelTemplateSupplierInfo?.defaultMaterials?.floors || ''
      }}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true);

        const reqData: ModelTemplateSupplierInfoRequest = {};
        reqData.cost = data.cost;
        reqData.defaultMaterials = {};
        reqData.defaultMaterials.bathroomFloors = data.bathroomFloors?._id || null;
        reqData.defaultMaterials.bathroomWalls = data.bathroomWalls?._id || null;
        reqData.defaultMaterials.floors = data.floors?._id || null;

        if (!modelTemplateSupplierInfo?._id) {
          reqData.supplier = supplier._id;
        }

        saveModelTemplateSupplierInfo(
          modelTemplate._id,
          reqData,
          modelTemplateSupplierInfo?._id
        ).then(() => {
          onSave();
        });
      }}>
      {({ values, setFieldValue, isSubmitting }) => {
        return (
          <ModelTemplateSupplierForm
            supplier={supplier}
            values={values}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            options={{
              bathroomWalls: bathroomWallOptions,
              bathroomFloors: bathroomFloorOptions,
              floors: floorOptions
            }}
          />
        );
      }}
    </Formik>
  );
};

export default ModelTemplateSupplierFormWrapper;
