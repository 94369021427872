import {
  CLOSE_CATALOG__DIALOG,
  CatelogActionTypes,
  CatelogName,
  OPEN_CATALOG_DIALOG
} from 'types/actions/Catelog.actions';

const initialState: {
  isOpen: boolean;
  catelogName?: CatelogName;
} = {
  isOpen: false,
  catelogName: undefined
};

const CatelogDialog = (state = initialState, action: CatelogActionTypes) => {
  switch (action.type) {
    case OPEN_CATALOG_DIALOG:
      return {
        ...state,
        isOpen: true,
        catelogName: action.payload.catelogName
      };

    case CLOSE_CATALOG__DIALOG:
      return {
        ...state,
        isOpen: false,
        catelogName: undefined
      };

    default:
      return state;
  }
};
export default CatelogDialog;
