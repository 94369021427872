import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { SvgIconProps, useTheme } from '@mui/material';
import { LayoutDirection } from 'shared/constants/AppEnums';

export const DirectionChevronRight = (props: SvgIconProps) => {
  const theme = useTheme();

  if (theme.direction === LayoutDirection.RTL) {
    return <ChevronLeft {...props} />;
  }

  return <ChevronRight {...props} />;
};
