import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ViewerEvents } from 'shared/constants/AppConst';
import { useAuthUser } from 'shared/utility/AuthHooks';
import useBus, { dispatch } from 'use-bus';

export const usePreviewMode = () => {
  const location = useLocation();
  const [previewMode, setPreviewMode] = useState(true);
  const authData = useAuthUser();

  useBus(ViewerEvents.PreviewModeOn, () => setPreviewMode(true), []);
  useBus(ViewerEvents.PreviewModeOff, () => setPreviewMode(false), []);

  const _setPreviewMode = (_previewMode: boolean) => {
    dispatch(_previewMode ? ViewerEvents.PreviewModeOn : ViewerEvents.PreviewModeOff);
  };

  useEffect(() => {
    if (!localStorage.getItem('previewMode')) {
      if (location.pathname) {
        const isInUnitPage = location.pathname.includes('units');
        setPreviewMode(isInUnitPage);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (authData?.user?.role === 'owner') {
      localStorage.setItem('previewMode', previewMode ? 'true' : 'false');
    }
  }, [previewMode, authData.user.role]);

  return [previewMode, _setPreviewMode] as const;
};
