import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import {
  GET_MODEL,
  GET_MODEL_MODEL_TEMPLATES,
  RESET_MODEL_STORE,
  LOADING_MODEL,
  GET_MODEL_TEMPLATE
} from 'types/actions/Model.actions';
import { fetchError, fetchSuccess } from './Common';
import jwtAxios from 'services/auth/jwt-auth';
import { IRoomPackage } from 'pages/projects/projectDetail/RoomsList/RoomListItem';

export const getModel = (id: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: LOADING_MODEL });
    jwtAxios
      .get(`/models/${id}`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_MODEL, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getModelTemplate = (id: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get(`/model-templates/${id}`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_MODEL_TEMPLATE, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const getModelTemplates = (id: string) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get(`/models/${id}/model-templates`)
      .then((data) => {
        dispatch(fetchSuccess());
        dispatch({ type: GET_MODEL_MODEL_TEMPLATES, payload: data.data });
      })
      .catch((error) => {
        dispatch(fetchError(error.message));
      });
  };
};

export const resetModelStore = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: RESET_MODEL_STORE });
  };
};

export const setDefaultModelTemplate = (id) => {
  return jwtAxios.put(`/model-templates/${id}/set-default`);
};

export const updateModelTemplate = (
  id,
  data: { title: string; cost: number; description: string; imageUrl: string }
) => {
  return jwtAxios.put(`/model-templates/${id}`, data);
};

export const updateModelTemplatePackages = (
  id,
  data: {
    title: string;
    cost: number;
    description: string;
    packages: IRoomPackage[];
  }
) => {
  return jwtAxios.put(`/model-templates/${id}`, data);
};

export const updateModelTemplatePackage = (
  modelTemplateId: string,
  packageId: string,
  packageData: IRoomPackage
) => {
  if (packageId === undefined) {
    packageId = '';
  }

  return jwtAxios.put(
    `/model-templates/${modelTemplateId}/update-package/${packageId}`,
    packageData
  );
};

export const getModelTemplateImageUploadLink = (
  id
): Promise<{ signedUrl: string; fileUrl: string }> => {
  return jwtAxios.get(`/model-templates/${id}/thumbnail-upload-link`).then((response) => {
    return response.data;
  });
};

export const getAllModelTemplateSupplierInfo = (id) => {
  return jwtAxios.get(`/model-templates/${id}/supplier-info`).then((response) => {
    return response.data;
  });
};

export interface ModelTemplateSupplierInfoRequest {
  supplier?: string;
  cost?: number;
  defaultMaterials?: {
    bathroomWalls?: string;
    bathroomFloors?: string;
    floors?: string;
  };
}

export const saveModelTemplateSupplierInfo = (
  modelTemplateId,
  data: ModelTemplateSupplierInfoRequest,
  modelTemplateSupplierInfoId = null
) => {
  if (modelTemplateSupplierInfoId) {
    return jwtAxios
      .put(`/model-templates/${modelTemplateId}/supplier-info/${modelTemplateSupplierInfoId}`, data)
      .then((response) => {
        return response.data;
      });
  } else {
    return jwtAxios
      .post(`/model-templates/${modelTemplateId}/supplier-info`, data)
      .then((response) => {
        return response.data;
      });
  }
};

export const getPackageCategoriesOptions = (
  query: string
): Promise<{ [key: string]: string[] }> => {
  return jwtAxios.get(`/model-templates/get-package-category-options/${query}`).then((r) => r.data);
};
