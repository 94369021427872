import { Dispatch } from 'redux';
import { AppActions } from '../../types';
import jwtAxios from 'services/auth/jwt-auth';
import {
  GET_CUSTOM_REQUESTS,
  CREATE_CUSTOM_REQUEST,
  CLOSE_CUSTOM_REQUEST,
  CUSTOM_REQUEST_GET_COMMENTS,
  CUSTOM_REQUEST_SET_MODE,
  CUSTOM_REQUEST_SELECTED_MARKER,
  CUSTOM_REQUEST_REMOVE_MARKER,
  GET_CUSTOM_REQUEST,
  ADD_CUSTOM_REQUEST_COMMENT,
  CUSTOM_REQUEST_CAMERA_INFO,
  UPDATE_CUSTOM_REQUEST
} from 'types/actions/CustomRequest.actions';

import {
  CustomRequestMode,
  IAddCustomRequestComment,
  ICustomRequestComment,
  ICustomRequestRequest
} from 'types/models/CustomRequest';
import { IMarker } from 'shared/extensions/tweaks-markers';
import BIService from 'shared/utility/BIService';
import { CONTEXTS, EVENT_TYPES } from 'shared/constants/AppConst';

export const getCustomRequests = (homeId) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get(`/custom-requests/all/${homeId}`)
      .then((data) => {
        dispatch({ type: GET_CUSTOM_REQUESTS, payload: data.data });
      })
      .catch((error) => {
        console.warn('error fetching custom requests', error);
      });
  };
};

export const createCustomRequest = (data: ICustomRequestRequest, callback?: (data) => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post(`/custom-requests/new`, data)
      .then((data) => {
        dispatch({ type: CREATE_CUSTOM_REQUEST, payload: data.data });
        const res = data.data;
        if (callback) callback(res);
      })
      .catch((error) => {
        console.warn('error creating custom request', error);
      });
  };
};

export const closeCustomRequest = (customRequestId, callback?: () => void) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .put(`/custom-requests/${customRequestId}/close`)
      .then((data) => {
        dispatch({ type: CLOSE_CUSTOM_REQUEST, payload: data.data });
        if (callback) callback();
      })
      .catch((error) => {
        console.warn('error closing custom request', error);
      });
  };
};

export const getCustomRequestComments = (customRequestId) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get(`/custom-requests/${customRequestId}/comments`)
      .then((data) => {
        dispatch({ type: CUSTOM_REQUEST_GET_COMMENTS, payload: data.data });
      })
      .catch((error) => {
        console.warn('error fetching custom request comments', error);
      });
  };
};

export const getCustomRequest = (requestId) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .get(`/custom-requests/one/${requestId}`)
      .then((data) => {
        dispatch({ type: GET_CUSTOM_REQUEST, payload: data.data });
      })
      .catch((error) => {
        console.warn('error fetching custom request', error);
      });
  };
};
export const addCustomRequestComment = (
  requestId,
  comment: IAddCustomRequestComment,
  callback?: () => void
) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post(`/custom-requests/${requestId}/add-comment`, comment)
      .then((data) => {
        dispatch({ type: ADD_CUSTOM_REQUEST_COMMENT, payload: data.data });
        if (callback) callback();
      })
      .catch((error) => {
        console.warn('error adding a custom request comment', error);
      });
  };
};

export const setSelectedMarker = (marker: IMarker) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: CUSTOM_REQUEST_SELECTED_MARKER, payload: marker });
  };
};

export const setCameraInfo = (cameraInfo) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: CUSTOM_REQUEST_CAMERA_INFO, payload: cameraInfo });
  };
};

export const setCustomRequestViewMode = (mode: CustomRequestMode | null) => {
  if (mode != 'View') {
    const biService = BIService.getInstance();
    biService.logEvent(EVENT_TYPES.TOOL_CLICKED, CONTEXTS.TOOLS, {
      tool_type: 'custom_request',
      is_active: mode === 'Edit' ? true : false
    });
  }
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: CUSTOM_REQUEST_SET_MODE, payload: mode });
  };
};

export const removeMarker = (marker: IMarker) => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: CUSTOM_REQUEST_REMOVE_MARKER, payload: marker });
  };
};

export const updateCustomRequestComment = (comment: ICustomRequestComment) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .post(`/custom-requests/${comment._id}/update-comment`, {
        comment: comment.comment
      })
      .then(() => {
        dispatch({ type: UPDATE_CUSTOM_REQUEST, payload: comment });
      })
      .catch((error) => {
        console.warn('error fetching custom requests', error);
      });
  };
};

export const deleteCustomRequest = (marker: IMarker) => {
  return (dispatch: Dispatch<AppActions>) => {
    jwtAxios
      .delete(`/custom-requests/${marker._id}`)
      .then((data) => {
        dispatch({ type: CUSTOM_REQUEST_REMOVE_MARKER, payload: marker });
      })
      .catch((error) => {
        console.warn('error fetching custom requests', error);
      });
  };
};
