import { useEffect, useState } from 'react';
import jwtAxios from 'services/auth/jwt-auth';

const useTranslationProgress = (modelTemplateId: string, interval = 10000) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress >= 100) {
      return; // Exit if progress is already 100
    }

    console.log('useTranslationProgress', modelTemplateId);

    const fetchProgress = async () => {
      try {
        const response = await jwtAxios.get(
          `model-templates/${modelTemplateId}/getTranslationProgress`
        );

        // Assuming response.data is the progress value, otherwise extract it accordingly
        const newProgress = parseFloat(response.data);
        setProgress(newProgress);

        // If progress is 100, clear the interval
        if (newProgress >= 100 && intervalId) {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error fetching translation progress:', error);
      }
    };

    // Initial fetch
    fetchProgress();

    // Polling
    const intervalId = setInterval(fetchProgress, interval);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [modelTemplateId, interval, progress]); // Added progress to dependency array

  return progress;
};

export default useTranslationProgress;
